import {format} from 'date-fns';

import {emptyOption} from '../components/common/inputs/select/options';
import {
  positiveIntegerOnly,
  positiveNumberWithTwoDecimalPlaces,
} from '../handsontable/customValidators';
import {editableColumnProps} from './editableTable';

export interface ReportItem {
  amountOtherCurrency: number | null;
  amountPhp: number | null;
  balanceDate: Date | null;
  balanceType: string;
  comment: string;
  contingentBalance: number | null;
  currencyType: string;
  currentBalance: number | null;
  dateGranted: Date | null;
  dueDate: Date | null;
  experienceCode: string;
  expiryDate: Date | null;
  facilityCode: string;
  facilityNo: string | null;
  inquiryDate: Date | null;
  itemsInLitigation: number | null;
  pastDueBalance: number | null;
  respondingBankFi: string;
  securityCode: string;
  securityName: string;
}

type radioType = 'Yes' | 'No' | null;

export const inquiringBankOptions = [
  emptyOption,
  {
    label: 'First Circle Growth Finance Corp.',
    value: 'First Circle Growth Finance Corp.',
  },
];

export interface LoanDex {
  aliasName: string;
  companyId: string | null;
  createdAt: Date | null;
  dtiNumber: string;
  entityName: string;
  entityType: string;
  gsisNumber: string;
  hasNoFindings: radioType;
  inquiredOn: Date | null;
  inquiringBankName: string;
  inquiringBankNumber: number | null;
  leadId: number | null;
  ownershipShare: number | null; // percent
  personId: string | null;
  report: ReportItem[];
  reportGenerator: string;
  riskopsNotes: string;
  secNumber: string;
  sssNumber: string;
  status: string;
  subjectNumber: number | null;
  tin: string;
  validUntil: Date | null;
}

export const loanDexDefault: LoanDex = {
  aliasName: '',
  companyId: null,
  createdAt: null,
  dtiNumber: '',
  entityName: '',
  entityType: '',
  gsisNumber: '',
  hasNoFindings: null,
  leadId: null,
  inquiredOn: null,
  inquiringBankName: '',
  inquiringBankNumber: null,
  ownershipShare: null,
  personId: null,
  report: [],
  reportGenerator: '',
  riskopsNotes: '',
  secNumber: '',
  sssNumber: '',
  status: '',
  subjectNumber: null,
  tin: '',
  validUntil: null,
};

export const reportItemColumns: editableColumnProps[] = [
  {
    name: 'Inquiry Date',
    config: {
      data: 'inquiryDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Due Date',
    config: {
      data: 'dueDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Responding Bank FI',
    config: {
      data: 'respondingBankFi',
    },
  },
  {
    name: 'Facility Code',
    config: {
      data: 'facilityCode',
    },
  },
  {
    name: 'Facility Number',
    config: {
      data: 'facilityNo',
      allowInvalid: false,
      allowEmpty: true,
      validator: positiveIntegerOnly,
    },
  },
  {
    name: 'Amount (PHP)',
    config: {
      data: 'amountPhp',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Amount (Other Currency)',
    config: {
      data: 'amountOtherCurrency',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Currency Type',
    config: {
      data: 'currencyType',
    },
  },
  {
    name: 'Balance Type',
    config: {
      data: 'balanceType',
    },
  },
  {
    name: 'Current Balance',
    config: {
      data: 'currentBalance',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Contingent Balance',
    config: {
      data: 'contingentBalance',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Past Due Balance',
    config: {
      data: 'pastDueBalance',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Items in Litigation',
    config: {
      data: 'itemsInLitigation',
      numericFormat: {
        pattern: '0',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Balance Date',
    config: {
      data: 'balanceDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
    },
  },
  {
    name: 'Security Code',
    config: {
      data: 'securityCode',
    },
  },
  {
    name: 'Security Name',
    config: {
      data: 'securityName',
    },
  },
  {
    name: 'Date Granted',
    config: {
      data: 'dateGranted',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Expiry Date',
    config: {
      data: 'expiryDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Experience Code',
    config: {
      data: 'experienceCode',
    },
  },
  {
    name: 'Comment',
    config: {
      data: 'comment',
    },
  },
];
