import {isNil, kebabCase} from 'lodash-es';
import React from 'react';

import {SelectOption} from '../../../interfaces/application';

interface Option {
  label: string;
  value: boolean;
}
type Value = boolean | null;

export interface Props {
  name: string;
  defaultValue?: boolean;
  error?: string;
  label?: string;
  options: Option[];
  required?: boolean;
  classes?: string;
  clearable?: boolean;
  onChange?: (event: boolean | null) => void;
}

const Radio: React.FC<Props> = ({
  defaultValue,
  name,
  error,
  label,
  options,
  required,
  classes,
  clearable,
  onChange,
}: Props) => {
  const defaultVal = isNil(defaultValue) ? null : defaultValue;

  const handleChange = (value: boolean) => {
    if (isNil(onChange)) return;

    if (defaultValue === value && clearable) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  const renderOption = (choice: Option) => {
    const {value, label} = choice;

    return (
      <div key={`${label}-value`} className="mx-1">
        <input
          type="radio"
          name={name}
          data-testid={`${kebabCase(name)}-${value}`}
          id={`${kebabCase(name)}-${value}`}
          onChange={() => {
            handleChange(value);
          }}
          onClick={() => {
            if (!required) {
              handleChange(value);
            }
          }}
          className="mx-1"
          checked={defaultVal === value}
        />
        <label htmlFor={`${kebabCase(name)}-${value}`}>{label}</label>
      </div>
    );
  };

  const errorClass = !isNil(error) ? '_error' : '';

  const renderOptions = () => {
    return options.map((option: SelectOption) => {
      return renderOption(option);
    });
  };

  const renderLabel = () => {
    if (isNil(label)) return;
    return (
      <label
        className="flex block tracking-wide text-xs font-bold mb-2"
        htmlFor={`grid-${name}`}
      >
        <span className="mr-1 uppercase">{label}</span>
        {required ? (
          <span className="text-red-500">*</span>
        ) : (
          <span className="text-gray-300">(optional)</span>
        )}
      </label>
    );
  };

  return (
    <div className={`${classes}`}>
      {renderLabel()}
      <div className={`grid grid-cols-${options.length}`}>
        {renderOptions()}
      </div>
      {!isNil(error) && (
        <div className="text-red-500 mt-3 text-xs italic">{error}</div>
      )}
    </div>
  );
};

export default Radio;
