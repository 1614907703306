import {datadogRum} from '@datadog/browser-rum';
import {RouteComponentProps, Router} from '@reach/router';
import {buildRoutes} from '@src/constants/routes';
import ApplicationContext from '@src/contexts/ApplicationContext';
import {isNil} from 'lodash-es';
import React, {useContext} from 'react';

import useFetchUserData from '../hooks/useFetchUserData';
import ErrorBoundary from './common/errors/ErrorBoundary';
import PageNotFound from './common/errors/PageNotFound';
import UnauthenticatedAccess from './common/errors/UnauthenticatedAccess';
import ResourceLoader from './common/loaders/ResourceLoader';
import ApiCallNotice from './common/notices/ApiCallNotice';

interface RouteProps {
  path: string;
  component: React.ReactNode;
}

const Route = ({component, path, ...rest}: RouteProps) => {
  const Component = component as React.FC<RouteComponentProps>;

  return <Component path={path} {...rest} />;
};

const Root: React.FC<RouteComponentProps> = () => {
  const {currentUser, permissions, fetchingUserAccessData} = useContext(ApplicationContext);

  // Fetch current user and lead data
  const {
    hasEncounteredError,
    needsToAuthenticate,
    errorMessage,
  } = useFetchUserData();

  if (fetchingUserAccessData || isNil(currentUser) || currentUser.id === 0) return <ResourceLoader resource="User" />;
  if (needsToAuthenticate) return <UnauthenticatedAccess />;
  if (hasEncounteredError) {
    <ApiCallNotice advance type="error" errorMessage={errorMessage} />;
  }

  // Start recording DataDog Session Replay
  datadogRum.startSessionReplayRecording();

  const routes = buildRoutes(false, currentUser, permissions);

  return (
    <div>
      <ErrorBoundary>
        <Router>
          {routes.map((route: RouteProps) => {
            return <Route key={route.path} {...route} />;
          })}
          <PageNotFound default />
        </Router>
      </ErrorBoundary>
    </div>
  );
};

export default Root;
