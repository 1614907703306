import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_CMAP = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      createdAt
      id
      leadId
      status
      ... on CMAPReport {
        companyId
        entityName
        entityType
        hasNoFindings
        inquiryDate
        notes
        personId
        courtCaseFindings {
          defendantNames
          natureOfCase
          plaintiff
          trialCourtCode
          provinceCode
          salaNumber
          caseNumber
          reportedDate
          caseStatus
        }
        validUntil
      }
    }
  }
`;

export function useFetchCmap(onSuccessCallback: any) {
  return useLazyQuery(FETCH_CMAP, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
