/* eslint-disable no-undef */
import React from 'react';

import ApiCallNotice from '../notices/ApiCallNotice';

interface OwnProps {
  children: React.ReactElement | React.ReactElement[];
}

type Props = OwnProps;

interface States {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, States> {
  public state: States = {
    hasError: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {...this.state};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  // Send post request error
  /* eslint-disable @typescript-eslint/no-explicit-any */
  componentDidCatch(error: any): null {
    return null;
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    // You can render any custom fallback UI
    return (
      <ApiCallNotice
        advance
        type="error"
        errorMessage={'Something went wrong'}
      />
    );
  }
}

export default ErrorBoundary;
