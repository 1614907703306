import React from 'react';
import {FaSpinner} from 'react-icons/fa';

interface Props {
  type:
    | 'primary'
    | 'bordered_primary'
    | 'secondary'
    | 'transparent'
    | 'danger'
    | 'verifying'
    | 'draft'; // Add more types here
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  micro?: boolean;
  dataTestId: string;
}

const Button: React.FC<Props> = ({
  type,
  label,
  className,
  onClick,
  disabled,
  isLoading,
  micro,
  dataTestId,
}) => {
  const baseClass =
    'inline-flex px-4 text-center items-center justify-center text-sm font-bold content-center rounded h-10';
  let classes = '';

  switch (type) {
    case 'primary':
      classes = 'bg-primary-500 text-white hover:bg-primary-400';
      break;
    case 'draft':
      classes = 'bg-fcGray text-white hover:bg-primary-400';
      break;
    case 'verifying':
      classes = 'bg-verifying text-white hover:bg-primary-400';
      break;
    case 'bordered_primary':
      classes =
        'bg-transparent text-primary-500 hover:bg-primary-500 hover:text-white border border-primary-500';
      break;
    case 'secondary':
      classes =
        'bg-fcSecondary-300 hover:bg-transparent hover:text-primary-500 hover:border-secondary-200 hover:border-secondary-400 border border-secondary text-primary-500';
      break;
    case 'transparent':
      classes =
        'bg-white hover:bg-transparent hover:text-primary-500 hover:border-secondary-400 hover:border-secondary-400 border border-secondary text-primary-500';
      break;
    case 'danger':
      classes =
        'hover:bg-red-600 bg-red-600 hover:border-sec-400 hover:border-red-600 border hover:border-red-600  border border-red-600 text-white';
      break;
    default:
      classes = 'bg-primary-500 hover:bg-primary-400';
  }

  classes = `${baseClass} ${classes} ${className}`;

  if (micro) {
    classes = `${classes} text-xs`;
  }

  if (isLoading) {
    switch (type) {
      case 'primary':
        classes = `${baseClass} text-white bg-primary-300`;
        break;
      case 'danger':
        classes = `${baseClass} text-white bg-red-300`;
        break;
      default:
        classes = `${baseClass} text-white bg-primary-300`;
    }
  }

  if (disabled) {
    classes = `${classes} bg-primary-300`;
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <>
          <div className="animate-spin mr-2">
            <FaSpinner />
          </div>
          <span>{label}</span>
        </>
      ) : (
        <span>{label}</span>
      )}
    </button>
  );
};

export default Button;
