import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {requiredStringField} from './common/fields';

const positiveNumberMessage = 'Value must be a positive number';

export const buildValidationSchema = () => {
  const shape = {
    amountLast12Months: yup.number().positive(positiveNumberMessage).nullable(),
    status: requiredStringField({attribute: 'Document Status'}),
    documentFormat: requiredStringField({attribute: 'Document Format'}),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
