import {
  debounce,
  isEmpty,
  isNil,
  isNull,
  isUndefined,
  toString,
} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateBankStatement} from '../../../../../api/mutations/bankStatement';
import {useFetchBankOptions} from '../../../../../api/queries/bankOptions';
import {useFetchBankStatement} from '../../../../../api/queries/bankStatement';
import DataTableToggle from '../../../../../components/common/inputs/DataTableToggle';
import {
  currencyOptions,
  documentStatusOptions,
} from '../../../../../components/common/inputs/select/options';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {
  accountTypeOptions,
  bankLineItemColumns,
  bankStatementDefault,
  buildBankOptions,
  documentSourceOptions,
  informationSourceOptions,
} from '../../../../../interfaces/bankStatement';
import {BankLineItem} from '../../../../../interfaces/bankStatement';
import {resubmissionReasonOptions} from '../../../../../interfaces/bankStatement';
import {isArrayEmpty} from '../../../../../utils/array';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/BankStatement';
import Button from '../../../buttons/Button';
import Datepicker from '../../../inputs/Datepicker';
import EditableTable from '../../../inputs/EditableTable';
import DynamicSelect from '../../../inputs/Select';
import TextInput from '../../../inputs/TextInput';
import StaticLink from '../../../StaticLink';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const BankStatementForm: React.FC = () => {
  const {lead, permissions} = useContext(ApplicationContext);
  const [firstUpdate, setFirstUpdate] = useState<boolean>(false);
  const [bankSearchTerm, setBankSearchTerm] = useState<
    string | null | undefined
  >(null);
  const {selectedDocument} = useContext(DocumentsContext);
  const [tableToggle, setTableToggle] = useState('');

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');
    const {bankId} = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
    });

    if (!isNil(bankId)) setBankSearchTerm(toString(bankId));
    setFirstUpdate(true);
  };

  const [
    fetchBankStatement,
    {
      loading: fetchingBankStatement,
      error: fetchBankStatementErrors,
      data: BankStatementData,
    },
  ] = useFetchBankStatement(onFetchSuccess);

  useEffect(() => {
    if (isNull(selectedDocument.id)) return;

    fetchBankStatement({
      variables: {
        documentType: 'BANK_STATEMENT',
        id: selectedDocument.id,
        leadId: Number(lead.id),
      },
    });
  }, [fetchBankStatement]);

  const [
    fetchBankOptions,
    {
      data: bankSearchData,
      loading: fetchingBankOptions,
      error: fetchBankOptionsErrors,
    },
  ] = useFetchBankOptions(() => null);

  const previousFirstUpdate = usePrevious(firstUpdate);
  useEffect(() => {
    if (!previousFirstUpdate && firstUpdate) {
      fetchBankOptions({
        variables: {searchParameter: bankSearchTerm, specifiedBank: true},
      });
    } else {
      if (!isNil(bankSearchTerm)) {
        fetchBankOptions({
          variables: {searchParameter: bankSearchTerm, specifiedBank: false},
        });
      }
    }
  }, [bankSearchTerm, firstUpdate]);

  const changeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    setFirstUpdate(false);
    setBankSearchTerm(value);
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const buildLineItems = () => {
    const lineItems = values.lineItems;

    if (isArrayEmpty(lineItems)) {
      return [];
    }

    const formattedLineItems: BankLineItem[] = [];

    const lineItemsLastIndex = lineItems.length - 1;
    const lastLineItem = Object.keys(lineItems[lineItemsLastIndex])
      .filter((k) => lineItems[lineItemsLastIndex][k] != null)
      .reduce((a, k) => ({...a, [k]: lineItems[lineItemsLastIndex][k]}), {});

    if (isEmpty(lastLineItem)) lineItems.pop();

    lineItems.forEach((lineItem) => {
      formattedLineItems.push({
        itemTimestamp: lineItem.itemTimestamp,
        transactionType: isEmpty(lineItem.transactionType)
          ? null
          : lineItem.transactionType,
        details: lineItem.details,
        debit: numeral(lineItem.debit).value(),
        credit: numeral(lineItem.credit).value(),
      });
    });

    return formattedLineItems;
  };

  const submit = () => {
    let payload = values;

    payload = {
      ...payload,
      attachment: selectedDocument.file,
      endBalance: numeral(values.endBalance).value(),
      leadId: Number(lead.id),
      lineItems: buildLineItems(),
      startBalance: numeral(values.startBalance).value(),
      __typename: undefined,
    };

    updateBankStatement({variables: {input: payload}});
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.bankStatementUpdate.bankStatement)) {
        showNotification('success', 'Bank Statement updated!');
        updateValuesAtOnce({
          ...values,
          ...response.bankStatementUpdate.bankStatement,
        });
      } else {
        showNotification('error', 'Failed to update Bank Statement!');
        handleGeneralError(response.bankStatementUpdate.errors);
      }
    }
  };

  const [
    updateBankStatement,
    {loading: updatingBankStatement, error: errorUpdatingBankStatement},
  ] = useUpdateBankStatement(updateSuccessCallback);

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: bankStatementDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const renderInputs = () => {
    if (isUndefined(BankStatementData) && fetchingBankStatement)
      return <FormLoader />;

    if (updatingBankStatement)
      return <FormLoader text="Updating Bank Statement.." />;
    return (
      <div id="bank-statement-base-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
          required
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="bank-document-type-select"
          defaultValue={values.documentSource}
          error={errors.documentSource}
          label="Document Type"
          name="documentSource"
          onChange={(value) => {
            handleSpecificChange({field: 'documentSource', value: value});
          }}
          onKeyDown={debouncedChangeHandler}
          options={documentSourceOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="bank-id-select"
          defaultValue={values.bankId}
          error={errors.bankId}
          isLoading={fetchingBankOptions}
          label="Bank ID"
          name="bankId"
          onChange={(value) => {
            handleSpecificChange({field: 'bankId', value: value});
          }}
          onKeyDown={debouncedChangeHandler}
          options={buildBankOptions(bankSearchData)}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <TextInput
          classes="mb-2"
          data-testid="bank-account-id-field"
          error={errors.bankAccountId}
          label="Bank Account Number"
          name="bankAccountId"
          numbersOnly
          onChange={handleChange}
          placeholder="Add Bank Account Number"
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.bankAccountId}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="statement-start-date-field"
          error={errors.statementStartDate}
          label="Statement Start Date"
          name="statementStartDate"
          onChange={(value) => {
            handleSpecificChange({field: 'statementStartDate', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.statementStartDate as boolean & (Date | null)}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="statement-end-date-field"
          error={errors.statementEndDate}
          label="Statement End Date"
          name="statementEndDate"
          onChange={(value) => {
            handleSpecificChange({field: 'statementEndDate', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.statementEndDate as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="account-name-field"
          error={errors.accountName}
          label="Account Name"
          name="accountName"
          onChange={handleChange}
          placeholder="Add text"
          required={values.status === 'VERIFIED'}
          value={values.accountName}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="account-type-select"
          defaultValue={values.accountType}
          error={errors.accountType}
          label="Account Type"
          name="accountType"
          onChange={(value) => {
            handleSpecificChange({field: 'accountType', value: value});
          }}
          options={accountTypeOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="currency-select"
          defaultValue={values.currency}
          error={errors.currency}
          label="Currency"
          name="currency"
          onChange={(value) => {
            handleSpecificChange({field: 'currency', value: value});
          }}
          options={currencyOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <TextInput
          classes="mb-2"
          data-testid="start-balance-field"
          error={errors.startBalance}
          formatNumber={true}
          label="Starting Balance"
          name="startBalance"
          onChange={handleChange}
          placeholder="Add amount"
          preventNegatives={true}
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.startBalance}
        />

        <TextInput
          classes="mb-2"
          data-testid="ending-balance-field"
          error={errors.endBalance}
          formatNumber={true}
          label="Ending Balance"
          name="endBalance"
          onChange={handleChange}
          placeholder="Add amount"
          preventNegatives={true}
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.endBalance}
        />

        <DataTableToggle
          data={values.lineItems}
          error={errors.lineItems}
          required={values.status === 'VERIFIED'}
          setTableToggle={setTableToggle}
          title="Bank Statement Line Items"
          toggle="lineItems"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="resubmission-reason-select"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          label="Resubmission Reason"
          menuPlacement="top"
          name="resubmission_reason"
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
          options={resubmissionReasonOptions}
          placeholder="Select"
          required={values.status === 'REQUIRES_RESUBMISSION'}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.validUntil as boolean & (Date | null)}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          menuPlacement="top"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />
      </div>
    );
  };

  const renderDataTable = () => {
    return (
      <div>
        <StaticLink
          path=""
          className="text-primary-500"
          icon="FaArrowLeft"
          dataTestId="back-to-document-information"
          text="Back to Document Information"
          onClickAction={() => setTableToggle('')}
        />
        <EditableTable
          headerProps={bankLineItemColumns}
          dataProps={values.lineItems}
          tableLabel="Bank Statement Line Items"
          minSpareRows={1}
        />
      </div>
    );
  };
  return (
    <div className="px-3 py-2">
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        {isEmpty(tableToggle) ? renderInputs() : renderDataTable()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingBankStatement
        }
        label="Save"
        type="primary"
        className="w-full rounded-none mt-4"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default BankStatementForm;
