import React from 'react';

interface Props {
  type: 'success' | 'secondary' | 'warning' | 'danger' | 'primary';
  text: string;
}

const Badge: React.FC<Props> = ({type, text}) => {
  let colors = '';

  switch (type) {
    case 'success':
      colors = 'bg-green-500 text-white';
      break;
    case 'secondary':
      colors = 'bg-fcSecondary text-secondary-400';
      break;
    case 'warning':
      colors = 'bg-warning-500 text-white';
      break;
    case 'danger':
      colors = 'bg-red-500 text-white';
      break;
    case 'primary':
      colors = 'bg-blue-500 text-white';
      break;
    default:
      colors = 'bg-grey-100 text-grey-800';
  }

  return (
    <span
      className={`px-4 inline-flex text-xs leading-5 font-semibold rounded-full ${colors}`}
    >
      {text}
    </span>
  );
};

export default Badge;
