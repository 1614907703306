import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_FUTURE_REVENUE = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      status
      ... on FutureRevenue {
        informationSource
        supplierCompanyId
        supplierSignatoryPersonId
        buyer {
          ... on Buyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Company {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on DimBuyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Lead {
            id
            gid
            name
            buyerAs
          }
        }
        buyerSignatoryPersonId
        primaryDocumentIdentifier
        projectIdentifier
        primaryDocumentType
        supportingDocumentTypes
        currency
        totalTradeAmount
        unpaidTradeAmount
        projectTradeAmount
        issuedOn
        deliveryDate
        paymentTermsInDays
        verifiedPaidOn
        lineItems {
          quantity
          unit
          itemName
          harmonizedSystemCode
          unitPrice
          amount
        }
        isFileClear
        isFileComplete
        isBuyerStamped
        isBuyerSigned
        isSuspectedTampered
        isSuspectedForged
        verificationMethod
        resubmissionReason
        validUntil
      }
    }
  }
`;

export function useFetchFutureRevenue(onSuccessCallback: any) {
  return useLazyQuery(FETCH_FUTURE_REVENUE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
