import {gql, useMutation} from '@apollo/client';

export const UPDATE_BANK_STATEMENT = gql`
  mutation bankStatementUpdate($input: BankStatementAttribute!) {
    bankStatementUpdate(input: $input) {
      bankStatement {
        id
        leadId
        accountName
        accountType
        bankAccountId
        bankId
        currency
        endBalance
        informationSource
        status
        documentSource
        lineItems {
          itemTimestamp
          transactionType
          details
          debit
          credit
        }
        startBalance
        statementEndDate
        statementStartDate
        validUntil
      }
      errors
    }
  }
`;

export function useUpdateBankStatement(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_BANK_STATEMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
