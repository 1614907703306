import {format} from 'date-fns';

import {percentValue} from '../../handsontable/customValidators';
import nationalityList from '../../utils/nationalityList';
import {editableColumnProps} from '../editableTable';

export interface beneficialOwnershipDeclarationItem {
  a: boolean | null;
  b: boolean | null;
  c: boolean | null;
  completeName: string;
  d: boolean | null;
  dateOfBirth: Date | null;
  e: boolean | null;
  f: boolean | null;
  g: boolean | null;
  h: boolean | null;
  i: boolean | null;
  nationality: string;
  percentOwnership: number | null;
  residentialAddress: string;
  tin: string;
  type: string;
}

export const beneficialOwnershipDeclarationItemColumns: editableColumnProps[] = [
  {
    name: 'Complete Name',
    config: {
      data: 'completeName',
      allowEmpty: true,
    },
  },
  {
    name: 'Residential Address',
    config: {
      data: 'residentialAddress',
      allowEmpty: true,
    },
  },
  {
    name: 'Nationality',
    config: {
      data: 'nationality',
      type: 'dropdown',
      source: [''].concat(nationalityList),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Date of Birth',
    config: {
      data: 'dateOfBirth',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Percent Ownership',
    config: {
      data: 'percentOwnership',
      type: 'numeric',
      allowInvalid: false,
      allowEmpty: true,
      validator: percentValue,
    },
  },
  {
    name: 'Type',
    config: {
      data: 'type',
      type: 'dropdown',
      source: ['', 'Direct', 'Indirect'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'TIN',
    config: {
      data: 'tin',
      allowEmpty: true,
    },
  },
  {
    name: 'a',
    config: {
      data: 'a',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'b',
    config: {
      data: 'b',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'c',
    config: {
      data: 'c',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'd',
    config: {
      data: 'd',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'e',
    config: {
      data: 'e',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'f',
    config: {
      data: 'f',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'g',
    config: {
      data: 'g',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'h',
    config: {
      data: 'h',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'i',
    config: {
      data: 'i',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
];
