import React from 'react';
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillExclamationCircle,
} from 'react-icons/ai';

// Badge only allows certain classes of color
interface colorProps {
  badgeColor: 'success' | 'secondary' | 'warning' | 'danger' | 'primary';
  textColor: string;
}
const greenStatuses = ['Verified'];
const yellowStatuses = ['Partially Processed', 'Fully Processed', 'Resubmit'];
const redStatuses = [
  'Abandoned',
  'Not Processed',
  'Rejected',
  'Reprocess',
  'Expired',
];
const blueStatuses = ['In Progress', 'Submitted'];

const colorByStatus = (status: string): colorProps => {
  if (yellowStatuses.includes(status)) {
    return {badgeColor: 'warning', textColor: 'text-yellow-500'};
  }

  if (redStatuses.includes(status)) {
    return {badgeColor: 'danger', textColor: 'text-danger'};
  }

  if (greenStatuses.includes(status)) {
    return {badgeColor: 'success', textColor: 'text-success'};
  }

  if (blueStatuses.includes(status)) {
    return {badgeColor: 'primary', textColor: 'text-blue-400'};
  }

  return {badgeColor: 'secondary', textColor: 'text-secondary'};
};

export const setBadgeColor = (status: string) => {
  return colorByStatus(status)['badgeColor'];
};

export const setTextColor = (status: string) => {
  return colorByStatus(status)['textColor'];
};

export const statusIcon = (status: string) => {
  return <span className={`${setTextColor(status)}`}>{stateIcon(status)}</span>;
};

const stateIcon = (status: string) => {
  if (greenStatuses.includes(status)) {
    return <AiFillCheckCircle size="1.5em" />;
  }
  if (yellowStatuses.includes(status)) {
    return <AiFillExclamationCircle size="1.5em" />;
  }
  if (redStatuses.includes(status)) {
    return <AiFillCloseCircle size="1.5em" />;
  }
  return;
};
