import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      name
      role
      permissions {
        downloadSecretaryCertificate
        viewBvpApp
        viewDocuments
      }
    }
  }
`;

export function useFetchCurrentUser(onSuccessCallback: any) {
  return useLazyQuery(FETCH_USER, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
