import {Link} from '@reach/router';
import React from 'react';

import {NavbarItemProps} from './SharedInterfaces';
import StaticLink from './StaticLink';

const NavbarItem: React.FC<NavbarItemProps> = ({
  text,
  path,
  dataTestId,
  staticLink = false,
  icon = '',
  onClickAction,
  className,
}) => {
  const link = staticLink ? (
    <StaticLink
      dataTestId={dataTestId}
      text={text}
      path={path}
      className={className || ''}
      icon={icon}
      onClickAction={onClickAction}
    />
  ) : (
    <Link
      data-testid={dataTestId}
      className={`${className} pr-6 py-4 flex items-center text-sm underline leading-snug hover:opacity-75`}
      to={path}
    >
      {text}
    </Link>
  );

  return <li className="nav-item">{link}</li>;
};

export default NavbarItem;
