import {gql, useMutation} from '@apollo/client';

export const UPDATE_AUDITED_FINANCIAL_STATEMENT = gql`
  mutation auditedFinancialStatementUpdate(
    $input: AuditedFinancialStatementAttribute!
  ) {
    auditedFinancialStatementUpdate(input: $input) {
      auditedFinancialStatement {
        auditorId
        balanceSheet {
          currentYearAmount
          header
          lineItem
          previousYearAmount
        }
        companyContactInformation {
          emailAddress
          mobileNumber
          telephoneNumber
        }
        contactPersonInformation {
          address
          emailAddress
          mobileNumber
          name
          telephoneNumber
        }
        currency
        documentYear
        hasAccreditedAuditor
        id
        incomeStatement {
          currentYearAmount
          lineItem
          previousYearAmount
        }
        informationSource
        isSecBirAcknowledged
        verificationMethod
        leadId
        principalOfficeAddress
        resubmissionReason
        revenue
        companyTin
        statementOfCashFlows {
          currentYearAmount
          lineItem
          previousYearAmount
        }
        status
        validUntil
      }
      errors
    }
  }
`;

export function useUpdateAuditedFinancialStatement(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_AUDITED_FINANCIAL_STATEMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
