import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_NFIS = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      createdAt
      id
      leadId
      riskopsNotes
      status
      ... on NFISReport {
        companyId
        entityName
        entityType
        hasNoFindings
        personId
        report {
          address
          amountLimit
          bankName
          birthDate
          businessAddress
          businessName
          cardBalance
          closeDate
          finding
          loanSala
          name
          negDate
          remarks
          reportedDate
          sec
          spouse
          sss
          tin
        }
        validUntil
      }
    }
  }
`;

export function useFetchNFIS(onSuccessCallback: any) {
  return useLazyQuery(FETCH_NFIS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
