import {isNil} from 'lodash-es';
import React from 'react';
import * as Icons from 'react-icons/fa';

interface StaticLinkProps {
  dataTestId: string;
  text: string;
  path: string;
  className: string;
  icon: string;
  onClickAction?: () => void;
}

const StaticLink: React.FC<StaticLinkProps> = ({
  dataTestId,
  text,
  className,
  path,
  icon,
  onClickAction,
}) => {
  const IconComponent = Icons[icon];
  const dynamicIcon = !IconComponent ? '' : <IconComponent />;

  const handleOnClick = () => {
    if (isNil(onClickAction)) {
      window.location.href = path;
    } else {
      onClickAction();
    }
  };

  return (
    <p
      data-testid={dataTestId}
      className={`${className} cursor-pointer pr-5 py-3 flex items-center text-sm underline leading-snug hover:opacity-75`}
      onClick={() => handleOnClick()}
    >
      <span className="mr-1">{dynamicIcon}</span>
      {text}
    </p>
  );
};
export default StaticLink;
