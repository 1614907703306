import {isValid, parse} from 'date-fns';
import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {requiredStringField} from './common/fields';

const positiveNumberMessage = 'Value must be a positive number';

export const buildValidationSchema = () => {
  const tinRegex = /^[\d-]+$/;

  const isValidMonth = (value) => {
    if (isNil(value)) return true;

    try {
      const parsedDate = parse(value, 'MMMM yyyy', new Date());
      return isValid(parsedDate);
    } catch (error) {
      return false;
    }
  };

  const shape = {
    tradingAmount: yup.number().positive(positiveNumberMessage).nullable(),
    tradingPartnerName: requiredStringField({
      attribute: 'Trading Partner Name',
    }),
    tradingPartnerTin: requiredStringField({
      attribute: 'Trading Partner TIN',
    }).matches(
      tinRegex,
      `Trading Partner TIN must contain only numbers and '-'`,
    ),
    relationshipType: yup
      .string()
      .oneOf(
        ['SUPPLIER', 'BUYER'],
        `Relationship type Must be either 'BUYER' or 'SUPPLIER'`,
      )
      .required('Relationship Type is required')
      .typeError(
        `Relationship type is required and must be either 'BUYER' or 'SUPPLIER'`,
      ),
    month: yup
      .string()
      .nullable()
      .test(
        'valid-month',
        "Must be in the format 'Month Year' (e.g., 'NOVEMBER 2023')",
        isValidMonth,
      ),
  };

  return yup.object().shape(omitBy(shape, isNil));
};
