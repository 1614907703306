import React from 'react';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';

import TextInput from '../inputs/TextInput';

export interface Page {
  page: number;
  cursor: string;
  items: number;
}

export interface Props {
  currentPage: number;
  totalPages: number;
  items: number;
  onPageClick: (page: number) => void;
  disabled?: boolean;
  itemPerPage: number;
}

const TablePagination: React.FC<Props> = ({
  onPageClick,
  currentPage,
  totalPages,
  items,
  itemPerPage,
}) => {
  const handleOnPageClick = (page: number) => {
    onPageClick(page);
  };

  const previousDisabled = currentPage === 1;
  const previousClasses = previousDisabled
    ? 'bg-gray-100'
    : 'bg-white hover:bg-gray-50';
  const onPreviousClick = () => {
    const pageNumber = currentPage - 1;
    handleOnPageClick(pageNumber);
  };

  const renderPreviousButton = () => {
    return (
      <button
        disabled={previousDisabled}
        data-testid="prev-page-btn"
        onClick={onPreviousClick}
        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 ${previousClasses}`}
      >
        <span className="sr-only">Previous</span>
        <FaChevronLeft />
      </button>
    );
  };

  const nextDisabled = currentPage === totalPages;
  const nextClasses = nextDisabled
    ? 'bg-gray-100'
    : 'bg-white hover:bg-gray-50';
  const onNextClick = () => {
    const pageNumber = currentPage + 1;
    handleOnPageClick(pageNumber);
  };

  const renderNextButton = () => {
    return (
      <button
        disabled={nextDisabled}
        onClick={onNextClick}
        data-testid="next-page-btn"
        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 ${nextClasses}`}
      >
        <span className="sr-only">Previous</span>
        <FaChevronRight />
      </button>
    );
  };

  const renderLeftText = () => {
    const itemNumber = currentPage * itemPerPage;
    return (
      <div className="flex-grow" data-testid="pagination-label">
        <p className="text-sm text-gray-700">
          Showing
          <span className="font-medium ml-1 mr-1">
            {itemNumber > items ? items : itemNumber}
          </span>
          of
          <span className="font-medium ml-1 mr-1">{items}</span>
          results
        </p>
      </div>
    );
  };

  const handleOnBlur = (event): void => {
    handleOnPageClick(event.target.value);
  };

  const handleKeyPress = (event): void => {
    if (event.key === 'Enter') handleOnPageClick(event.target.value);
  };
  const renderCurrentPageSelector = () => {
    return (
      <TextInput
        id="pageInput"
        type="number"
        classes="w-8 appearance-none"
        inputOnly={true}
        value={currentPage}
        data-testid="curPageInput"
        onBlur={(e) => handleOnBlur(e)}
        onChange={(e) => {
          return;
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      />
    );
  };

  return (
    <div className="bg-white mt-4 py-3 flex flex-grow items-center justify-between">
      {renderLeftText()}
      <div className="flex-row-reverse flex-grow sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm"
          aria-label="Pagination"
        >
          {renderPreviousButton()}
          <div className="bg-white hover:bg-gray-50 md:inline-flex relative items-center px-4 py-0.5 border text-sm font-medium border-gray-300 text-gray-500">
            {renderCurrentPageSelector()}
            <span>of {totalPages}</span>
          </div>
          {renderNextButton()}
        </nav>
      </div>
    </div>
  );
};

export default TablePagination;
