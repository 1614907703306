import { useState, useEffect } from 'react';

const useDebounce = (value: string, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // We don't want to change value until user
      // stopped typing after the delay
      return () => {
        clearTimeout(handler);
      };
    },
    // Only do operation once value changed
    [value, delay]
  );

  return debouncedValue;
};

export default useDebounce;
