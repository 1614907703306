import SuspenseLoader from '@src/components/common/loaders/SuspenseLoader';
import BuyersPage from '@src/components/common/pages/BuyersPage';
import DocumentsPage from '@src/components/common/pages/DocumentsPage';
import HomePage from '@src/components/common/pages/HomePage';
import {RouteProps} from '@src/interfaces/route';
import {Permissions, User} from '@src/interfaces/user';
import {isNil} from 'lodash-es';

export const buildRoutes = (
  disabled: boolean,
  currentUser: User | null,
  permissions: Permissions | null,
): RouteProps[] => {
  const routes: RouteProps[] = [];

  if (isNil(currentUser)) return routes;

  if (permissions?.viewBvpApp) {
    routes.push({
      name: 'Leads',
      path: '/leads/:leadId',
      component: DocumentsPage,
      disabled: true,
    });

    routes.push({
      name: 'Buyers',
      path: '/buyers',
      component: BuyersPage,
      disabled,
    });
  }

  return routes;
};

export const buildUserRoutes = (disabled: boolean): RouteProps[] => {
  const routes = [
    {
      name: 'FCC',
      path: '/',
      component: SuspenseLoader,
      disabled,
    },
  ];

  return routes;
};
