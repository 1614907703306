import {gql, useMutation} from '@apollo/client';

const mutation = `
  mutation documentUpload($document: DocumentAttribute!) {
    documentUpload(input: $document) {
      document {
        documentType
        id
        leadId
        status
      }
      errors
    }
  }
`;
export const UPLOAD_DOCUMENT = gql(mutation);

export function useUploadDocument(onSuccessCallback: (response: any) => void) {
  return useMutation(UPLOAD_DOCUMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
