import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_TRADING_RELATIONSHIP_LINE_ITEMS = gql`
  query TradingRelationshipLineItems(
    $tradingRelationshipId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    tradingRelationshipLineItems(
      tradingRelationshipId: $tradingRelationshipId
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        id
        atcCode
        month
        taxRate
        taxWithheld
        tradingAmount
        tradingPartnerName
        tradingPartnerTin
        buyerId
        buyerName
        relationshipType
        hasNda
        verificationMethod
        currency
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      totalPages
      pages {
        after
        before
        first
        last
      }
    }
  }
`;

export function useFetchTradingRelationshipLineItems(onSuccessCallback: any) {
  return useLazyQuery(FETCH_TRADING_RELATIONSHIP_LINE_ITEMS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
