import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_TEMPLATE = gql`
  query Template($documentType: DocumentsDynamicDocumentDynamicTypeEnum!) {
    template(documentType: $documentType)
  }
`;

export function useFetchTemplate(onSuccessCallback: any) {
  return useLazyQuery(FETCH_TEMPLATE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
