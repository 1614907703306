import {isNull} from 'lodash-es';
import React, {useContext} from 'react';

import DocumentsContext from '../../../../../contexts/DocumentsContext';
import {Document} from '../../../../../interfaces/documents';
import {informationSourceOptions} from '../../../../../interfaces/futureRevenue';
import {dateFormatter} from '../../../../../utils/dateFormater';
import {setBadgeColor} from '../../../../../utils/docStatusFormatter';
import Formatters from '../../../../../utils/formatter';
import {titleizeUnderscoredWords} from '../../../../../utils/string';
import {getLabelFromOptions} from '../../../../../utils/value';
import Badge from '../../../Badge';
import DocumentViewerButton from '../DocumentViewerButton';
import TableData from './TableData';

interface Props {
  fetchingDocuments?: boolean;
}

const FutureRevenueTable: React.FC<Props> = ({fetchingDocuments}) => {
  const {documents} = useContext(DocumentsContext);

  return (
    <table className="text-center text-gray-500 whitespace-nowrap w-full divide-y divide-gray-100">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="p-4">
            Submitted Date
          </th>
          <th scope="col" className="p-4">
            Document Status
          </th>
          <th scope="col" className="p-4">
            Buyer Company ID
          </th>
          <th scope="col" className="p-4">
            Issue Date
          </th>
          <th scope="col" className="p-4">
            Valid Until
          </th>
          <th scope="col" className="p-4">
            Unpaid Amount
          </th>
          <th scope="col" className="p-4">
            Information Source
          </th>
          <th scope="col" className="p-4"></th>
        </tr>
      </thead>

      <tbody className="text-sm bg-white divide-y divide-gray-200">
        {(documents as Document[]).map((document) => {
          return (
            <tr key={document.id} className="even:bg-gray-100">
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.createdAt)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                <Badge
                  type={setBadgeColor(
                    titleizeUnderscoredWords(document.status),
                  )}
                  text={titleizeUnderscoredWords(document.status)}
                ></Badge>
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.buyer?.name}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.issuedOn)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.validUntil)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {isNull(document.unpaidTradeAmount)
                  ? null
                  : Formatters.amount(
                      document.currency,
                      document.unpaidTradeAmount,
                    )}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {getLabelFromOptions(
                  informationSourceOptions,
                  document.informationSource,
                )}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                <DocumentViewerButton document={document} />
              </TableData>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FutureRevenueTable;
