/* eslint-disable @typescript-eslint/no-explicit-any */

import {includes, isEmpty, isNil, isUndefined, pull, uniq} from 'lodash-es';
import * as React from 'react';
import {useEffect, useState} from 'react';
import * as yup from 'yup';

import {showNotification} from '../utils/toast';

interface Props {
  defaultState: Record<string, any>;
  submitAction: (data?: Record<string, any>) => void;
  validationSchema?: any;
  runValidationOnEveryChange?: boolean;
  dynamicFields?: string[];
  clearFormState?: Record<string, any>;
}

interface FormValues {
  [key: string]: any;
}

interface DirectChangeField {
  field: string;
  value: any;
}

interface DirectClearError {
  field: string;
}

export type FormErrors = {
  [key: string]: any;
};

const useForm = ({
  defaultState,
  submitAction,
  dynamicFields = [],
  validationSchema,
  clearFormState,
  runValidationOnEveryChange = false,
}: Props) => {
  const [values, setValues] = useState<typeof defaultState>(defaultState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [generalError, setGeneralError] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // Run submit action when
  // there are no errors and is submitted
  useEffect(() => {
    const formFields = Object.keys(values);
    if (!isEmpty(dynamicFields)) formFields.push(...dynamicFields);

    const foundErrors = formFields.some((name: string) => !!errors[name]);
    if (isSubmitted) {
      if (foundErrors) {
        showNotification('error', 'Error: Please verify submitted information');
      } else {
        submitAction(values);
      }
      setIsSubmitted(false);
    }
  }, [errors, isSubmitted, submitAction, values]);

  const detectTouchedFieldPaths = (touchedFieldPaths: string[] = []) => {
    // cannot run any validation without a schema
    if (isUndefined(validationSchema)) {
      return touchedFieldPaths;
    }

    return [...touchedFieldPaths];
  };

  function clearErrors(touchedFieldNames: string[]) {
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc, key) => ({
        ...acc,
        [key]: undefined,
      }),
      Object(errors),
    );
    setErrors(updatedErrors);
  }

  const updateErrors = (
    error: yup.ValidationError,
    touchedFieldNames: string[],
  ) => {
    const detectedErrors = error.inner.reduce(
      (acc: any, err: any) => ({
        ...acc,
        [err.path]: err.message,
      }),
      {},
    );
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc: any, key: keyof typeof detectedErrors) => ({
        ...acc,
        [key]: detectedErrors[key],
      }),
      Object(errors),
    );
    setErrors(updatedErrors);
  };

  function runValidations(values: FormValues, touchedFieldNames: string[]) {
    if (isNil(validationSchema)) return;

    const keys = Object.keys(values);
    if (!isEmpty(dynamicFields)) keys.push(...dynamicFields);
    const data = keys.reduce((acc, fieldName) => {
      let value;
      if (isUndefined(values[fieldName]) && !isUndefined(values['data'])) {
        const data_object = values['data'];
        value = ['', undefined].includes(data_object[fieldName])
          ? undefined
          : values[fieldName];
      } else {
        value = values[fieldName] !== '' ? values[fieldName] : undefined;
      }

      return {
        ...acc,
        [fieldName]: value,
      };
    }, {});

    const fieldNames = touchedFieldNames;
    if (!isEmpty(dynamicFields)) fieldNames.push(...dynamicFields);

    const affectedFieldNames = fieldNames.filter((name: string) =>
      includes(keys, name),
    );

    try {
      validationSchema.validateSync(data, {abortEarly: false});
      clearErrors(affectedFieldNames);
    } catch (error) {
      updateErrors(error, affectedFieldNames);
    }

    // Always set submitting to false
    // to be safe, it should only be set when submit is performed
    setIsSubmitted(false);
  }

  function assignUpdatedValue(fieldName: string, value: any) {
    let newVal: any;
    const fieldNameCurrentValue = values[fieldName];

    const handleArrayValue = () => {
      if (value.length === 0 || isNil(value)) {
        return [];
      } else {
        return uniq([...value]);
      }
    };

    if (isNil(value)) {
      newVal = null;
    } else if (Array.isArray(fieldNameCurrentValue)) {
      if (fieldNameCurrentValue.includes(value) && !Array.isArray(value)) {
        newVal = pull(fieldNameCurrentValue, value);
      } else if (Array.isArray(value)) {
        newVal = handleArrayValue();
      } else {
        newVal = [...fieldNameCurrentValue, value];
      }
    } else {
      newVal = value;
    }
    return {...Object(values), [fieldName]: newVal};
  }

  function handleChange(event: React.ChangeEvent<any>) {
    const {name, value, checked, type} = event.target;

    if (isNil(name)) {
      return;
    }

    const parsed = parseFloat(value);
    let val = !/number|range|checkbox/.test(type)
      ? value
      : !isNaN(parsed)
      ? parsed
      : /checkbox/.test(type)
      ? checked
      : '';

    // Checkboxes with specified values
    if (/checkbox/.test(type) && !isNil(value) && value !== 'on') {
      val = value;
    }

    const updatedValues = assignUpdatedValue(name, val);

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      runValidations(updatedValues, [name]);
    }
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    setValues(updatedValues);
  }

  // This is usable in cases wherein the form component has element
  // that will do changes in the fields also.
  // E.g icons click in logging calls.
  // This will also useful in using react-datepicker since it has it's
  // own onChange method inside that has multiple return
  // and we only need the return value
  function handleSpecificChange(
    fields: DirectChangeField | DirectChangeField[],
  ) {
    let updatedValues = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const {field, value} = fields[i];
        updatedValues = {...updatedValues, ...{[field]: value}};
      }
      setValues({...values, ...updatedValues});
    } else {
      updatedValues = assignUpdatedValue(fields.field, fields.value);
      setValues(updatedValues);
    }

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      if (fields instanceof Array) {
        runValidations(
          updatedValues,
          fields.map((f: DirectChangeField) => f.field),
        );
      } else {
        runValidations(updatedValues, [fields.field]);
      }
    }

    if (isSubmitted) {
      setIsSubmitted(false);
    }
  }

  function handleGeneralError(errors: string[]) {
    const stringErrors = errors.join();
    setGeneralError(stringErrors);
  }

  // Will be useful for clearing validation error directly
  function directClearError(fields: DirectClearError | DirectClearError[]) {
    let updatedErrors = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const {field} = fields[i];
        updatedErrors = {...updatedErrors, ...{[field]: undefined}};
      }
    } else {
      updatedErrors = {...errors, [fields.field]: undefined};
    }

    setErrors(updatedErrors);
  }

  function clearAllErrors() {
    setErrors({});
  }

  function resetForm() {
    setValues(!isNil(clearFormState) ? clearFormState : defaultState);
  }

  function formHasValue() {
    return (
      Object.entries(values).filter(([, v]) => {
        return !isNil(v) && v !== '' && v.length !== 0;
      }).length !== 0
    );
  }

  function notFilledOut(): boolean {
    return (
      Object.entries(values).filter(([, v]) => {
        return isNil(v) || v === '' || v.length === 0;
      }).length !== 0
    );
  }

  function hasErrors(): boolean {
    return (
      Object.entries(errors).filter(([, v]) => {
        return !isNil(v);
      }).length !== 0
    );
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function handleSubmit() {
    runValidations(values, Object.keys(values));
    setIsSubmitted(true);
  }

  function updateValuesAtOnce(values: Record<string, any>) {
    setValues(values);
  }

  return {
    handleChange,
    handleSpecificChange,
    handleSubmit,
    directClearError,
    clearAllErrors,
    resetForm,
    values,
    errors,
    formHasValue,
    runValidations,
    handleGeneralError,
    notFilledOut: notFilledOut(),
    hasErrors: hasErrors(),
    updateValuesAtOnce,
  };
};

export default useForm;
