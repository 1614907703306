import {debounce, isNil, isNull, isUndefined} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateIncomeTaxReturn} from '../../../../../api/mutations/incomeTaxReturn';
import {useFetchAuditorSearch} from '../../../../../api/queries/auditorSearch';
import {useFetchIncomeTaxReturn} from '../../../../../api/queries/incomeTaxReturn';
import Button from '../../../../../components/common/buttons/Button';
import Datepicker from '../../../../../components/common/inputs/Datepicker';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {buildAuditorOptions} from '../../../../../interfaces/auditors';
import {
  incomeTaxReturnDefault,
  verificationMethodOptions,
} from '../../../../../interfaces/incomeTaxReturn';
import {
  informationSourceOptions,
  resubmissionReasonOptions,
} from '../../../../../interfaces/incomeTaxReturn';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/IncomeTaxReturn';
import Radio from '../../../inputs/Radio';
import DynamicSelect, {Value} from '../../../inputs/Select';
import {
  YesOrNoBooleanOptions,
  currencyOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextInput from '../../../inputs/TextInput';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const IncomeTaxReturnForm: React.FC = () => {
  const {lead, permissions} = useContext(ApplicationContext);
  const {selectedDocument} = useContext(DocumentsContext);
  const [auditorSearchTerm, setAuditorSearchTerm] = useState<Value>('');

  const onFetchIncomeTaxReturnSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {auditorId} = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
    });

    if (!isNil(auditorId)) setAuditorSearchTerm(auditorId.toString());
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.incomeTaxReturnUpdate.incomeTaxReturn)) {
        showNotification('success', 'Income Tax Return updated!');
        updateValuesAtOnce({
          ...values,
          ...response.incomeTaxReturnUpdate.incomeTaxReturn,
        });
      } else {
        showNotification('error', 'Failed to update Income Tax Return!');
        handleGeneralError(response.tradingRelationshipUpdate.errors);
      }
    }
  };

  const [
    fetchIncomeTaxReturn,
    {
      loading: fetchingIncomeTaxReturn,
      error: fetchIncomeTaxReturnErrors,
      data: ITRData,
    },
  ] = useFetchIncomeTaxReturn(onFetchIncomeTaxReturnSuccess);

  const [
    updateIncomeTaxReturn,
    {loading: updatingIncomeTaxReturn, error: errorUpdatingIncomeTaxReturn},
  ] = useUpdateIncomeTaxReturn(updateSuccessCallback);

  useEffect(() => {
    fetchAuditorSearch({
      variables: {
        searchParameter: auditorSearchTerm,
        specifiedAuditor: false,
      },
    });
  }, [auditorSearchTerm]);

  useEffect(() => {
    if (selectedDocument.id) {
      fetchIncomeTaxReturn({
        variables: {
          documentType: 'INCOME_TAX_RETURN',
          id: selectedDocument.id,
          leadId: Number(lead.id),
        },
      });
    }
  }, [fetchIncomeTaxReturn]);

  const submit = () => {
    let payload = values;

    payload = {
      ...payload,
      attachment: selectedDocument.file,
      leadId: Number(lead.id),
      costOfSalesServices: numeral(payload.costOfSalesServices).value(),
      currentAssets: numeral(payload.currentAssets).value(),
      currentLiabilities: numeral(payload.currentLiabilities).value(),
      grossIncomeFromOperation: numeral(
        payload.grossIncomeFromOperation,
      ).value(),
      intangibleAssets: numeral(payload.intangibleAssets).value(),
      longTermInvestment: numeral(payload.longTermInvestment).value(),
      longTermLiabilities: numeral(payload.longTermLiabilities).value(),
      longTermReceivables: numeral(payload.longTermReceivables).value(),
      netProfit: numeral(payload.netProfit).value(),
      otherAssets: numeral(payload.otherAssets).value(),
      propertyPlantAndEquipment: numeral(
        payload.propertyPlantAndEquipment,
      ).value(),
      revenue: numeral(payload.revenue).value(),
      totalAllowableItemizedDeductions: numeral(
        payload.totalAllowableItemizedDeductions,
      ).value(),
      totalAssets: numeral(payload.totalAssets).value(),
      totalEquity: numeral(payload.totalEquity).value(),
      totalLiabilities: numeral(payload.totalLiabilities).value(),
      __typename: undefined,
    };

    updateIncomeTaxReturn({
      variables: {input: payload},
    });
  };

  const [
    fetchAuditorSearch,
    {
      data: auditorSearchData,
      loading: fetchingAuditorSearch,
      error: fetchAuditorSearchErrors,
    },
  ] = useFetchAuditorSearch(() => null);

  const changeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    setAuditorSearchTerm(value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const defaultValidUntil = (currentValue: Date | null) => {
    if (!isNull(currentValue)) return currentValue;
    if (!isNull(values.fiscalYearEndDate)) {
      const defaultValue = new Date(values.fiscalYearEndDate);
      defaultValue.setFullYear(defaultValue.getFullYear() + 1);
      defaultValue.setMonth(5);
      handleSpecificChange({field: 'validUntil', value: defaultValue});
      return defaultValue;
    }

    return null;
  };

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: incomeTaxReturnDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const renderInputs = () => {
    if (isUndefined(ITRData) && fetchingIncomeTaxReturn) return <FormLoader />;

    if (updatingIncomeTaxReturn)
      return <FormLoader text="Updating Income Tax Return" />;

    return (
      <div id="itr-base-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
          required={true}
        />

        <DynamicSelect
          name="auditorId"
          dataTestId="auditor-select"
          classes="mb-2"
          placeholder="Select Auditor"
          options={buildAuditorOptions(auditorSearchData)}
          label="Auditor ID"
          defaultValue={values.auditorId}
          error={errors.auditorId}
          onChange={(value) => {
            handleSpecificChange({field: 'auditorId', value: value});
          }}
          isLoading={fetchingAuditorSearch}
          onKeyDown={debouncedChangeHandler}
        />

        <Datepicker
          name="fiscalYearEndDate"
          classes="mb-2"
          label="Fiscal Year End Date"
          value={values.fiscalYearEndDate}
          onChange={(value) => {
            handleSpecificChange({field: 'fiscalYearEndDate', value: value});
          }}
          autoComplete="off"
          selected={values.fiscalYearEndDate as boolean & (Date | null)}
          placeholder="Date"
          error={errors.fiscalYearEndDate}
          dataTestId="start-date-field"
          required={values.status === 'VERIFIED'}
        />

        <Radio
          name="isSecBirStamped"
          label="Is SEC or BIR Stamped?"
          options={YesOrNoBooleanOptions}
          defaultValue={values.isSecBirStamped}
          classes="px-3 py-2 w-3/4"
          required={values.status === 'VERIFIED'}
          onChange={(value) => {
            handleSpecificChange({field: 'isSecBirStamped', value: value});
          }}
          error={errors.isSecBirStamped}
        />

        <TextInput
          name="companyTin"
          label="Company TIN"
          classes="mb-2"
          value={values.companyTin}
          placeholder="Add TIN"
          data-testid="company-tin"
          error={errors.companyTin}
          required={values.status === 'VERIFIED'}
          onChange={handleChange}
          type="number"
          preventNegatives={true}
        />

        <DynamicSelect
          dataTestId="currency-select"
          defaultValue={values.currency}
          name="currency"
          classes="mb-2"
          placeholder="Select"
          label="Currency"
          required={values.status === 'VERIFIED'}
          onChange={(value) => {
            handleSpecificChange({field: 'currency', value: value});
          }}
          options={currencyOptions}
          error={errors.currency}
        />

        <TextInput
          classes="mb-2"
          data-testid="revenue"
          error={errors.revenue}
          formatNumber={true}
          label="Revenue"
          name="revenue"
          onChange={handleChange}
          placeholder="Add amount"
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.revenue}
        />

        <TextInput
          name="costOfSalesServices"
          label="Cost of Sales Service"
          classes="mb-2"
          value={values.costOfSalesServices}
          placeholder="Add amount"
          data-testid="cost-of-sales-services"
          error={errors.costOfSalesServices}
          required={
            values.status === 'VERIFIED' || values.status === 'IN_PROCESSING'
          }
          onChange={handleChange}
          preventNegatives={true}
          type="number"
          formatNumber
        />
        <TextInput
          name="grossIncomeFromOperation"
          label="Gross Income from Operation"
          classes="mb-2"
          value={values.grossIncomeFromOperation}
          placeholder="Add amount"
          data-testid="gross-income-from-operation"
          error={errors.grossIncomeFromOperation}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="totalAllowableItemizedDeductions"
          label="Total Allowable Itemized Deductions"
          classes="mb-2"
          value={values.totalAllowableItemizedDeductions}
          placeholder="Add amount"
          data-testid="total-allowable-itemized-deductions"
          error={errors.totalAllowableItemizedDeductions}
          required={values.status === 'VERIFIED'}
          onChange={handleChange}
          preventNegatives={true}
          type="number"
          formatNumber
        />
        <TextInput
          name="netProfit"
          label="Net Income"
          classes="mb-2"
          value={values.netProfit}
          placeholder="Add amount"
          data-testid="net-income"
          error={errors.netProfit}
          required={values.status === 'VERIFIED'}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="currentAssets"
          label="Current Assets"
          classes="mb-2"
          value={values.currentAssets}
          placeholder="Add amount"
          data-testid="current-assets"
          error={errors.currentAssets}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="longTermInvestment"
          label="Long Term Investment"
          classes="mb-2"
          value={values.longTermInvestment}
          placeholder="Add amount"
          data-testid="long-term-investment"
          error={errors.longTermInvestment}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="propertyPlantAndEquipment"
          label="Property, Plant, and Equipment"
          classes="mb-2"
          value={values.propertyPlantAndEquipment}
          placeholder="Add amount"
          data-testid="property-plant-and-equipment"
          error={errors.propertyPlantAndEquipment}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="longTermReceivables"
          label="Long Term Receivables"
          classes="mb-2"
          value={values.longTermReceivables}
          placeholder="Add amount"
          data-testid="long-term-receivables"
          error={errors.longTermReceivables}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="intangibleAssets"
          label="Intangible Assets"
          classes="mb-2"
          value={values.intangibleAssets}
          placeholder="Add amount"
          data-testid="intangible-assets"
          error={errors.intangibleAssets}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="otherAssets"
          label="Other Assets"
          classes="mb-2"
          value={values.otherAssets}
          placeholder="Add amount"
          data-testid="other-assets"
          error={errors.otherAssets}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="totalAssets"
          label="Total Assets"
          classes="mb-2"
          value={values.totalAssets}
          placeholder="Add amount"
          data-testid="total-assets"
          error={errors.totalAssets}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="currentLiabilities"
          label="Current Liabilities"
          classes="mb-2"
          value={values.currentLiabilities}
          placeholder="Add amount"
          data-testid="current-liabilities"
          error={errors.currentLiabilities}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="longTermLiabilities"
          label="Non-current Liabilities"
          classes="mb-2"
          value={values.longTermLiabilities}
          placeholder="Add amount"
          data-testid="long-term-liabilities"
          error={errors.longTermLiabilities}
          onChange={handleChange}
          type="number"
          formatNumber
        />
        <TextInput
          name="totalLiabilities"
          label="Total Liabilities"
          classes="mb-2"
          value={values.totalLiabilities}
          placeholder="Add amount"
          data-testid="total-liabilities"
          error={errors.totalLiabilities}
          onChange={handleChange}
          integerOnly={true}
          type="number"
          formatNumber
        />
        <TextInput
          name="totalEquity"
          label="Total Equity"
          classes="mb-2"
          value={values.totalEquity}
          placeholder="Add amount"
          data-testid="total-equity"
          error={errors.totalEquity}
          onChange={handleChange}
          type="number"
          formatNumber
        />

        <DynamicSelect
          dataTestId="verification-method-select"
          name="verificationMethod"
          classes="mb-2"
          label="Verification Method"
          defaultValue={values.verificationMethod}
          required={values.status === 'VERIFIED'}
          placeholder="Select"
          onChange={(value) => {
            handleSpecificChange({field: 'verificationMethod', value: value});
          }}
          options={verificationMethodOptions}
          error={errors.verificationMethod}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="resubmission-reason-select"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          label="Resubmission Reason"
          menuPlacement="top"
          name="resubmissionReason"
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
          options={resubmissionReasonOptions}
          placeholder="Select"
          required={values.status === 'REQUIRES_RESUBMISSION'}
        />

        <Datepicker
          name="validUntil"
          label="Valid Until"
          classes="mb-2"
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          value={values.validUntil}
          selected={
            defaultValidUntil(values.validUntil) as boolean & (Date | null)
          }
          error={errors.validUntil}
          dataTestId="valid-until-field"
        />

        <DynamicSelect
          dataTestId="status-select"
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          name="status"
          classes="mb-2"
          label="Document Status"
          defaultValue={values.status || 'SUBMITTED'}
          menuPlacement={'top'}
          required={true}
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          error={errors.status}
        />
      </div>
    );
  };

  return (
    <div className="px-3 py-2">
      <div
        id="form-container"
        className="h-[630px] overflow-hidden overflow-y-scroll"
      >
        {renderInputs()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingIncomeTaxReturn
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default IncomeTaxReturnForm;
