import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_COUNTRIES = gql`
  query Countries {
    countries {
      name
      numeric
      alpha2
      alpha3
      currency
      iban
    }
  }
`;

export function useFetchCountries(onSuccessCallback?: any, onErrorCallback?: any) {
  return useLazyQuery(FETCH_COUNTRIES, {
    onCompleted: onSuccessCallback,
    onError: onErrorCallback,
    errorPolicy: 'all',
  });
}
