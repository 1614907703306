import {isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';

import {titleizeUnderscoredWords} from '../../utils/string';

interface Props {
  filePath: string;
  fixedHeaderScrollHeight?: number;
}
interface Headers {
  name: string;
  selector: any;
  sortable: boolean;
}

const CsvTable: React.FC<Props> = ({filePath, fixedHeaderScrollHeight}) => {
  const [tblHeaders, setTblHeaders] = useState<Headers[]>([]);
  const [tblRows, setTblRows] = useState<Record<string, unknown>[]>([]);
  const [parsingTbl, setParsingTbl] = useState<boolean>(true);
  const tableHeight = isNil(fixedHeaderScrollHeight)
    ? '500px'
    : `${fixedHeaderScrollHeight}px`;

  useEffect(() => {
    fetchFile();
  }, [filePath]);

  // SEE: https://stackoverflow.com/a/8497474
  const CSVtoArray = (text: string): string[] => {
    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

    // Return empty array if input string is not well formed CSV string.
    if (!re_valid.test(text)) return [];

    // Initialize array to receive values.
    const a: string[] = [];

    // "Walk" the string using replace with callback.
    text.replace(re_value, function (m0, m1, m2, m3) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ''; // Return empty string.
    });

    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');

    return a;
  };

  const processCsv = (csvString: string | null | ArrayBuffer): void => {
    if (isNil(csvString)) return;
    const csvContent = typeof csvString === 'string' ? csvString : '';
    const row_array = csvContent.split('\n');
    const headerRow = CSVtoArray(row_array[0]);
    const headerLabels = headerRow.map((t) =>
      titleizeUnderscoredWords(t).toUpperCase(),
    );
    row_array.shift();
    const data = row_array.map((row) => mapRowToHeader(row, headerRow));
    setTblHeaders(parseHeaderConfig(headerLabels, headerRow));
    setTblRows(data);
    setParsingTbl(false);
  };

  const parseHeaderConfig = (labels, selectors): Headers[] => {
    const headers: Headers[] = [];

    labels.forEach((label, index) => {
      const rowData = {
        name: label,
        selector: (row) => row[selectors[index]],
        sortable: true,
      };
      headers.push(rowData);
    });
    return headers;
  };

  const mapRowToHeader = (data, headers) => {
    const dataObj = {};
    const dataArray = CSVtoArray(data);

    for (const [index, value] of headers.entries()) {
      dataObj[value] = dataArray[headers.indexOf(value)] || '';
    }
    return dataObj;
  };

  const fetchFile = () => {
    fetch(filePath)
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const text = (e.target || {result: ''}).result;
          processCsv(text);
        };
        reader.readAsText(blob);
      });
  };

  if (!filePath) return null;

  const Loading = () => {
    return (
      <div className="flex flex-row">
        <div className="w-8 h-8 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader" />
        <strong className="m-auto pl-4">Loading...</strong>
      </div>
    );
  };

  return (
    <div className="mx-4 mt-6">
      <DataTable
        columns={tblHeaders}
        data={tblRows}
        highlightOnHover
        pagination
        progressComponent={<Loading />}
        progressPending={parsingTbl}
        fixedHeader={true}
        fixedHeaderScrollHeight={tableHeight}
      />
    </div>
  );
};
export default CsvTable;
