import {gql, useMutation} from '@apollo/client';

export const UPDATE_NFIS = gql`
  mutation NFISReportUpdate($NFISReport: NFISReportAttribute!) {
    nfisReportUpdate(input: $NFISReport) {
      nfisReport {
        companyId
        createdAt
        entityName
        entityType
        hasNoFindings
        id
        leadId
        personId
        report {
          finding
          name
          birthDate
          address
          spouse
          tin
          sss
          businessName
          businessAddress
          sec
          reportedDate
          remarks
          negDate
          closeDate
          bankName
          loanSala
          amountLimit
          cardBalance
        }
        riskopsNotes
        status
        validUntil
      }
      errors
    }
  }
`;

export function useUpdateNFIS(onSuccessCallback: (response: any) => void) {
  return useMutation(UPDATE_NFIS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
