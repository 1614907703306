import {isNil} from 'lodash-es';
import React, {useEffect, useState} from 'react';

import Pagination from '@src/components/common/paginations/Pagination';
import SimpleError from '@src/components/common/errors/SimpleError';
import { Spinner, Table } from 'flowbite-react';

interface Props {
  records: Array<any>;
  cursors: Array<any>;
  containerClass?: string;
  isLoading: boolean;
  error: any;
  errorMessage: string;
  isEmpty: boolean;
  emptyMessage: string | React.ReactNode;
  loaderMessage?: string;
  tableContainerClass?: string;
  totalCount: number;
  totalPages: number;
  itemsPerPage?: number;
  pageCursor: string;
  tableHeader?: React.ReactElement;
  page?: number;
  makeNull?: boolean;
  alwaysReload?: boolean;
  hidePagination?: boolean;
  header?: string;
  headerDataTestId?: string;
  theme?: 'teal' | 'purple';
  limited?: boolean;
  asTable?: boolean;
  tableHead?: React.ReactElement;
  dataTestId?: string;
  onPageClick: (cursor: string | null, page?: number) => void;
  renderItem: (item: any, index?: number) => React.ReactNode;
}

const PaginatedTable: React.FC<Props> = ({
  asTable,
  records,
  containerClass,
  cursors,
  pageCursor,
  error,
  errorMessage,
  loaderMessage,
  isEmpty,
  emptyMessage,
  header,
  headerDataTestId,
  tableContainerClass,
  totalCount,
  totalPages,
  itemsPerPage,
  isLoading,
  onPageClick,
  renderItem,
  hidePagination,
  theme,
  limited,
  tableHead,
  page,
  dataTestId,
}) => {
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [collection, setCollection] = useState<Array<any>>(records);
  const [currentPageCursor, setCurrentPageCursor] = useState<string>(pageCursor);
  const [pageCursors, setPageCursors] = useState<Array<any>>(cursors);
  const [currentPage, setCurrentPage] = useState<number>(page || 1);
  const [totalItemsCount, setItemsCount] = useState<number>(totalCount);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);

  useEffect(() => {
    setCurrentPage(page || 1);
  }, [page]);

  useEffect(() => {
    if (!isLoading && cursors.length > 0 && isNil(error)) {
      setPageCursors(cursors);
    }
  }, [isLoading, cursors]);

  useEffect(() => {
    if (!isLoading) {
      setLoadingData(false);
    }

    if (!isLoading && isNil(error) && records.length > 0) {
      setCollection(records);
    }
  }, [records, isLoading]);

  useEffect(() => {
    if (!isLoading && isNil(error)) {
      setPageCursors(cursors);
    }
  }, [cursors]);

  useEffect(() => {
    if (!isLoading && totalPageCount === 0) {
      setTotalPageCount(totalPages);
    }
  }, [totalPages]);

  useEffect(() => {
    if (!isLoading) {
      setItemsCount(totalCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentPageCursor(pageCursor);
    }
  }, [pageCursor]);

  const fetching = isLoading || loadingData;

  function apiTryAgainCallback() {
    if (currentPage === 1) {
      onPageClick(null);
    } else {
      onPageClick(currentPageCursor);
    }
  }

  function renderPagination() {
    if (hidePagination) return null;

    return (
      <>
        <Pagination
            page={currentPage}
            total={totalItemsCount}
            itemsPerPage={itemsPerPage || 10}
            totalPages={totalPageCount}
            onPageClick={(page: number, cursor: string) => {
              setCurrentPage(page);
              setCurrentPageCursor(cursor);
              onPageClick(cursor, page);
            }}
            pages={pageCursors}
            disabled={fetching}
            currentCursor={currentPageCursor}
          />
      </>
    );
  }

  if (fetching && collection.length === 0) {
    return (
      <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white opacity-60">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!fetching && !isNil(error) && collection.length === 0) {
    return (
      <div className="my-20">
        <SimpleError
          message={errorMessage}
          tryAgainCallback={apiTryAgainCallback}
        />
      </div>
    );
  }

  if (!fetching && isEmpty) {
    return <div className="text-sm text-gray-400">{emptyMessage}</div>;
  }

  if (asTable) {
    return (
      <div className="overflow-x-auto">
        {fetching && (
          <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white opacity-60">
            <Spinner size="md" />
          </div>
        )}
        <Table hoverable>
          {tableHead}
          <Table.Body className="divide-y">
            {collection.map((item: any, index: number) => {
              return renderItem(item, index);
            })}
          </Table.Body>
        </Table>

        {!isNil(error) && (
          <div className="mb-4 text-sm text-pink-700">
            Oops! We encountered an error fetching your records. Please try
            again.
          </div>
        )}

        {renderPagination()}
      </div>
    );
  }

  return (
    <div className={containerClass}>
      {!isNil(header) && (
        <h1
          className="mb-6 text-xl text-gray-900"
          data-testid={
            isNil(headerDataTestId) ? 'paginated-table-title' : headerDataTestId
          }
        >
          {header}
        </h1>
      )}
      <div
        className={`${tableContainerClass} relative mb-6 ${
          limited ? 'h-60 overflow-y-auto' : ''
        }`}
      >
        {fetching && (
          <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white opacity-60">
            <Spinner size="md" />
          </div>
        )}
        {collection.map((item: any, index: number) => {
          if (isNil(item)) return null;
          return renderItem(item, index);
        })}
      </div>

      {!isNil(error) && (
        <div className="mx-10 mb-4 text-sm text-pink-700">
          Oops! We encountered an error fetching your records. Please try again.
        </div>
      )}

      {renderPagination()}
    </div>
  );
};

export default PaginatedTable;
