import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredBooleanWhenVerifiedField,
  requiredNumberWhenVerified,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

export const buildValidationSchema = () => {
  const shape = {
    // TODO: Add the rest of the validations on the BE ticket
    informationSource: requiredStringField({attribute: 'Information Source'}),
    buyerGlobalID: requiredStringWhenVerified({attribute: 'Buyer'}),
    primaryDocumentType: requiredStringWhenVerified({
      attribute: 'Primary Document Type',
    }),
    currency: requiredStringWhenVerified({attribute: 'Currency'}),
    totalTradeAmount: requiredNumberWhenVerified({
      attribute: 'Total Trade Amount',
    }),
    unpaidTradeAmount: requiredNumberWhenVerified({
      attribute: 'Unpaid Trade Amount',
    }),
    issuedOn: requiredStringWhenVerified({attribute: 'Issue Date'}),
    verifiedPaidOn: requiredStringWhenVerified({
      attribute: 'Verified Payment Date',
    }),
    isFileClear: requiredBooleanWhenVerifiedField({
      attribute: 'Is Image Clear?',
    }),
    isFileComplete: requiredBooleanWhenVerifiedField({
      attribute: 'Is Document Complete?',
    }),
    isBuyerStamped: requiredBooleanWhenVerifiedField({
      attribute: 'Is Document Stamped by Buyer?',
    }),
    isBuyerSigned: requiredBooleanWhenVerifiedField({
      attribute: 'Is Document Signed by Buyer?',
    }),
    isSuspectedTampered: requiredBooleanWhenVerifiedField({
      attribute: 'File Looks Tampered?',
    }),
    isSuspectedForged: requiredBooleanWhenVerifiedField({
      attribute: 'Signature Looks Forged?',
    }),
    verificationMethod: requiredStringWhenVerified({
      attribute: 'Verification Method',
    }),
    resubmissionReason: requiredStringWhenRequiresResubmission({
      attribute: 'Resubmission Reason',
    }),
    validUntil: requiredStringWhenVerified({attribute: 'Valid Until'}),
    status: requiredStringField({attribute: 'Document Status'}),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
