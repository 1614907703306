import {gql, useMutation} from '@apollo/client';

export const UPDATE_INCOME_TAX_RETURN = gql`
  mutation incomeTaxReturnUpdate($input: IncomeTaxReturnAttribute!) {
    incomeTaxReturnUpdate(input: $input) {
      incomeTaxReturn {
        informationSource
        auditorId
        fiscalYearEndDate
        isSecBirStamped
        companyTin
        currency
        revenue
        costOfSalesServices
        grossIncomeFromOperation
        totalAllowableItemizedDeductions
        netProfit
        currentAssets
        longTermInvestment
        propertyPlantAndEquipment
        longTermReceivables
        intangibleAssets
        otherAssets
        totalAssets
        currentLiabilities
        longTermLiabilities
        totalLiabilities
        totalEquity
        verificationMethod
        resubmissionReason
        validUntil
        status
        id
        leadId
      }
      errors
    }
  }
`;

export function useUpdateIncomeTaxReturn(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_INCOME_TAX_RETURN, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
