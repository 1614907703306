import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_SUPPLIER_COMPANY = gql`
  query supplierCompanyIdOptions(
    $searchParameter: String!
    $specifiedSupplierCompany: Boolean!
  ) {
    supplierCompanyIdOptions(
      searchParameter: $searchParameter
      specifiedSupplierCompany: $specifiedSupplierCompany
    ) {
      value
      label
    }
  }
`;

export function useFetchSupplierCompanySearch(onSuccessCallback: any) {
  return useLazyQuery(FETCH_SUPPLIER_COMPANY, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
