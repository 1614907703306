import {gql, useMutation} from '@apollo/client';

export const UPDATE_CMAP = gql`
  mutation cmapReportUpdate($cmapReport: CMAPReportAttribute!) {
    cmapReportUpdate(input: $cmapReport) {
      cmapReport {
        companyId
        createdAt
        entityName
        entityType
        hasNoFindings
        id
        inquiryDate
        leadId
        notes
        personId
        courtCaseFindings {
          defendantNames
          natureOfCase
          plaintiff
          trialCourtCode
          provinceCode
          salaNumber
          caseNumber
          reportedDate
          caseStatus
        }
        status
        validUntil
      }
      errors
    }
  }
`;

export function useUpdateCmap(onSuccessCallback: (response: any) => void) {
  return useMutation(UPDATE_CMAP, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
