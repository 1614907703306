import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_LEADS = gql`
  query Leads($id: Int!) {
    lead(id: $id) {
      id
      companyName
      hasGis
      rclEndedOn
      seccertCompanyType
    }
  }
`;

export function useFetchLeads(onSuccessCallback: any) {
  return useLazyQuery(FETCH_LEADS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
