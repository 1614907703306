import React from 'react';

interface Props {
  resource: string;
}

const ResourceLoader: React.FC<Props> = ({resource}) => {
  return (
    <div className="flex flex-col items-center justify-center p-4 overflow-hidden opacity-75 self-center h-screen">
      <div className="w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader" />
      <h2 className="text-xl font-semibold text-center">{`Loading ${resource}...`}</h2>
      <p className="w-1/3 text-center">
        This may take a few seconds, please wait.
      </p>
    </div>
  );
};

export default ResourceLoader;
