import 'react-toastify/dist/ReactToastify.css';

import React, {useContext} from 'react';
import {ToastContainer} from 'react-toastify';

import ApplicationContext from '../../contexts/ApplicationContext';
import {mainLeftRoutes, mainRightRoutes} from './Nav';
import Navbar from './Navbar';

interface Props {
  size: 'lg' | 'md' | 'sm' | 'xl';
  dataTestId: string;
  children: React.ReactChild | React.ReactChild[];
}

const AppTemplate: React.FC<Props> = ({size, dataTestId, children}) => {
  const {lead, permissions} = useContext(ApplicationContext);

  return (
    <div data-testid={dataTestId}>
      <div className="flex min-h-screen bg-white">
        <div className="flex-none bg-gray-900"></div>
        <div className="flex-1">
          <Navbar
            leftRoutes={mainLeftRoutes(lead.id)}
            rightRoutes={mainRightRoutes(
              lead.id,
              permissions.downloadSecretaryCertificate,
            )}
          />
          <ToastContainer
            bodyClassName="_padding--normal"
            hideProgressBar
            pauseOnHover
          />
          <div className="text-gray-500 text-sm">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppTemplate;
