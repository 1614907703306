import {isUndefined} from 'lodash-es';
import React from 'react';

interface Props {
  text?: string;
}

const FormLoader: React.FC<Props> = ({text}) => {
  return (
    <div className="flex items-center justify-center p-4 overflow-hidden opacity-75">
      <div className="w-8 h-8 mr-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
      <h2 className="text-xl font-semibold text-center">
        {isUndefined(text) ? 'Populating form...' : text}
      </h2>
    </div>
  );
};

export default FormLoader;
