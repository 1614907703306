import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_DYNAMIC_FORM = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      status
      ... on DynamicDocument {
        fileName
        fileType
        filePath
        data
      }
    }
  }
`;

export function useFetchDynamicForm(onSuccessCallback: any) {
  return useLazyQuery(FETCH_DYNAMIC_FORM, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
