import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_AUDITOR = gql`
  query AuditorIdOptions(
    $searchParameter: String!
    $specifiedAuditor: Boolean!
  ) {
    auditorIdOptions(
      searchParameter: $searchParameter
      specifiedAuditor: $specifiedAuditor
    ) {
      value
      label
    }
  }
`;

export function useFetchAuditorSearch(onSuccessCallback: any) {
  return useLazyQuery(FETCH_AUDITOR, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
