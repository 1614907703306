import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredArrayWhenVerified,
  requiredNumberWhenVerified,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

export const buildValidationSchema = () => {
  const shape = {
    informationSource: requiredStringField({attribute: 'Information Source'}),
    auditorId: requiredStringWhenVerified({attribute: 'Auditor ID'}),
    revenueOn: requiredStringWhenVerified({attribute: 'Fiscal Year End Date'}),
    documentYear: requiredStringWhenVerified({attribute: 'Filing Year'}),
    currency: requiredStringWhenVerified({attribute: 'Currency'}),
    revenue: requiredNumberWhenVerified({attribute: 'Revenue'}),
    balanceSheet: requiredArrayWhenVerified({
      attribute: 'Statement of Financial Position',
    }),
    incomeStatement: requiredArrayWhenVerified({
      attribute: 'Income Statement',
    }),
    isSecBirAcknowledged: requiredStringWhenVerified({
      attribute: 'Acknowledged by SEC or BIR?',
    }),
    hasAccreditedAuditor: requiredStringWhenVerified({
      attribute: 'Auditor is Accredited?',
    }),
    verificationMethod: requiredStringWhenVerified({
      attribute: 'Verification Method',
    }),
    resubmissionReason: requiredStringWhenRequiresResubmission({
      attribute: 'Resubmission Reason',
    }),
    validUntil: requiredStringWhenVerified({attribute: 'Valid Until'}),
    status: requiredStringField({attribute: 'Document Status'}),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
