import {isNil} from 'lodash-es';

import {
  notEmptyValidator,
  positiveNumberWithTwoDecimalPlaces,
} from '../handsontable/customValidators';
import {SelectOption} from './application';
import {editableColumnProps} from './editableTable';

export interface TradingRelationship {
  amountLast12Months: number | null;
  buyer: BuyerOption | string | null;
  buyerType: string | null;
  currency: string;
  hasNda: boolean | null;
  informationSource: string | null;
  productProvided: string | null;
  relationshipType: string | null;
  resubmissionReason: string | null;
  riskopsNotes: string;
  salesNotes: string;
  startDate: Date | null;
  status: string;
  validUntil: Date | null;
  verificationMethod: string | null;
  documentFormat: string | null;
  timePeriod: string | null;
  fcCustomerTin: string | null;
  fcCustomerName: string | null;
  fcCustomerAddress: string | null;
}

export interface TradingRelationshipLineItem {
  id?: string | null;
  atcCode?: string | null;
  month?: string | null;
  taxRate?: string | null;
  taxWithheld?: string | null;
  tradingAmount?: string | null;
  tradingPartnerName: string;
  tradingPartnerTin: string;
  buyerId?: string | null;
  buyerName?: string | null;
  relationshipType: string;
  hasNda?: boolean | null;
  verificationMethod?: string | null;
  currency?: string | null;
}

export const tradingRelationshipDefault: TradingRelationship = {
  amountLast12Months: null,
  buyer: null,
  buyerType: null,
  currency: '',
  hasNda: null,
  informationSource: null,
  productProvided: null,
  relationshipType: null,
  resubmissionReason: null,
  riskopsNotes: '',
  salesNotes: '',
  startDate: null,
  status: '',
  validUntil: null,
  verificationMethod: null,
  documentFormat: '',
  timePeriod: '',
  fcCustomerTin: '',
  fcCustomerName: '',
  fcCustomerAddress: '',
};

export const tradingRelationshipLineItemDefault: TradingRelationshipLineItem = {
  id: null,
  atcCode: null,
  month: null,
  taxRate: null,
  taxWithheld: null,
  tradingAmount: null,
  tradingPartnerName: '',
  tradingPartnerTin: '',
  relationshipType: '',
  hasNda: null,
  verificationMethod: null,
  currency: null,
  buyerId: null,
  buyerName: null,
};

const emptyOption: SelectOption = {
  label: 'Select',
  value: null,
};

export const documentFormatOptions: SelectOption[] = [
  {label: 'SAWT', value: 'SAWT'},
  {label: 'SLS', value: 'SLS'},
  {label: 'SLP', value: 'SLP'},
  {label: 'Alphalist of Payees', value: 'ALPHALIST_OF_PAYEES'},
  {label: 'TR Form', value: 'TR_FORM'},
  {label: 'Government Scraping', value: 'GOVERNMENT_SCRAPING'},
  {label: 'Customer Website', value: 'CUSTOMER_WEBSITE'},
];

export const informationSourceOptions: SelectOption[] = [
  emptyOption,
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'SAWT', value: 'SAWT'},
  {label: 'Official Receipt', value: 'OFFICIAL_RECEIPT'},
  {label: 'Summary List of Sales', value: 'SUMMARY_LIST_OF_SALES'},
];

export const buyerTypeOptions: SelectOption[] = [
  emptyOption,
  {label: 'Single', value: 'SINGLE'},
  {label: 'Partnership', value: 'PARTNERSHIP'},
  {label: 'Corporation', value: 'CORPORATION'},
  {label: 'Government', value: 'GOVERNMENT'},
  {label: 'Cooperative', value: 'COOPERATIVE'},
];

export const relationshipTypeOptions: SelectOption[] = [
  emptyOption,
  {label: 'Buyer', value: 'BUYER'},
  {label: 'Supplier', value: 'SUPPLIER'},
];

export const productProvidedOptions: SelectOption[] = [
  emptyOption,
  {label: 'Goods', value: 'GOODS'},
  {label: 'Services', value: 'SERVICES'},
  {label: 'Manpower', value: 'MANPOWER'},
  {label: 'Others', value: 'OTHERS'},
];
export const currencyOptions: SelectOption[] = [
  emptyOption,
  {label: 'PHP', value: 'PHP'},
  {label: 'USD', value: 'USD'},
];

export const verificationMethodOptions: SelectOption[] = [
  emptyOption,
  {label: 'Portal Viewing', value: 'PORTAL_VIEWING'},
  {label: 'Email', value: 'EMAIL'},
  {label: 'Phone', value: 'PHONE'},
  {label: 'Stamp/Signature', value: 'STAMP_SIGNATURE'},
  {label: 'Automatic', value: 'AUTOMATIC'},
  {label: 'Post Dated Cheque', value: 'POST_DATED_CHEQUE'},
  {label: 'Bank Statement', value: 'BANK_STATEMENT'},
  {label: 'Client Website', value: 'CLIENT_WEBSITE'},
];

export const resubmissionReasonOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Buyer is a Related Party', value: 'BUYER_IS_A_RELATED_PARTY'},
  {label: 'Buyer is Blacklisted', value: 'BUYER_IS_BLACKLISTED'},
  {
    label: 'Buyer is Not a Registered Business',
    value: 'BUYER_IS_NOT_A_REGISTERED_BUSINESS',
  },
  {label: 'Buyer is Sole Prop', value: 'BUYER_IS_SOLE_PROP'},
  {label: 'Buyer Name is Missing', value: 'BUYER_NAME_IS_MISSING'},
  {label: 'Cropped', value: 'CROPPED'},
  {
    label: 'Incomplete Contact Information',
    value: 'INCOMPLETE_CONTACT_INFORMATION',
  },
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Missing Stamp', value: 'MISSING_STAMP'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const hasNdaOptions: SelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const buildBuyerOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.buyerIdOptions.length === 0) return [];

  const options = data.buyerIdOptions;

  return [{label: 'Select', value: ''}, ...options];
};

export const tradingRelationshipLineItemColumns: editableColumnProps[] = [
  {
    name: 'Month',
    config: {
      data: 'month',
      allowEmpty: false,
    },
  },
  {
    name: 'Partner Name',
    config: {
      data: 'tradingPartnerName',
      validator: notEmptyValidator,
    },
  },
  {
    name: 'Partner TIN',
    config: {
      data: 'tradingPartnerTin',
      validator: notEmptyValidator,
    },
  },
  {
    name: 'Amount',
    config: {
      data: 'tradingAmount',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.00',
        culture: 'en-us',
      },
      allowEmpty: true,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Tax Withheld',
    config: {
      data: 'taxWithheld',
      type: 'numeric',
      allowEmpty: true,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'ATC Code',
    config: {
      data: 'atcCode',
      allowEmpty: true,
    },
  },
  {
    name: 'Tax Rate',
    config: {
      data: 'taxRate',
      type: 'numeric',
      allowEmpty: true,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Buyer ID',
    config: {
      data: 'buyerId',
      allowEmpty: true,
    },
  },
  {
    name: 'Buyer Name',
    config: {
      data: 'buyerName',
      allowEmpty: true,
    },
  },
  {
    name: 'Relationship Type',
    config: {
      data: 'relationshipType',
      type: 'dropdown',
      source: ['BUYER', 'SUPPLIER'],
      validator: notEmptyValidator,
    },
  },
  {
    name: 'Verification Method',
    config: {
      data: 'verificationMethod',
      type: 'dropdown',
      source: [
        'PORTAL_VIEWING',
        'EMAIL',
        'PHONE',
        'STAMP_SIGNATURE',
        'AUTOMATIC',
        'POST_DATED_CHEQUE',
        'BANK_STATEMENT',
        'CLIENT_WEBSITE',
      ],
      allowEmpty: true,
    },
  },
  {
    name: 'Has NDA',
    config: {
      data: 'hasNda',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'Currency',
    config: {
      data: 'currency',
      type: 'dropdown',
      source: ['PHP', 'USD'],
      allowEmpty: true,
      allowInvalid: false,
    },
  },
];

interface TradingRelationshipLineItemsDefault {
  tradingRelationshipLineItems: TradingRelationshipLineItem[];
}

export const tradingRelationshipLineItemsDefault: TradingRelationshipLineItemsDefault = {
  tradingRelationshipLineItems: [],
};
