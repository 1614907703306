import {isEmpty, isNil} from 'lodash-es';
import React, {useContext, useEffect, useState} from 'react';
import {
  FaCloudUploadAlt,
  FaFileImage,
  FaFilePdf,
  FaRegFileImage,
} from 'react-icons/fa';

import ApplicationContext from '../../../contexts/ApplicationContext';
import {Attachment} from '../../../interfaces/application';
import {ModalComponent} from '../modals/ModalComponents';

// TODO: Finalize this.
// 4 mb.
const FILE_SIZE_LIMIT = 5 * 1024 * 1024;

// For now we only allow thes file extensions
export type AllowedFileType = 'jpeg' | 'jpg' | 'pdf' | 'png' | 'csv';

interface Props {
  classes?: string;
  dataTestId?: string;
  deleteAllowedOnUploaded?: boolean;
  disabled?: boolean;
  error?: string;
  hideInput?: boolean;
  hideLabel?: boolean;
  inlineLabel?: boolean;
  label?: string;
  lighter?: boolean;
  multiple?: boolean;
  onChange: (value: any) => void;
  onUploadedDeleteCallback?: (files: Attachment[]) => void;
  required?: boolean;
  savedFiles: Attachment[]; // TODO: This should be an array
  sublabel?: string;
  fileLimit?: number; // in MB
  fileTypes?: string[];
  pdfCount?: number;
}

export interface FileUpload {
  path: string;
  file: any;
  type: AllowedFileType;
}

const FileUpload: React.FC<Props> = ({
  classes,
  deleteAllowedOnUploaded,
  disabled,
  error,
  fileLimit,
  fileTypes,
  pdfCount,
  hideInput,
  hideLabel,
  inlineLabel,
  label,
  lighter,
  multiple,
  onChange,
  onUploadedDeleteCallback,
  required,
  savedFiles,
  sublabel,
}) => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Attachment[]>(savedFiles);
  const {setModalType, setModalProps, lead} = useContext(ApplicationContext);

  const handleChange = (event: any) => {
    let fileInstances: FileUpload[] = [...files];
    const uploadedCSV = fileInstances.filter((file) => file['type'] === 'csv');
    const uploadedPDF = fileInstances.filter((file) => file['type'] === 'pdf');
    if (uploadedCSV.length === 1) return;
    if (pdfCount && pdfCount > 0 && uploadedPDF.length === pdfCount) return;
    let fileType: string;
    let fileSize: number;
    const whiteListedFileTypes = fileTypes || ['jpeg', 'pdf', 'png'];
    const defaultFileLimit = (fileLimit || 5) * 1024 * 1024;

    const isFileValid = (fileType: string, fileSize: number) => {
      if (!whiteListedFileTypes.includes(fileType)) return false;
      if (fileSize >= defaultFileLimit) return false;

      return true;
    };

    if (multiple) {
      let inputFiles = Array.prototype.slice.call(event.target.files);
      const csv_files = inputFiles.filter((file) => file.type === 'text/csv');
      const pdf_files = inputFiles.filter(
        (file) => file.type === 'application/pdf',
      );
      if (csv_files.length > 0) inputFiles = [csv_files[0]];
      if (pdfCount && pdfCount > 0) {
        inputFiles = pdf_files.slice(0, pdfCount);
      }

      inputFiles.map((file: any) => {
        fileType = file.type.replace(/(.*)\//g, '');
        fileSize = Math.round(file.size / 1024);

        if (isFileValid(fileType, fileSize)) {
          fileInstances = [
            ...fileInstances,
            {
              file,
              path: URL.createObjectURL(file),
              type: fileType as AllowedFileType,
            },
          ];
        }
      });
    } else {
      const file = event.target.files[0];
      fileType = file.type.replace(/(.*)\//g, '');
      fileSize = Math.round(file.size / 1024);

      if (isFileValid(fileType, fileSize)) {
        fileInstances = [
          {
            file: file,
            path: URL.createObjectURL(event.target.files[0]),
            type: fileType as AllowedFileType,
          },
        ];
      }
    }

    const formFiles = fileInstances.map((file: FileUpload) => file.file);

    multiple ? onChange(formFiles) : onChange(formFiles[0]);

    // This allows this input file field to allow same file.
    event.target.value = null;

    setFiles(fileInstances);
  };

  // Remove created Url Objects from memory to avoid leak
  // when the component unmounts.
  useEffect(() => {
    return () => {
      if (files.length > 0) {
        files.map((file: FileUpload) => {
          return () => URL.revokeObjectURL(file.path);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (savedFiles?.length === 0) {
      setFiles([]);
    }
  }, [savedFiles]);

  const handlePreviewRemove = (file: FileUpload) => {
    URL.revokeObjectURL(file.path);
    const fileInstances = [...files].filter(
      (fileInstance: FileUpload) => file.path !== fileInstance.path,
    );
    const formFiles = fileInstances.map((file: FileUpload) => file.file);

    if (multiple) {
      onChange(formFiles.length === 0 ? undefined : formFiles);
    } else {
      onChange(undefined);
    }

    setFiles(fileInstances);
  };

  const onDeleteSavedAttachmentCallback = (id: string) => {
    const newuploadedFiles = uploadedFiles.filter(
      (uploadedFile: Attachment) => uploadedFile.id !== id,
    );
    setUploadedFiles(newuploadedFiles);

    if (!isNil(onUploadedDeleteCallback)) {
      onUploadedDeleteCallback(newuploadedFiles);
    }
  };

  const handleSavedAttachmentRemove = (file: Attachment) => {
    setModalType(ModalComponent.DELETE_ATTACHMENT_CONFIRMATION_MODAL);
    setModalProps({
      modalTitle: 'Remove File Attachment',
      attachmentId: file.id,
      leadId: `${lead.id}`,
      onDeleteSuccessCallback: onDeleteSavedAttachmentCallback,
    });
  };

  const onPreviewClick = (file: FileUpload) => {
    setModalType(ModalComponent.FILE_VIEWER_MODAL);
    setModalProps({
      fileName: file.file.name,
      filePath: file.path,
      fileType: file.type,
      modalTitle: file.file.name,
    });
  };

  const renderFileAvatar = (file: FileUpload) => {
    let Icon: any = '';

    switch (file.type) {
      case 'jpeg':
      case 'jpg':
        Icon = FaRegFileImage;
        break;
      case 'png':
        Icon = FaFileImage;
        break;
      case 'pdf':
        Icon = FaFilePdf;
        break;
      default:
        Icon = FaFileImage;
    }

    const renderToolTip = () => {
      return (
        <div className="tooltip -mt-10">
          <div className="absolute bottom-0 flex flex-col items-center hidden group-hover:flex" />
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-primary-500 shadow-lg rounded rounded-md inline-block">
            {file.file.name}
          </span>
          <div className="w-3 h-3 transform ml-1 -mt-2 rotate-45 bg-primary-500"></div>
        </div>
      );
    };

    return (
      <div
        key={file.path}
        className="relative inline-block text-primary-300 hover:text-primary-500 has-tooltip mr-1"
      >
        {renderToolTip()}
        <Icon size={46} onClick={() => onPreviewClick(file)} />
        <span
          className="absolute top-0 right-0 inline-block -mt-2 w-6 h-6 bg-primary-500 text-sm font-bold hover:bg-primary-300 hover:text-primary-500 text-center border-2 border-white rounded-full text-white"
          onClick={() => handlePreviewRemove(file)}
        >
          x
        </span>
        <div className="text-center -mt-0.5 w-full px-1 py-1 text-white bg-primary-500 text-xs font-bold rounded shadow-md">
          {file.type}
        </div>
      </div>
    );
  };

  const renderSavedAvatar = (attachment: Attachment) => {
    if (isNil(attachment)) return null;

    const fileName = attachment.url.substring(
      attachment.url.lastIndexOf('/') + 1,
    );
    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);

    let Icon: any = '';

    switch (fileType) {
      case 'jpeg':
      case 'jpg':
        Icon = FaRegFileImage;
        break;
      case 'png':
        Icon = FaFileImage;
        break;
      case 'pdf':
        Icon = FaFilePdf;
        break;
      default:
        Icon = FaFileImage;
    }

    const file: FileUpload = {
      path: attachment.url,
      file: {name: fileName},
      type: fileType as AllowedFileType,
    };

    const renderToolTip = () => {
      return (
        <div className="tooltip -mt-10">
          <div className="absolute bottom-0 flex flex-col items-center hidden group-hover:flex" />
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-primary-500 shadow-lg rounded rounded-md inline-block">
            {file.file.name}
          </span>
          <div className="w-3 h-3 transform ml-1 -mt-2 rotate-45 bg-primary-500"></div>
        </div>
      );
    };

    return (
      <div
        key={attachment.id}
        className="relative inline-block text-green-300 hover:text-green-500 has-tooltip mr-1"
      >
        {renderToolTip()}
        <Icon size={46} onClick={() => onPreviewClick(file)} />
        {deleteAllowedOnUploaded && (
          <span
            className="absolute top-0 right-0 inline-block -mt-2 w-6 h-6 bg-green-500 text-sm font-bold hover:bg-primary-300 hover:text-primary-500 text-center border-2 border-white rounded-full text-white"
            onClick={() => handleSavedAttachmentRemove(attachment)}
          >
            x
          </span>
        )}
        <div className="text-center -mt-0.5 w-full px-1 py-1 text-white bg-green-500 text-xs font-bold rounded shadow-md">
          {file.type}
        </div>
      </div>
    );
  };

  const errorClass = !isNil(error) ? 'border-red-600' : '';

  const renderFileAvatars = (files): [] | undefined => {
    if (isEmpty(files)) return;
    return files.map((file: FileUpload) => {
      return renderFileAvatar(file);
    });
  };

  const renderSavedAvatars = (uploadedFiles): [] | undefined => {
    if (isEmpty(uploadedFiles)) return;
    return uploadedFiles.map((file: Attachment) => {
      return renderSavedAvatar(file);
    });
  };

  const renderLabel = () => {
    return (
      <label
        className={`w-full flex ${
          inlineLabel
            ? 'flex-row gap-4 place-content-center'
            : 'flex-col uppercase'
        } border ${errorClass} items-center py-4 ${
          lighter ? 'bg-white border-gray-300' : 'bg-fcSecondary-300 shadow-lg'
        } text-primary-500 rounded tracking-wide border border-blue cursor-pointer
        ${disabled ? '' : 'hover:bg-primary-500 hover:text-white'}`}
      >
        <span>
          <FaCloudUploadAlt size={20} />
        </span>
        <span
          className={`${inlineLabel ? '' : 'mt-2'} text-base leading-normal`}
        >
          {sublabel || 'Select a file'}
        </span>
        <input
          disabled={disabled || false}
          type="file"
          multiple
          className="hidden"
          onChange={handleChange}
        />
      </label>
    );
  };

  return (
    <div className={`w-full ${classes}`}>
      {!hideLabel && !hideInput && (
        <label className="flex block tracking-wide text-xs font-bold mb-2">
          <span className="mr-1 uppercase">{label}</span>
          {required ? (
            <span className="text-red-500">*</span>
          ) : (
            <span className="text-gray-300">(optional)</span>
          )}
        </label>
      )}

      {!hideInput && (
        <>
          <div className="flex w-full items-center">{renderLabel()}</div>
          <div>
            {!isNil(error) && (
              <p className="text-red-500 text-xs italic mt-4">{error}</p>
            )}
          </div>
        </>
      )}

      <div className="flex mt-6">
        {renderFileAvatars(files)}
        {renderSavedAvatars(uploadedFiles)}
      </div>
    </div>
  );
};

export default FileUpload;
