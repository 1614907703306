import React, {useContext} from 'react';

import DocumentsContext from '../../../../../contexts/DocumentsContext';
import {perPage} from '../../../../../interfaces/documents';
import {titleizeUnderscoredWords} from '../../../../../utils/string';
import DateRangePicker from '../../../inputs/DateRangePicker';
import Select from '../../../inputs/Select';

const SummaryFilters = () => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    documentStatus,
    documentStatuses,
    setDocumentStatus,
    setPagination,
    setPageNum,
    defaultEndDate,
    defaultStartDate,
  } = useContext(DocumentsContext);

  const resetPageDetails = () => {
    setPageNum(1);

    setPagination({
      after: null,
      before: null,
      first: perPage,
      last: perPage,
    });
  };

  const handleSubmittedDate = (selStartDate, selEndDate) => {
    setStartDate(selStartDate);
    setEndDate(selEndDate);
    resetPageDetails();
  };

  const handleOnChange = (value: string | null) => {
    setDocumentStatus(value);
    resetPageDetails();
  };

  const docStateOptions = ['All', ...documentStatuses].map((documentStatus) => {
    return {
      value: documentStatus,
      label: titleizeUnderscoredWords(documentStatus),
    };
  });

  return (
    <div className="container" data-testid="documents-table-filters">
      <div className="grid grid-cols-8 mt-2 mb-4 gap-4">
        <div className="col-span-2">
          <Select
            label="Document Status"
            options={docStateOptions}
            defaultValue={documentStatus}
            dataTestId="document-status-filter"
            onChange={(status: string) => {
              handleOnChange(status);
            }}
            classes={'px-3'}
            hideRequiredTag={true}
          />
        </div>
        <div className="col-span-2">
          <DateRangePicker
            label="Submitted Date"
            onDateRangeChange={handleSubmittedDate}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            allowResetToDefault
            currentStartDate={startDate}
            currentEndDate={endDate}
            hideRequiredTag={true}
            dataTestId="SubmittedDateFilter"
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryFilters;
