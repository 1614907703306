import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_REPORT_GENERATORS = gql`
  query reportGeneratorOptions(
    $searchParameter: String
    $specifiedReportGenerator: Boolean
  ) {
    reportGeneratorOptions(
      searchParameter: $searchParameter
      specifiedReportGenerator: $specifiedReportGenerator
    ) {
      value
      label
    }
  }
`;

export function useFetchReportGenerators(onSuccessCallback?: any) {
  return useLazyQuery(FETCH_REPORT_GENERATORS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
