import {isEmpty, isNil} from 'lodash-es';
import React, {useContext, useEffect, useState} from 'react';

import {useUpdateTradingRelationshipLineItems} from '../../../../../api/mutations/tradingRelationshipLineItem';
import {useFetchTradingRelationshipLineItems} from '../../../../../api/queries/tradingRelationshipLineItems';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {
  tradingRelationshipLineItemColumns,
  tradingRelationshipLineItemDefault,
  tradingRelationshipLineItemsDefault,
} from '../../../../../interfaces/tradingRelationship';
import {showNotification} from '../../../../../utils/toast';
import {buildValidationSchema} from '../../../../../validations/TradingRelationshipLineItem';
import Button from '../../../../common/buttons/Button';
import Loader from '../../../../common/loaders/Loader';
import EditableTable from '../../../inputs/EditableTable';
import TablePagination from '../../../paginations/TablePagination';
import FormLoader from '../forms/FormLoader';
import {isTempWorkerAndHasNoAccess} from '../forms/utils/HelperFunctions';
import { FaWindowClose } from 'react-icons/fa';

const TradingRelationshipLineItemsTable = ({tradingRelationship}) => {
  const {selectedDocument} = useContext(DocumentsContext);
  const {permissions} = useContext(ApplicationContext);
  const itemsPerPage = 20;
  const [showLineItemStateMessage, setShowLineItemStateMessage] = useState<boolean>(false);
  const hasNegativeLineItemsState = selectedDocument.lineItemsState && 
    (selectedDocument.lineItemsState !== "SCRAPED" && selectedDocument.lineItemsState !== "MANUALLY_UPDATED");

  // for own pagination
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [paginationDetails, setPaginationDetails] = useState([]);
  const [pagination, setPagination] = useState({
    first: null,
    last: null,
    after: null,
    before: null,
  });
  const [pageNum, setPageNum] = useState(1);

  const onFetchSuccess = (response: any) => {
    if (!isNil(response)) {
      const {
        nodes,
        pageInfo: {startCursor, endCursor},
        pages,
        totalCount,
        totalPages,
      } = response.tradingRelationshipLineItems;

      if (nodes.length >= 1) {
        updateValuesAtOnce(nodes);
        setPaginationDetails(pages);
        setPagination(pages[pageNum - 1]);
      } else {
        // array of tradingRelationshipLineItemDefault instances
        const spareLineItems = Array.from({length: 5}, () => ({
          ...tradingRelationshipLineItemDefault,
        }));
        updateValuesAtOnce(spareLineItems);

        setPagination({
          first: pagination.first,
          last: pagination.last,
          after: startCursor,
          before: endCursor,
        });
      }
      setTotalItems(totalCount);
      setTotalPages(totalPages);
    }
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      const {
        tradingRelationshipId,
        errors,
      } = response.tradingRelationshipLineItemsUpdate;

      if (!isEmpty(errors)) {
        showNotification(
          'error',
          `Trading Relationship Line Items update failed! ${errors}.`,
        );
        return;
      }

      showNotification('success', 'Trading Relationship Line Items updated!');

      // trigger query here to get
      // trading relationship line items with pagination
      fetchTradingRelationshipLineItems({
        variables: {tradingRelationshipId: tradingRelationshipId},
      });
    }
  };

  const [
    fetchTradingRelationshipLineItems,
    {loading: fetchingTradingRelationshipLineItems},
  ] = useFetchTradingRelationshipLineItems(onFetchSuccess);

  const [
    updateTradingRelationshipLineItems,
    {loading: updatingTradingRelationshipLineItems},
  ] = useUpdateTradingRelationshipLineItems(updateSuccessCallback);

  useEffect(() => {
    const id = tradingRelationship.id || selectedDocument.id;

    if (id) {
      fetchTradingRelationshipLineItems({
        variables: {
          tradingRelationshipId: id,
          first: pagination.first,
          last: pagination.last,
          after: pagination.after,
          before: pagination.before,
        },
      });

      if (hasNegativeLineItemsState) {
        setShowLineItemStateMessage(true);
      }
    }
  }, [tradingRelationship, selectedDocument.id, pageNum]);

  const submit = async () => {
    const lineItemValidationSchema = buildValidationSchema();

    const payload = await Promise.all(
      values.map(async (item) => {
        try {
          await lineItemValidationSchema.validate(item);
          const {__typename, ...newItem} = item;
          return newItem;
        } catch (error) {
          showNotification(
            'error',
            `Trading Relationship Line Items update halted! ${error.errors.join(
              ' ',
            )}`,
            10000,
          );
          return null;
        }
      }),
    );

    if (payload.some((item) => item === null)) return;

    updateTradingRelationshipLineItems({
      variables: {
        input: {
          tradingRelationshipId: tradingRelationship.id || selectedDocument.id,
          lineItems: payload,
        },
      },
    });
  };

  const viewPage = (page: number) => {
    let pageDetailIndex = page - 1;
    if (page > totalPages) pageDetailIndex = totalPages - 1;
    if (!page || page <= 0) pageDetailIndex = 0;
    setPagination(paginationDetails[pageDetailIndex]);
    setPageNum(page);
  };

  const {values, handleSubmit, updateValuesAtOnce} = useForm({
    defaultState: tradingRelationshipLineItemsDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const renderLineItemsState = () => {
    if (!showLineItemStateMessage) return;
    if (fetchingTradingRelationshipLineItems) 
      return;

    let lineItemStateDescription;

    switch (selectedDocument.lineItemsState) {
      case "INVALID_MULTIPLE_PAYEES":
        lineItemStateDescription = "The scraper had no result due to invalid multiple payees.";
        break
      case "UNSUPPORTED_DOCUMENT":
        lineItemStateDescription = (
          <div>
            The scraper had no result due to unsupported document. <br />
            Possible reasons include image-based pages, invalid table formats, or selecting the wrong scraper algorithm.
          </div>
        );
        break
      default:
        lineItemStateDescription = "The scraper had no result.";
        break
    };

    return (
      <div>
        <div className='w-fit max-w-md mt-6 m-auto flex flex-col bg-pink-100 rounded-lg p-4'>
          <div className='flex justify-end'>
            <FaWindowClose
              onClick={() => setShowLineItemStateMessage(false)}
              className="ml-4 cursor-pointer"
              size={20}
            />
          </div>
          <div className='mt-1 text-black'>{lineItemStateDescription}</div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (fetchingTradingRelationshipLineItems) 
      return <Loader />;

    if (tradingRelationship.status === "SCRAPING")
      return <Loader 
        header="Scraping..."
        message="Please refresh the page or come back after a short while."
        messageClass='text-center mt-2'
      />;

    if (updatingTradingRelationshipLineItems)
      return <FormLoader text="Updating Trading Relationship Line Items" />;

    return (
      <div className="py-6">
        <EditableTable
          tableLabel="Trading Relationship Line Items"
          headerProps={tradingRelationshipLineItemColumns}
          dataProps={values as Record<string, unknown>[]}
          minSpareRows={0}
          height={'auto'}
        />

        <TablePagination
          currentPage={pageNum}
          totalPages={totalPages}
          items={totalItems}
          itemPerPage={itemsPerPage}
          onPageClick={(page: number) => viewPage(page)}
        />

        <Button
          dataTestId="submit"
          disabled={
            isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
            updatingTradingRelationshipLineItems ||
            fetchingTradingRelationshipLineItems
          }
          label="Save line items"
          type="primary"
          className="mt-4 w-40 rounded-none"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  return (
    <div>
      {renderLineItemsState()}
      {renderTable()}
    </div>
  );
};

export default TradingRelationshipLineItemsTable;
