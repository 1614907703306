import React, {useContext} from 'react';

import ApplicationContext from '../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../contexts/DocumentsContext';
import {
  Document,
  documentDefault,
  documentTypeEnum,
  perPage,
} from '../../../../interfaces/documents';
import {dateFormatter} from '../../../../utils/dateFormater';
import {setTextColor, statusIcon} from '../../../../utils/docStatusFormatter';
import {titleizeUnderscoredWords} from '../../../../utils/string';

const DocumentsSidebar: React.FC = () => {
  const {
    latestSubmittedDocuments,
    setActiveDocumentGroup,
    activeDocumentGroup,
    setDocumentStatus,
    setEndDate,
    setEntityType,
    setPageNum,
    setPagination,
    setStartDate,
    setSelectedDocument,
  } = useContext(DocumentsContext);

  const {showSelectedDocument, setShowSelectedDocument} = useContext(
    ApplicationContext,
  );

  const handleSelect = (documentType: string): void => {
    setSelectedDocument(documentDefault);
    setActiveDocumentGroup(documentType);
    setPageNum(1);
    setPagination({
      after: null,
      before: null,
      first: perPage,
      last: perPage,
    });

    if (
      ['LOANDEX_REPORT', 'NFIS_REPORT', 'CMAP_REPORT'].includes(documentType)
    ) {
      setDocumentStatus(null);
      setEndDate(null);
      setStartDate(null);
    } else {
      setEntityType(null);
    }

    if (showSelectedDocument) setShowSelectedDocument(false);
  };

  const isActive = (documentType: string) => {
    if (documentType.length === 0) return '';
    if (documentType === activeDocumentGroup) return 'border-l-8 bg-blue-50';
    return '';
  };

  const renderStatusIcon = (document: Document) => {
    if (document.documentType === activeDocumentGroup)
      return statusIcon(titleizeUnderscoredWords(document.status));
    return;
  };

  const renderListItem = (submittedDocument: Document) => {
    const documentLabel = submittedDocument.documentType;

    const linkCss = `block border-primary-500 cursor-pointer hover:bg-blue-50 ${isActive(
      documentLabel,
    )}`;

    const statusCss = `font-medium ${setTextColor(
      titleizeUnderscoredWords(submittedDocument.status),
    )}`;

    return (
      <li
        data-testid="sidebar-item"
        className="w-full border-b border-blue-50"
        key={submittedDocument.id}
      >
        <a
          className={linkCss}
          onClick={() => handleSelect(documentLabel)}
          data-testid={`${submittedDocument.documentType}_LINK`}
        >
          <div className="inline-flex w-full justify-between pt-4 px-4">
            <span className="font-bold text-gray-500 text-left">
              {documentTypeEnum[documentLabel]}
            </span>
            {renderStatusIcon(submittedDocument)}
          </div>
          <p className="pl-4 text-gray-400 text-left pb-1">
            Submitted Date: {dateFormatter(submittedDocument.createdAt)}
          </p>
          <p className="pl-4 pb-4 text-gray-400 text-left">
            <span className="pr-1">Document Status: </span>
            <span className={statusCss}>
              {titleizeUnderscoredWords(submittedDocument.status)}
            </span>
          </p>
        </a>
      </li>
    );
  };

  return (
    <div className="border-r" data-testid="documents-sidebar">
      <a
        className="cursor-pointer block hover:bg-blue-50 text-center text-xl p-4 border-b border-blue-50 text-gray-600 font-bold"
        onClick={() => handleSelect('')}
        data-testid={'SUBMITTED_DOCUMENT'}
      >
        Submitted Documents
      </a>
      <ul>
        {(latestSubmittedDocuments as Document[]).map((submittedDocument) => {
          return renderListItem(submittedDocument);
        })}
      </ul>
    </div>
  );
};

export default DocumentsSidebar;
