import React, {useContext} from 'react';

import DocumentsContext from '../../../../contexts/DocumentsContext';
import {datetimeFormatter} from '../../../../utils/dateFormater';

const DocumentInfo: React.FC = () => {
  const {selectedDocument} = useContext(DocumentsContext);
  const {submittedBy, createdAt, updatedBy, updatedAt} = selectedDocument;

  const submittedByDate = createdAt ? datetimeFormatter(createdAt) : '';
  const modifiedOnDate = updatedAt ? datetimeFormatter(updatedAt) : '';

  return (
    <div className="bg-fcLightGray">
      <div className="document-metadata m-3">
        <div className="text-sm font-normal">
          <strong> Document Information </strong>
        </div>
        <div>
          <span className="text-sm font-normal text-gray-500 pr-1">
            Submitted by:
          </span>
          <strong className="text-sm text-black-500">{submittedBy}</strong>
        </div>
        <div>
          <span className="text-sm font-normal text-gray-500 pr-1">
            Submitted on:
          </span>
          <strong className="text-sm text-black-500">{submittedByDate}</strong>
        </div>
        <div>
          <span className="text-sm font-normal text-gray-500 pr-1">
            Modified by:
          </span>
          <strong className="text-sm text-black-500">{updatedBy}</strong>
        </div>
        <div>
          <span className="text-sm font-normal text-gray-500 pr-1">
            Modified on:
          </span>
          <strong className="text-sm text-black-500">{modifiedOnDate}</strong>
        </div>
      </div>
      <hr />
    </div>
  );
};
export default DocumentInfo;
