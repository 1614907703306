import {isNull, isUndefined} from 'lodash-es';
import {PDFDocument} from 'pdf-lib';
import React, {useContext, useState} from 'react';

import {useUploadDocument} from '../../../api/mutations/uploadDocument';
import ApplicationContext from '../../../contexts/ApplicationContext';
import DocumentsContext from '../../../contexts/DocumentsContext';
import useForm from '../../../hooks/useForm';
import {SelectOption} from '../../../interfaces/application';
import {Document, documentDefault} from '../../../interfaces/documents';
import {CommonModalProps} from '../../../interfaces/modal';
import {showNotification} from '../../../utils/toast';
import {buildValidationSchema} from '../../../validations/DocumentAttachment';
import Button from '../buttons/Button';
import FileUpload from '../inputs/FileUpload';
import DynamicSelect from '../inputs/Select';
import {
  isSalesAdmin,
  limitedDocumentTypeOptions,
} from '../pages/documents/forms/utils/HelperFunctions';

export interface OwnProps {
  selectedDocument?: Document;
  goToPage: (response: any) => void;
}
export type Props = OwnProps & CommonModalProps;

const UploadDocumentModal: React.FC<Props> = ({
  selectedDocument,
  hideModal,
  goToPage,
}) => {
  const {
    lead,
    permissions,
    setShowSelectedDocument,
    showSelectedDocument,
  } = useContext(ApplicationContext);

  const closeModal = () => {
    if (!isNull(hideModal) && !isUndefined(hideModal)) hideModal();
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNull(response)) {
      if (!isNull(response.documentUpload.document)) {
        showNotification('success', 'Document uploaded!');

        goToPage(response);

        closeModal();
      } else {
        showNotification('error', 'Failed to upload document!');
        handleGeneralError(response.documentUpload.errors);
      }
    }
  };

  const [
    uploadDocument,
    {loading: uploadingDocument, error: errorUploadingDocument},
  ] = useUploadDocument(updateSuccessCallback);

  const {
    values,
    handleGeneralError,
    handleSpecificChange,
    handleSubmit,
    errors,
  } = useForm({
    defaultState: {},
    submitAction: isSalesAdmin(permissions.role) ? upload : nextStep,
    validationSchema: buildValidationSchema(),
  });

  async function upload() {
    const file =
      documentAttachment.length > 1
        ? await handleMultipleAttachments(documentAttachment)
        : documentAttachment[0];

    uploadDocument({
      variables: {
        document: {
          attachment: file ? file : null,
          documentType: documentTypeValue,
          id: selectedDocument?.id,
          informationSource: 'Customer',
          leadId: Number(lead.id),
          status: 'SUBMITTED',
        },
      },
    });
  }

  const {setSelectedDocument} = useContext(DocumentsContext);
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(
    !showSelectedDocument,
  );
  const {documentType, documentAttachment} = values;
  const documentTypeValue = documentType || selectedDocument?.documentType;
  const defaultPDFCount = documentTypeValue == 'CMAP_REPORT' ? 1 : 0;
  const [pdfCount, setPdfCount] = useState<number>(defaultPDFCount);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const allowedFileTypes = () => {
    let fileTypes = ['jpeg', 'jpg', 'pdf', 'png'];
    if (['LOANDEX_REPORT', 'NFIS_REPORT'].includes(documentTypeValue))
      fileTypes = ['csv'];
    if (documentTypeValue === 'CMAP_REPORT') fileTypes = ['pdf'];
    return fileTypes;
  };

  const isAttachmentOptional = (documentType: string): boolean => {
    return ['CMAP_REPORT', 'LOANDEX_REPORT'].includes(documentType);
  };

  const handleDocumentTypeChange = (value: string): void => {
    if (value == 'CMAP_REPORT') setPdfCount(1);
    values.documentAttachment = [];
    handleSpecificChange({field: 'documentType', value: value});
    setUploadDisabled(false);
    setSubmitDisabled(!isAttachmentOptional(value));
  };

  const isNewRecord = (): boolean => {
    return isNull(selectedDocument) || isUndefined(selectedDocument);
  };

  const renderDocumentSelect = () => {
    return (
      <DynamicSelect
        name="documentType"
        dataTestId="documentType-select"
        classes="py-2"
        placeholder="Select"
        options={limitedDocumentTypeOptions(permissions.role)}
        label="Document Type"
        required={true}
        error={errors.documentType}
        defaultValue={documentTypeValue}
        disabled={!isNewRecord()}
        onChange={handleDocumentTypeChange}
      />
    );
  };

  const onFilesChanged = (value) => {
    handleSpecificChange({field: 'documentAttachment', value: value});
    setSubmitDisabled(false);
  };
  const renderForm = () => {
    return (
      <div className="w-full">
        {renderDocumentSelect()}
        <FileUpload
          dataTestId="documentAttachment-upload"
          classes="py-2"
          inlineLabel={true}
          label="Attach Document"
          fileLimit={512}
          fileTypes={allowedFileTypes()}
          sublabel="Choose document"
          error={errors.documentAttachment}
          required={!isAttachmentOptional(documentTypeValue)}
          onChange={onFilesChanged}
          pdfCount={pdfCount}
          savedFiles={documentAttachment}
          multiple
          disabled={uploadDisabled}
        />
      </div>
    );
  };

  async function nextStep(): Promise<void> {
    const file =
      documentAttachment.length > 1
        ? await handleMultipleAttachments(documentAttachment)
        : documentAttachment[0];

    const defaultDoc = isNewRecord() ? documentDefault : selectedDocument;
    const newDocumentObj = {
      documentType: documentTypeValue,
      file: file ? file : defaultDoc?.file,
      fileType: file ? file.type : defaultDoc?.fileType,
      filePath: file ? URL.createObjectURL(file) : defaultDoc?.filePath,
    };

    const document = Object.assign(defaultDoc, newDocumentObj);
    setSelectedDocument(document);
    setShowSelectedDocument(true);

    closeModal();
  }

  const imageMimeTypes = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleMultipleAttachments = async (attachments) => {
    const pdfDoc = await PDFDocument.create();

    await buildPdf(attachments, pdfDoc);
    const pdfBytes = await pdfDoc.save();

    return new Blob([pdfBytes], {type: 'application/pdf'});
  };

  const buildPdf = async (attachments, pdfDoc) => {
    const uploadedPdfs = attachments.filter(
      (doc) => doc.type == 'application/pdf',
    );
    const uploadedImgs = attachments.filter((doc) =>
      imageMimeTypes.includes(doc.type),
    );
    if (uploadedImgs.length > 0) await combineImage(uploadedImgs, pdfDoc);
    if (uploadedPdfs.length > 0)
      await copyPagesFromPdfSource(uploadedPdfs, pdfDoc);
  };

  const copyPagesFromPdfSource = async (pdfFiles, pdfDoc) => {
    for (const pdfFile of pdfFiles) {
      const pdf = await pdfFile.arrayBuffer();
      const uploadedPdf = await PDFDocument.load(pdf);
      const pdfPages = await pdfDoc.copyPages(
        uploadedPdf,
        uploadedPdf.getPageIndices(),
      );
      await pdfPages.forEach((pdfPage) => {
        pdfDoc.addPage(pdfPage);
      });
    }
  };

  const combineImage = async (images, pdfDoc) => {
    for (const image of images) {
      let embeddedImage;
      const page = pdfDoc.addPage();
      const imageSize = await image.arrayBuffer();

      if (['image/jpeg', 'image/jpg'].includes(image.type)) {
        embeddedImage = await pdfDoc.embedJpg(imageSize);
      }
      if (image.type == 'image/png') {
        embeddedImage = await pdfDoc.embedPng(imageSize);
      }
      const {height, width} = embeddedImage;
      page.setSize(width, height);

      await page.drawImage(embeddedImage, {
        x: 25,
        y: 25,
        width: width,
        height: height,
      });
    }
  };

  return (
    <>
      <div className="bg-transparent shadow-lg">
        <div className="p-6 min-w-[500px]">
          {renderForm()}
          <Button
            className="mr-2"
            dataTestId="nextModalBtn"
            label={
              isNewRecord()
                ? isSalesAdmin(permissions.role)
                  ? 'Upload'
                  : 'Next'
                : 'Update'
            }
            disabled={submitDisabled}
            isLoading={uploadingDocument}
            micro
            onClick={handleSubmit}
            type="primary"
          />
        </div>
      </div>
    </>
  );
};

export default UploadDocumentModal;
