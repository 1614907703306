import React, {useContext} from 'react';

import DocumentsContext from '../../../../../contexts/DocumentsContext';
import {SelectOption} from '../../../../../interfaces/application';
import {perPage} from '../../../../../interfaces/documents';
import Select from '../../../inputs/Select';

interface Props {
  entityTypeOptions: SelectOption[];
}
const ReportFilters: React.FC<Props> = ({entityTypeOptions}) => {
  const {entityType, setEntityType, setPagination, setPageNum} = useContext(
    DocumentsContext,
  );

  const handleOnChange = (value: string | null) => {
    setEntityType(value);
    setPageNum(1);
    setPagination({after: null, before: null, first: perPage, last: perPage});
  };

  const options = entityTypeOptions.map((hash) => {
    if (hash['label'] === 'Select') return {label: 'All', value: 'All'};

    return hash;
  });

  return (
    <div className="container" data-testid="documents-table-filters">
      <div className="grid grid-cols-8 mt-2 mb-4 gap-4">
        <div className="col-span-2">
          <Select
            label="Entity Type"
            options={options}
            dataTestId="entityTypeFilter"
            defaultValue={entityType}
            onChange={(status: string) => {
              handleOnChange(status);
            }}
            classes={'px-3'}
            hideRequiredTag={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportFilters;
