import React, {useContext} from 'react';

import ApplicationContext from '../../../../contexts/ApplicationContext';
import DocumentDetails from './DocumentDetails';
import DocumentsTable from './DocumentsTable';

const DocumentsMainPanel: React.FC = () => {
  const {showSelectedDocument, setShowSelectedDocument} = useContext(
    ApplicationContext,
  );

  const renderTable = () => {
    return (
      <div className="col-span-9">
        <DocumentsTable />
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div className="col-span-12 pl-4">
        <DocumentDetails />
      </div>
    );
  };

  return !showSelectedDocument ? renderTable() : renderDetails();
};

export default DocumentsMainPanel;
