import {NavbarItemProps} from './SharedInterfaces';

export const mainLeftRoutes = (leadId: number): NavbarItemProps[] => {
  return [
    {
      text: 'Back to FCC',
      path: `${process.env.REACT_APP_FCC_ABSOLUTE_URI}/central/leads/${leadId}`,
      dataTestId: 'go-back-link',
      staticLink: true,
      icon: 'FaArrowLeft',
    },
  ];
};

export const mainRightRoutes = (
  leadId: number,
  downloadSecretaryCertificate: boolean,
): NavbarItemProps[] => {
  const leadHubspotPage = `${process.env.REACT_APP_FCC_ABSOLUTE_URI}/central/leads/${leadId}/hubspot_page`;

  const hubspotLink = {
    text: 'Hubspot',
    path: leadHubspotPage,
    paths: [leadHubspotPage],
    dataTestId: 'hubspot-link',
    secondary: true,
    staticLink: true,
  };

  return [hubspotLink];
};
