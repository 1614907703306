import {SelectOption} from './application';
import {
  BalanceSheet,
  defaultBalanceSheet,
} from './auditedFinancialStatement/balanceSheet';
import {CompanyContactInformation} from './auditedFinancialStatement/companyContactInformation';
import {ContactPersonInformation} from './auditedFinancialStatement/contactPersonInformation';
import {
  IncomeStatement,
  defaultIncomeStatement,
} from './auditedFinancialStatement/incomeStatement';
import {
  StatementOfCashFlows,
  defaulsStatementOfCashFlows,
} from './auditedFinancialStatement/statementOfCashFlows';

export interface activeTableList {
  balanceSheet: boolean;
  companyContact: boolean;
  contactPerson: boolean;
  incomeStatement: boolean;
  statementOfCashFlows: boolean;
}

export const defaultActiveTableList: activeTableList = {
  balanceSheet: false,
  companyContact: false,
  contactPerson: false,
  incomeStatement: false,
  statementOfCashFlows: false,
};

export interface AuditedFinancialStatement {
  auditorId: number | null;
  balanceSheet: BalanceSheet[];
  companyContactInformation: CompanyContactInformation[];
  companyTin: string;
  contactPersonInformation: ContactPersonInformation[];
  currency: string | null;
  documentYear: string | null;
  hasAccreditedAuditor: boolean | null;
  incomeStatement: IncomeStatement[];
  informationSource: string | null;
  isSecBirAcknowledged: boolean | null;
  principalOfficeAddress: string;
  resubmissionReason: string | null;
  revenue: number | null;
  revenueOn: Date | null;
  statementOfCashFlows: StatementOfCashFlows[];
  status: string;
  verificationMethod: string | null;
}

export const auditedFinancialStatementDefault = {
  auditorId: null,
  balanceSheet: defaultBalanceSheet,
  companyContactInformation: [],
  contactPersonInformation: [],
  companyTin: '',
  currency: '',
  documentYear: null,
  hasAccreditedAuditor: null,
  incomeStatement: defaultIncomeStatement,
  informationSource: null,
  isSecBirAcknowledged: null,
  principalOfficeAddress: '',
  resubmissionReason: null,
  revenue: null,
  revenueOn: null,
  statementOfCashFlows: defaulsStatementOfCashFlows,
  status: 'SUBMITTED',
  validUntil: null,
  verificationMethod: null,
};

export const informationSourceOptions: SelectOption[] = [
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'SEC', value: 'SEC'},
];

export const resubmissionReasonOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Cropped', value: 'CROPPED'},
  {
    label: 'Did Not Match Business Information',
    value: 'DID_NOT_MATCH_BUSINESS_INFORMATION',
  },
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Missing Pages', value: 'MISSING_PAGES'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'No Stamp', value: 'NO_STAMP'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const verificationMethodOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'SEC/BIR Acknowledgment', value: 'SEC_BIR_ACKNOWLEDGMENT'},
  {label: 'Bank Stamp', value: 'BANK_STAMP'},
  {label: 'Manual Call Verification', value: 'MANUAL_CALL_VERIFICATION'},
];
