import {editableColumnProps} from './../editableTable';

export interface IncomeStatement {
  lineItem: string;
  currentYearAmount: number | null;
  previousYearAmount: number | null;
}

export const incomeStatementTableConfig: editableColumnProps[] = [
  {
    name: 'Line Item',
    config: {
      data: 'lineItem',
      editor: false,
      type: 'text',
      readOnly: true,
    },
  },
  {
    name: 'Current Year Amount',
    config: {
      data: 'currentYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
  {
    name: 'Previous Year Amount',
    config: {
      data: 'previousYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
];

export const defaultIncomeStatement = [
  {
    lineItem: 'Revenue',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Cost of Goods Sold',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Operating Expenses',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Net Income Before Interest & Tax',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Interest Income',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Interest Expenses',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Tax Expenses',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Net Income',
    currentYearAmount: null,
    previousYearAmount: null,
  },
];
