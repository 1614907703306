import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_GENERAL_INFORMATION_SHEET = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      riskopsNotes
      status
      ... on GeneralInformationSheet {
        actualDateOfAnnualMeeting
        beneficialOwnershipDeclaration {
          a
          b
          c
          completeName
          d
          dateOfBirth
          e
          f
          g
          h
          i
          nationality
          percentOwnership
          residentialAddress
          tin
          type
        }
        businessName
        completeBusinessAddress
        completePrincipalOfficeAddress
        corporateName
        corporateTin
        dateOfAnnualMeetingPerByLaws
        directorsAndOfficers {
          address
          board
          execomm
          incorporator
          name
          nationality
          officer
          sex
          stockholder
          tin
        }
        emailAddress
        gisYear
        industryClassification
        informationSource
        intercompanyAffiliations {
          address
          company
          relationship
          secRegistrationNumber
        }
        investmentInOtherCorporation {
          amountPhp
          assetType
          dateOfBoardResolution
        }
        isAmlaCovered
        isAmlaKycCompliant
        isNotarized
        isSignedByCorporateSecretary
        primaryPurpose
        registeredOn
        resubmissionReason
        secondaryLicenses {
          dateIssued
          dateStartedOperations
          governmentAgency
          typeOfLicense
        }
        secRegistrationNumber
        stampedOn
        stockholderInformation {
          address
          amountPhpPaid
          amountPhp
          entityType
          name
          nationality
          numberOfShares
          percentOwnership
          tin
          typeOfShare
        }
        telephoneNumber
        totalManpowerComplement
        totalNumberOfEmployees
        totalNumberOfOfficers
        validUntil
        websiteUrl
      }
    }
  }
`;

export function useFetchGeneralInformationSheet(onSuccessCallback: any) {
  return useLazyQuery(FETCH_GENERAL_INFORMATION_SHEET, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
