import {isEmpty, isNil} from 'lodash-es';
import React, {useContext, useEffect, useState} from 'react';
import ResizePanel from 'react-resize-panel';

import ApplicationContext from '../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../contexts/DocumentsContext';
import {
  documentDefault,
  documentTypeEnum,
  perPage,
} from '../../../../interfaces/documents';
import FileViewer from '../../FileViewer';
import {ModalComponent} from '../../modals/ModalComponents';
import StaticLink from '../../StaticLink';
import DocumentInfo from './DocumentInfo';
import AuditedFinancialStatementForm from './forms/AuditedFinancialStatementForm';
import BankStatementForm from './forms/BankStatementForm';
import CmapReportForm from './forms/CmapReportForm';
import DynamicDocumentForm from './forms/DynamicDocumentForm';
import FutureRevenueForm from './forms/FutureRevenueForm';
import GeneralInformationSheetForm from './forms/GeneralInformationSheetForm';
import IncomeTaxReturnForm from './forms/IncomeTaxReturnForm';
import LoanDexform from './forms/LoanDexForm';
import NFISForm from './forms/NFISForm';
import TradingRelationshipForm from './forms/TradingRelationshipForm';
import {
  isSalesAdmin,
  isTempWorkerAndHasNoAccess,
} from './forms/utils/HelperFunctions';
import TradingRelationshipLineItemsTable from './tables/TradingRelationshipLineItemsTable';
import { TradingRelationship } from '../../../../interfaces/tradingRelationship';

const DocumentDetails: React.FC = () => {
  const [tradingRelationship, setTradingRelationship] = useState<
    TradingRelationship | undefined
  >(undefined);

  const {
    uploadableDocuments,
    selectedDocument,
    setActiveDocumentGroup,
    setPageNum,
    setPagination,
    setSelectedDocument,
  } = useContext(DocumentsContext);
  const {
    permissions,
    showSelectedDocument,
    setShowSelectedDocument,
    setModalType,
    setModalProps,
  } = useContext(ApplicationContext);

  const {
    fileName,
    filePath,
    fileType,
    documentType,
    supportingDocumentPath,
  } = selectedDocument;

  const renderForm = () => {
    switch (documentType) {
      case 'TRADING_RELATIONSHIP':
        return (
          <TradingRelationshipForm
            setTradingRelationship={setTradingRelationship}
          />
        );
      case 'BANK_STATEMENT':
        return <BankStatementForm />;
      case 'INCOME_TAX_RETURN':
        return <IncomeTaxReturnForm />;
      case 'FUTURE_REVENUE':
        return <FutureRevenueForm />;
      case 'LOANDEX_REPORT':
        return <LoanDexform />;
      case 'NFIS_REPORT':
        return <NFISForm />;
      case 'GENERAL_INFORMATION_SHEET':
        return <GeneralInformationSheetForm />;
      case 'AUDITED_FINANCIAL_STATEMENT':
        return <AuditedFinancialStatementForm />;
      case 'CMAP_REPORT':
        return <CmapReportForm />;
      default:
        return <DynamicDocumentForm />;
    }
  };

  const goBackToList = () => {
    setSelectedDocument(documentDefault);
    setPageNum(1);
    setPagination({
      after: null,
      before: null,
      first: perPage,
      last: perPage,
    });

    if (showSelectedDocument) setShowSelectedDocument(false);
  };

  const renderSupportingDocuments = () => {
    if (documentType === 'FUTURE_REVENUE') {
      if (isEmpty(supportingDocumentPath) || isNil(supportingDocumentPath)) {
        return;
      } else {
        return (
          <div id="secondary-document">
            <div className="text-lg font-normal">
              <strong> Secondary Document </strong>
            </div>
            <FileViewer
              filePath={supportingDocumentPath}
              fileType="application/pdf"
            />
          </div>
        );
      }
    } else {
      return;
    }
  };

  const onUpdateDocumentClick = () => {
    setModalType(ModalComponent.DOCUMENT_UPLOAD_FORM);
    setModalProps({
      selectedDocument: selectedDocument,
      modalTitle: 'Update Document',
      goToPage: goBackToList,
    });
  };

  const renderUpdateAttachmentBtn = () => {
    if (!uploadableDocuments.includes(selectedDocument.documentType))
      return null;
    if (isSalesAdmin(permissions.role)) return null;
    if (isTempWorkerAndHasNoAccess(permissions.role, selectedDocument.status))
      return null;

    return (
      <div id="update-button-container" className="mt-6">
        <button
          className="float-left bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-6"
          data-testid="addDocumentBtn"
          onClick={() => onUpdateDocumentClick()}
        >
          Update Document
        </button>
      </div>
    );
  };

  const showDocumentTypeLabel = (selectedDocument) => {
    const {documentType} = selectedDocument;

    return documentTypeEnum[documentType] || documentType;
  };

  return (
    <div className="flex document-details-panel h-[850px]">
      <div className="flex-grow overflow-hidden overflow-x-scroll overflow-y-scroll">
        <div className="flex flex-col ">
          <div className="document-info text-left">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <StaticLink
                  dataTestId="back-to-list-nav"
                  text="Back to List"
                  path=""
                  className="text-primary-500"
                  icon="FaArrowLeft"
                  onClickAction={goBackToList}
                />
              </li>
            </ul>
            <div className="text-sm font-normal text-gray-500">
              Document Type
            </div>
            <div className="text-sm font-normal">
              <strong> {showDocumentTypeLabel(selectedDocument)} </strong>
            </div>
            <div>
              <span className="text-sm font-normal text-gray-500">
                Document:
              </span>
              <strong className="text-sm text-black-500"> {fileName} </strong>
            </div>
          </div>
          <div id="primary-document">
            <div className="text-lg font-normal">
              <strong> Primary Document </strong>
            </div>
            <FileViewer filePath={filePath} fileType={fileType} />
            {renderSupportingDocuments()}
          </div>
          {renderUpdateAttachmentBtn()}
        </div>

        {documentType === 'TRADING_RELATIONSHIP' &&
          (tradingRelationship || selectedDocument.id) && (
            <TradingRelationshipLineItemsTable
              tradingRelationship={
                tradingRelationship || selectedDocument
              }
            />
          )}
      </div>

      {!isSalesAdmin(permissions.role) && (
        <div className="border-l-2 border-gray-200 z-9">
          <ResizePanel direction="w" style={{width: '550px'}}>
            <div className="bg-fcLightGray" style={{width: '550px'}}>
              <DocumentInfo />
              {renderForm()}
            </div>
          </ResizePanel>
        </div>
      )}
    </div>
  );
};

export default DocumentDetails;
