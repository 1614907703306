import {isEmpty, isNil} from 'lodash-es';

export const buildBuyerOptions = (options: BuyerOption[]) => {
  if (isNil(options) || isEmpty(options)) return [];

  return [
    {label: 'None', value: ''},
    ...options.map((option) => {
      let label: string;
      const value = option.gid;

      if (option.buyerAs === 'BUYER' || option.buyerAs === 'COMPANY') {
        if (!isNil(option.regNum)) {
          label = `${option.buyerAs}(${option.regNum}) | ${option.name}`;
        } else if (!isNil(option.customId)) {
          label = `${option.buyerAs}(${option.customId}) | ${option.name}`;
        } else if (!isNil(option.dunsNum)) {
          label = `${option.buyerAs}(${option.dunsNum}) | ${option.name}`;
        } else {
          label = `${option.buyerAs} | ${option.name}`;
        }
      } else {
        label = `${option.buyerAs}(${option.id}) | ${option.name}`;
      }

      return {
        label,
        value,
      };
    }),
  ];
};
