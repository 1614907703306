import React from 'react';

import {stockholderInformationItemColumns} from '../../../../../../interfaces/generalInformationSheet/stockholderInformation';
import EditableTable from '../../../../inputs/EditableTable';
import StaticLink from '../../../../StaticLink';

interface tableProps {
  data: [];
  setTableToggle: (toggle: string) => void;
}

const StockholderInformationTable = (tableProps: tableProps) => {
  const {data, setTableToggle} = tableProps;
  return (
    <div className="h-[630px] overflow-hidden overflow-y-scroll">
      <StaticLink
        path=""
        className="text-primary-500"
        icon="FaArrowLeft"
        dataTestId="back-to-document-information"
        text="Back to Document Information"
        onClickAction={() => setTableToggle('')}
      />
      <EditableTable
        tableLabel="Stockholder Information"
        headerProps={stockholderInformationItemColumns}
        dataProps={data}
        minSpareRows={1}
      />
    </div>
  );
};
export default StockholderInformationTable;
