import {HotTable} from '@handsontable/react';
import {registerAllModules} from 'handsontable/registry';
import React, {useRef} from 'react';

import {EditableTableProps} from '../../../interfaces/editableTable';

registerAllModules();

const EditableTable: React.FC<EditableTableProps> = ({
  headerProps,
  dataProps,
  tableLabel,
  tableCaption,
  minSpareRows,
  disableContextMenu,
  height,
}) => {
  registerAllModules();

  const hotTableComponent: any = useRef(null);

  const headerKeys = headerProps.map((header) => header.name);
  const columnsConfig = headerProps.map((header) => header.config);

  const handleBeforePaste = (data: string[][], coords: any[]) => {
    if (
      minSpareRows === 0 &&
      coords[0].startRow + data.length > dataProps.length
    ) {
      data.splice(
        data.length - (coords[0].startRow + data.length - dataProps.length),
      );
    }

    return true;
  };

  return (
    <div id="hot-app">
      <label className="text-lg font-bold">{tableLabel}</label>
      <p className="py-3">{tableCaption}</p>
      <HotTable
        ref={hotTableComponent}
        data={dataProps}
        beforePaste={handleBeforePaste}
        colHeaders={headerKeys}
        columns={columnsConfig}
        rowHeaders={true}
        contextMenu={
          disableContextMenu ? [] : ['row_above', 'row_below', 'remove_row']
        }
        viewportColumnRenderingOffset={columnsConfig.length}
        stretchH="all"
        minSpareRows={minSpareRows}
        licenseKey="non-commercial-and-evaluation"
        height={height}
      />
    </div>
  );
};

export default EditableTable;
