import * as yup from 'yup';

export interface CommonAttributeProps {
  attribute: string;
}

export const requiredStringField = ({attribute}: CommonAttributeProps) =>
  yup.string().nullable(true).trim().required().label(attribute);

export const requiredBooleanField = ({attribute}: CommonAttributeProps) =>
  yup
    .boolean()
    .nullable(false)
    .required()
    .label(attribute)
    .typeError(attribute + ' is a required field');

export const requiredNumberField = ({attribute}: CommonAttributeProps) =>
  yup.number().nullable(false).required().label(attribute);

export const requiredBooleanWhenVerifiedField = ({
  attribute,
}: CommonAttributeProps) =>
  yup
    .boolean()
    .when('status', (status: string, schema: yup.BooleanSchema) => {
      if (status === 'VERIFIED') {
        return schema.nullable(false).required();
      } else {
        return schema.nullable(true);
      }
    })
    .label(attribute)
    .typeError(attribute + ' is a required field');

export const requiredStringWhenVerified = ({attribute}: {attribute: string}) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .when('status', (status: string, schema: yup.StringSchema) => {
      if (status === 'VERIFIED') {
        return schema.required();
      } else {
        return schema;
      }
    })
    .label(attribute);

export const requiredNumberWhenVerified = ({attribute}: {attribute: string}) =>
  yup
    .number()
    .nullable(true)
    .when('status', (status: string, schema: yup.NumberSchema) => {
      if (status === 'VERIFIED') {
        return schema.required();
      } else {
        return schema;
      }
    })
    .label(attribute);

export const requiredNumberWhenVerifiedOrInProcess = ({
  attribute,
}: {
  attribute: string;
}) =>
  yup
    .number()
    .nullable(true)
    .when('status', (status: string, schema: yup.NumberSchema) => {
      if (status === 'IN_PROCESSING' || status === 'VERIFIED') {
        return schema.required();
      } else {
        return schema;
      }
    })
    .label(attribute);

export const requiredArrayWhenVerified = ({attribute}: {attribute: string}) =>
  yup
    .array()
    .nullable(true)
    .when(
      'status',
      (
        status: string,
        schema: yup.ArraySchema<
          yup.AnySchema<any, any, any>,
          any,
          any[] | null | undefined
        >,
      ) => {
        if (status === 'VERIFIED') {
          return schema.min(1);
        } else {
          return schema;
        }
      },
    )
    .label(attribute);

export const requiredStringWhenRequiresResubmission = ({
  attribute,
}: {
  attribute: string;
}) =>
  yup
    .string()
    .nullable(true)
    .trim()
    .when('status', (status: string, schema: yup.StringSchema) => {
      if (status === 'REQUIRES_RESUBMISSION') {
        return schema.required();
      } else {
        return schema;
      }
    })
    .label(attribute);
