import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_TRADING_RELATIONSHIP = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      riskopsNotes
      status
      ... on TradingRelationship {
        amountLast12Months
        buyer {
          ... on Buyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Company {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on DimBuyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Lead {
            id
            gid
            name
            buyerAs
          }
        }
        buyerType
        createdAt
        currency
        hasNda
        informationSource
        productProvided
        relationshipType
        resubmissionReason
        salesNotes
        startDate
        updatedBy
        validUntil
        verificationMethod
        timePeriod
        documentFormat
        fcCustomerTin
        fcCustomerName
        fcCustomerAddress
      }
    }
  }
`;

export function useFetchTradingRelationship(onSuccessCallback: any) {
  return useLazyQuery(FETCH_TRADING_RELATIONSHIP, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
