import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  useCreateBuyer,
  useDeleteBuyer,
  useUpdateBuyer,
} from '@src/api/mutations/buyer';
import {regSubTypes, regTypes, useFetchBuyers} from '@src/api/queries/buyers';
import {useFetchCountries} from '@src/api/queries/countries';
import AppTemplate from '@src/components/common/dashboard/AppTemplate';
import Datepicker from '@src/components/common/inputs/Datepicker';
import useDebounce from '@src/hooks/useDebounce';
import useForm from '@src/hooks/useForm';
import {usePrevious} from '@src/hooks/usePrevious';
import {buildValidationSchema} from '@src/validations/buyer';
import {
  Badge,
  Button,
  Label,
  Modal,
  Select,
  Spinner,
  Table,
  TextInput,
} from 'flowbite-react';
import {
  includes,
  isEmpty,
  isNil,
  isUndefined,
  merge,
  omit,
  remove,
  toPairs,
} from 'lodash-es';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {HiOutlineExclamationCircle} from 'react-icons/hi';
import Moment from 'react-moment';

import PaginatedTable from '../PaginatedTable';

const BuyersPage = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [apiErrors, setApiErrors] = useState<ApiError[]>([]);
  const [errors, setErrors] = useState<RecordErrors>({});

  const resetCurrentBuyer = () => {
    setCurrentBuyer({} as Buyer);
  };

  const resetErrors = () => {
    setApiErrors([]);
    resetFormErrors();
  };

  const [
    fetchBuyers,
    {loading: fetchingBuyers, error: errorFetchingBuyers, data: buyersData},
  ] = useFetchBuyers();

  const successCreateBuyerCallback = (data: any) => {
    if (isUndefined(data.buyerCreate)) return;

    const buyer = data.buyerCreate.buyer as Buyer;
    const errs = data.buyerCreate.errors as ApiError[];
    successBuyerCallback(buyer, errs);
  };

  const successUpdateBuyerCallback = (data: any) => {
    if (isUndefined(data.buyerUpdate)) return;

    const buyer = data.buyerUpdate.buyer as Buyer;
    const errs = data.buyerUpdate.errors as ApiError[];
    successBuyerCallback(buyer, errs);
  };

  const successBuyerCallback = (buyer: Buyer, errs: ApiError[]) => {
    if (!isNil(errs) && errs.length !== 0) {
      setApiErrors(errs);
      return;
    }

    buyersData.buyers.nodes = buyersData.buyers.nodes.map((node: Buyer) => {
      if (node.id === buyer.id) {
        return buyer;
      }

      return node;
    });

    resetErrors();
    setOpenModal(false);
    resetCurrentBuyer();
  };

  const successDeleteBuyerCallback = (data: any) => {
    if (isUndefined(data.buyerDelete)) return;

    const buyer = data.buyerDelete.buyer as Buyer;

    remove(buyersData.buyers.nodes, (node: Buyer) => {
      return node.id === buyer.id;
    });
    buyersData.buyers.totalPages = buyersData.buyers.totalPages - 1;

    setOpenDeleteConfirmation(false);
  };

  const [
    createBuyerData,
    {loading: creatingBuyer, error: errorCreatingBuyer},
  ] = useCreateBuyer(successCreateBuyerCallback);

  const [
    updateBuyerData,
    {loading: updatingBuyer, error: errorUpdatingBuyer},
  ] = useUpdateBuyer(successUpdateBuyerCallback);

  const [
    deleteBuyerData,
    {loading: deletingBuyer, error: errorDeletingBuyer},
  ] = useDeleteBuyer(successDeleteBuyerCallback);

  const [
    fetchCountries,
    {
      loading: loadingCountries,
      error: errorFetchCountries,
      data: countriesData,
    },
  ] = useFetchCountries();

  const fetchBuyersBySearch = (cursor?: string) => {
    let payload: any = {
      first: itemsPerPage,
      after: '',
    };

    if (!isNil(searchTerm) && searchTerm.length !== 0) {
      payload = {...payload, search: searchTerm};
    }

    if (!isNil(cursor)) {
      payload = {...payload, after: cursor};
    }

    fetchBuyers({
      variables: payload,
    });
  };

  const fetchBuyersByPage = (cursor: string, page: number) => {
    setCurrentPage(page);
    fetchBuyersBySearch(cursor);
  };

  const createBuyer = () => {
    const buyer = {
      regNum: '',
      name: '',
      countryCode: 'PH',
      regType: 'PRIVATE',
      regSubType: 'CORPORATION',
      regDate: new Date(),
      registrantFirstName: '',
      registrantMiddleName: '',
      registrantLastName: '',
    } as Buyer;

    resetErrors();
    setCurrentBuyer(buyer);
    setOpenModal(true);
  };

  const updateBuyer = (buyer: Buyer) => {
    if (!isNil(buyer.regDate)) {
      buyer.regDate = moment(buyer.regDate).toDate();
    }

    // Backward compatibity for already created buyers
    buyer.registrantFirstName ||= '';
    buyer.registrantMiddleName ||= '';
    buyer.registrantLastName ||= '';

    resetErrors();
    setCurrentBuyer(buyer);
    setOpenModal(true);
  };

  const deleteBuyer = () => {
    deleteBuyerData({variables: {id: currentBuyer.id}});
  };

  const confirmDeleteBuyer = (buyer: Buyer) => {
    resetErrors();
    setCurrentBuyer(buyer);
    setOpenDeleteConfirmation(true);
  };

  const cancelDeleteBuyer = () => {
    setOpenDeleteConfirmation(false);
  };

  const saveBuyer = (data: Record<string, any>) => {
    const omitFields = ['createdAt', 'updatedAt', 'entity'];
    const {__typename, ...payload} = merge({}, currentBuyer, data);
    const buyer = {...omit(payload, omitFields)} as Record<string, any>;

    if (!isNil(buyer.regDate)) {
      buyer.regDate = moment(buyer.regDate).format('YYYY-MM-DD');
    }
    if (buyer.regType !== 'PRIVATE') {
      buyer.regSubType = null;
    }
    if (data.regType !== 'PRIVATE' || data.regSubType !== 'SOLE_PROP') {
      buyer.registrantFirstName = null;
      buyer.registrantMiddleName = null;
      buyer.registrantLastName = null;
    }
    if (
      !includes(
        ['INTERNATIONAL', 'MULTINATIONAL', 'PRIVATE', 'PUBLIC'],
        data.regType,
      )
    ) {
      buyer.regDate = null;
    }

    const variables = {buyer};

    if (isUndefined(buyer.id)) {
      createBuyerData({variables});
    } else {
      updateBuyerData({variables});
    }
  };

  const {
    handleChange,
    handleSubmit,
    errors: formErrors,
    hasErrors: hasFormErrors,
    values: currentBuyer,
    updateValuesAtOnce: setCurrentBuyer,
    clearAllErrors: resetFormErrors,
  } = useForm({
    defaultState: {} as Buyer,
    runValidationOnEveryChange: true,
    submitAction: saveBuyer,
    validationSchema: buildValidationSchema(),
  });

  const handleDatePickerChange = (date: any) => {
    setCurrentBuyer({
      ...currentBuyer,
      regDate: date,
    });
  };

  useEffect(() => {
    if (currentBuyer.regType === 'PRIVATE') {
      if (
        currentBuyer.regSubType !== 'COOPERATIVE' &&
        isNil(currentBuyer.regDate)
      ) {
        setCurrentBuyer({
          ...currentBuyer,
          regDate: new Date(),
        });
      }
      if (isNil(currentBuyer.regSubType)) {
        setCurrentBuyer({
          ...currentBuyer,
          regSubType: 'CORPORATION',
        });
      }
    } else {
      setCurrentBuyer({
        ...currentBuyer,
        regSubType: null,
        registrantFirstName: null,
        registrantMiddleName: null,
        registrantLastName: null,
      });
    }
  }, [currentBuyer.regType, currentBuyer.regSubType]);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const previousDebouncedSearchTerm = usePrevious(debouncedSearchTerm);
  useEffect(
    () => {
      if (debouncedSearchTerm !== previousDebouncedSearchTerm) {
        // Do fetch with debounced search term
        setCurrentPage(1);
        fetchBuyersBySearch();
      }
    },
    // only do operation when these references updates
    [debouncedSearchTerm, previousDebouncedSearchTerm, currentPage],
  );

  useEffect(() => {
    const errors: RecordErrors = {};

    toPairs(formErrors).forEach((err) => {
      if (isUndefined(err[1])) return;

      errors[err[0]] ||= {errored: true, errors: []};
      errors[err[0]].errors.push({
        kind: 'yup',
        message: err[1],
      });
    });

    apiErrors.forEach((err) => {
      errors[err.field] ||= {errored: true, errors: []};
      errors[err.field].errors.push({
        kind: err.kind,
        message: err.message,
      });
    });

    setErrors(errors);
  }, [apiErrors, formErrors]);

  useEffect(() => {
    fetchBuyersBySearch();
  }, [fetchBuyers]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const renderSearch = () => {
    return (
      <div
        className="w-full md:w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <form className="flex items-center">
          <TextInput
            id="search"
            type="text"
            placeholder="Type to search"
            sizing="md"
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
            }}
          />
        </form>
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
        <Button
          onClick={createBuyer}
          disabled={
            fetchingBuyers ||
            creatingBuyer ||
            loadingCountries ||
            !isNil(errorFetchCountries) ||
            isNil(countriesData)
          }
        >
          <PlusIcon className="mr-2 h-5 w-5" />
          Add Buyer
        </Button>
      </div>
    );
  };

  const renderItem = (item: Buyer) => {
    return (
      <Table.Row
        key={item.id}
        className="bg-white dark:border-gray-700 dark:bg-gray-800"
      >
        <Table.Cell>{item.customId}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white items-center">
          <div className="flex items-center">
            {!isNil(item.entity) && (
              <CheckCircleIcon color="success" className="mr-1 h-5 w-5" />
            )}
            <div>{item.regNum}</div>
          </div>
        </Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          {!isNil(countriesData)
            ? countriesData.countries.find((country: Country) => {
                country.alpha2 === item.countryCode;
              })?.name || item.countryCode
            : item.countryCode}
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-wrap gap-2">
            <Badge size="sm" color="info">
              {regTypes.find((type) => type.id === item.regType)?.name ||
                item.regType}
            </Badge>
            {item.regType === 'PRIVATE' && !isNil(item.regSubType) && (
              <Badge size="sm" color="success">
                {regSubTypes.find((type) => type.id === item.regSubType)
                  ?.name || item.regSubType}
              </Badge>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {isNil(item.regDate) ? (
            ''
          ) : (
            <Moment format="YYYY-MM-DD">{item.regDate}</Moment>
          )}
        </Table.Cell>
        <Table.Cell>
          {item.registrantFirstName} {item.registrantMiddleName}{' '}
          {item.registrantLastName}
        </Table.Cell>
        <Table.Cell>
          {item.docsCount && item.docsCount > 0 ? (
            <Badge size="sm" color="info">
              {item.docsCount}
            </Badge>
          ) : (
            ''
          )}
        </Table.Cell>
        <Table.Cell>
          {(updatingBuyer || deletingBuyer) && currentBuyer.id === item.id ? (
            <Button color="gray">
              <Spinner size="xs" />
            </Button>
          ) : (
            <div className="flex items-center gap-2">
              <Button
                size="xs"
                color={
                  errorUpdatingBuyer && currentBuyer.id === item.id
                    ? 'failure'
                    : undefined
                }
                disabled={
                  loadingCountries ||
                  !isNil(errorFetchCountries) ||
                  isNil(countriesData) ||
                  updatingBuyer ||
                  deletingBuyer
                }
                onClick={() => updateBuyer(item)}
              >
                {errorUpdatingBuyer && currentBuyer.id === item.id
                  ? 'Retry Edit'
                  : 'Edit'}
              </Button>
              <Button
                size="xs"
                color={
                  errorDeletingBuyer && currentBuyer.id === item.id
                    ? 'failure'
                    : 'gray'
                }
                disabled={deletingBuyer}
                onClick={() => confirmDeleteBuyer(item)}
              >
                {deletingBuyer && currentBuyer.id === item.id ? (
                  <Spinner size="xs" />
                ) : (
                  <>
                    {errorDeletingBuyer && currentBuyer.id === item.id
                      ? 'Retry Delete'
                      : 'Delete'}
                  </>
                )}
              </Button>
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTable = () => {
    let data: Buyer[] = [];
    let isEmpty = false;
    let pages: Array<any> = [];
    let totalPages = 0;
    let totalCount = 0;
    let cursor = '';

    if (!isNil(buyersData)) {
      data = buyersData.buyers.nodes || [];
      isEmpty = !fetchingBuyers && data.length === 0;
      totalPages = buyersData.buyers.totalPages;
      totalCount = buyersData.buyers.totalCount;
      cursor = buyersData.buyers.pageInfo.endCursor;

      if (!isNil(buyersData.buyers.pageCursors)) {
        pages = [
          buyersData.buyers.pageCursors.first,
          ...buyersData.buyers.pageCursors.around,
          buyersData.buyers.pageCursors.last,
        ];
      }
    }

    const tableHead = () => {
      return (
        <Table.Head>
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Registration No.</Table.HeadCell>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Country</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Registration Date</Table.HeadCell>
          <Table.HeadCell>Registrant</Table.HeadCell>
          <Table.HeadCell>Docs</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Actions</span>
          </Table.HeadCell>
        </Table.Head>
      );
    };

    const paginatedTable = (
      <PaginatedTable
        asTable={true}
        totalCount={totalCount}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        isEmpty={isEmpty}
        emptyMessage={<>No transactions made</>}
        isLoading={fetchingBuyers}
        records={data}
        page={currentPage}
        cursors={pages}
        error={errorFetchingBuyers}
        pageCursor={cursor}
        errorMessage="Oops! We encountered an error fetching the data.<br/> Please try again."
        onPageClick={fetchBuyersByPage}
        renderItem={renderItem}
        tableHead={tableHead()}
      />
    );

    return paginatedTable;
  };

  const renderDeleteConfirmation = () => {
    return (
      <Modal
        show={openDeleteConfirmation}
        size="md"
        onClose={() => setOpenDeleteConfirmation(false)}
        popup
      >
        <Modal.Body>
          <div className="text-center mb-4">
            <HiOutlineExclamationCircle
              className="mx-auto mb-4 mt-6 h-14 w-14 text-gray-400 dark:text-gray-200"
              size={56}
            />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete the buyer?
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={deleteBuyer}
                disabled={deletingBuyer}
              >
                {deletingBuyer ? (
                  <>
                    <Spinner size="xs" />
                    <span className="pl-3">Deleting...</span>
                  </>
                ) : (
                  <>{errorDeletingBuyer ? 'Retry Delete' : "Yes, I'm sure"}</>
                )}
              </Button>
              {!deletingBuyer && (
                <Button color="gray" onClick={cancelDeleteBuyer}>
                  No, cancel
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        popup
      >
        <div className="flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {isUndefined(currentBuyer.id) ? 'Add ' : 'Update '}Buyer
          </h3>
          <button
            type="button"
            disabled={creatingBuyer || updatingBuyer}
            onClick={() => {
              setOpenModal(false);
            }}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <XMarkIcon className="w-5 h-5" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 mb-4">
              <div className="divide-y divide-dashed">
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="regNum" value="Registration No." />
                    </div>
                    <TextInput
                      id="regNum"
                      name="regNum"
                      type="text"
                      value={currentBuyer.regNum || ''}
                      required
                      onChange={handleChange}
                      color={isUndefined(errors.regNum) ? undefined : 'failure'}
                      helperText={
                        <>
                          {(errors.regNum?.errors || []).map((err, idx) => {
                            return <span key={idx}>{err.message}</span>;
                          })}
                        </>
                      }
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="name" value="Name" />
                    </div>
                    <TextInput
                      id="name"
                      name="name"
                      type="text"
                      value={currentBuyer.name || ''}
                      required
                      onChange={handleChange}
                      color={isUndefined(errors.name) ? undefined : 'failure'}
                      helperText={
                        <>
                          {(errors.name?.errors || []).map((err, idx) => {
                            return <span key={idx}>{err.message}</span>;
                          })}
                        </>
                      }
                    />
                  </div>
                  <div className="max-w-md">
                    <div className="mb-2 block">
                      <Label htmlFor="countryCode" value="Country" />
                    </div>
                    {!isNil(countriesData) && (
                      <Select
                        id="countryCode"
                        name="countryCode"
                        required
                        onChange={handleChange}
                        value={currentBuyer.countryCode || ''}
                        color={
                          isUndefined(errors.countryCode)
                            ? undefined
                            : 'failure'
                        }
                        helperText={
                          <>
                            {(errors.countryCode?.errors || []).map(
                              (err, idx) => {
                                return <span key={idx}>{err.message}</span>;
                              },
                            )}
                          </>
                        }
                      >
                        {(isEmpty(currentBuyer.countryCode)
                          ? [{numeric: 'unknown', alpha2: '', name: ''}]
                          : []
                        )
                          .concat(countriesData.countries)
                          .map((country: Country) => {
                            return (
                              <option
                                key={country.numeric}
                                value={country.alpha2}
                              >
                                {country.numeric === 'unknown'
                                  ? ''
                                  : `${country.name} (${country.alpha2})`}
                              </option>
                            );
                          })}
                      </Select>
                    )}
                  </div>
                  <div className="max-w-md">
                    <div className="mb-2 block">
                      <Label htmlFor="regType" value="Registration Type" />
                    </div>
                    <Select
                      id="regType"
                      name="regType"
                      required
                      onChange={handleChange}
                      value={currentBuyer.regType || ''}
                      color={
                        isUndefined(errors.regType) ? undefined : 'failure'
                      }
                      helperText={
                        <>
                          {(errors.regType?.errors || []).map((err, idx) => {
                            return <span key={idx}>{err.message}</span>;
                          })}
                        </>
                      }
                    >
                      {(isEmpty(currentBuyer.regType)
                        ? [{id: '', name: ''}]
                        : []
                      )
                        .concat(regTypes)
                        .map((regType) => {
                          return (
                            <option key={regType.id} value={regType.id}>
                              {regType.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                  {currentBuyer.regType === 'PRIVATE' && (
                    <div className="max-w-md">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="regSubType"
                          value="Registration Sub Type"
                        />
                      </div>
                      <Select
                        id="regSubType"
                        name="regSubType"
                        required={currentBuyer.regType === 'PRIVATE'}
                        disabled={currentBuyer.regType !== 'PRIVATE'}
                        onChange={handleChange}
                        value={currentBuyer.regSubType || ''}
                        color={
                          isUndefined(errors.regSubType) ? undefined : 'failure'
                        }
                        helperText={
                          <>
                            {(errors.regSubType?.errors || []).map(
                              (err, idx) => {
                                return <span key={idx}>{err.message}</span>;
                              },
                            )}
                          </>
                        }
                      >
                        {(isEmpty(currentBuyer.regSubType)
                          ? [{id: '', name: ''}]
                          : []
                        )
                          .concat(regSubTypes)
                          .map((regType) => {
                            return (
                              <option key={regType.id} value={regType.id}>
                                {regType.name}
                              </option>
                            );
                          })}
                      </Select>
                    </div>
                  )}
                  {(currentBuyer.regType === 'PRIVATE' ||
                    currentBuyer.regType === 'INTERNATIONAL' ||
                    currentBuyer.regType === 'MULTINATIONAL' ||
                    currentBuyer.regType === 'PUBLIC') && (
                    <div className="max-w-md">
                      <div className="mb-2 block">
                        <Label htmlFor="regDate" value="Registration Date" />
                      </div>
                      <Datepicker
                        id="regDate"
                        name="regDate"
                        dataTestId="reg-date"
                        selected={currentBuyer.regDate}
                        minDate={new Date(1400, 1, 1)}
                        maxDate={new Date()}
                        required={
                          currentBuyer.regType === 'PRIVATE' &&
                          currentBuyer.regSubType !== 'COOPERATIVE'
                        }
                        disabled={
                          currentBuyer.regType !== 'PRIVATE' &&
                          currentBuyer.regType !== 'INTERNATIONAL' &&
                          currentBuyer.regType !== 'MULTINATIONAL' &&
                          currentBuyer.regType !== 'PUBLIC'
                        }
                        onChange={handleDatePickerChange}
                        color={
                          isUndefined(errors.regDate) ? undefined : 'failure'
                        }
                        className="z-[100]"
                      />
                    </div>
                  )}
                </div>
                {currentBuyer.regType === 'PRIVATE' &&
                  currentBuyer.regSubType === 'SOLE_PROP' && (
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="registrantFirstName"
                            value="First Name"
                          />
                        </div>
                        <TextInput
                          id="registrantFirstName"
                          name="registrantFirstName"
                          type="text"
                          value={currentBuyer.registrantFirstName || ''}
                          required
                          onChange={handleChange}
                          color={
                            isUndefined(errors.registrantFirstName)
                              ? undefined
                              : 'failure'
                          }
                          helperText={
                            <>
                              {(errors.registrantFirstName?.errors || []).map(
                                (err, idx) => {
                                  return <span key={idx}>{err.message}</span>;
                                },
                              )}
                            </>
                          }
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="registrantFirstName"
                            value="Middle Name"
                          />
                        </div>
                        <TextInput
                          id="registrantMiddleName"
                          name="registrantMiddleName"
                          type="text"
                          value={currentBuyer.registrantMiddleName || ''}
                          required
                          onChange={handleChange}
                          color={
                            isUndefined(errors.registrantMiddleName)
                              ? undefined
                              : 'failure'
                          }
                          helperText={
                            <>
                              {(errors.registrantMiddleName?.errors || []).map(
                                (err, idx) => {
                                  return <span key={idx}>{err.message}</span>;
                                },
                              )}
                            </>
                          }
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="registrantLastName"
                            value="Last Name"
                          />
                        </div>
                        <TextInput
                          id="registrantLastName"
                          name="registrantLastName"
                          type="text"
                          value={currentBuyer.registrantLastName || ''}
                          required
                          onChange={handleChange}
                          color={
                            isUndefined(errors.registrantLastName)
                              ? undefined
                              : 'failure'
                          }
                          helperText={
                            <>
                              {(errors.registrantLastName?.errors || []).map(
                                (err, idx) => {
                                  return <span key={idx}>{err.message}</span>;
                                },
                              )}
                            </>
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div className="w-full">
                {creatingBuyer || updatingBuyer ? (
                  <Button color="gray">
                    <Spinner size="sm" />
                    <span className="pl-3">Saving...</span>
                  </Button>
                ) : (
                  <Button
                    disabled={hasFormErrors}
                    color={
                      errorCreatingBuyer || errorUpdatingBuyer
                        ? 'failure'
                        : undefined
                    }
                    onClick={handleSubmit}
                  >
                    {creatingBuyer || updatingBuyer ? 'Retry Save' : 'Save'}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <AppTemplate size="md" dataTestId="buyers-page">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-6">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {renderSearch()}
            {renderOptions()}
          </div>
          {isNil(buyersData) ? (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden opacity-75">
              <div className="w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
              <h2 className="text-md font-semibold text-center">Loading...</h2>
            </div>
          ) : (
            renderTable()
          )}
          {renderModal()}
          {renderDeleteConfirmation()}
        </div>
      </div>
    </AppTemplate>
  );
};

export default BuyersPage;
