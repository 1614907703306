import {format} from 'date-fns';
import {isNil} from 'lodash-es';

import {SelectOption} from './application';
import {editableColumnProps} from './editableTable';

export interface BankLineItem {
  itemTimestamp: string;
  transactionType: string;
  details: string;
  debit: number | null;
  credit: number | null;
}

export interface BankStatement {
  informationSource: string | null;
  documentSource: string | null;
  bankId: number | null;
  bankAccountId: string;
  statementStartDate: Date | null;
  statementEndDate: Date | null;
  accountName: string;
  accountType: string | null;
  currency: string | null;
  startBalance: number | null;
  endBalance: number | null;
  lineItems: BankLineItem[];
  resubmissionReason: string | null;
  validUntil: Date | null;
  status: string;
}

export const bankStatementDefault: BankStatement = {
  informationSource: null,
  documentSource: null,
  bankId: null,
  bankAccountId: '',
  statementStartDate: null,
  statementEndDate: null,
  accountName: '',
  accountType: null,
  currency: null,
  startBalance: null,
  endBalance: null,
  lineItems: [],
  resubmissionReason: null,
  validUntil: null,
  status: 'SUBMITTED',
};

const emptyOption: SelectOption = {
  label: 'Select',
  value: null,
};

export const accountTypeOptions: SelectOption[] = [
  emptyOption,
  {label: 'Personal', value: 'PERSONAL'},
  {label: 'Corporate', value: 'CORPORATE'},
];

export const informationSourceOptions: SelectOption[] = [
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'Brankas', value: 'BRANKAS'},
  {label: 'Scraped Online', value: 'SCRAPED_ONLINE'},
  {label: 'First Circle Bank Account', value: 'FIRST_CIRCLE_BANK_ACCOUNT'},
];

export const documentSourceOptions: SelectOption[] = [
  emptyOption,
  {label: 'Scanned Passbook', value: 'SCANNED_PASSBOOK'},
  {label: 'Scanned BS', value: 'SCANNED_BS'},
  {label: 'Computer Generated', value: 'COMPUTER_GENERATED'},
];

export const resubmissionReasonOptions: SelectOption[] = [
  emptyOption,
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Cropped', value: 'CROPPED'},
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Missing Transactions', value: 'MISSING_TRANSACTIONS'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const bankLineItemColumns: editableColumnProps[] = [
  {
    name: 'Timestamp',
    config: {
      data: 'itemTimestamp',
      type: 'date',
      dateFormat: 'YYYY-MM-DD',
      correctFormat: true,
      defaultDate: format(new Date(), 'yyyy-MM-dd'),
      allowInvalid: false,
    },
  },
  {
    name: 'Transaction Type',
    config: {
      data: 'transactionType',
      type: 'dropdown',
      source: ['BANK_CREDIT', 'UNFUNDED_CHEQUES'],
      allowInvalid: false,
    },
  },
  {
    name: 'Details',
    config: {
      data: 'details',
    },
  },
  {
    name: 'Debit',
    config: {
      data: 'debit',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowEmpty: true,
      allowInvalid: false,
    },
  },
  {
    name: 'Credit',
    config: {
      type: 'numeric',
      data: 'credit',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowEmpty: true,
      allowInvalid: false,
    },
  },
];

export const buildBankOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.bankIdOptions.length === 0) return [];

  const options = data.bankIdOptions;

  return [{label: 'Select', value: ''}, ...options];
};
