import {isNil} from 'lodash-es';
import React from 'react';
import {FaExclamationTriangle, FaFolderMinus} from 'react-icons/fa';

import Button from '../buttons/Button';

interface Props {
  type: 'empty' | 'error' | 'success';
  advance?: boolean;
  tryAgainCallback?: () => void;
  errorMessage?: string;
}

const ApiCallNotice: React.FC<Props> = ({
  type,
  advance,
  tryAgainCallback,
  errorMessage,
}) => {
  let header = '';
  let subheader = '';
  let colorClasses = '';
  let textColor = '';
  let Icon = FaExclamationTriangle;

  switch (type) {
    case 'empty':
      header = 'Oops! No data found.';
      subheader = 'Sorry, we cannot find any records to show here.';
      colorClasses = 'bg-warning-100 border-warning-500';
      textColor = 'text-warning-700';
      Icon = FaFolderMinus;
      break;
    case 'error':
      header = 'Oops! An error occurred.';
      subheader =
        'Something went wrong! Please try again. If problem persists contact support.';
      colorClasses = 'bg-red-100 border-red-500';
      textColor = 'text-red-700';
      Icon = FaExclamationTriangle;
      break;
    case 'success':
      header = 'Yay!';
      subheader = 'Data fetch success.';
      colorClasses = 'bg-green-100 border-green-500';
      textColor = 'text-green-700';
      break;
    default:
      header = 'Yay!';
      subheader = 'Data fetch success.';
      colorClasses = 'bg-green-100 border-green-500';
      textColor = 'text-green-700';
  }

  const handleTryAgainClick = () => {
    if (!isNil(tryAgainCallback)) {
      tryAgainCallback();
    }
  };

  if (advance) {
    return (
      <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
        <Icon size={30} />
        <h2 className="text-xl font-semibold text-center">{header}</h2>
        <p className="text-gray-400 text-xs">{subheader}</p>
        <p className="text-gray-400 text-xs">{errorMessage}</p>

        {!isNil(tryAgainCallback) && (
          <div className="mt-4">
            <Button
              type="primary"
              dataTestId="try-again-button"
              label="Try again"
              onClick={handleTryAgainClick}
              micro
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`${colorClasses} p-4 border rounded rounded-lg`}
      role="alert"
    >
      <div className="flex">
        <Icon size={20} className={`mt-1.5 mr-2 ${textColor}`} />
        <span className={`font-bold ${textColor} text-2xl`}>{header}</span>
      </div>
      <p className={`${textColor} text-sm font-bold`}>{subheader}</p>

      {!isNil(errorMessage) && (
        <p className={`${textColor} mt-2 text-sm`}>{errorMessage}</p>
      )}
    </div>
  );
};

export default ApiCallNotice;
