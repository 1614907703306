import {buildBuyerOptions} from '../../../../../utils/buyer';
import {debounce, isEmpty, isNull, isUndefined, merge, omit} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useFutureRevenue} from '../../../../../api/mutations/futureRevenue';
import {useFetchBuyerOptions} from '../../../../../api/queries/buyers';
import {useFetchFutureRevenue} from '../../../../../api/queries/futureRevenue';
import {useFetchHarmonizedSystemCodeOptions} from '../../../../../api/queries/harmonizedSystemCode';
import {useFetchSignatoryPersonSearch} from '../../../../../api/queries/signatoryPersonSearch';
import {useFetchSupplierCompanySearch} from '../../../../../api/queries/supplierCompanySearch';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {
  FutureRevenueLineItem,
  FutureRevenueLineItemColumns,
  buildBuyerSignatoryOptions,
  buildHarmonizedSystemCodeOptions,
  buildSupplierCompanyOptions,
  buildSupplierSignatoryOptions,
  documentTypeOptions,
  futureRevenueDefault,
  informationSourceOptions,
  verificationMethodOptions,
} from '../../../../../interfaces/futureRevenue';
import {resubmissionReasonOptions} from '../../../../../interfaces/futureRevenue';
import {isArrayEmpty} from '../../../../../utils/array';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/FutureRevenue';
import Button from '../../../buttons/Button';
import DataTableToggle from '../../../inputs/DataTableToggle';
import Datepicker from '../../../inputs/Datepicker';
import EditableTable from '../../../inputs/EditableTable';
import Radio from '../../../inputs/Radio';
import DynamicSelect from '../../../inputs/Select';
import {
  YesOrNoTextOptions,
  currencyOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextInput from '../../../inputs/TextInput';
import StaticLink from '../../../StaticLink';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const FutureRevenueForm: React.FC = () => {
  const {lead, permissions} = useContext(ApplicationContext);
  const [buyerCompanyFirstUpdate, setBuyerCompanyFirstUpdate] = useState<
    boolean
  >(false);
  const [buyerSignatoryFirstUpdate, setBuyerSignatoryFirstUpdate] = useState<
    boolean
  >(false);
  const [supplierCompanyFirstUpdate, setSupplierCompanyFirstUpdate] = useState<
    boolean
  >(false);
  const [
    supplierSignatoryFirstUpdate,
    setSupplierSignatoryFirstUpdate,
  ] = useState<boolean>(false);

  const [supplierCompanySearchTerm, setSupplierCompanySearchTerm] = useState<
    string | null | undefined
  >('');
  const [
    supplierSignatorySearchTerm,
    setSupplierSignatorySearchTerm,
  ] = useState<string | null | undefined>('');
  const [buyerCompanySearchTerm, setBuyerCompanySearchTerm] = useState<
    string | null | undefined
  >(null);
  const [buyerSignatorySearchTerm, setBuyerSignatorySearchTerm] = useState<
    string | null | undefined
  >(null);
  const [buyerOptions, setBuyerOptions] = useState<BuyerOption[]>([]);

  const {selectedDocument} = useContext(DocumentsContext);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {
      supplierCompanyId,
      supplierSignatoryPersonId,
      buyer,
      buyerSignatoryPersonId,
    } = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
      buyerGlobalID: buyer?.gid,
    });

    if (!isNull(buyer)) {
      setBuyerCompanySearchTerm(buyer.regNum || buyer.name || '');
      setBuyerCompanyFirstUpdate(true);
    }

    if (!isNull(supplierCompanyId)) {
      const mainCompanyId = supplierCompanyId.replace('Company-', '');
      setSupplierCompanySearchTerm(mainCompanyId || '');
      setSupplierCompanyFirstUpdate(true);
    }

    if (!isNull(buyerSignatoryPersonId)) {
      const mainBuyerSignatoryId = buyerSignatoryPersonId.split('-')[1];
      setBuyerSignatorySearchTerm(mainBuyerSignatoryId || '');
      setBuyerSignatoryFirstUpdate(true);
    }

    if (!isNull(supplierSignatoryPersonId)) {
      const mainSupplierSignatoryId = supplierSignatoryPersonId.split('-')[1];
      setSupplierSignatorySearchTerm(mainSupplierSignatoryId || '');
      setSupplierSignatoryFirstUpdate(true);
    }
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNull(response)) {
      if (!isNull(response.futureRevenueUpdate.futureRevenue)) {
        const {buyer} = response.futureRevenueUpdate.futureRevenue;

        showNotification('success', 'Future Revenue updated!');
        updateValuesAtOnce({
          ...values,
          ...response.futureRevenueUpdate.futureRevenue,
          buyerGlobalID: buyer?.gid,
        });
      } else {
        showNotification('error', 'Failed to update Future Revenue!');
        handleGeneralError(response.futureRevenueUpdate.errors);
      }
    }
  };

  const [
    fetchFutureRevenue,
    {
      loading: fetchingFutureRevenue,
      error: fetchFutureRevenueErrors,
      data: futureRevenueData,
    },
  ] = useFetchFutureRevenue(onFetchSuccess);

  const [
    updateFutureRevenue,
    {loading: updatingFutureRevenue, error: errorUpdatingFutureRevenue},
  ] = useFutureRevenue(updateSuccessCallback);

  const [
    fetchHarmonizedSystemCodeOption,
    {
      data: harmonizedSystemCodeOptions,
      loading: fetchingHarmonizedSystemCode,
      error: fetchHarmonizedSystemCodeErrors,
    },
  ] = useFetchHarmonizedSystemCodeOptions(() => null);

  useEffect(() => {
    fetchHarmonizedSystemCodeOption();

    if (isNull(selectedDocument.id)) return;

    fetchFutureRevenue({
      variables: {
        documentType: 'FUTURE_REVENUE',
        id: selectedDocument.id,
        leadId: Number(lead.id),
      },
    });
  }, [fetchFutureRevenue]);

  const [
    fetchBuyerOptions,
    {
      data: buyerOptionsData,
      loading: fetchingBuyerOptions,
      error: fetchBuyerOptionsErrors,
    },
  ] = useFetchBuyerOptions();

  useEffect(() => {
    const buyer = futureRevenueData?.document?.buyer;
    const options: BuyerOption[] = buyerOptionsData?.buyerOptions || [];

    if (!isUndefined(buyer) && !isNull(buyer)) {
      options.push(futureRevenueData.document.buyer);
    }

    setBuyerOptions(options);
  }, [buyerOptionsData, futureRevenueData]);

  const [
    fetchBuyerSignatorySearch,
    {
      data: buyerSignatorySearchData,
      loading: fetchingBuyerSignatories,
      error: fetchBuyerSignatorySearchErrors,
    },
  ] = useFetchSignatoryPersonSearch(() => null);

  const [
    fetchSupplierCompanySearch,
    {
      data: supplierCompanySearchData,
      loading: fetchingSupplierCompanies,
      error: fetchSupplierCompanySearchErrors,
    },
  ] = useFetchSupplierCompanySearch(() => null);

  const [
    fetchSupplierSignatorySearch,
    {
      data: supplierSignatorySearchData,
      loading: fetchingSupplierSignatories,
      error: fetchSupplierSignatorySearchErrors,
    },
  ] = useFetchSignatoryPersonSearch(() => null);

  const previousBuyerCompanyFirstUpdate = usePrevious(buyerCompanyFirstUpdate);
  const previousSupplierCompanyFirstUpdate = usePrevious(
    supplierCompanyFirstUpdate,
  );
  const previousBuyerSignatoryFirstUpdate = usePrevious(
    buyerSignatoryFirstUpdate,
  );
  const previousSupplierSignatoryFirstUpdate = usePrevious(
    supplierSignatoryFirstUpdate,
  );

  useEffect(() => {
    if (!previousBuyerCompanyFirstUpdate && buyerCompanyFirstUpdate) {
      fetchBuyerOptions({
        variables: {
          search: buyerCompanySearchTerm,
          buyerAs: ['BUYER', 'DIMBUYER'] // Todo: Remove DIMBUYER once moved to BUYER
        },
      });
    } else {
      if (!isNull(buyerCompanySearchTerm)) {
        fetchBuyerOptions({
          variables: {
            search: buyerCompanySearchTerm,
          },
        });
      }
    }
  }, [buyerCompanySearchTerm, buyerCompanyFirstUpdate]);

  useEffect(() => {
    if (!previousBuyerSignatoryFirstUpdate && buyerSignatoryFirstUpdate) {
      fetchBuyerSignatorySearch({
        variables: {
          searchParameter: buyerSignatorySearchTerm,
          specifiedSignatoryPerson: true,
        },
      });
    } else {
      if (!isNull(buyerSignatorySearchTerm)) {
        fetchBuyerSignatorySearch({
          variables: {
            searchParameter: buyerSignatorySearchTerm,
            specifiedSignatoryPerson: false,
          },
        });
      }
    }
  }, [buyerSignatorySearchTerm, supplierCompanyFirstUpdate]);

  useEffect(() => {
    if (!previousSupplierCompanyFirstUpdate && supplierCompanyFirstUpdate) {
      fetchSupplierCompanySearch({
        variables: {
          searchParameter: supplierCompanySearchTerm,
          specifiedSupplierCompany: true,
        },
      });
    } else {
      if (!isNull(supplierCompanySearchTerm)) {
        fetchSupplierCompanySearch({
          variables: {
            searchParameter: supplierCompanySearchTerm,
            specifiedSupplierCompany: false,
          },
        });
      }
    }
  }, [supplierCompanySearchTerm, buyerSignatoryFirstUpdate]);

  useEffect(() => {
    if (!previousSupplierSignatoryFirstUpdate && supplierSignatoryFirstUpdate) {
      fetchSupplierSignatorySearch({
        variables: {
          searchParameter: supplierSignatorySearchTerm,
          specifiedSignatoryPerson: true,
        },
      });
    } else {
      if (!isNull(supplierSignatorySearchTerm)) {
        fetchSupplierSignatorySearch({
          variables: {
            searchParameter: supplierSignatorySearchTerm,
            specifiedSignatoryPerson: false,
          },
        });
      }
    }
  }, [supplierSignatorySearchTerm, supplierSignatoryFirstUpdate]);

  const buildLineItems = () => {
    const lineItems = values.lineItems;

    if (isArrayEmpty(lineItems)) {
      return [];
    }

    const formattedLineItems: FutureRevenueLineItem[] = [];

    const tradeItemsLastIndex = lineItems.length - 1;
    const lastLineItem = Object.keys(lineItems[tradeItemsLastIndex])
      .filter((k) => lineItems[tradeItemsLastIndex][k] != null)
      .reduce((a, k) => ({...a, [k]: lineItems[tradeItemsLastIndex][k]}), {});

    if (isEmpty(lastLineItem)) lineItems.pop();

    lineItems.forEach((lineItem) => {
      formattedLineItems.push({
        quantity: numeral(lineItem.quantity).value(),
        unit: lineItem.unit,
        itemName: lineItem.itemName,
        harmonizedSystemCode: lineItem.harmonizedSystemCode,
        unitPrice: numeral(lineItem.unitPrice).value(),
        amount: numeral(lineItem.amount).value(),
      });
    });

    return formattedLineItems;
  };

  const submit = () => {
    const omitFields = ['createdAt', 'updatedAt', 'buyer', 'buyerGlobalID'];
    const {__typename, ...payload} = merge({}, values);

    const futureRevenue = {
      ...omit(payload, omitFields),
      attachment: selectedDocument.file,
      leadId: Number(lead.id),
      buyer: values.buyerGlobalID,
      lineItems: buildLineItems(),
      paymentTermsInDays: numeral(values.paymentTermsInDays).value(),
      projectTradeAmount: numeral(values.projectTradeAmount).value(),
      totalTradeAmount: numeral(values.totalTradeAmount).value(),
      unpaidTradeAmount: numeral(values.unpaidTradeAmount).value(),
    };

    updateFutureRevenue({
      variables: {
        futureRevenue,
      },
    });
  };

  const buyerCompanyChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setBuyerCompanyFirstUpdate(false);
    setBuyerCompanySearchTerm(value);
  };
  const buyerCompanyDebouncedChangeHandler = useCallback(
    debounce(buyerCompanyChangeHandler, 300),
    [],
  );

  const buyerSignatoryChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setBuyerSignatoryFirstUpdate(false);
    setBuyerSignatorySearchTerm(value);
  };
  const buyerSignatoryDebouncedChangeHandler = useCallback(
    debounce(buyerSignatoryChangeHandler, 300),
    [],
  );

  const supplierCompanyChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setSupplierCompanyFirstUpdate(false);
    setSupplierCompanySearchTerm(value);
  };
  const supplierCompanyDebouncedChangeHandler = useCallback(
    debounce(supplierCompanyChangeHandler, 300),
    [],
  );

  const supplierSignatoryChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setSupplierSignatoryFirstUpdate(false);
    setSupplierSignatorySearchTerm(value);
  };
  const supplierSignatoryDebouncedChangeHandler = useCallback(
    debounce(supplierSignatoryChangeHandler, 300),
    [],
  );

  const [tableToggle, setTableToggle] = useState('');

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: futureRevenueDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const defaultValidUntil = (currentValue: Date | null) => {
    if (!isNull(currentValue)) return currentValue;
    if (!isNull(lead.rclEndedOn)) {
      handleSpecificChange({field: 'validUntil', value: lead.rclEndedOn});
      return lead.rclEndedOn;
    }

    return null;
  };

  const renderInputs = () => {
    if (isUndefined(futureRevenueData) && fetchingFutureRevenue)
      return <FormLoader />;

    if (updatingFutureRevenue)
      return <FormLoader text="Updating Future Revenue" />;

    return (
      <div id="future-revenue-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
          required
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="supplier-company-id-select"
          defaultValue={values.supplierCompanyId}
          error={errors.supplierCompanyId}
          isLoading={fetchingSupplierCompanies}
          label="Supplier Company ID"
          name="supplierCompanyId"
          onChange={(value) => {
            handleSpecificChange({field: 'supplierCompanyId', value: value});
          }}
          onKeyDown={supplierCompanyDebouncedChangeHandler}
          options={buildSupplierCompanyOptions(supplierCompanySearchData)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="supplier-signatory-person-id-select"
          defaultValue={values.supplierSignatoryPersonId}
          error={errors.supplierSignatoryPersonId}
          isLoading={fetchingSupplierSignatories}
          label="Supplier Signatory Person ID"
          name="supplierSignatoryPersonId"
          onChange={(value) => {
            handleSpecificChange({
              field: 'supplierSignatoryPersonId',
              value: value,
            });
          }}
          onKeyDown={supplierSignatoryDebouncedChangeHandler}
          options={buildSupplierSignatoryOptions(supplierSignatorySearchData)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="buyer-company-id-select"
          defaultValue={values.buyerGlobalID}
          error={errors.buyerGlobalID}
          isLoading={fetchingBuyerOptions}
          label="Buyer Company ID"
          name="buyerGlobalID"
          onChange={(value) => {
            handleSpecificChange({field: 'buyerGlobalID', value: value});
          }}
          onKeyDown={buyerCompanyDebouncedChangeHandler}
          options={buildBuyerOptions(buyerOptions)}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="buyer-signatory-person-id-select"
          defaultValue={values.buyerSignatoryPersonId}
          error={errors.buyerSignatoryPersonId}
          isLoading={fetchingBuyerSignatories}
          label="Buyer Signatory Person ID"
          name="buyerSignatoryPersonId"
          onChange={(value) => {
            handleSpecificChange({
              field: 'buyerSignatoryPersonId',
              value: value,
            });
          }}
          onKeyDown={buyerSignatoryDebouncedChangeHandler}
          options={buildBuyerSignatoryOptions(buyerSignatorySearchData)}
          placeholder="Select"
        />

        <TextInput
          classes="mb-2"
          data-testid="primary-document-identifier-field"
          error={errors.primaryDocumentIdentifier}
          label="Primary Document Identifier"
          name="primaryDocumentIdentifier"
          onChange={handleChange}
          placeholder="Add text"
          value={values.primaryDocumentIdentifier}
        />

        <TextInput
          classes="mb-2"
          data-testid="project-identifier-field"
          error={errors.projectIdentifier}
          label="Project Identifier"
          name="projectIdentifier"
          onChange={handleChange}
          placeholder="Add text"
          value={values.projectIdentifier}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="primary-document-type-select"
          defaultValue={values.primaryDocumentType}
          error={errors.primaryDocumentType}
          label="Primary Document Type"
          name="primaryDocumentType"
          onChange={(value) => {
            handleSpecificChange({field: 'primaryDocumentType', value: value});
          }}
          options={documentTypeOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="supporting-document-types-select"
          defaultValue={values.supportingDocumentTypes}
          error={errors.supportingDocumentTypes}
          isMulti
          label="Supporting Document Types"
          name="supportingDocumentTypes"
          onChange={(value) => {
            handleSpecificChange({
              field: 'supportingDocumentTypes',
              value: value,
            });
          }}
          options={documentTypeOptions.slice(1)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="currency-select"
          defaultValue={values.currency}
          error={errors.currency}
          label="Currency"
          name="currency"
          onChange={(value) => {
            handleSpecificChange({field: 'currency', value: value});
          }}
          options={currencyOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <TextInput
          classes="mb-2"
          data-testid="total-trade-amount-field"
          error={errors.totalTradeAmount}
          formatNumber
          label="Total Trade Amount"
          name="totalTradeAmount"
          integerOnly
          onChange={handleChange}
          placeholder="Add amount"
          preventNegatives
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.totalTradeAmount}
        />

        <TextInput
          classes="mb-2"
          data-testid="unpaid-trade-amount-field"
          error={errors.unpaidTradeAmount}
          formatNumber
          label="Unpaid Trade Amount"
          name="unpaidTradeAmount"
          integerOnly
          onChange={handleChange}
          placeholder="Add amount"
          preventNegatives
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.unpaidTradeAmount}
        />

        <TextInput
          classes="mb-2"
          data-testid="project-trade-amount-field"
          error={errors.projectTradeAmount}
          formatNumber
          label="Project Trade Amount"
          name="projectTradeAmount"
          integerOnly
          onChange={handleChange}
          placeholder="Add amount"
          preventNegatives
          type="number"
          value={values.projectTradeAmount}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="issued-on-field"
          error={errors.issuedOn}
          label="Issue Date"
          name="issuedOn"
          onChange={(value) => {
            handleSpecificChange({field: 'issuedOn', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.issuedOn as boolean & (Date | null)}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="delivery-date-field"
          error={errors.deliveryDate}
          label="Delivery Date"
          name="deliveryDate"
          onChange={(value) => {
            handleSpecificChange({field: 'deliveryDate', value: value});
          }}
          placeholder="Date"
          selected={values.deliveryDate as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="payment-terms-in-days-field"
          error={errors.paymentTermsInDays}
          formatNumber
          label="Payment Terms in Days"
          name="paymentTermsInDays"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          preventNegatives
          type="number"
          value={values.paymentTermsInDays}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="verified-paid-on-field"
          error={errors.verifiedPaidOn}
          label="Verified Payment Date"
          name="verifiedPaidOn"
          onChange={(value) => {
            handleSpecificChange({field: 'verifiedPaidOn', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.verifiedPaidOn as boolean & (Date | null)}
        />

        <DataTableToggle
          data={values.lineItems}
          error={errors.lineItems}
          setTableToggle={setTableToggle}
          title="Line Items"
          toggle="lineItems"
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isFileClear}
          error={errors.isFileClear}
          label="Is Image Clear?"
          name="isFileClear"
          onChange={(value) => {
            handleSpecificChange({field: 'isFileClear', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isFileComplete}
          error={errors.isFileComplete}
          label="Is Document Complete?"
          name="isFileComplete"
          onChange={(value) => {
            handleSpecificChange({field: 'isFileComplete', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isBuyerStamped}
          error={errors.isBuyerStamped}
          label="Is Document Stamped by Buyer?"
          name="isBuyerStamped"
          onChange={(value) => {
            handleSpecificChange({field: 'isBuyerStamped', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isBuyerSigned}
          error={errors.isBuyerSigned}
          label="Is Document Signed by Buyer?"
          name="isBuyerSigned"
          onChange={(value) => {
            handleSpecificChange({field: 'isBuyerSigned', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isSuspectedTampered}
          error={errors.isSuspectedTampered}
          label="File Looks Tampered?"
          name="isSuspectedTampered"
          onChange={(value) => {
            handleSpecificChange({field: 'isSuspectedTampered', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isSuspectedForged}
          error={errors.isSuspectedForged}
          label="Signature Looks Forged?"
          name="isSuspectedForged"
          onChange={(value) => {
            handleSpecificChange({field: 'isSuspectedForged', value: value});
          }}
          options={YesOrNoTextOptions}
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="verification-method-select"
          defaultValue={values.verificationMethod}
          error={errors.verificationMethod}
          label="Verification Method"
          name="verificationMethod"
          onChange={(value) => {
            handleSpecificChange({field: 'verificationMethod', value: value});
          }}
          options={verificationMethodOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="resubmission-reason-select"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          label="Resubmission Reason"
          menuPlacement="top"
          name="resubmissionReason"
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
          options={resubmissionReasonOptions}
          placeholder="Select"
          required={values.status === 'REQUIRES_RESUBMISSION'}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={
            defaultValidUntil(values.validUntil) as boolean & (Date | null)
          }
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          menuPlacement="top"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />
      </div>
    );
  };

  const renderDataTable = () => {
    return (
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        <StaticLink
          path=""
          className="text-primary-500"
          icon="FaArrowLeft"
          dataTestId="back-to-document-information"
          text="Back to Document Information"
          onClickAction={() => setTableToggle('')}
        />
        <EditableTable
          tableLabel="Line Items"
          headerProps={FutureRevenueLineItemColumns(
            buildHarmonizedSystemCodeOptions(harmonizedSystemCodeOptions),
          )}
          dataProps={values.lineItems}
          minSpareRows={1}
        />
      </div>
    );
  };
  return (
    <div className="px-3 py-2">
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        {isEmpty(tableToggle) ? renderInputs() : renderDataTable()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingFutureRevenue
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default FutureRevenueForm;
