import {SelectOption} from './application';
import {beneficialOwnershipDeclarationItem} from './generalInformationSheet/beneficialOwnershipDeclaration';
import {directorsAndOfficerItem} from './generalInformationSheet/directorsAndOfficer';
import {intercompanyAffiliationItem} from './generalInformationSheet/intercompanyAffiliations';
import {investmentInOtherCorporationItem} from './generalInformationSheet/investmentInOtherCorporation';
import {secondaryLicenseItem} from './generalInformationSheet/secondaryLicenses';
import {stockholderInformationItem} from './generalInformationSheet/stockholderInformation';

type radioType = 'Yes' | 'No' | null;

export interface GeneralInformationSheet {
  actualDateOfAnnualMeeting: Date | null;
  beneficialOwnershipDeclaration: beneficialOwnershipDeclarationItem[];
  businessName: string;
  completeBusinessAddress: string;
  completePrincipalOfficeAddress: string;
  corporateName: string;
  corporateTin: string;
  dateOfAnnualMeetingPerByLaws: string | null;
  directorsAndOfficers: directorsAndOfficerItem[];
  emailAddress: string;
  gisYear: Date | null;
  industryClassification: string;
  informationSource: string | null;
  intercompanyAffiliations: intercompanyAffiliationItem[];
  investmentInOtherCorporation: investmentInOtherCorporationItem[];
  isAmlaCovered: radioType;
  isAmlaKycCompliant: radioType;
  isNotarized: radioType;
  isSignedByCorporateSecretary: radioType;
  primaryPurpose: string;
  registeredOn: Date | null;
  resubmissionReason: string | null;
  riskopsNotes: string;
  secRegistrationNumber: string;
  secondaryLicenses: secondaryLicenseItem[];
  stampedOn: Date | null;
  status: string;
  stockholderInformation: stockholderInformationItem[];
  telephoneNumber: string;
  totalManpowerComplement: number | null;
  totalNumberOfEmployees: number | null;
  totalNumberOfOfficers: number | null;
  validUntil: Date | null;
  websiteUrl: string;
}

export const generalInformationSheetDefault: GeneralInformationSheet = {
  actualDateOfAnnualMeeting: null,
  beneficialOwnershipDeclaration: [],
  businessName: '',
  completeBusinessAddress: '',
  completePrincipalOfficeAddress: '',
  corporateName: '',
  corporateTin: '',
  dateOfAnnualMeetingPerByLaws: null,
  directorsAndOfficers: [],
  emailAddress: '',
  gisYear: null,
  industryClassification: '',
  informationSource: null,
  intercompanyAffiliations: [],
  investmentInOtherCorporation: [],
  isAmlaCovered: null,
  isAmlaKycCompliant: null,
  isNotarized: null,
  isSignedByCorporateSecretary: null,
  primaryPurpose: '',
  registeredOn: null,
  resubmissionReason: null,
  riskopsNotes: '',
  secRegistrationNumber: '',
  secondaryLicenses: [],
  stampedOn: null,
  status: 'SUBMITTED',
  stockholderInformation: [],
  telephoneNumber: '',
  totalManpowerComplement: null,
  totalNumberOfEmployees: null,
  totalNumberOfOfficers: null,
  validUntil: null,
  websiteUrl: '',
};

export const informationSourceOptions: SelectOption[] = [
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'SEC', value: 'SEC'},
];

export const resubmissionReasonOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Cropped', value: 'CROPPED'},
  {
    label: 'Did Not Match Business Information',
    value: 'DID_NOT_MATCH_BUSINESS_INFORMATION',
  },
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Missing Pages', value: 'MISSING_PAGES'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'No SEC Stamp', value: 'NO_SEC_STAMP'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];
