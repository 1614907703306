import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredArrayWhenVerified,
  requiredBooleanWhenVerifiedField,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

export const buildValidationSchema = () => {
  const shape = {
    // TODO: Add the rest of the validations on the BE ticket
    informationSource: requiredStringField({attribute: 'Information Source'}),
    stampedOn: requiredStringWhenVerified({attribute: 'SEC Stamped Date'}),
    gisYear: requiredStringWhenVerified({attribute: 'GIS Year'}),
    corporateName: requiredStringWhenVerified({
      attribute: 'Corporate Legal Name',
    }),
    secRegistrationNumber: requiredStringWhenVerified({
      attribute: 'SEC Registration Number',
    }),
    registeredOn: requiredStringWhenVerified({attribute: 'Date Registered'}),
    dateOfAnnualMeetingPerByLaws: requiredStringWhenVerified({
      attribute: 'Date of Annual Meeting per Bylaws',
    }),
    actualDateOfAnnualMeeting: requiredStringWhenVerified({
      attribute: 'Actual Date of Annual Meeting',
    }),
    completePrincipalOfficeAddress: requiredStringWhenVerified({
      attribute: 'Complete Principal Office Address',
    }),
    completeBusinessAddress: requiredStringWhenVerified({
      attribute: 'Complete Business Address',
    }),
    corporateTin: requiredStringWhenVerified({attribute: 'Corporate TIN'}),
    websiteUrl: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'URL is not valid',
      )
      .nullable(),
    emailAddress: yup.string().email('Email is not valid').nullable(),
    isAmlaCovered: requiredBooleanWhenVerifiedField({
      attribute: 'AMLA Covered?',
    }),
    isAmlaKycCompliant: requiredBooleanWhenVerifiedField({
      attribute: 'Is AMLA KYC Compliant?',
    }),
    directorsAndOfficers: requiredArrayWhenVerified({
      attribute: 'Directors and Officers',
    }),
    stockholderInformation: requiredArrayWhenVerified({
      attribute: 'Stockholder Information',
    }),
    resubmissionReason: requiredStringWhenRequiresResubmission({
      attribute: 'Resubmission Reason',
    }),
    validUntil: requiredStringWhenVerified({attribute: 'Valid Until'}),
    status: requiredStringField({attribute: 'Document Status'}),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
