import React from 'react';
import {datadogRum} from '@datadog/browser-rum';

import Modal from '../components/common/modals/Modal';
import ApplicationContext from '../contexts/ApplicationContext';
import useApplicationContextUpdater from '../hooks/useApplicationContextUpdater';
import ApolloProvider from './ApolloProvider';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const ApplicationProvider: React.FC<Props> = ({children}) => {
  const applicationUpdater = useApplicationContextUpdater();

  // Start recording DataDog Session Replay
  datadogRum.startSessionReplayRecording();

  return (
    <ApplicationContext.Provider value={applicationUpdater}>
      <ApolloProvider>
        <Modal />
        {children}
      </ApolloProvider>
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
