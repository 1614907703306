import {useParams} from '@reach/router';
import {isNil, isNull} from 'lodash-es';
import React, {useContext, useEffect, useState} from 'react';

import {useFetchDocuments} from '../../../../api/queries/documents';
import ApplicationContext from '../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../contexts/DocumentsContext';
import {documentTypeEnum, perPage} from '../../../../interfaces/documents';
import {ISODateFormatter} from '../../../../utils/dateFormater';
import {entityTypeOptions} from '../../../../utils/optionsBuilder';
import {showNotification} from '../../../../utils/toast';
import {ModalComponent} from '../../modals/ModalComponents';
import ApiCallNotice from '../../notices/ApiCallNotice';
import TablePagination from '../../paginations/TablePagination';
import ReportFilters from './filters/ReportFilters';
import SummaryFilters from './filters/SummaryFilters';
import AuditedFinancialStatementTable from './tables/AuditedFinancialStatementTable';
import BankStatementTable from './tables/BankStatementTable';
import CmapReportTable from './tables/CmapReportTable';
import ArticlesOfPartnershipTable from './tables/dynamicDocuments/ArticlesOfPartnershipTable';
import BirCorTable from './tables/dynamicDocuments/BirCorTable';
import DtiCorTable from './tables/dynamicDocuments/DtiCorTable';
import PersonalIDTable from './tables/dynamicDocuments/PersonalIDTable';
import ProofOfBillingTable from './tables/dynamicDocuments/ProofOfBillingTable';
import SecCorTable from './tables/dynamicDocuments/SecCorTable';
import SecretaryCertificateTable from './tables/dynamicDocuments/SecretaryCertificateTable';
import FutureRevenueTable from './tables/FutureRevenueTable';
import GeneralInformationSheetTable from './tables/GeneralInformationSheetTable';
import IncomeTaxReturnTable from './tables/IncomeTaxReturnTable';
import LoandexReportTable from './tables/LoandexReportTable';
import NFISReportTable from './tables/NFISReportTable';
import SummaryTable from './tables/SummaryTable';
import TradingRelationshipTable from './tables/TradingRelationshipTable';

const DocumentsTable: React.FC = () => {
  const params = useParams();
  const [prevPage, setPrevPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);

  const {setModalType, setModalProps} = useContext(ApplicationContext);
  const {
    documents,
    documentStatus,
    startDate,
    endDate,
    entityType,
    paginationDetails,
    pagination,
    pageNum,
    selectedDocument,
    setDocumentStatuses,
    setActiveDocumentGroup,
    activeDocumentGroup,
    setDocuments,
    setLatestSubmittedDocuments,
    setPagination,
    setPaginationDetails,
    setPageNum,
  } = useContext(DocumentsContext);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {documents, latestDocuments, documentStatuses} = response;
    setDocuments(documents.nodes);
    setLatestSubmittedDocuments(latestDocuments);
    setDocumentStatuses(documentStatuses);
    setTotalPages(documents.totalPages);
    setTotalItems(documents.totalCount);

    if (documents.nodes.length > 1) {
      setPaginationDetails(documents.pages);
      setPagination(documents.pages[pageNum - 1]);
    } else {
      setPagination({
        first: pagination.first,
        last: pagination.last,
        after: documents.pageInfo.startCursor,
        before: documents.pageInfo.endCursor,
      });
    }
  };

  const [
    fetchDocuments,
    {loading: fetchingDocuments, error: fetchDocumentErrors},
  ] = useFetchDocuments(onFetchSuccess);

  const docType = activeDocumentGroup === '' ? null : activeDocumentGroup;
  const defaultProps = {
    variables: {
      filter: {
        endDate: ISODateFormatter(endDate),
        entityType: entityType === 'All' ? null : entityType,
        startDate: ISODateFormatter(startDate),
        status: documentStatus === 'All' ? null : documentStatus,
      },
      leadId: parseInt(params.leadId),
      first: pagination.first,
      after: pagination.after,
      last: pagination.last,
      before: pagination.before,
      documentType: docType,
    },
  };
  const sanitizePageInput = (parsedPageNum: number) => {
    if (parsedPageNum === 1) setPageNum(0);
    if (parsedPageNum > 1) setPageNum(totalPages + 1);
    setPrevPage(pageNum);
  };

  const checkPageInput = () => {
    const parsedPageNum = parseInt(pageNum.toString());
    const hasLeadingZeroes =
      pageNum.toString() !== prevPage.toString() && parsedPageNum === prevPage;

    if (hasLeadingZeroes) return sanitizePageInput(parsedPageNum);
    if (!pageNum || pageNum <= 0) return setPageNum(1);
    if (pageNum > totalPages && totalItems > 0) return setPageNum(totalPages);
    if (prevPage === parseInt(pageNum.toString())) return setPageNum(prevPage);
    if (prevPage === pageNum) return;
  };

  useEffect(() => {
    checkPageInput();
    setPrevPage(pageNum);
    fetchDocuments(defaultProps);
  }, [
    selectedDocument,
    fetchDocuments,
    pageNum,
    startDate,
    endDate,
    entityType,
    activeDocumentGroup,
    documentStatus,
  ]);

  const activeDocumentGroupName = () => {
    if (activeDocumentGroup === '') return 'Submitted Documents';
    return activeDocumentGroup;
  };

  const viewPage = (page: number) => {
    let pageDetailIndex = page - 1;
    if (page > totalPages) pageDetailIndex = totalPages - 1;
    if (!page || page <= 0) pageDetailIndex = 0;
    setPagination(paginationDetails[pageDetailIndex]);
    setPageNum(page);
  };

  const renderFilters = () => {
    const documentType = isNull(docType) ? '' : docType;
    if (
      ['LOANDEX_REPORT', 'NFIS_REPORT', 'CMAP_REPORT'].includes(documentType)
    ) {
      return <ReportFilters entityTypeOptions={entityTypeOptions} />;
    } else {
      return <SummaryFilters />;
    }
  };

  const renderTable = () => {
    switch (docType) {
      case 'TRADING_RELATIONSHIP':
        return (
          <TradingRelationshipTable fetchingDocuments={fetchingDocuments} />
        );
      case 'BANK_STATEMENT':
        return <BankStatementTable fetchingDocuments={fetchingDocuments} />;
      case 'INCOME_TAX_RETURN':
        return <IncomeTaxReturnTable fetchingDocuments={fetchingDocuments} />;
      case 'FUTURE_REVENUE':
        return <FutureRevenueTable fetchingDocuments={fetchingDocuments} />;
      case 'LOANDEX_REPORT':
        return <LoandexReportTable fetchingDocuments={fetchingDocuments} />;
      case 'NFIS_REPORT':
        return <NFISReportTable fetchingDocuments={fetchingDocuments} />;
      case 'GENERAL_INFORMATION_SHEET':
        return (
          <GeneralInformationSheetTable fetchingDocuments={fetchingDocuments} />
        );
      case 'AUDITED_FINANCIAL_STATEMENT':
        return (
          <AuditedFinancialStatementTable
            fetchingDocuments={fetchingDocuments}
          />
        );
      case 'PERSONAL_ID':
        return <PersonalIDTable fetchingDocuments={fetchingDocuments} />;
      case 'SEC_COR':
        return <SecCorTable fetchingDocuments={fetchingDocuments} />;
      case 'PROOF_OF_BILLING':
        return <ProofOfBillingTable fetchingDocuments={fetchingDocuments} />;
      case 'SECRETARY_CERTIFICATE':
        return (
          <SecretaryCertificateTable fetchingDocuments={fetchingDocuments} />
        );
      case 'DTI_COR':
        return <DtiCorTable fetchingDocuments={fetchingDocuments} />;
      case 'BIR_COR':
        return <BirCorTable fetchingDocuments={fetchingDocuments} />;
      case 'CMAP_REPORT':
        return <CmapReportTable fetchingDocuments={fetchingDocuments} />;
      case 'AOP':
        return (
          <ArticlesOfPartnershipTable fetchingDocuments={fetchingDocuments} />
        );
      default:
        return <SummaryTable fetchingDocuments={fetchingDocuments} />;
    }
  };

  const retryDocumentFetch = () => {
    if (isNil(document)) fetchDocuments(defaultProps);
  };

  if (fetchDocumentErrors) {
    <ApiCallNotice
      advance
      type="error"
      errorMessage={fetchDocumentErrors.message}
      tryAgainCallback={retryDocumentFetch}
    />;
  }

  const goToPage = (response: any) => {
    setActiveDocumentGroup(response.documentUpload.document.documentType);
    setPageNum(1);
    setPagination({
      after: null,
      before: null,
      first: perPage,
      last: perPage,
    });
  };

  const onAddDocumentClick = () => {
    setModalType(ModalComponent.DOCUMENT_UPLOAD_FORM);
    setModalProps({
      modalTitle: 'Add Document',
      goToPage: goToPage,
    });
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 py-4">
        <div className="col-start-1 cols-span-10 col-end-3">
          <h3 className="pl-2 text-xl text-gray-600 font-bold">
            {documentTypeEnum[activeDocumentGroupName()]}
          </h3>
        </div>
        <div className="col-end-7 col-span-2 pr-2">
          <button
            className="float-right bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-6"
            data-testid="addDocumentBtn"
            onClick={() => onAddDocumentClick()}
          >
            Add Document
          </button>
        </div>
      </div>

      {renderFilters()}

      {documents.length > 0 ? (
        <div className="overflow-x-scroll">
          {renderTable()}
          <div className="flex px-4">
            <TablePagination
              currentPage={pageNum}
              totalPages={totalPages}
              items={totalItems}
              itemPerPage={perPage}
              onPageClick={(page: number) => viewPage(page)}
            />
          </div>
        </div>
      ) : (
        <div className="py-20 bg-primary-100">
          <p className="text-center font-medium">
            No available documents to display
          </p>
        </div>
      )}
    </>
  );
};

export default DocumentsTable;
