import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_BUYERS = gql(/* GraphQL */ `
  query Buyers(
    $name: String
    $regNum: String
    $search: String
    $first: Int
    $after: String
  ) {
    buyers(
      name: $name
      regNum: $regNum
      search: $search
      first: $first
      after: $after
    ) {
      nodes {
        id
        countryCode
        customId
        docsCount
        frCount
        name
        regNum
        regType
        regDate
        regSubType
        registrantFirstName
        registrantMiddleName
        registrantLastName
        trCount
        createdAt
        updatedAt
        entity {
          id
          secNumber
          companyName
          businessType
          dateRegistered
          industryDescription
          psicCode
          region
          address
        }
      }
      totalCount
      totalPages
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export function useFetchBuyers(onSuccessCallback?: any, onErrorCallback?: any) {
  return useLazyQuery(FETCH_BUYERS, {
    onCompleted: onSuccessCallback,
    onError: onErrorCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}

export const FETCH_BUYER_OPTIONS = gql(/* GraphQL */ `
  query BuyerOptions($search: String, $buyerAs: [BuyerSourceTypeEnum!]) {
    buyerOptions(search: $search, buyerAs: $buyerAs) {
      ... on Buyer {
        id
        customId
        dunsNum
        gid
        name
        regNum
        buyerAs
      }
      ... on Company {
        id
        gid
        name
        regNum
        buyerAs
      }
      ... on DimBuyer {
        id
        gid
        name
        regNum
        buyerAs
      }
      ... on Lead {
        id
        gid
        name
        buyerAs
      }
    }
  }
`);

export function useFetchBuyerOptions(onSuccessCallback?: any) {
  return useLazyQuery(FETCH_BUYER_OPTIONS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}

export const regTypes = [
  {id: 'PRIVATE', name: 'Private'},
  {id: 'GOVERNMENT', name: 'Government'},
  {id: 'MULTINATIONAL', name: 'Multinational'},
  {id: 'PUBLIC', name: 'Public'},
  {id: 'INTERNATIONAL', name: 'International'},
];

export const regSubTypes = [
  {id: 'CORPORATION', name: 'Corporation'},
  {id: 'PARTNERSHIP', name: 'Partnership'},
  {id: 'SOLE_PROP', name: 'Sole Proprietorship'},
  {id: 'COOPERATIVE', name: 'Cooperative'},
];
