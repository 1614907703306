/* eslint-disable @typescript-eslint/no-explicit-any */

import {isNil} from 'lodash-es';
import React, {useRef} from 'react';
import Select, {MultiValueProps, components} from 'react-select';

import {
  lighterTextColor,
  primaryColor,
  secondaryColor,
  white,
} from '../../../assets/colors';
import {SelectOption} from '../../../interfaces/application';

export type Value = string | string[] | null;

interface Props {
  dataTestId: string;
  defaultValue?: Value;
  onChange: (value: Value) => void;
  onInputChange?: (value: Value) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  options: SelectOption[];
  isClearable?: boolean;
  name?: string;
  isMulti?: boolean;
  inputId?: string;
  styles?: Record<string, any>;
  isLoading?: boolean;
  fetchOptions?: () => void;
  placeholder?: string;
  label?: string;
  dataTrackSelect?: string;
  classes?: string;
  error?: string;
  required?: boolean;
  noResultsMessage?: string;
  noResultsOnClick?: () => void;
  hideLabel?: boolean;
  fetchErrorCallback?: () => void;
  disabled?: boolean;
  fetchError?: string;
  withoutBorder?: boolean;
  hideRequiredTag?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

const commonStyles = {
  control: (styles: any) => {
    return {
      ...styles,
      backgroundColor: white,
      boxShadow: 'none',
    };
  },
  option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
    return {
      ...styles,
      zIndex: 50,
      fontWeight: isSelected ? 'bold' : '',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? white
        : isFocused
        ? secondaryColor
        : null,
      color: isDisabled ? '#ccc' : isSelected ? primaryColor : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : white),
      },
    };
  },
};

const multiStyles = {
  ...commonStyles,
  multiValue: (styles: any) => {
    return {
      ...styles,
      color: primaryColor,
      backgroundColor: primaryColor,
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: secondaryColor,
      color: primaryColor,
    },
  }),
};

const singleStyles = (error: boolean, withoutBorder?: boolean) => {
  let styles = {
    ...commonStyles,
    input: (styles: any) => ({...styles}),
    placeholder: (styles: any) => ({...styles}),
    singleValue: (styles: any) => ({...styles, color: lighterTextColor}),
    control: (styles: any) => ({
      ...styles,
      border: '1px solid #cbd5e0',
      paddingTop: 0,
      height: '44px',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #cbd5e0',
      },
    }),
  };

  if (error) {
    styles = {
      ...styles,
      control: (styles: any) => ({
        ...styles,
        border: '1px solid red',
        paddingTop: 0,
        height: '42px',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid red',
        },
      }),
    };
  }

  if (withoutBorder) {
    styles = {
      ...styles,
      input: (styles: any) => ({...styles}),
      control: (styles: any) => ({
        ...styles,
        border: '0',
        paddingTop: 0,
        height: '42px',
        boxShadow: 'none',
        '&:hover': {
          border: '0',
        },
      }),
    };
  }

  return styles;
};

const Option = (props: any) => {
  return (
    <div className={props.label === selectAllOption.label ? '' : ''}>
      <components.Option {...props}>
        <div className="flex">
          {/* <Checkbox checked={props.isSelected} /> */}
          <label
            data-testid={props.label}
            className={`_marginTop--xxxsmall ${
              props.isSelected ? 'selected' : ''
            }`}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
};

const noOptionsMessage = (
  props: any,
  message: string,
  onClick?: () => void | undefined,
) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div
        className="flex text-primary-500 font-bold hover:text-primary-300"
        onClick={onClick}
      >
        {/* <Checkbox checked={props.isSelected} /> */}
        <label data-testid="no-options-option">{message}</label>
      </div>
    </components.NoOptionsMessage>
  );
};

const MultiValue = (props: MultiValueProps<any>) => {
  return (
    <components.MultiValue {...props}>{props.data.label}</components.MultiValue>
  );
};

const selectAllOption = {
  value: 'all',
  label: 'Select all',
};

const DynamicSelect: React.FC<Props> = ({
  defaultValue,
  isClearable,
  isMulti,
  options,
  name,
  inputId,
  styles,
  isLoading,
  dataTestId,
  placeholder,
  label,
  dataTrackSelect,
  classes,
  error,
  fetchError,
  required,
  noResultsMessage,
  hideLabel,
  noResultsOnClick,
  fetchErrorCallback,
  fetchOptions,
  onChange,
  onInputChange,
  onKeyDown,
  withoutBorder,
  disabled,
  hideRequiredTag,
  menuPlacement,
}) => {
  const valueRef = useRef(defaultValue);
  valueRef.current = defaultValue;

  function getOptions() {
    if (isLoading || options.length === 0) return [];

    return options;
    // return [selectAllOption, ...options];
  }

  function isAllSelected(): boolean {
    if (!isNil(valueRef.current) && valueRef.current instanceof Array) {
      return getOptions().length === valueRef.current.length;
    }

    return false;
  }

  function isOptionSelected(option: any) {
    if (
      !isNil(valueRef.current) &&
      valueRef.current instanceof Array &&
      valueRef.current.length > 0
    ) {
      return (
        valueRef.current.some((value: string) => value === option.value) ||
        isAllSelected()
      );
    } else {
      return false;
    }
  }

  // We use any here since the value of react select expects
  // a type that conflicts what we need.
  // TODO: recheck this
  function processValue(): any {
    let value: SelectOption | SelectOption[] | null = [];
    let selected: SelectOption | undefined;

    if (isNil(valueRef.current) || valueRef.current.length === 0) {
      return value;
    }

    const allOptions = getOptions();

    if (!isNil(valueRef.current)) {
      // Handles single value
      if (!(valueRef.current instanceof Array)) {
        selected = allOptions.find(
          (option: SelectOption) =>
            valueRef.current && option.value === valueRef.current.toString(),
        );
        if (!isNil(selected)) value = selected;
      } else {
        // Handles multiple values
        if (isAllSelected()) {
          value = [selectAllOption];
        } else {
          const arr: SelectOption[] = [];
          valueRef.current.forEach((o: string) => {
            selected = allOptions.find(
              (option: SelectOption) =>
                !isNil(o) && option.value.toString() === o.toString(),
            );
            if (!isNil(selected)) arr.push(selected);
          });
          value = arr;
          return value;
        }
      }
    }

    return value;
  }

  function handleChange(selectValue: any, meta: any): void {
    let value: string | string[] | null = isMulti ? [] : null;

    if (isNil(selectValue)) {
      onChange(value);
    }

    if (selectValue instanceof Array) {
      const {action, option} = meta;
      const allOptions = getOptions();

      const selectedAll = (): boolean => {
        return (
          action === 'select-option' && option.value === selectAllOption.value
        );
      };

      const deSelectedAll = (): boolean => {
        return (
          action === 'deselect-option' && option.value === selectAllOption.value
        );
      };

      const allSelectedAndDeselectOther = (): boolean => {
        return (
          action === 'deselect-option' &&
          !isNil(valueRef.current) &&
          valueRef.current.length === allOptions.length
        );
      };

      if (selectedAll()) value = allOptions.map((o: SelectOption) => o.value);
      else if (deSelectedAll()) value = [];
      else if (allSelectedAndDeselectOther()) {
        value = options
          .filter(({value}) => value !== option.value)
          .map((option: SelectOption) => option.value);
      } else {
        value =
          selectValue.length !== 0
            ? selectValue.map((option: SelectOption) => option.value)
            : [];
      }
    } else if (selectValue instanceof Object) {
      value = selectValue.value;
    }
    onChange(value);
  }

  function onMenuOpen(): void {
    if (!isNil(fetchOptions) && options.length === 0) fetchOptions();
  }
  function renderRequiredTag(): any {
    if (required) {
      return <span className="text-red-500">*</span>;
    } else {
      return <span className="text-gray-300">(optional)</span>;
    }
  }

  if (isMulti) {
    return (
      <div className={`w-full ${classes}`} data-testid={dataTestId}>
        {!hideLabel && (
          <label
            className="flex block tracking-wide text-xs font-bold mb-2"
            htmlFor={`${name}`}
          >
            <span className="mr-1 uppercase">{label}</span>
            {!hideRequiredTag && renderRequiredTag()}
          </label>
        )}
        <Select
          placeholder={placeholder}
          value={processValue()}
          name={name}
          id={name}
          inputId={inputId}
          closeMenuOnSelect={false}
          isMulti={true}
          components={{
            Option,
            MultiValue,
          }}
          options={getOptions()}
          onChange={handleChange}
          hideSelectedOptions={false}
          styles={{...multiStyles, ...styles}}
          isLoading={isLoading}
          onMenuOpen={onMenuOpen}
          isOptionSelected={isOptionSelected}
          onBlur={(event: any) => event.preventDefault()}
          dataTrackSelect={dataTrackSelect}
        />
      </div>
    );
  } else {
    const customStyles = singleStyles(!isNil(error), withoutBorder);

    return (
      <div className={`w-full ${classes}`} data-testid={dataTestId}>
        {!hideLabel && (
          <label
            className="flex block tracking-wide text-xs font-bold mb-2"
            htmlFor={`${name}`}
          >
            <span className="mr-1 uppercase">{label}</span>
            {!hideRequiredTag && renderRequiredTag()}
          </label>
        )}
        <Select
          placeholder={placeholder}
          value={processValue()}
          options={options}
          name={name}
          inputId={inputId}
          styles={{...customStyles, ...styles}}
          isClearable={isClearable}
          onChange={handleChange}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          isLoading={isLoading}
          onMenuOpen={onMenuOpen}
          menuPlacement={menuPlacement || 'auto'}
          onMenu
          isDisabled={disabled}
          dataTrackSelect={dataTrackSelect}
          components={{
            NoOptionsMessage: (props: any) =>
              noOptionsMessage(
                props,
                isNil(noResultsMessage) ? 'No results found' : noResultsMessage,
                noResultsOnClick,
              ),
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={({inputValue}) =>
            !inputValue
              ? 'No results found'
              : isNil(noResultsMessage)
              ? 'No results found'
              : noResultsMessage
          }
        />
        <div className="flex justify-between">
          {!isNil(error) && (
            <div className="text-red-500 mt-3 text-xs italic">{error}</div>
          )}
          {!isNil(fetchErrorCallback) && !isNil(fetchError) && (
            <span
              className="text-primary-500 mt-3 hover:underline font-bold"
              onClick={fetchErrorCallback}
            >
              Load again
            </span>
          )}
        </div>
      </div>
    );
  }
};

export default DynamicSelect;
