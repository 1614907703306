import {isNil, omitBy, values} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredBooleanWhenVerifiedField,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

export const buildValidationSchema = (
  documentType: any,
  dynamicFields: Record<string, any>,
) => {
  const shape = {
    status: requiredStringField({attribute: 'Document Status'}),
    informationSource: requiredStringField({attribute: 'Information Source'}),
    personalTin: yup
      .string()
      .nullable(true)
      .trim()
      .when(['idType', 'status'], {
        is: (idType, status) =>
          idType === 'Tax Identification Number' && status == 'VERIFIED',
        then: yup.string().required('Personal TIN is a required field'),
      }),

    licenseNo: yup
      .string()
      .nullable(true)
      .trim()
      .when(['idType', 'status'], {
        is: (idType, status) =>
          idType === "Driver's License" && status == 'VERIFIED',
        then: yup.string().required('License No. is a required field'),
      }),

    validUntil: yup
      .string()
      .nullable(true)
      .trim()
      .when(['noExpiration', 'status'], {
        is: (noExpiration, status) => {
          return (
            (noExpiration === false || noExpiration === 'false') &&
            status === 'VERIFIED'
          );
        },
        then: yup.string().required('Valid Until is a required field'),
      }),
  };

  Object.keys(dynamicFields).map((key) => {
    const {required, label, requiredOnStatus, type} = dynamicFields[key];
    if (type === 'boolean' && requiredOnStatus === 'VERIFIED')
      return (shape[key] = requiredBooleanWhenVerifiedField({
        attribute: label,
      }));
    if (requiredOnStatus === 'VERIFIED' && label != 'Valid Until')
      return (shape[key] = requiredStringWhenVerified({attribute: label}));
    if (requiredOnStatus === 'REQUIRES_RESUBMISSION')
      return (shape[key] = requiredStringWhenRequiresResubmission({
        attribute: label,
      }));

    if (!required) return;
    return (shape[key] = requiredStringField({attribute: label}));
  });

  return yup.object().shape(omitBy(shape, isNil));
};
