import React from 'react';

import {secondaryLicenseItemColumns} from '../../../../../../interfaces/generalInformationSheet/secondaryLicenses';
import EditableTable from '../../../../inputs/EditableTable';
import StaticLink from '../../../../StaticLink';

interface tableProps {
  data: [];
  setTableToggle: (toggle: string) => void;
}

const SecondaryLicenseTable = (tableProps: tableProps) => {
  const {data, setTableToggle} = tableProps;
  return (
    <div className="h-[630px] overflow-hidden overflow-y-scroll">
      <StaticLink
        path=""
        className="text-primary-500"
        icon="FaArrowLeft"
        dataTestId="back-to-document-information"
        text="Back to Document Information"
        onClickAction={() => setTableToggle('')}
      />
      <EditableTable
        tableLabel="Secondary Licenses"
        headerProps={secondaryLicenseItemColumns}
        dataProps={data}
        minSpareRows={1}
      />
    </div>
  );
};
export default SecondaryLicenseTable;
