import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_INCOME_TAX_RETURN = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      status
      ... on IncomeTaxReturn {
        informationSource
        auditorId
        fiscalYearEndDate
        isSecBirStamped
        companyTin
        currency
        revenue
        costOfSalesServices
        grossIncomeFromOperation
        totalAllowableItemizedDeductions
        netProfit
        currentAssets
        longTermInvestment
        propertyPlantAndEquipment
        longTermReceivables
        intangibleAssets
        otherAssets
        totalAssets
        currentLiabilities
        longTermLiabilities
        totalLiabilities
        totalEquity
        verificationMethod
        resubmissionReason
        validUntil
      }
    }
  }
`;

export function useFetchIncomeTaxReturn(onSuccessCallback: any) {
  return useLazyQuery(FETCH_INCOME_TAX_RETURN, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
