import {isNull} from 'lodash-es';
import React from 'react';

import Button from '../buttons/Button';

interface tableProps {
  data: [];
  error?: string;
  required?: boolean;
  title: string;
  toggle: string;
  setTableToggle: (toggle: string) => void;
}

const DataTableToggle = (tableProps: tableProps) => {
  const {data, error, required, setTableToggle, title, toggle} = tableProps;

  return (
    <div className="w-full mb-2">
      <label className="block tracking-wide text-xs font-bold mb-2">
        <span className="mr-1 uppercase">{title}</span>

        {required ? (
          <span className="text-red-500">*</span>
        ) : (
          <span className="text-gray-300">(optional)</span>
        )}
      </label>
      <Button
        dataTestId="submit"
        label={`${data.length >= 1 ? 'Edit' : 'Add'} Table`}
        type="primary"
        className="rounded"
        onClick={() => setTableToggle(toggle)}
      />
      {!isNull(error) && (
        <p className="mt-3 text-red-500 text-xs italic">{error}</p>
      )}
    </div>
  );
};

DataTableToggle.defaultProps = {
  error: null,
  required: false,
};

export default DataTableToggle;
