import {isEmpty} from 'lodash-es';
import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  loading: boolean | undefined;
}

const TableData: React.FC<Props> = ({children, className, loading}) => {
  if (loading) {
    return (
      <td className={className}>
        <div className="animate-pulse flex space-x-4">
          <div className="h-4 bg-blue-400 rounded w-full"></div>
        </div>
      </td>
    );
  } else {
    return (
      <td className={className}>{isEmpty(children) ? '---' : children}</td>
    );
  }
};

export default TableData;
