import {gql, useMutation} from '@apollo/client';

export const UPDATE_GENERAL_INFORMATION_SHEET = gql`
  mutation generalInformationSheetUpdate(
    $input: GeneralInformationSheetAttribute!
  ) {
    generalInformationSheetUpdate(input: $input) {
      generalInformationSheet {
        id
        actualDateOfAnnualMeeting
        isAmlaCovered
        isAmlaKycCompliant
        beneficialOwnershipDeclaration {
          a
          b
          c
          completeName
          d
          dateOfBirth
          e
          f
          g
          h
          i
          nationality
          percentOwnership
          residentialAddress
          tin
          type
        }
        businessName
        emailAddress
        completeBusinessAddress
        completePrincipalOfficeAddress
        corporateName
        corporateTin
        dateOfAnnualMeetingPerByLaws
        registeredOn
        status
        directorsAndOfficers {
          address
          board
          execomm
          incorporator
          name
          nationality
          officer
          sex
          stockholder
          tin
        }
        gisYear
        industryClassification
        informationSource
        intercompanyAffiliations {
          address
          company
          relationship
          secRegistrationNumber
        }
        investmentInOtherCorporation {
          amountPhp
          assetType
          dateOfBoardResolution
        }
        isNotarized
        primaryPurpose
        resubmissionReason
        riskopsNotes
        secondaryLicenses {
          dateIssued
          dateStartedOperations
          governmentAgency
          typeOfLicense
        }
        secRegistrationNumber
        isSignedByCorporateSecretary
        stampedOn
        stockholderInformation {
          address
          amountPhpPaid
          amountPhp
          entityType
          name
          nationality
          numberOfShares
          percentOwnership
          tin
          typeOfShare
        }
        telephoneNumber
        totalManpowerComplement
        totalNumberOfEmployees
        totalNumberOfOfficers
        validUntil
        websiteUrl
      }
    }
  }
`;

export function useUpdateGeneralInformationSheet(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_GENERAL_INFORMATION_SHEET, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
