import {ApolloError} from '@apollo/client';
import {Disclosure} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import ApiCallNotice from '@src/components/common/notices/ApiCallNotice';
import {RouteProps} from '@src/interfaces/route';
import {isNil} from 'lodash-es';
import React from 'react';

interface Props {
  routes: RouteProps[];
  error?: ApolloError | undefined;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Nav: React.FC<Props> = ({routes, error}) => {
  const renderNotice = () => {
    if (isNil(error)) return null;

    return (
      <div className="mb-6">
        <ApiCallNotice type="error" errorMessage={error.message} />
      </div>
    );
  };

  return (
    <>
      {renderNotice()}
      <Disclosure as="nav" className="bg-white">
        {({open}) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src="https://design.firstcircle.io/images/logo.svg"
                      alt="First Circle Growth Finance Corp."
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {routes.map(
                        (route: RouteProps) =>
                          !route.disabled && (
                            <a
                              key={route.name}
                              href={route.path}
                              className={classNames(
                                route.current
                                  ? 'bg-gray-400 text-white'
                                  : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                                'rounded-md px-3 py-2 text-sm font-medium',
                              )}
                              aria-current={route.current ? 'page' : undefined}
                            >
                              {route.name}
                            </a>
                          ),
                      )}
                    </div>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {routes.map((route: RouteProps) => (
                  !route.disabled && (
                    <Disclosure.Button
                      key={route.name}
                      as="a"
                      href={route.path}
                      className={classNames(
                        route.current
                          ? 'bg-gray-400 text-white'
                          : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                        'block rounded-md px-3 py-2 text-base font-medium',
                      )}
                      aria-current={route.current ? 'page' : undefined}
                    >
                      {route.name}
                    </Disclosure.Button>
                  )
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Nav;
