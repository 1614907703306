import React, {useContext} from 'react';

import DocumentsContext from '../../../../../contexts/DocumentsContext';
import {Document} from '../../../../../interfaces/documents';
import {dateFormatter} from '../../../../../utils/dateFormater';
import DocumentViewerButton from '../DocumentViewerButton';
import TableData from './TableData';

interface Props {
  fetchingDocuments?: boolean;
}

const CmapReportTable: React.FC<Props> = ({fetchingDocuments}) => {
  const {documents} = useContext(DocumentsContext);

  return (
    <table className="text-center text-gray-500 whitespace-nowrap w-full divide-y divide-gray-100">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="p-4">
            Entity Type
          </th>
          <th scope="col" className="p-4">
            Subject Name
          </th>
          <th scope="col" className="p-4">
            Report Timestamp
          </th>
          <th scope="col" className="p-4">
            Valid Until
          </th>
          <th scope="col" className="p-4"></th>
        </tr>
      </thead>

      <tbody className="text-sm bg-white divide-y divide-gray-200">
        {(documents as Document[]).map((document) => {
          return (
            <tr key={document.id} className="even:bg-gray-100">
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.entityType}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.entityName}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.createdAt)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.validUntil)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                <DocumentViewerButton document={document} />
              </TableData>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CmapReportTable;
