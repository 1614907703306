import {editableColumnProps} from './../editableTable';

export interface BalanceSheet {
  header: string;
  lineItem: string;
  currentYearAmount: number | null;
  previousYearAmount: number | null;
}

export const balanceSheetTableConfig: editableColumnProps[] = [
  {
    name: 'Header',
    config: {
      data: 'header',
      editor: false,
      type: 'text',
      readOnly: true,
    },
  },
  {
    name: 'Line Item',
    config: {
      data: 'lineItem',
      editor: false,
      type: 'text',
      readOnly: true,
    },
  },
  {
    name: 'Current Year Amount',
    config: {
      data: 'currentYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
  {
    name: 'Previous Year Amount',
    config: {
      data: 'previousYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
];

export const defaultBalanceSheet = [
  {
    header: 'Current Assets',
    lineItem: 'Cash',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Assets',
    lineItem: 'Inventory',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Assets',
    lineItem: 'Receivables',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Assets',
    lineItem: 'Prepaid Expenses',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Assets',
    lineItem: 'Total',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Assets',
    lineItem: 'Property, Plant, Equipment',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Assets',
    lineItem: 'Intangible Assets',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Assets',
    lineItem: 'Total',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Trades and Accounts Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Taxes Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Interest Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Loans Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Notes Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Advances from Stockholders',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Current Liabilities',
    lineItem: 'Total',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Liabilities',
    lineItem: 'Interest Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Liabilities',
    lineItem: 'Loans Payable',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Liabilities',
    lineItem: 'Advances from Stockholders',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Noncurrent Liabilities',
    lineItem: 'Total',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    header: 'Equity',
    lineItem: 'Total',
    currentYearAmount: null,
    previousYearAmount: null,
  },
];
