import {gql, useMutation} from '@apollo/client';

const mutation = `
  mutation dynamicDocumentUpdate($input: DynamicDocumentAttribute!) {
    dynamicDocumentUpdate(input: $input) {
      dynamicDocument {
        data
        documentType
        id
        leadId
        resubmissionReason
        status
        validUntil
      }
      errors
    }
  }
`;
export const UPDATE_DYNAMIC_DOCUMENT = gql(mutation);

export function useUpdateDynamicDocument(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_DYNAMIC_DOCUMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
