import {isNil} from 'lodash-es';

import {emptyOption} from '../components/common/inputs/select/options';

export const buildOptions = (key: string, data: any) => {
  if (isNil(data)) return [];
  if (data[key].length === 0) return [];

  return [emptyOption, ...data[key]];
};

export const entityTypeOptions = [
  emptyOption,
  {label: 'Natural Person', value: 'Natural Person'},
  {label: 'Company', value: 'Company'},
];
