import {useState} from 'react';

import {defaultCurrentUser, defaultLead, defaultPermissions} from '../contexts/ApplicationContext';
import {ApplicationContext} from '../interfaces/application';
import Lead from '../interfaces/leads';
import {Props as ModalProps} from '../interfaces/modal';
import {Permissions,User} from '../interfaces/user';

const useApplicationContextUpdater = (): ApplicationContext => {
  const [lead, setLead] = useState<Lead>(defaultLead);
  const [permissions, setPermissions] = useState<Permissions>(defaultPermissions);
  const [modalType, setModalType] = useState<string | undefined>(undefined);
  const [modalProps, setModalProps] = useState<ModalProps>({} as ModalProps);
  const [showSelectedDocument, setShowSelectedDocument] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>(defaultCurrentUser);
  const [fetchingUserAccessData, setFetchingUserAccessData] = useState<boolean>(true);

  return {
    lead,
    modalProps,
    modalType,
    currentUser,
    setCurrentUser,
    permissions,
    fetchingUserAccessData,
    setFetchingUserAccessData,
    setLead,
    setModalProps,
    setModalType,
    setPermissions,
    setShowSelectedDocument,
    showSelectedDocument,
  };
};

export default useApplicationContextUpdater;
