import {format} from 'date-fns';

import {alphanumericValueRegex} from '../handsontable/customValidators';
import {editableColumnProps} from './editableTable';

export interface CMAPReport {
  companyId: string | null;
  entityName: string;
  entityType: string;
  hasNoFindings: boolean | null;
  inquiryDate: Date | null;
  leadId: number | null;
  notes: string;
  personId: string;
  courtCaseFindings: courtCaseFinding[];
  createdAt: Date | null;
  status: string;
  validUntil: Date | null;
}
export interface courtCaseFinding {
  defendantNames: string;
  natureOfCase: string | null;
  plaintiff: string;
  trialCourtCode: string;
  provinceCode: string;
  salaNumber: string;
  caseNumber: string;
  reportedDate: Date | null;
  caseStatus: string | null;
}

export const cmapDefault: CMAPReport = {
  companyId: null,
  entityName: '',
  entityType: '',
  hasNoFindings: null,
  inquiryDate: null,
  leadId: null,
  notes: '',
  personId: '',
  courtCaseFindings: [],
  createdAt: null,
  status: '',
  validUntil: null,
};

const natureOfCaseOptions = [
  'ATTACHEMENT',
  'SUM_OF_MONEY',
  'REPLEVIN',
  'EJECTMENT',
  'FALSIFICATION_OF_PUBLIC_DOCUMENT',
  'ESTAFA',
  'BATAS PAMBANSA BILANG 22',
  'FORECLOSURE',
  'ILLEGAL_RECRUITMENT',
  'SWINDLING',
  'UNLAWFUL_DETAINER',
  'OTHER',
];

const caseStatusOptions = ['DISMISSED', 'NOT_AVAILABLE', 'SETTLED'];

export const courtCaseFindingItemColumns: editableColumnProps[] = [
  {
    name: 'Defendant Names',
    config: {
      data: 'defendantNames',
    },
  },
  {
    name: 'Nature of Case',
    config: {
      data: 'natureOfCase',
      type: 'dropdown',
      source: [''].concat(natureOfCaseOptions),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Plaintiff',
    config: {
      data: 'plaintiff',
    },
  },
  {
    name: 'Trial Court Code',
    config: {
      data: 'trialCourtCode',
      allowInvalid: false,
      allowEmpty: true,
      validator: alphanumericValueRegex,
    },
  },
  {
    name: 'Province Code',
    config: {
      data: 'provinceCode',
      allowInvalid: false,
      allowEmpty: true,
      validator: alphanumericValueRegex,
    },
  },
  {
    name: 'Sala Number',
    config: {
      data: 'salaNumber',
      allowInvalid: false,
      allowEmpty: true,
      validator: alphanumericValueRegex,
    },
  },
  {
    name: 'Case Number',
    config: {
      data: 'caseNumber',
      allowInvalid: false,
      allowEmpty: true,
      validator: alphanumericValueRegex,
    },
  },
  {
    name: 'Reported Date',
    config: {
      data: 'reportedDate',
      type: 'date',
      dateFormat: 'YYYY-MM-DD',
      correctFormat: true,
      defaultDate: format(new Date(), 'yyyy-MM-dd'),
      allowInvalid: false,
    },
  },
  {
    name: 'Case Status',
    config: {
      data: 'caseStatus',
      type: 'dropdown',
      source: [''].concat(caseStatusOptions),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
];
