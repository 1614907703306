import {gql, useMutation} from '@apollo/client';

export const UPDATE_LOANDEX = gql`
  mutation loandexReportUpdate($loandexReport: LoandexReportAttribute!) {
    loandexReportUpdate(input: $loandexReport) {
      loandexReport {
        aliasName
        companyId
        createdAt
        dtiNumber
        entityName
        entityType
        gsisNumber
        hasNoFindings
        id
        inquiredOn
        inquiringBankName
        inquiringBankNumber
        leadId
        ownershipShare
        personId
        report {
          amountOtherCurrency
          amountPhp
          balanceDate
          balanceType
          comment
          contingentBalance
          currencyType
          currentBalance
          dateGranted
          dueDate
          experienceCode
          expiryDate
          facilityCode
          facilityNo
          inquiryDate
          itemsInLitigation
          pastDueBalance
          respondingBankFi
          securityCode
          securityName
        }
        reportGenerator
        riskopsNotes
        secNumber
        sssNumber
        status
        subjectNumber
        tin
        validUntil
      }
      errors
    }
  }
`;

export function useUpdateLoanDex(onSuccessCallback: (response: any) => void) {
  return useMutation(UPDATE_LOANDEX, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
