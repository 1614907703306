// Declare Colors
export const errorColor = '#da5e5e';
export const warningColor = '#f3cf72';
export const successColor = '#0ec84f';
export const primaryColor = '#565ef0';
export const secondaryColor = '#e8e9ff';
export const lighterTextColor = '#79859A';
export const inputBorderColor = '#8082a9';
export const fcblue = '#003a70';
export const white = '#ffffff';
