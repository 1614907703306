import {RouteComponentProps, useLocation} from '@reach/router';
import React from 'react';
import {FaExclamationTriangle} from 'react-icons/fa';

const PageNotFound: React.FC<RouteComponentProps> = () => {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center"
      data-testid="not-found"
    >
      <div className="sm:block md:flex">
        <div className="pr-4 md:border-r-2 border-gray-200">
          <div className="hidden md:block text-primary-500">
            <FaExclamationTriangle size="60" className="mb-3 mx-auto" />
          </div>
          <h2 className="uppercase text-5xl text-primary-500 font-bold mb-3">
            404
          </h2>
        </div>

        <div className="md:pl-5 md:mt-8">
          <h2 className="uppercase lg:text-3xl font-bold text-gray-500">
            Page not found
          </h2>
          <p className="text-sm lg:text-base text-gray-400">
            The requested URL {useLocation().pathname} was not found on this
            server. That is all we know.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
