import {SelectOption} from './application';

export interface IncomeTaxReturn {
  informationSource: string;
  auditorId: string | null; // Need extra query
  fiscalYearEndDate: Date | null;
  isSecBirStamped: boolean;
  companyTin: string;
  currency: string | null;
  revenue: number | null;
  costOfSalesServices: number | null;
  grossIncomeFromOperation: number | null;
  totalAllowableItemizedDeductions: number | null;
  netProfit: number | null;
  currentAssets: number | null;
  longTermInvestment: number | null;
  propertyPlantAndEquipment: number | null;
  longTermReceivables: number | null;
  intangibleAssets: number | null;
  otherAssets: number | null;
  totalAssets: number | null;
  currentLiabilities: number | null;
  longTermLiabilities: number | null;
  totalLiabilities: number | null;
  totalEquity: number | null;
  verificationMethod: string | null;
  resubmissionReason: string | null;
  validUntil: Date | null;
  status: string;
}

export const incomeTaxReturnDefault: IncomeTaxReturn = {
  informationSource: '',
  auditorId: null,
  fiscalYearEndDate: null,
  isSecBirStamped: false,
  companyTin: '',
  currency: null,
  revenue: null,
  costOfSalesServices: null,
  grossIncomeFromOperation: null,
  totalAllowableItemizedDeductions: null,
  netProfit: null,
  currentAssets: null,
  longTermInvestment: null,
  propertyPlantAndEquipment: null,
  longTermReceivables: null,
  intangibleAssets: null,
  otherAssets: null,
  totalAssets: null,
  currentLiabilities: null,
  longTermLiabilities: null,
  totalLiabilities: null,
  totalEquity: null,
  verificationMethod: null,
  resubmissionReason: null,
  validUntil: null,
  status: 'SUBMITTED',
};

export const resubmissionReasonOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Cropped', value: 'CROPPED'},
  {
    label: 'Did Not Match Business Information',
    value: 'DID_NOT_MATCH_BUSINESS_INFORMATION',
  },
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Missing Pages', value: 'MISSING_PAGES'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'No Stamp', value: 'NO_STAMP'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const informationSourceOptions: SelectOption[] = [
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'Scraped from Website', value: 'SCRAPED_FROM_WEBSITE'},
];

export const verificationMethodOptions: SelectOption[] = [
  {label: 'Select', value: null},
  {label: 'EFPS', value: 'EFPS'},
  {label: 'Official Receipt', value: 'OFFICIAL_RECEIPT'},
  {label: 'Proof of Payment', value: 'PROOF_OF_PAYMENT'},
  {label: 'BIR or Bank Stamp', value: 'BIR_OR_BANK_STAMP'},
  {label: 'Manual Call Verification', value: 'MANUAL_CALL_VERIFICATION'},
];
