import {isEmpty, isNil, isNull, isUndefined} from 'lodash-es';
import numeral from 'numeral';
import React, {useContext, useEffect, useState} from 'react';

import {useUpdateGeneralInformationSheet} from '../../../../../api/mutations/generalInformationSheet';
import {useFetchGeneralInformationSheet} from '../../../../../api/queries/generalInformationSheet';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {
  generalInformationSheetDefault,
  informationSourceOptions,
} from '../../../../../interfaces/generalInformationSheet';
import {resubmissionReasonOptions} from '../../../../../interfaces/generalInformationSheet';
import {beneficialOwnershipDeclarationItem} from '../../../../../interfaces/generalInformationSheet/beneficialOwnershipDeclaration';
import {directorsAndOfficerItem} from '../../../../../interfaces/generalInformationSheet/directorsAndOfficer';
import {intercompanyAffiliationItem} from '../../../../../interfaces/generalInformationSheet/intercompanyAffiliations';
import {investmentInOtherCorporationItem} from '../../../../../interfaces/generalInformationSheet/investmentInOtherCorporation';
import {secondaryLicenseItem} from '../../../../../interfaces/generalInformationSheet/secondaryLicenses';
import {stockholderInformationItem} from '../../../../../interfaces/generalInformationSheet/stockholderInformation';
import {isArrayEmpty} from '../../../../../utils/array';
import {convertToDate} from '../../../../../utils/dateFormater';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/GeneralInformationSheet';
import Button from '../../../buttons/Button';
import DataTableToggle from '../../../inputs/DataTableToggle';
import Datepicker from '../../../inputs/Datepicker';
import Radio from '../../../inputs/Radio';
import DynamicSelect from '../../../inputs/Select';
import {
  YesOrNoBooleanOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextInput from '../../../inputs/TextInput';
import YearPicker from '../../../inputs/YearPicker';
import FormLoader from './FormLoader';
import BeneficialOwnershipDeclarationTable from './generalInformationSheet/BeneficialOwnershipDeclarationTable';
import DirectorsAndOfficersTable from './generalInformationSheet/DirectorsAndOfficerTable';
import IntercompanyAffiliationTable from './generalInformationSheet/IntercompanyAffiliationTable';
import InvestmentInOtherCorporationTable from './generalInformationSheet/InvestmentInOtherCorporationTable';
import SecondaryLicenseTable from './generalInformationSheet/SecondaryLicenseTable';
import StockholderInformationTable from './generalInformationSheet/StockholderInformationTable';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const GeneralInformationSheetForm: React.FC = () => {
  const {selectedDocument} = useContext(DocumentsContext);
  const [tableToggle, setTableToggle] = useState('');
  const {lead, permissions} = useContext(ApplicationContext);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    updateValuesAtOnce({
      ...values,
      ...response.document,
    });
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (
        !isNil(response.generalInformationSheetUpdate.generalInformationSheet)
      ) {
        showNotification('success', 'General Information Sheet updated!');
        updateValuesAtOnce({
          ...values,
          ...response.generalInformationSheetUpdate.generalInformationSheet,
        });
      } else {
        showNotification(
          'error',
          'Failed to update General Information Sheet!',
        );
        handleGeneralError(response.generalInformationSheetUpdate.errors);
      }
    }
  };

  const [
    fetchGeneralInformationSheet,
    {
      data: generalInformationSheetData,
      loading: fetchingGeneralInformationSheet,
      error: fetchGeneralInformationSheetErrors,
    },
  ] = useFetchGeneralInformationSheet(onFetchSuccess);

  const [
    updateGeneralInformationSheet,
    {
      loading: updatingGeneralInformationSheet,
      error: errorUpdatingGeneralInformationSheet,
    },
  ] = useUpdateGeneralInformationSheet(updateSuccessCallback);

  useEffect(() => {
    if (isNull(selectedDocument.id)) return;

    fetchGeneralInformationSheet({
      variables: {
        documentType: 'GENERAL_INFORMATION_SHEET',
        id: selectedDocument.id,
        leadId: Number(lead.id),
      },
    });
  }, [fetchGeneralInformationSheet]);

  const buildIntercompanyAffiliations = () => {
    if (isArrayEmpty(intercompanyAffiliations)) return [];

    const formattedInterCompanyAffiliations: intercompanyAffiliationItem[] = [];

    const interCompanyAffiliationsLastIndex =
      intercompanyAffiliations.length - 1;
    const lastAffiliation = Object.keys(
      intercompanyAffiliations[interCompanyAffiliationsLastIndex],
    )
      .filter(
        (k) =>
          intercompanyAffiliations[interCompanyAffiliationsLastIndex][k] !=
          null,
      )
      .reduce(
        (a, k) => ({
          ...a,
          [k]: intercompanyAffiliations[interCompanyAffiliationsLastIndex][k],
        }),
        {},
      );

    if (isEmpty(lastAffiliation)) intercompanyAffiliations.pop();

    intercompanyAffiliations.forEach((affiliation) => {
      formattedInterCompanyAffiliations.push({
        address: affiliation.address,
        company: affiliation.company,
        relationship: affiliation.relationship,
        secRegistrationNumber: affiliation.secRegistrationNumber,
      });
    });

    return formattedInterCompanyAffiliations;
  };

  const buildDirectorsAndOfficers = () => {
    if (isArrayEmpty(directorsAndOfficers)) return [];

    const formattedDirectorsAndOfficers: directorsAndOfficerItem[] = [];

    const directorsAndOfficersLastIndex = directorsAndOfficers.length - 1;
    const lastDirectorAndOfficer = Object.keys(
      directorsAndOfficers[directorsAndOfficersLastIndex],
    )
      .filter(
        (k) => directorsAndOfficers[directorsAndOfficersLastIndex][k] != null,
      )
      .reduce(
        (a, k) => ({
          ...a,
          [k]: directorsAndOfficers[directorsAndOfficersLastIndex][k],
        }),
        {},
      );

    if (isEmpty(lastDirectorAndOfficer)) intercompanyAffiliations.pop();

    directorsAndOfficers.forEach((officer) => {
      formattedDirectorsAndOfficers.push({
        address: officer.address,
        board: officer.board,
        execomm: officer.execomm,
        incorporator: officer.incorporator,
        name: officer.name,
        nationality: officer.nationality,
        officer: officer.officer,
        sex: officer.sex,
        stockholder: officer.stockholder,
        tin: isNil(officer.tin) ? '' : officer.tin.toString(),
      });
    });

    return formattedDirectorsAndOfficers;
  };

  const buildStockholderInformation = () => {
    if (isArrayEmpty(stockholderInformation)) return [];

    const formattedStockholderInformation: stockholderInformationItem[] = [];

    const stockholderInformationLastIndex = stockholderInformation.length - 1;
    const lastStockholderInformation = Object.keys(
      stockholderInformation[stockholderInformationLastIndex],
    )
      .filter(
        (k) =>
          stockholderInformation[stockholderInformationLastIndex][k] != null,
      )
      .reduce(
        (a, k) => ({
          ...a,
          [k]: stockholderInformation[stockholderInformationLastIndex][k],
        }),
        {},
      );

    if (isEmpty(lastStockholderInformation)) stockholderInformation.pop();

    stockholderInformation.forEach((stockholder) => {
      formattedStockholderInformation.push({
        address: stockholder.address,
        amountPhpPaid: numeral(stockholder.amountPhpPaid).value(),
        amountPhp: numeral(stockholder.amountPhp).value(),
        entityType: stockholder.entityType,
        name: stockholder.name,
        nationality: stockholder.nationality,
        numberOfShares: numeral(stockholder.numberOfShares).value(),
        percentOwnership: numeral(stockholder.percentOwnership).value(),
        tin: isNil(stockholder.tin) ? '' : stockholder.tin.toString(),
        typeOfShare: stockholder.typeOfShare,
      });
    });

    return formattedStockholderInformation;
  };

  const buildInvestmentInOtherCorporations = () => {
    if (isArrayEmpty(investmentInOtherCorporation)) return [];

    const formattedInvestmentInOtherCorporations: investmentInOtherCorporationItem[] = [];

    const investmentInOtherCorporationsLastIndex =
      investmentInOtherCorporation.length - 1;
    const lastInvestmentInOtherCorporations = Object.keys(
      investmentInOtherCorporation[investmentInOtherCorporationsLastIndex],
    )
      .filter(
        (k) =>
          investmentInOtherCorporation[investmentInOtherCorporationsLastIndex][
            k
          ] != null,
      )
      .reduce(
        (a, k) => ({
          ...a,
          [k]:
            investmentInOtherCorporation[
              investmentInOtherCorporationsLastIndex
            ][k],
        }),
        {},
      );

    if (isEmpty(lastInvestmentInOtherCorporations))
      investmentInOtherCorporation.pop();

    investmentInOtherCorporation.forEach((investment) => {
      formattedInvestmentInOtherCorporations.push({
        amountPhp: numeral(investment.amountPhp).value(),
        assetType: investment.assetType,
        dateOfBoardResolution: convertToDate(investment.dateOfBoardResolution),
      });
    });

    return formattedInvestmentInOtherCorporations;
  };

  const buildSecondaryLicenses = () => {
    if (isArrayEmpty(secondaryLicenses)) return [];

    const formattedSecondaryLicenses: secondaryLicenseItem[] = [];

    const secondaryLicensesLastIndex = secondaryLicenses.length - 1;
    const lastsecondaryLicenses = Object.keys(
      secondaryLicenses[secondaryLicensesLastIndex],
    )
      .filter((k) => secondaryLicenses[secondaryLicensesLastIndex][k] != null)
      .reduce(
        (a, k) => ({
          ...a,
          [k]: secondaryLicenses[secondaryLicensesLastIndex][k],
        }),
        {},
      );

    if (isEmpty(lastsecondaryLicenses)) secondaryLicenses.pop();

    secondaryLicenses.forEach((license) => {
      formattedSecondaryLicenses.push({
        dateIssued: convertToDate(license.dateIssued),
        dateStartedOperations: convertToDate(license.dateStartedOperations),
        governmentAgency: license.governmentAgency,
        typeOfLicense: license.typeOfLicense,
      });
    });

    return formattedSecondaryLicenses;
  };

  const buildBeneficialOwnershipDeclarations = () => {
    if (isArrayEmpty(beneficialOwnershipDeclaration)) return [];

    const formattedBeneficialOwnershipDeclarations: beneficialOwnershipDeclarationItem[] = [];

    const beneficialOwnershipDeclarationsLastIndex =
      beneficialOwnershipDeclaration.length - 1;
    const lastBeneficialOwnershipDeclarations = Object.keys(
      beneficialOwnershipDeclaration[beneficialOwnershipDeclarationsLastIndex],
    )
      .filter(
        (k) =>
          beneficialOwnershipDeclaration[
            beneficialOwnershipDeclarationsLastIndex
          ][k] != null,
      )
      .reduce(
        (a, k) => ({
          ...a,
          [k]:
            beneficialOwnershipDeclaration[
              beneficialOwnershipDeclarationsLastIndex
            ][k],
        }),
        {},
      );

    if (isEmpty(lastBeneficialOwnershipDeclarations)) secondaryLicenses.pop();

    beneficialOwnershipDeclaration.forEach((declaration) => {
      formattedBeneficialOwnershipDeclarations.push({
        a: declaration.a,
        b: declaration.b,
        c: declaration.c,
        completeName: declaration.completeName,
        d: declaration.d,
        dateOfBirth: convertToDate(declaration.dateOfBirth),
        e: declaration.e,
        f: declaration.f,
        g: declaration.g,
        h: declaration.h,
        i: declaration.i,
        nationality: declaration.nationality,
        percentOwnership: numeral(declaration.percentOwnership).value(),
        residentialAddress: declaration.residentialAddress,
        tin: isNil(declaration.tin) ? '' : declaration.tin.toString(),
        type: declaration.type,
      });
    });

    return formattedBeneficialOwnershipDeclarations;
  };

  const submit = () => {
    let payload = values;

    payload = {
      ...payload,
      __typename: undefined,
      attachment: selectedDocument.file,
      beneficialOwnershipDeclaration: buildBeneficialOwnershipDeclarations(),
      directorsAndOfficers: buildDirectorsAndOfficers(),
      intercompanyAffiliations: buildIntercompanyAffiliations(),
      investmentInOtherCorporation: buildInvestmentInOtherCorporations(),
      leadId: Number(lead.id),
      secondaryLicenses: buildSecondaryLicenses(),
      stockholderInformation: buildStockholderInformation(),
      totalManpowerComplement: numeral(values.totalManpowerComplement).value(),
      totalNumberOfEmployees: numeral(values.totalNumberOfEmployees).value(),
      totalNumberOfOfficers: numeral(values.totalNumberOfOfficers).value(),
    };

    updateGeneralInformationSheet({variables: {input: payload}});
  };

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: generalInformationSheetDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const {
    intercompanyAffiliations,
    directorsAndOfficers,
    stockholderInformation,
    investmentInOtherCorporation,
    secondaryLicenses,
    beneficialOwnershipDeclaration,
  } = values;

  const renderFields = () => {
    switch (tableToggle) {
      case 'intercompany':
        return (
          <IntercompanyAffiliationTable
            data={intercompanyAffiliations}
            setTableToggle={setTableToggle}
          />
        );
      case 'directorsAndOfficers':
        return (
          <DirectorsAndOfficersTable
            data={directorsAndOfficers}
            setTableToggle={setTableToggle}
          />
        );
      case 'stockholderInformation':
        return (
          <StockholderInformationTable
            data={stockholderInformation}
            setTableToggle={setTableToggle}
          />
        );
      case 'investments':
        return (
          <InvestmentInOtherCorporationTable
            data={investmentInOtherCorporation}
            setTableToggle={setTableToggle}
          />
        );
      case 'secondaryLicenses':
        return (
          <SecondaryLicenseTable
            data={secondaryLicenses}
            setTableToggle={setTableToggle}
          />
        );
      case 'beneficialOwnership':
        return (
          <BeneficialOwnershipDeclarationTable
            data={beneficialOwnershipDeclaration}
            setTableToggle={setTableToggle}
          />
        );
      default:
        return renderInputs();
    }
  };

  const renderInputs = () => {
    if (
      isUndefined(generalInformationSheetData) &&
      fetchingGeneralInformationSheet
    )
      return <FormLoader />;

    if (updatingGeneralInformationSheet)
      return <FormLoader text="Updating General Information Sheet.." />;

    return (
      <div id="general-information-sheet-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
          required
        />

        <Datepicker
          classes="mb-2"
          dataTestId="filed-on-field"
          error={errors.stampedOn}
          label="SEC Stamped Date"
          name="stampedOn"
          onChange={(value) => {
            handleSpecificChange({field: 'stampedOn', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.stampedOn as boolean & (Date | null)}
        />

        <YearPicker
          autoComplete="off"
          classes="mb-2"
          dataTestId="gis-year-field"
          error={errors.gisYear}
          label="GIS Period Year"
          maxDate={new Date()}
          name="gisYear"
          onChange={(value) => {
            handleSpecificChange({field: 'gisYear', value: value});
          }}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
          selected={values.gisYear as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="corporate-name-field"
          error={errors.corporateName}
          label="Corporate Legal Name"
          name="corporateName"
          onChange={handleChange}
          placeholder="Add text"
          required={values.status === 'VERIFIED'}
          value={values.corporateName}
        />

        <TextInput
          classes="mb-2"
          data-testid="business-name-field"
          error={errors.businessName}
          label="Business Name"
          name="businessName"
          onChange={handleChange}
          placeholder="Add text"
          value={values.businessName}
        />

        <TextInput
          classes="mb-2"
          data-testid="sec-registration-number-field"
          error={errors.secRegistrationNumber}
          label="SEC Registration Number"
          name="secRegistrationNumber"
          onChange={handleChange}
          placeholder="Add number"
          required={values.status === 'VERIFIED'}
          value={values.secRegistrationNumber}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="registered-on-field"
          error={errors.registeredOn}
          label="Date Registered"
          name="registeredOn"
          onChange={(value) => {
            handleSpecificChange({field: 'registeredOn', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.registeredOn as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="date-of-annual-meeting-per-by-laws-field"
          error={errors.dateOfAnnualMeetingPerByLaws}
          label="Date of Annual Meeting per Bylaws"
          name="dateOfAnnualMeetingPerByLaws"
          onChange={handleChange}
          placeholder="Text"
          required={values.status === 'VERIFIED'}
          value={values.dateOfAnnualMeetingPerByLaws}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="actual-date-of-annual-meeting-field"
          error={errors.actualDateOfAnnualMeeting}
          label="Actual Date of Annual Meeting"
          name="actualDateOfAnnualMeeting"
          onChange={(value) => {
            handleSpecificChange({
              field: 'actualDateOfAnnualMeeting',
              value: value,
            });
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.actualDateOfAnnualMeeting as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="complete-principal-office-address-field"
          error={errors.completePrincipalOfficeAddress}
          label="Complete Principal Office Address"
          name="completePrincipalOfficeAddress"
          onChange={handleChange}
          placeholder="Add text"
          required={values.status === 'VERIFIED'}
          value={values.completePrincipalOfficeAddress}
        />

        <TextInput
          classes="mb-2"
          data-testid="complete-business-address-field"
          error={errors.completeBusinessAddress}
          label="Complete Business Address"
          name="completeBusinessAddress"
          onChange={handleChange}
          placeholder="Add text"
          required={values.status === 'VERIFIED'}
          value={values.completeBusinessAddress}
        />

        <TextInput
          classes="mb-2"
          data-testid="corporate-tin-field"
          error={errors.corporateTin}
          label="Corporate TIN"
          name="corporateTin"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.corporateTin}
        />

        <TextInput
          classes="mb-2"
          data-testid="website-url-field"
          error={errors.websiteUrl}
          label="Website URL"
          name="websiteUrl"
          onChange={handleChange}
          placeholder="Add text"
          type="url"
          value={values.websiteUrl}
        />

        <TextInput
          classes="mb-2"
          data-testid="email-address-field"
          error={errors.emailAddress}
          label="Company Email Address"
          name="emailAddress"
          onChange={handleChange}
          placeholder="Add text"
          value={values.emailAddress}
        />

        <TextInput
          classes="mb-2"
          data-testid="telephone-number-field"
          error={errors.telephoneNumber}
          label="Telephone Number"
          name="telephoneNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.telephoneNumber}
        />

        <TextInput
          classes="mb-2"
          data-testid="primary-purpose-field"
          error={errors.primaryPurpose}
          label="Primary Purpose"
          name="primaryPurpose"
          onChange={handleChange}
          placeholder="Add text"
          value={values.primaryPurpose}
        />

        <TextInput
          classes="mb-2"
          data-testid="industry-classification-field"
          error={errors.industryClassification}
          label="Industry Classification"
          name="industryClassification"
          onChange={handleChange}
          placeholder="Add text"
          value={values.industryClassification}
        />

        <DataTableToggle
          data={intercompanyAffiliations}
          error={errors.intercompanyAffiliations}
          setTableToggle={setTableToggle}
          title="Intercompany Affiliations"
          toggle="intercompany"
        />

        <Radio
          classes="mb-2 w-3/4"
          defaultValue={values.isAmlaCovered}
          error={errors.isAmlaCovered}
          label="AMLA Covered?"
          name="isAmlaCovered"
          onChange={(value) => {
            handleSpecificChange({field: 'isAmlaCovered', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          defaultValue={values.isAmlaKycCompliant}
          error={errors.isAmlaKycCompliant}
          label="Is AMLA KYC Compliant?"
          name="isAmlaKycCompliant"
          onChange={(value) => {
            handleSpecificChange({field: 'isAmlaKycCompliant', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required={values.status === 'VERIFIED'}
        />

        <DataTableToggle
          data={directorsAndOfficers}
          error={errors.directorsAndOfficers}
          required={values.status === 'VERIFIED'}
          setTableToggle={setTableToggle}
          title="Directors and Officers"
          toggle="directorsAndOfficers"
        />

        <DataTableToggle
          data={stockholderInformation}
          error={errors.stockholderInformation}
          required={values.status === 'VERIFIED'}
          setTableToggle={setTableToggle}
          title="Stockholder Information"
          toggle="stockholderInformation"
        />

        <DataTableToggle
          data={investmentInOtherCorporation}
          error={errors.investmentInOtherCorporation}
          setTableToggle={setTableToggle}
          title="Investment in Other Corporation"
          toggle="investments"
        />

        <DataTableToggle
          data={values.secondaryLicenses}
          error={errors.secondaryLicenses}
          setTableToggle={setTableToggle}
          title="Secondary Licenses"
          toggle="secondaryLicenses"
        />

        <TextInput
          classes="mb-2"
          data-testid="total-number-of-officers-field"
          error={errors.totalNumberOfOfficers}
          formatNumber
          label="Total Number of Officers"
          name="totalNumberOfOfficers"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.totalNumberOfOfficers}
        />

        <TextInput
          classes="mb-2"
          data-testid="total-number-of-employees-field"
          error={errors.totalNumberOfEmployees}
          formatNumber
          label="Total Number of Employees"
          name="totalNumberOfEmployees"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.totalNumberOfEmployees}
        />

        <TextInput
          classes="mb-2"
          data-testid="total-number-of-complement-field"
          error={errors.totalManpowerComplement}
          formatNumber
          label="Total Number of Complement"
          name="totalManpowerComplement"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.totalManpowerComplement}
        />

        <DataTableToggle
          data={values.beneficialOwnershipDeclaration}
          error={errors.beneficialOwnershipDeclaration}
          setTableToggle={setTableToggle}
          title="Beneficial Ownership Declaration"
          toggle="beneficialOwnership"
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isNotarized}
          error={errors.isNotarized}
          label="Notarized?"
          name="isNotarized"
          onChange={(value) => {
            handleSpecificChange({field: 'isNotarized', value: value});
          }}
          options={YesOrNoBooleanOptions}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isSignedByCorporateSecretary}
          error={errors.isSignedByCorporateSecretary}
          label="Signed by Corporate Secretary?"
          name="isSignedByCorporateSecretary"
          onChange={(value) => {
            handleSpecificChange({
              field: 'isSignedByCorporateSecretary',
              value: value,
            });
          }}
          options={YesOrNoBooleanOptions}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="resubmission-reason-select"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          label="Resubmission Reason"
          menuPlacement="top"
          name="resubmissionReason"
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
          options={resubmissionReasonOptions}
          placeholder="Select"
          required={values.status === 'REQUIRES_RESUBMISSION'}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={
            defaultValidUntil(values.validUntil) as boolean & (Date | null)
          }
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          menuPlacement="top"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />
      </div>
    );
  };

  const defaultValidUntil = (currentValue: Date | null) => {
    if (!isNull(currentValue)) return currentValue;
    if (!isNull(values.actualDateOfAnnualMeeting)) {
      const defaultValue = new Date(values.actualDateOfAnnualMeeting);
      defaultValue.setDate(defaultValue.getDate() + 30);
      handleSpecificChange({field: 'validUntil', value: defaultValue});
      return defaultValue;
    }

    return null;
  };

  return (
    <div className="px-3 mb-2">
      <div className="h-[680px] overflow-hidden overflow-y-scroll">
        {renderFields()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingGeneralInformationSheet
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default GeneralInformationSheetForm;
