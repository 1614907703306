import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_BANK_STATEMENT = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      status
      ... on BankStatement {
        accountName
        accountType
        bankAccountId
        bankId
        currency
        endBalance
        informationSource
        documentSource
        lineItems {
          credit
          debit
          details
          itemTimestamp
          transactionType
        }
        resubmissionReason
        startBalance
        statementEndDate
        statementStartDate
        validUntil
      }
    }
  }
`;

export function useFetchBankStatement(onSuccessCallback: any) {
  return useLazyQuery(FETCH_BANK_STATEMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
