import {isNil, startCase, words} from 'lodash-es';

export const convertToNumber = (value: any) => {
  if (isNil(value)) return undefined;

  try {
    return Number(value.replace(/[^0-9.-]+/g, ''));
  } catch {
    return value;
  }
};

export const titleizeUnderscoredWords = (
  word_args: string | null | undefined,
) => {
  if (isNil(word_args)) return '';

  const string_array = words(word_args)
    .map((str: string) => {
      return startCase(str.toLowerCase());
    })
    .join(' ');

  return string_array;
};
