import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredBooleanWhenVerifiedField,
  requiredNumberWhenVerified,
  requiredNumberWhenVerifiedOrInProcess,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

const positiveNumberMessage = 'Value must be a positive number';

export const buildValidationSchema = () => {
  const shape = {
    informationSource: requiredStringField({attribute: 'Information Source'}),
    fiscalYearEndDate: requiredStringWhenVerified({
      attribute: 'Fiscal Year End Date',
    }),
    isSecBirStamped: requiredBooleanWhenVerifiedField({
      attribute: 'Is SEC or BIR Stamped?',
    }),
    companyTin: requiredNumberWhenVerified({attribute: 'Company TIN'}),
    currency: requiredStringWhenVerified({attribute: 'Currency'}),
    revenue: requiredNumberWhenVerified({attribute: 'Revenue'}),
    costOfSalesServices: requiredNumberWhenVerifiedOrInProcess({
      attribute: 'Cost of Sales Service',
    }),
    totalAllowableItemizedDeductions: requiredNumberWhenVerified({
      attribute: 'Total Allowable Itemized Deductions',
    }),
    netProfit: requiredNumberWhenVerified({attribute: 'Net Income'}),
    verificationMethod: requiredStringWhenVerified({
      attribute: 'Verification Method',
    }),
    validUntil: requiredStringWhenVerified({attribute: 'Valid Until'}),
    resubmissionReason: requiredStringWhenRequiresResubmission({
      attribute: 'Resubmission Reason',
    }),
    currentAssets: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    currentLiabilities: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    intangibleAssets: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    grossIncomeFromOperation: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null)),
    longTermInvestment: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    longTermLiabilities: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    longTermReceivables: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    otherAssets: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    propertyPlantAndEquipment: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    status: requiredStringField({attribute: 'Document Status'}),
    totalAssets: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    totalEquity: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
    totalLiabilities: yup
      .number()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .positive(positiveNumberMessage),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
