import {toast} from 'react-toastify';

type NotificationType = 'success' | 'warning' | 'error';

export const showNotification = (
  type: NotificationType,
  message: string,
  toastUptime?: number,
): void => {
  const toastOptions = toastUptime ? {autoClose: toastUptime} : undefined;

  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      return;
    case 'warning':
      toast.warn(message, toastOptions);
      return;
    case 'error':
      toast.error(message, toastOptions);
      return;
    default:
      return;
  }
};
