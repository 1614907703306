import './styles/styles.css';

import {datadogRum} from '@datadog/browser-rum';
import {isUndefined} from 'lodash-es';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import SuspenseLoader from './components/common/loaders/SuspenseLoader';
import ApplicationProvider from './providers/ApplicationProvider';

if (!isUndefined(process.env.REACT_APP_DD_CLIENT_TOKEN)) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID as string,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    service: process.env.REACT_APP_DD_SERVICE,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENV,
    sampleRate: process.env.REACT_APP_ENV === 'production' ? 100 : 0,
    sessionReplaySampleRate:
      process.env.REACT_APP_ENV === 'production' ? 100 : 0,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    allowedTracingOrigins: [/https:\/\/.*\.firstcircle\.(ph|com|io)/],
    enableExperimentalFeatures: ['clickmap'],
  });
}

ReactDOM.render(
  <ApplicationProvider>
    <React.Suspense fallback={<SuspenseLoader />}>
      <App />
    </React.Suspense>
  </ApplicationProvider>,
  document.getElementById('app-root'),
);
