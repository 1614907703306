import {useParams} from '@reach/router';
import React, {useContext} from 'react';
import {FaBars} from 'react-icons/fa';

import {useFetchSecretaryCertificateInfo} from '../../api/queries/secretaryCertificateInfo';
import ApplicationContext from '../../contexts/ApplicationContext';
import certificateGenerator from '../../utils/certificateGenerator';
import NavbarItem from './NavbarItem';
import {NavbarItemProps} from './SharedInterfaces';

export interface NavbarProps {
  leftRoutes: NavbarItemProps[];
  rightRoutes: NavbarItemProps[];
}

const Navbar: React.FC<NavbarProps> = ({leftRoutes, rightRoutes}) => {
  const params = useParams();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const {lead, permissions} = useContext(ApplicationContext);

  const onFetchSuccess = (response: any) => {
    certificateGenerator(response.secretaryCertificate);
  };

  const [fetchSecretaryCertificateInfo] = useFetchSecretaryCertificateInfo(
    onFetchSuccess,
  );

  const isCorpoPartnershipType = () => {
    const companyType = lead.seccertCompanyType
      ? lead.seccertCompanyType.toLowerCase()
      : '';
    const companyTypeForSecCert = ['partnership', 'corporation'];

    return companyTypeForSecCert.includes(companyType);
  };

  const renderSecretaryCertificateLink = () => {
    if (permissions.downloadSecretaryCertificate && isCorpoPartnershipType()) {
      return (
        <NavbarItem
          text="Download Secretary Certificate"
          path=""
          dataTestId="download-secretary-cert-link"
          staticLink={true}
          onClickAction={() =>
            fetchSecretaryCertificateInfo({
              variables: {
                leadId: parseInt(params.leadId),
              },
            })
          }
        />
      );
    } else {
      return;
    }
  };

  const renderRightNav = (rightRoutes) => {
    if (!rightRoutes) return null;
    return (
      <div className="flex-none w-200 h-13 items-center">
        <ul className="flex">
          {renderSecretaryCertificateLink()}
          {rightRoutes.map((route: NavbarItemProps) => {
            return <NavbarItem key={route.text} {...route} />;
          })}
        </ul>
      </div>
    );
  };
  return (
    <nav className="bg-white border-b-2 px-4 sticky top-0 z-10">
      <div className="w-full relative flex justify-between lg:hidden lg:w-auto lg:static lg:justify-start">
        <button
          className="cursor-pointer text-md leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
          type="button"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <FaBars />
        </button>
      </div>
      <div
        className={`lg:flex justify-between items-center ${
          navbarOpen ? 'flex' : 'hidden'
        }`}
      >
        <ul className="flex flex-col lg:flex-row w-1/4">
          {leftRoutes.map((route: NavbarItemProps) => {
            return <NavbarItem key={route.text} {...route} />;
          })}
        </ul>

        <div className="flex-col lg:flex-row lg:text-xl font-bold text-black text-center w-2/4">
          {lead.companyName}
        </div>

        <ul className="flex flex-col justify-end lg:flex-row w-1/4">
          {renderSecretaryCertificateLink()}
          {rightRoutes.map((route: NavbarItemProps) => {
            return <NavbarItem key={route.text} {...route} />;
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
