import {
  emailValidator,
  mobileNumberValidator,
  telephoneNumberValidator,
} from '../../handsontable/customValidators';
import {editableColumnProps} from './../editableTable';

export interface ContactPersonInformation {
  name: string;
  emailAddress: string;
  telephoneNumber: string;
  mobileNumber: string;
  address: string;
}

export const contactPersonTableConfig: editableColumnProps[] = [
  {
    name: 'Name',
    config: {
      data: 'name',
      type: 'text',
    },
  },
  {
    name: 'Email Address',
    config: {
      data: 'emailAddress',
      validator: emailValidator,
      allowInvalid: false,
    },
  },
  {
    name: 'Telephone Number',
    config: {
      data: 'telephoneNumber',
      validator: telephoneNumberValidator,
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Mobile Number',
    config: {
      data: 'mobileNumber',
      validator: mobileNumberValidator,
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Address',
    config: {
      data: 'address',
      type: 'text',
    },
  },
];
