/* eslint-disable @typescript-eslint/no-explicit-any */
import {isEmpty, isNil} from 'lodash-es';
import {useCallback, useEffect, useState} from 'react';

interface ReturnProps {
  formattedValue: string | undefined;
  addDecimalToValue: () => void;
  convertToNumber: (value: any) => number;
}

const useFormatTextInputToCurrency = (
  value: string | undefined | number,
  delay: number,
  callback: (value: string | undefined) => void,
): ReturnProps => {
  const [formattedValue, setFormattedValue] = useState<string | undefined>(
    !isNil(value) ? value.toString() : undefined,
  );

  useEffect(addDecimalToValue, []);

  function formatNumber(n: string) {
    // format number 1000 to 1,000
    return n
      .replace(/^(-)|[^0-9]+/g, '$1')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const formatCurrency = useCallback((value: string | undefined | number) => {
    if (isNil(value)) return;

    // get input value
    let input_val = value.toString();

    // don't validate empty input
    if (input_val === '') return;

    // check for decimal
    if (input_val.indexOf('.') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered.
      const decimal_position = input_val.indexOf('.');

      const inputValueArray = input_val.split('.');

      // split number by decimal point
      let left_side = inputValueArray[0];
      let right_side = inputValueArray[1];

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = `${left_side}.${right_side}`;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
    }

    if (!isEmpty(input_val)) {
      input_val = `${input_val}`;
    }

    return input_val;
  }, []);

  function addDecimalToValue() {
    if (isNil(formattedValue) || isEmpty(formattedValue)) return;

    let newValue = formattedValue;
    const decimal_position = newValue.indexOf('.');

    if (newValue.indexOf('.') <= 0) {
      newValue = `${newValue}.00`;
    } else {
      const right_side = newValue.substring(decimal_position);

      if (right_side === '.') {
        newValue = `${newValue}00`;
      }
    }

    setFormattedValue(newValue);
    callback(newValue);
  }

  function convertToNumber(value: any) {
    if (isNil(value) || value === '') return undefined;

    try {
      return Number(value.replace(/[^0-9.-]+/g, ''));
    } catch {
      return value;
    }
  }

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        const val = formatCurrency(value);
        callback(val);
        setFormattedValue(val);
      }, delay);

      // We don't want to change value until user
      // stopped typing after the delay and most importantly
      // prevent memory leak.
      return () => {
        clearTimeout(handler);
      };
    },

    // Only do operation once value changed
    [value, delay, callback, formatCurrency],
  );

  return {
    formattedValue,
    addDecimalToValue,
    convertToNumber,
  };
};

export default useFormatTextInputToCurrency;
