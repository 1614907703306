import 'react-toastify/dist/ReactToastify.css';

import {buildRoutes} from '@src/constants/routes';
import ApplicationContext from '@src/contexts/ApplicationContext';
import React, {useContext} from 'react';

import Nav from './Nav';
import ErrorBoundary from '../errors/ErrorBoundary';

interface Props {
  size: 'lg' | 'md' | 'sm' | 'xl';
  dataTestId: string;
  children: React.ReactChild | React.ReactChild[];
}

const AppTemplate: React.FC<Props> = ({size, dataTestId, children}) => {
  const {currentUser, permissions} = useContext(ApplicationContext);

  const routes = buildRoutes(false, currentUser, permissions);

  return (
    <div className="min-h-full" data-testid={dataTestId}>
      <Nav routes={routes} />
      <main>
        <ErrorBoundary>
          <div className="mx-auto max-w-7xl py-6 sm:px-4 lg:px-6">
            {children}
          </div>
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default AppTemplate;
