
export interface Document {
  id: string | null;
  bankIdentifier?: string | null;
  buyer?: BuyerOption,
  createdAt: Date | null;
  currency?: string | null;
  data?: any | null;
  documentType: string;
  documentYear?: Date | null;
  entityName?: string | null;
  entityType?: string | null;
  fiscalYearEndDate?: Date | null;
  fileName?: string;
  filePath?: string;
  fileType?: string;
  file?: File | null;
  gisYear?: Date | null;
  informationSource: string | null;
  issuedOn?: Date | null;
  leadId: number | null;
  statementEndDate?: Date | null;
  statementStartDate?: Date | null;
  status: string | null;
  submittedBy?: string | null;
  supportingDocumentPath?: string | null;
  updatedBy?: string | null;
  updatedAt: Date | null;
  unpaidTradeAmount?: number | null;
  validUntil?: Date | null;
  lineItemsState?: string | null;
}

export const perPage = 10;

export const documentDefault: Document = {
  id: null,
  bankIdentifier: undefined,
  buyer: undefined,
  createdAt: null,
  currency: null,
  data: {},
  documentType: '',
  documentYear: undefined,
  entityName: undefined,
  entityType: undefined,
  fileName: undefined,
  filePath: undefined,
  fileType: undefined,
  file: null,
  fiscalYearEndDate: undefined,
  gisYear: undefined,
  informationSource: null,
  issuedOn: undefined,
  leadId: null,
  status: null,
  statementEndDate: undefined,
  statementStartDate: undefined,
  submittedBy: undefined,
  supportingDocumentPath: undefined,
  unpaidTradeAmount: undefined,
  updatedAt: null,
  updatedBy: undefined,
  validUntil: undefined,
};

export interface DocumentsContext {
  documents: Document[] | [];
  documentStatus: string | null;
  documentStatuses: string[];
  entityType: string | null;
  latestSubmittedDocuments: Document[] | [];
  startDate: Date | null;
  endDate: Date | null;
  defaultStartDate: Date | null;
  defaultEndDate: Date | null;
  activeDocumentGroup: string;
  pagination: Pagination;
  paginationDetails: Pagination[];
  pageNum: number;
  selectedDocument: Document;
  setDocuments: (documents: Document[]) => void;
  setDocumentStatus: (statuses: string | null) => void;
  setDocumentStatuses: (statuses: string[]) => void;
  setEntityType: (value: string | null) => void;
  setStartDate: (startDate: any) => void;
  setEndDate: (endDate: any) => void;
  setLatestSubmittedDocuments: (documents: Document[]) => void;
  setActiveDocumentGroup: (documentGroup: string) => void;
  setPagination: (pagination: Pagination) => void;
  setPaginationDetails: (details: Pagination[]) => void;
  setPageNum: (pageNum: number) => void;
  setSelectedDocument: (selectedDocument: Document) => void;
  uploadableDocuments: (string | null)[];
}
export interface Pagination {
  first: number | null;
  before: string | null;
  last: number | null;
  after: string | null;
}

export const documentTypeEnum = {
  AOP: 'Articles of Partnership',
  AUDITED_FINANCIAL_STATEMENT: 'Audited Financial Statement',
  BANK_STATEMENT: 'Bank Statement',
  BIR_COR: 'BIR COR',
  CMAP_REPORT: 'CMAP Report',
  DTI_COR: 'DTI COR',
  FUTURE_REVENUE: 'Future Revenue',
  GENERAL_INFORMATION_SHEET: 'General Information Sheet',
  INCOME_TAX_RETURN: 'Income Tax Return',
  LOANDEX_REPORT: 'Loandex Report',
  NFIS_REPORT: 'NFIS Report',
  PERSONAL_ID: 'Personal ID',
  PROOF_OF_BILLING: 'Proof of Billing',
  SECRETARY_CERTIFICATE: "Secretary's Certificate",
  SEC_COR: 'SEC COR',
  TRADING_RELATIONSHIP: 'Trading Relationship',
  DYNAMIC_DOCUMENT: 'DYNAMIC_DOCUMENT',
};

export const statusEnum = [
  'ABANDONED',
  'IN_PROGRESS',
  'NOT_PROCESSED',
  'REJECTED',
  'RESUBMIT',
  'SUBMITTED',
  'VERIFIED',
];

export const uploadableDocumentType = [
  {label: 'Articles of Partnership', value: 'AOP'},
  {label: 'Audited Financial Statement', value: 'AUDITED_FINANCIAL_STATEMENT'},
  {label: 'Bank Statement', value: 'BANK_STATEMENT'},
  {label: 'BIR COR', value: 'BIR_COR'},
  {label: 'CMAP Report', value: 'CMAP_REPORT'},
  {label: 'DTI COR', value: 'DTI_COR'},
  {label: 'Future Revenue', value: 'FUTURE_REVENUE'},
  {label: 'General Information Sheet', value: 'GENERAL_INFORMATION_SHEET'},
  {label: 'Income Tax Return', value: 'INCOME_TAX_RETURN'},
  {label: 'Loandex', value: 'LOANDEX_REPORT'},
  {label: 'NFIS Report', value: 'NFIS_REPORT'},
  {label: 'Personal ID', value: 'PERSONAL_ID'},
  {label: 'Proof of Billing', value: 'PROOF_OF_BILLING'},
  {label: "Secretary's Certificate", value: 'SECRETARY_CERTIFICATE'},
  {label: 'SEC COR', value: 'SEC_COR'},
  {label: 'Trading Relationship', value: 'TRADING_RELATIONSHIP'},
];
