import isNull from 'lodash-es/isNull';

import {documentStatusOptions} from '../../../../../../components/common/inputs/select/options';
import {uploadableDocumentType} from '../../../../../../interfaces/documents';

export const defaultInformationSource = (
  role: string,
  currentValue: string | null,
) => {
  if (!isNull(currentValue)) return currentValue;
  if (isSalesAdmin(role)) return 'CUSTOMER';

  return null;
};

export const isSalesAdmin = (role: string) => {
  return ['AM', 'RM', 'FCCAP'].includes(role);
};

export const isTempWorker = (role: string) => {
  return ['Temp'].includes(role);
};

export const isTempWorkerAndHasNoAccess = (
  role: string,
  status: string | null,
) => {
  return (
    ['Temp'].includes(role) &&
    !['SUBMITTED', 'IN_PROCESSING'].includes(status || '')
  );
};

export const limitedDocumentStatusOptions = (role: string) => {
  const options = JSON.parse(JSON.stringify(documentStatusOptions));

  if (isTempWorker(role))
    return options.filter((option) =>
      ['SUBMITTED', 'IN_PROCESSING'].includes(option.value),
    );

  return options;
};

export const limitedDocumentTypeOptions = (role: string) => {
  const options = JSON.parse(JSON.stringify(uploadableDocumentType));

  if (isSalesAdmin(role))
    return options.filter(
      (option) =>
        !['CMAP_REPORT', 'LOANDEX_REPORT', 'NFIS_REPORT'].includes(
          option.value,
        ),
    );

  return options;
};
