import {debounce, isEmpty, isNil, isUndefined} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateLoanDex} from '../../../../../api/mutations/loanDex';
import {useFetchCompanies} from '../../../../../api/queries/companies';
import {useFetchLoanDex} from '../../../../../api/queries/loanDex';
import {useFetchPeople} from '../../../../../api/queries/people';
import {useFetchReportGenerators} from '../../../../../api/queries/reportGenerators';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {
  ReportItem,
  inquiringBankOptions,
  loanDexDefault,
  reportItemColumns,
} from '../../../../../interfaces/loanDex';
import {isArrayEmpty} from '../../../../../utils/array';
import {convertToDate} from '../../../../../utils/dateFormater';
import {
  buildOptions,
  entityTypeOptions,
} from '../../../../../utils/optionsBuilder';
import {showNotification} from '../../../../../utils/toast';
import {
  getLabelFromOptions,
  handleNumeral,
  parseInteger,
} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/LoanDex';
import Button from '../../../buttons/Button';
import DataTableToggle from '../../../inputs/DataTableToggle';
import Datepicker from '../../../inputs/Datepicker';
import EditableTable from '../../../inputs/EditableTable';
import Radio from '../../../inputs/Radio';
import DynamicSelect from '../../../inputs/Select';
import {
  YesOrNoBooleanOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextArea from '../../../inputs/TextArea';
import TextInput from '../../../inputs/TextInput';
import StaticLink from '../../../StaticLink';
import FormLoader from './FormLoader';
import {
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const LoanDexform: React.FC = () => {
  const [tableToggle, setTableToggle] = useState('');
  const [personFirstUpdate, setPersonFirstUpdate] = useState<boolean>(false);
  const [companyFirstUpdate, setCompanyFirstUpdate] = useState<boolean>(false);
  const [reportGeneratorFirstUpdate, setReportGeneratorFirstUpdate] = useState<
    boolean
  >(false);
  const [personSearchTerm, setPersonSearchTerm] = useState<string>('');
  const [companySearchTerm, setCompanySearchTerm] = useState<string>('');
  const [reportGeneratorSearchTerm, setReportGeneratorSearchTerm] = useState<
    string
  >('');
  const {selectedDocument} = useContext(DocumentsContext);
  const {file: uploadedFile} = selectedDocument;
  const {lead, permissions} = useContext(ApplicationContext);
  const leadId = Number(lead.id);
  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {companyId, personId, report, reportGenerator} = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
    });

    if (!isNil(companyId)) {
      const companyValueArray = companyId.split('-');
      companyValueArray.shift();

      const mainCompanyId = companyValueArray.join('-');

      setCompanySearchTerm(mainCompanyId);
      setCompanyFirstUpdate(true);
    }

    if (!isNil(personId)) {
      setPersonSearchTerm(personId);
      setPersonFirstUpdate(true);
    }

    if (!isNil(reportGenerator)) {
      setReportGeneratorSearchTerm(reportGenerator);
      setReportGeneratorFirstUpdate(true);
    }
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.loandexReportUpdate.loandexReport)) {
        showNotification('success', 'Loandex Report updated!');
        updateValuesAtOnce({
          ...values,
          ...response.loandexReportUpdate.loandexReport,
        });
      } else {
        showNotification('error', 'Failed to update LoanDex Report!');
        handleGeneralError(response.loandexReport.errors);
      }
    }
  };

  const [
    fetchLoanDex,
    {data: LoandexData, loading: fetchingLoanDex, error: fetchLoanDexErrors},
  ] = useFetchLoanDex(onFetchSuccess);

  const [
    updateLoanDex,
    {loading: updatingLoanDex, error: errorUpdatingLoanDex},
  ] = useUpdateLoanDex(updateSuccessCallback);

  useEffect(() => {
    if (selectedDocument.id) {
      fetchLoanDex({
        variables: {
          documentType: 'LOANDEX_REPORT',
          id: selectedDocument.id,
          leadId: Number(lead.id),
        },
      });
    }
  }, [fetchLoanDex]);

  const [
    fetchPersonSearch,
    {
      data: personSearchData,
      loading: fetchingPeople,
      error: fetchPersonSearchErrors,
    },
  ] = useFetchPeople();

  const [
    fetchCompaniesSearch,
    {
      data: companySearchData,
      loading: fetchingCompanies,
      error: fetchCompanySearchErrors,
    },
  ] = useFetchCompanies();

  const [
    fetchReportGeneratorSearch,
    {
      data: reportGeneratorSearchData,
      loading: fetchingReportGenerators,
      error: fetchReportGeneratorSearchErrors,
    },
  ] = useFetchReportGenerators();

  const previousPersonFirstUpdate = usePrevious(personFirstUpdate);
  const previousCompanyFirstUpdate = usePrevious(companyFirstUpdate);
  const previousReportGeneratorFirstUpdate = usePrevious(
    reportGeneratorFirstUpdate,
  );

  useEffect(() => {
    if (!previousPersonFirstUpdate && personFirstUpdate) {
      fetchPersonSearch({
        variables: {
          searchParameter:
            isNil(personSearchTerm) || isEmpty(personSearchTerm)
              ? ''
              : personSearchTerm,
          specifiedSignatoryPerson: true,
        },
      });
    } else {
      if (!isNil(personSearchTerm)) {
        fetchPersonSearch({
          variables: {
            searchParameter: personSearchTerm,
            specifiedSignatoryPerson: false,
          },
        });
      }
    }
  }, [personSearchTerm, personFirstUpdate]);

  useEffect(() => {
    if (!previousCompanyFirstUpdate && companyFirstUpdate) {
      fetchCompaniesSearch({
        variables: {
          searchParameter:
            isNil(companySearchTerm) || isEmpty(companySearchTerm)
              ? ''
              : companySearchTerm,
          specifiedCompany: true,
        },
      });
    } else {
      if (!isNil(companySearchTerm)) {
        fetchCompaniesSearch({
          variables: {
            searchParameter: companySearchTerm,
            specifiedCompany: false,
          },
        });
      }
    }
  }, [companySearchTerm, personFirstUpdate]);

  useEffect(() => {
    if (!previousReportGeneratorFirstUpdate && reportGeneratorFirstUpdate) {
      fetchReportGeneratorSearch({
        variables: {
          searchParameter:
            isNil(reportGeneratorSearchTerm) ||
            isEmpty(reportGeneratorSearchTerm)
              ? ''
              : reportGeneratorSearchTerm,
          specifiedReportGenerator: true,
        },
      });
    } else {
      if (!isNil(reportGeneratorSearchTerm)) {
        fetchReportGeneratorSearch({
          variables: {
            searchParameter: reportGeneratorSearchTerm,
            specifiedReportGenerator: false,
          },
        });
      }
    }
  }, [reportGeneratorSearchTerm, reportGeneratorFirstUpdate]);

  const personChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setPersonFirstUpdate(false);
    setPersonSearchTerm(value);
  };
  const personDebouncedChangeHandler = useCallback(
    debounce(personChangeHandler, 300),
    [],
  );

  const companyChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setCompanyFirstUpdate(false);
    setCompanySearchTerm(value);
  };
  const companyDebouncedChangeHandler = useCallback(
    debounce(companyChangeHandler, 300),
    [],
  );

  const reportGeneratorChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setReportGeneratorFirstUpdate(false);
    setReportGeneratorSearchTerm(value);
  };
  const reportGeneratorDebouncedChangeHandler = useCallback(
    debounce(reportGeneratorChangeHandler, 300),
    [],
  );

  const buildReport = () => {
    if (isArrayEmpty(report)) {
      return [];
    }

    const formattedReport: ReportItem[] = [];

    const reportItemLastIndex = report.length - 1;
    const lastLineItem = Object.keys(report[reportItemLastIndex])
      .filter((k) => report[reportItemLastIndex][k] != null)
      .reduce((a, k) => ({...a, [k]: report[reportItemLastIndex][k]}), {});

    if (isEmpty(lastLineItem)) report.pop();

    report.forEach((reportItem) => {
      formattedReport.push({
        amountOtherCurrency: numeral(reportItem.amountOtherCurrency).value(),
        amountPhp: numeral(reportItem.amountPhp).value(),
        balanceDate: convertToDate(reportItem.balanceDate),
        balanceType: reportItem.balanceType,
        comment: reportItem.comment,
        contingentBalance: numeral(reportItem.contingentBalance).value(),
        currencyType: reportItem.currencyType,
        currentBalance: numeral(reportItem.currentBalance).value(),
        dateGranted: convertToDate(reportItem.dateGranted),
        dueDate: convertToDate(reportItem.dueDate),
        experienceCode: reportItem.experienceCode,
        expiryDate: convertToDate(reportItem.expiryDate),
        facilityCode: reportItem.facilityCode,
        facilityNo: reportItem.facilityNo,
        inquiryDate: convertToDate(reportItem.inquiryDate),
        itemsInLitigation: numeral(reportItem.itemsInLitigation).value(),
        pastDueBalance: numeral(reportItem.pastDueBalance).value(),
        respondingBankFi: reportItem.respondingBankFi,
        securityCode: reportItem.securityCode,
        securityName: reportItem.securityName,
      });
    });

    return formattedReport;
  };

  const submit = () => {
    let payload = values;

    payload = {
      ...payload,
      leadId: leadId,
      inquiringBankNumber: parseInteger(values.inquiringBankNumber),
      report: buildReport(),
      subjectNumber: parseInteger(values.subjectNumber),
      __typename: undefined,
      ownershipShare: handleNumeral(values.ownershipShare),
    };
    if (uploadedFile) payload.attachment = selectedDocument.file;

    updateLoanDex({
      variables: {
        loandexReport: payload,
      },
    });
  };

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: loanDexDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const {companyId, ownershipShare, personId, report, reportGenerator} = values;

  const renderFields = () => {
    if (tableToggle) return renderDataTable();
    return renderInputs();
  };

  const updateBankNumber = (event: React.ChangeEvent<any>) => {
    const {value} = event.target;
    const newValue = value === 0 ? '' : value;
    event.target.value = newValue;
    handleChange(event);
  };

  const renderInputs = () => {
    if (isUndefined(LoandexData) && fetchingLoanDex) return <FormLoader />;

    if (updatingLoanDex) return <FormLoader text="Updating Loandex..." />;

    return (
      <div id="loandex-report-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="person-id-select"
          defaultValue={personId}
          error={errors.personId}
          isLoading={fetchingPeople}
          label="Person ID"
          name="personId"
          onChange={(value) => {
            handleSpecificChange({field: 'personId', value: value});
          }}
          onKeyDown={personDebouncedChangeHandler}
          options={buildOptions('personIdOptions', personSearchData)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="company-id-select"
          defaultValue={companyId}
          error={errors.companyId}
          isLoading={fetchingCompanies}
          label="Company ID"
          name="companyId"
          onChange={(value) => {
            handleSpecificChange({field: 'companyId', value: value});
          }}
          onKeyDown={companyDebouncedChangeHandler}
          options={buildOptions('companyIdOptions', companySearchData)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="report-generator-select"
          defaultValue={reportGenerator}
          error={errors.reportGenerator}
          isLoading={fetchingReportGenerators}
          label="Report Generator"
          name="reportGenerator"
          onChange={(value) => {
            handleSpecificChange({field: 'reportGenerator', value: value});
          }}
          onKeyDown={reportGeneratorDebouncedChangeHandler}
          options={buildOptions(
            'reportGeneratorOptions',
            reportGeneratorSearchData,
          )}
          placeholder="Select"
        />

        <TextInput
          classes="mb-2"
          data-testid="entityName-field"
          error={errors.entityName}
          label="Subject Name (Natural Person or Company)"
          name="entityName"
          onChange={handleChange}
          placeholder="Add text"
          required
          value={values.entityName}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="entity-type-select"
          defaultValue={values.entityType}
          error={errors.entityType}
          label="Entity Type"
          name="entityType"
          onChange={(value) => {
            handleSpecificChange({field: 'entityType', value: value});
          }}
          options={entityTypeOptions}
          placeholder="Select"
          required
        />

        <TextInput
          classes="mb-2"
          data-testid="aliasName-field"
          error={errors.aliasName}
          label="Alias/Former Name (Natural Person or Company)"
          name="aliasName"
          onChange={handleChange}
          placeholder="Add text"
          value={values.aliasName}
        />

        <TextInput
          classes="mb-2"
          data-testid="ownershipShare-field"
          error={errors.ownershipShare}
          formatNumber
          formatPercentage
          label="Ownership Share"
          name="ownershipShare"
          onChange={handleChange}
          placeholder="Add Number"
          type="number"
          value={values.ownershipShare}
          preventNegatives
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.hasNoFindings}
          error={errors.hasNoFindings}
          label="Has no findings?"
          name="hasNoFindings"
          onChange={(value) => {
            handleSpecificChange({field: 'hasNoFindings', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required
        />

        <Datepicker
          classes="mb-2"
          dataTestId="report-timestamp-field"
          error={errors.createdAt}
          label="Report Timestamp"
          name="createdAt"
          onChange={(value) => {
            handleSpecificChange({field: 'createdAt', value: value});
          }}
          placeholder="Date"
          required
          selected={values.createdAt as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="subjectNumber-field"
          error={errors.subjectNumber}
          formatNumber
          label="Subject Number"
          name="subjectNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.subjectNumber}
        />

        <TextInput
          classes="mb-2"
          data-testid="tin-field"
          error={errors.tin}
          label="TIN"
          name="tin"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.tin}
        />

        <TextInput
          classes="mb-2"
          data-testid="sssNumber-field"
          error={errors.sssNumber}
          label="SSS Number"
          name="sssNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.sssNumber}
        />

        <TextInput
          classes="mb-2"
          data-testid="gsisNumber-field"
          error={errors.gsisNumber}
          label="GSIS Number"
          name="gsisNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.gsisNumber}
        />

        <TextInput
          classes="mb-2"
          data-testid="dtiNumber-field"
          error={errors.dtiNumber}
          label="DTI Number"
          name="dtiNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.dtiNumber}
        />

        <TextInput
          classes="mb-2"
          data-testid="secNumber-field"
          error={errors.secNumber}
          label="SEC Number"
          name="secNumber"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.secNumber}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="inquired-on-field"
          error={errors.inquiredOn}
          label="Inquiry Date by BAP"
          name="inquiredOn"
          onChange={(value) => {
            handleSpecificChange({field: 'inquiredOn', value: value});
          }}
          placeholder="Date"
          selected={values.inquiredOn as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="inquiringBankNumber-field"
          error={errors.inquiringBankNumber}
          formatNumber
          label="Inquiring Bank Number"
          name="inquiringBankNumber"
          numbersOnly
          onChange={updateBankNumber}
          placeholder="Add Number"
          required
          type="number"
          value={values.inquiringBankNumber}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="inquiring-bank-name-select"
          defaultValue={values.inquiringBankName}
          error={errors.inquiringBankName}
          label="Inquiring Bank Name"
          name="inquiringBankName"
          onChange={(value) => {
            handleSpecificChange({
              field: 'inquiringBankName',
              value: value,
            });
          }}
          options={inquiringBankOptions}
          placeholder="Select"
          required
        />

        <DataTableToggle
          data={report}
          error={errors.report}
          setTableToggle={setTableToggle}
          title="Report"
          toggle="report"
        />

        <TextArea
          classes="mb-2"
          data-testid="risk_ops_notes-field"
          defaultValue={values.riskopsNotes}
          error={errors.riskopsNotes}
          label="RiskOps Notes"
          name="riskopsNotes"
          onChange={handleChange}
          placeholder="Add text"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          selected={values.validUntil as boolean & (Date | null)}
        />
      </div>
    );
  };

  const renderDataTable = () => {
    return (
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        <StaticLink
          path=""
          className="text-primary-500"
          icon="FaArrowLeft"
          dataTestId="back-to-document-information"
          text="Back to Document Information"
          onClickAction={() => setTableToggle('')}
        />
        <EditableTable
          tableLabel="Report"
          headerProps={reportItemColumns}
          dataProps={report}
          minSpareRows={1}
        />
      </div>
    );
  };
  return (
    <div className="px-3 py-2">
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        {renderFields()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingLoanDex
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default LoanDexform;
