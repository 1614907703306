import {isNil, isUndefined} from 'lodash-es';

import {SelectOption} from '../interfaces/application';

export const parseInteger = (value: string | undefined) => {
  return isNil(value) ? null : parseInt(value);
};

export const handleNumeral = (value: string | number | undefined) => {
  return value === '' ? null : value;
};

export const isEven = (value: number | undefined) => {
  return isUndefined(value) ? undefined : value % 2 === 0;
};

export const getLabelFromOptions = (
  options: SelectOption[],
  value: string | null,
) => {
  if (isNil(value)) return value;

  const option = options.find((option) => option.value === value);
  return option ? option.label : '';
};
