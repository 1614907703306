import React from 'react';
import {FaExclamationTriangle} from 'react-icons/fa';

import type { GraphQLError } from 'graphql';

interface Props {
  errors: readonly GraphQLError[];
}

const GraphQLFailure: React.FC<Props> = ({
  errors,
}) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
      <div className="sm:block md:flex">
        <div className="pr-4 md:border-r-2 border-gray-200">
          <div className="hidden md:block text-primary-500">
            <FaExclamationTriangle size="60" className="mb-3" />
          </div>
          <h2 className="uppercase text-5xl text-primary-500 font-bold mb-3">
            500
          </h2>
        </div>

        <div className="md:pl-5">
          <h2 className="uppercase lg:text-3xl font-bold text-gray-500">
            Something went wrong
          </h2>
          <p className="text-sm lg:text-base text-gray-400">
            We apologize for the inconvenience. Please contact our team.
          </p>
          {
            errors.map((error, index) => (
              <div key={index} className={process.env.REACT_APP_ENV === 'production' ? 'invisible' : 'visible'}>
                <p className="text-xs lg:text-sm text-gray-300">
                  {error.message}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default GraphQLFailure;
