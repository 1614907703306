import {isNil} from 'lodash-es';
import React, {useCallback, useEffect, useState} from 'react';

import useFormatTextInputToCurrency from '../../../hooks/useFormatTextInputToCurrency';

type InputValue = string | number | undefined;
interface OwnProps {
  classes?: string;
  error?: string;
  clearable?: boolean;
  showError?: boolean;
  label?: string;
  onClearAction?: () => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
  formatCurrency?: boolean;
  value: InputValue;
  onChange: (event: any) => void;
  hideLabel?: boolean;
  withoutBorder?: boolean;
  withoutOuterPadding?: boolean;
  rightAlign?: boolean;
  formatNumber?: boolean;
  formatPercentage?: boolean;
  preventNegatives?: boolean;
  inputOnly?: boolean;
  autoComplete?: string;
  numbersOnly?: boolean;
  integerOnly?: boolean;
}

export type Props = OwnProps & React.HTMLProps<HTMLInputElement>;

const TextInput: React.FC<Props> = (props) => {
  const {
    error,
    showError,
    onChange,
    onClearAction,
    label,
    value,
    classes,
    placeholder,
    required,
    disabled,
    name,
    formatCurrency,
    hideLabel,
    inputOnly,
    withoutBorder,
    withoutOuterPadding,
    rightAlign,
    type,
    formatNumber,
    formatPercentage,
    preventNegatives,
    autoComplete,
    numbersOnly,
    integerOnly,
    ...attr
  } = props;

  const [inputValue, setInputValue] = useState<InputValue>(
    isNil(value) ? '' : value,
  );

  const [blurred, setBlurred] = useState<boolean>(false);

  const handleFormatChange = useCallback(
    (currencyValue: string | undefined) => {
      if (preventNegatives && (currencyValue || 0) < 0) {
        currencyValue = '';
      }
      if (formatCurrency) {
        setInputValue(currencyValue);
        onChange({target: {name: name, value: currencyValue}} as any);
      }
    },
    [inputValue],
  );

  const {addDecimalToValue, convertToNumber} = useFormatTextInputToCurrency(
    inputValue,
    100,
    handleFormatChange,
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let eventValue: string | number = event.target.value;

    if (type === 'number' && (formatNumber || formatPercentage)) {
      eventValue = convertToNumber(event.target.value);

      if (preventNegatives && eventValue < 0) {
        eventValue = '';
      } else if (formatPercentage && (eventValue <= 0 || eventValue > 100)) {
        eventValue = '';
      } else {
        const currentValueArray = event.target.value.split('.');
        if (currentValueArray.length > 1) {
          currentValueArray[1] = currentValueArray[1].substring(0, 2);
        }
        const currentValue = currentValueArray.join('.');
        eventValue = convertToNumber(currentValue);
      }
    }

    setInputValue(eventValue);

    if (onChange) {
      onChange({target: {name: name, value: eventValue}});
    }
  };

  const onBlur = () => {
    if (!formatCurrency) return;

    addDecimalToValue();

    if (!isNil(inputValue) && inputValue !== '') {
      onChange({
        target: {name: name, value: convertToNumber(inputValue)},
      } as any);
    }

    setBlurred(true);
  };

  useEffect(() => {
    if (!blurred) {
      setInputValue(value);
    }
  }, [value]);

  const border = withoutBorder
    ? ''
    : !isNil(error)
    ? 'border border-red-500'
    : 'border border-gray-300';
  const background = disabled ? 'bg-gray-200' : 'bg-white';
  const textRight = rightAlign ? 'text-right' : '';

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === '-') {
      if (inputValue) {
        if (inputValue != '' && integerOnly && type === 'number') {
          event.preventDefault();
        }
      }
    }

    if (type === 'number' && event.key === '.') {
      if ((event.target as HTMLInputElement).value.includes('.')) {
        event.preventDefault();
      }
    }

    if (event.key.toLowerCase() === 'e') {
      if (type === 'number') {
        event.preventDefault();
      }
    }

    if (numbersOnly) {
      if (
        event.key.toLowerCase() === 'e' ||
        event.key === '-' ||
        event.key === '.'
      ) {
        event.preventDefault();
      }
    }

    if (type === 'number' && formatNumber && preventNegatives) {
      if (event.key.toLowerCase() === 'e' || event.key === '-') {
        event.preventDefault();
      }
    }
  };

  const renderInputOnly = () => {
    return (
      <input
        className={`${classes} rounded py-1 px-2 m-1 border border-solid border-gray-300 text-center rounded leading-tight focus:outline-none focus:bg-white`}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleOnChange(event);
        }}
        onBlur={onBlur}
        value={inputValue ?? ''}
        disabled={disabled}
        name={name}
        type={type}
        {...attr}
      />
    );
  };

  if (inputOnly) return renderInputOnly();

  return (
    <div className={`w-full ${classes}`}>
      {!hideLabel && (
        <label
          className="flex block tracking-wide text-xs font-bold mb-2"
          htmlFor={`${name}`}
        >
          <span className="mr-1 uppercase">{label}</span>
          {required ? (
            <span className="text-red-500">*</span>
          ) : (
            <span className="text-gray-300">(optional)</span>
          )}
        </label>
      )}
      <input
        className={`appearance-none block w-full ${textRight} ${background} ${border} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleOnChange(event);
        }}
        value={inputValue ?? ''}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={onBlur}
        type={type}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          handleKeyPress(event);
        }}
        {...attr}
        autoComplete={isNil(autoComplete) ? 'on' : autoComplete}
      />
      {!isNil(error) && (
        <p className="mt-3 text-red-500 text-xs italic">{error}</p>
      )}
    </div>
  );
};

export default TextInput;
