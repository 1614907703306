import {isNil} from 'lodash-es';
import React, {useState} from 'react';
import {ImMinus, ImPlus, ImRedo, ImUndo} from 'react-icons/im';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import {CommonModalProps} from '../../../interfaces/modal';
import {datetimeFormatter} from '../../../utils/dateFormater';
import Button from '../buttons/Button';
import PDFPagination from '../paginations/PDFPagination';

export interface OwnProps {
  filePath: string;
  fileName: string;
  fileType: string;
  documentType?: string;
  className?: string;
  submittedBy?: string;
  submittedOn?: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export type Props = OwnProps & CommonModalProps;

const FileViewerModal: React.FC<Props> = ({
  filePath,
  fileName,
  fileType,
  documentType,
  className,
  hideModal,
  submittedBy,
  submittedOn,
  updatedBy,
  updatedAt,
}) => {
  const defaultOrientationValue = 0;
  const maxOrientationValue = 270;
  const defaultScale = 1;
  const orientationValueInterval = 90;
  const scaleValueInterval = 0.1;
  const minimumScaleValue = 0.2;

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(defaultScale);
  const [orientation, setOrientation] = useState<number>(
    defaultOrientationValue,
  );
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const onPdfDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setNumPages(numPages);
    setShowButtons(true);
  };

  const closeModal = () => {
    if (!isNil(hideModal)) hideModal();
  };

  const zoomIn = () => {
    const newScale = scale + scaleValueInterval;
    setScale(newScale);
  };

  const zoomOut = () => {
    const newScale = scale - scaleValueInterval;
    setScale(newScale);
  };

  const rotateRight = () => {
    const newOrientation =
      orientation === maxOrientationValue
        ? defaultOrientationValue
        : orientation + orientationValueInterval;
    setOrientation(newOrientation);
  };

  const rotateLeft = () => {
    const newOrientation =
      orientation === defaultOrientationValue
        ? maxOrientationValue
        : orientation - orientationValueInterval;
    setOrientation(newOrientation);
  };

  const buttonClass =
    'opacity-80 row-span p-0 w-10 h-10 bg-gray-500 rounded-full border-0 shadow-lg hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none';
  const buttonContainerClass =
    'absolute rotate-buttons grid grid-rows-3 grid-flow-col gap-4 right-0 px-16 pt-16';

  const renderButtons = () => {
    if (!showButtons) {
      return null;
    }
    return (
      <div className="pdf-buttons">
        <div className={`${buttonContainerClass} top-0`}>
          <button className={buttonClass} onClick={() => rotateRight()}>
            <ImRedo className="text-white inline" />
          </button>
          <button className={buttonClass} onClick={() => rotateLeft()}>
            <ImUndo className="text-white inline" />
          </button>
        </div>
        <div className={`${buttonContainerClass} bottom-0`}>
          <button className={buttonClass} onClick={() => zoomIn()}>
            <ImPlus className="text-white inline" />
          </button>
          <button
            className={`${buttonClass} disabled:opacity-50 disabled:bg-gray-500`}
            onClick={() => zoomOut()}
            disabled={scale < minimumScaleValue}
          >
            <ImMinus className="text-white inline" />
          </button>
        </div>
      </div>
    );
  };

  const renderPdfContent = () => {
    return (
      <>
        <div className="document-container relative h-[650px] bg-fcDarkGray m-8 p-3">
          <Document
            file={filePath}
            onLoadSuccess={onPdfDocumentLoadSuccess}
            rotate={orientation}
          >
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              scale={scale}
              className={`max-w-full ${className}`}
            />
          </Document>
          {renderButtons()}
        </div>
        {numPages > 1 && (
          <div className="flex mx-auto justify-center">
            <PDFPagination
              page={pageNumber}
              totalPages={numPages}
              onPageClick={(page: number) => setPageNumber(page)}
            />
          </div>
        )}
      </>
    );
  };

  const renderImageContent = () => {
    return (
      <div>
        <img className="object-contain rounded-lg" src={filePath} />
      </div>
    );
  };

  const renderContent = () => {
    if (fileType === 'pdf') {
      return renderPdfContent();
    } else if (
      fileType === 'jpeg' ||
      fileType === 'jpg' ||
      fileType === 'png'
    ) {
      return renderImageContent();
    }

    return null;
  };

  return (
    <>
      <div className="bg-transparent shadow-lg">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-start-1 col-span-9 col-end-10">
            <div className="document-info m-8">
              <div className="text-sm font-normal text-gray-500">
                Document Type
              </div>
              <div className="text-sm font-normal">
                <strong> {documentType} </strong>
              </div>
              <div>
                <span className="text-sm font-normal text-gray-500">
                  Document:
                </span>
                <strong className="text-sm text-black-500"> {fileName} </strong>
              </div>
            </div>
            {renderContent()}
            <div className="m-8">
              <Button
                onClick={() => closeModal()}
                dataTestId="closeModalBtn"
                label="Close"
                type="transparent"
                className="mr-2"
                micro
              />
            </div>
          </div>
          <div className="bg-fcLightGray col-start-10 col-end-13 col-span-3 w-[300px]">
            <div className="document-metadata m-8">
              <div className="text-sm font-normal">
                <strong> Document Information </strong>
              </div>
              <div>
                <span className="text-sm font-normal text-gray-500 pr-1">
                  Submitted by:
                </span>
                <strong className="text-sm text-black-500">
                  {submittedBy}
                </strong>
              </div>
              <div>
                <span className="text-sm font-normal text-gray-500 pr-1">
                  Submitted on:
                </span>
                <strong className="text-sm text-black-500">
                  {datetimeFormatter(submittedOn)}
                </strong>
              </div>
              <div>
                <span className="text-sm font-normal text-gray-500 pr-1">
                  Modified by:
                </span>
                <strong className="text-sm text-black-500">{updatedBy}</strong>
              </div>
              <div>
                <span className="text-sm font-normal text-gray-500 pr-1">
                  Modified on:
                </span>
                <strong className="text-sm text-black-500">
                  {datetimeFormatter(updatedAt)}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewerModal;
