import {isNil} from 'lodash-es';
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';

interface OwnProps {
  classes?: string;
  error?: string;
  clearable?: boolean;
  showError?: boolean;
  label?: string;
  onClearAction?: () => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
  hideLabel?: boolean;
  hideRequiredTag?: boolean;
  dataTestId: string;
  currentStartDate: Date | null;
  currentEndDate: Date | null;
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
  allowResetToDefault?: boolean;
  onDateRangeChange: (
    selStartDate: Date | null,
    selEndDate: Date | null,
  ) => void;
}

export type Props = React.HTMLProps<HTMLInputElement> & OwnProps;

const DateRangePicker: React.FC<Props> = (props) => {
  const {
    error,
    onDateRangeChange,
    label,
    classes,
    required,
    disabled,
    hideLabel,
    hideRequiredTag,
    dataTestId,
    defaultStartDate,
    defaultEndDate,
    currentStartDate,
    currentEndDate,
    allowResetToDefault,
  } = props;

  const [startDate, setStartDate] = useState<Date | null>(currentStartDate);
  const [endDate, setEndDate] = useState<Date | null>(currentEndDate);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const onChange = (
    dates: [Date | null, Date | null],
    event: React.SyntheticEvent<any>,
  ) => {
    const element = event.currentTarget as HTMLInputElement;
    let [selStartDate, selEndDate] = dates;

    if (element.value === '' && allowResetToDefault) {
      if (!isNil(defaultStartDate)) selStartDate = defaultStartDate;
      if (!isNil(defaultEndDate)) selEndDate = defaultEndDate;
    }

    setStartDate(selStartDate);
    setEndDate(selEndDate);

    onDateRangeChange(selStartDate, selEndDate);

    if (dates[1]) setCalendarOpen(false);
  };

  const border = !isNil(error) ? 'border-red-500' : 'border-gray-300';
  const background = disabled ? 'bg-gray-200' : 'bg-white';

  const renderOptionalTag = () => {
    if (required) {
      return <span className="text-red-500">*</span>;
    } else {
      return <span className="text-gray-300">(optional)</span>;
    }
  };

  return (
    <div className={`w-full px-3 ${classes}`} data-testid={dataTestId}>
      {!hideLabel && (
        <label
          className="flex block tracking-wide text-xs font-bold mb-2"
          htmlFor={`${dataTestId}`}
        >
          <span className="mr-1 uppercase">{label}</span>
          {!hideRequiredTag && renderOptionalTag()}
        </label>
      )}

      <DatePicker
        name={dataTestId}
        open={calendarOpen}
        onFocus={() => {
          setCalendarOpen(true);
        }}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        className={`text-center appearance-none block w-full border ${background} ${border} rounded py-3 px-3 mb-3 leading-tight focus:outline-none focus:bg-white`}
        onBlur={() => {
          setCalendarOpen(false);
        }}
        selectsRange
        strictParsing
      />

      {!isNil(error) && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default DateRangePicker;
