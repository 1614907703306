import {format} from 'date-fns';
import {isNil} from 'lodash-es';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';

interface OwnProps {
  name?: string;
  classes?: string;
  error?: string;
  clearable?: boolean;
  showError?: boolean;
  label?: string;
  onClearAction?: () => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
  selected?: Date | null | undefined;
  hideLabel?: boolean;
  maxDate?: Date;
  minDate?: Date;
  hideRequiredTag?: boolean;
  dataTestId: string;
  onChange: (value: string | null) => void;
}

export type Props = React.HTMLProps<HTMLInputElement> & OwnProps;

const YearPicker: React.FC<Props> = (props) => {
  const {
    name,
    error,
    showError,
    onChange,
    onClearAction,
    label,
    value,
    classes,
    placeholder,
    required,
    disabled,
    selected,
    hideLabel,
    maxDate,
    minDate,
    hideRequiredTag,
    dataTestId,
  } = props;

  const [inputValue, setInputValue] = useState<Date | null | undefined>(
    isNil(value) ? undefined : selected,
  );

  const handleChangesInValueProp = (): void => {
    setInputValue(selected);
  };

  useEffect(handleChangesInValueProp, [selected]);

  const handleOnChange = (date: any): void => {
    if (onChange) {
      const dateWithoutTimezone = isNil(date)
        ? null
        : format(new Date(date), 'yyyy-MM-dd');
      onChange(dateWithoutTimezone);
    }
  };

  const border = !isNil(error) ? 'border-red-500' : 'border-gray-300';
  const background = disabled ? 'bg-gray-200' : 'bg-white';
  const renderOptionalTag = () => {
    if (required) {
      return <span className="text-red-500">*</span>;
    } else {
      return <span className="text-gray-300">(optional)</span>;
    }
  };
  return (
    <div className={`w-full ${classes}`}>
      {!hideLabel && (
        <label
          className="flex block tracking-wide text-xs font-bold mb-2"
          htmlFor={`${name}`}
        >
          <span className="mr-1 uppercase">{label}</span>
          {!hideRequiredTag && renderOptionalTag()}
        </label>
      )}
      <DatePicker
        name={name}
        id={name}
        onChange={(date) => handleOnChange(date)}
        className={`appearance-none block w-full border ${background} ${border} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
        selected={!isNil(inputValue) ? new Date(inputValue) : null}
        disabled={disabled}
        placeholderText={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        customInput={<input data-testid={dataTestId} type="text" />}
        showYearPicker
        dateFormat="yyyy"
      />

      {!isNil(error) && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default YearPicker;
