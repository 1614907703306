import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {
  requiredArrayWhenVerified,
  requiredNumberWhenVerified,
  requiredStringField,
  requiredStringWhenRequiresResubmission,
  requiredStringWhenVerified,
} from './common/fields';

const positiveNumberMessage = 'Value must be a positive number';

export const buildValidationSchema = () => {
  const shape = {
    // TODO: Add the rest of the validations on the BE ticket
    informationSource: requiredStringField({attribute: 'Information Source'}),
    documentSource: requiredStringWhenVerified({attribute: 'Document Type'}),
    bankId: requiredStringWhenVerified({attribute: 'Bank ID'}),
    bankAccountId: requiredNumberWhenVerified({
      attribute: 'Bank Account Number',
    }),
    statementStartDate: requiredStringWhenVerified({
      attribute: 'Statement Start Date',
    }),
    statementEndDate: requiredStringWhenVerified({
      attribute: 'Statement End Date',
    }),
    accountName: requiredStringWhenVerified({attribute: 'Account Name'}),
    accountType: requiredStringWhenVerified({attribute: 'Account Type'}),
    currency: requiredStringWhenVerified({attribute: 'Currency'}),
    startBalance: requiredNumberWhenVerified({attribute: 'Starting Balance'}),
    endBalance: requiredNumberWhenVerified({attribute: 'Ending Balance'}),
    lineItems: requiredArrayWhenVerified({
      attribute: 'Bank Statement Line Items',
    }),
    resubmissionReason: requiredStringWhenRequiresResubmission({
      attribute: 'Resubmission Reason',
    }),
    validUntil: requiredStringWhenVerified({attribute: 'Valid Until'}),
    status: requiredStringField({attribute: 'Document Status'}),
  };

  return yup.object().shape(omitBy(shape, isNil));
};
