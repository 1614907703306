/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import numeral from 'numeral';

const DEFAULT_FORMAT = '0,0.00';
const CURRENCY_OPTIONS = {
  PHP: '₱',
  USD: '$',
};

const Formatters = {
  amount: (
    currency?: string | null,
    amount: number | null = 0,
    format: string = DEFAULT_FORMAT,
  ) => {
    return `${CURRENCY_OPTIONS[currency ?? 'PHP']} ${numeral(amount).format(
      format,
    )}`;
  },
  fractionalPart: ({amount = 0, format = DEFAULT_FORMAT}) => {
    return `${numeral(amount).format(format)}`.split('.')[1];
  },
  integerPart: ({amount = 0, format = DEFAULT_FORMAT}) => {
    return `${numeral(amount).format(format)}`.split('.')[0];
  },
  currency: ({amount = 0, format = DEFAULT_FORMAT}) => {
    return `${numeral(amount).format(format)}`;
  },
};

export default Formatters;
