import {format} from 'date-fns';
import {isNil} from 'lodash-es';

import {emptyOption} from '../components/common/inputs/select/options';
import {positiveNumberWithTwoDecimalPlaces} from '../handsontable/customValidators';
import {editableColumnProps} from './editableTable';

export interface ReportItem {
  address: string;
  amountLimit: number | null;
  bankName: string;
  birthDate: Date | null;
  businessAddress: string;
  businessName: string;
  cardBalance: number | null;
  closeDate: Date | null;
  finding: string;
  loanSala: string;
  name: string;
  negDate: Date | null;
  remarks: string;
  reportedDate: Date | null;
  sec: string;
  spouse: string;
  sss: string;
  tin: string;
}

type radioType = 'Yes' | 'No' | null;

export interface NFIS {
  companyId: string;
  createdAt: Date | null;
  entityName: string;
  entityType: string;
  hasNoFindings: radioType;
  leadId: number | null;
  personId: string;
  report: ReportItem[];
  riskopsNotes: string;
  status: string;
  validUntil: Date | null;
}

export const NFISDefault: NFIS = {
  companyId: '',
  createdAt: null,
  entityName: '',
  entityType: '',
  hasNoFindings: null,
  leadId: null,
  personId: '',
  report: [],
  riskopsNotes: '',
  status: '',
  validUntil: null,
};

export const reportItemColumns: editableColumnProps[] = [
  {
    name: 'Finding',
    config: {
      data: 'finding',
    },
  },
  {
    name: 'Name',
    config: {
      data: 'name',
    },
  },
  {
    name: 'Birth Date',
    config: {
      data: 'birthDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Address',
    config: {
      data: 'address',
    },
  },
  {
    name: 'Spouse',
    config: {
      data: 'spouse',
    },
  },
  {
    name: 'TIN',
    config: {
      data: 'tin',
    },
  },
  {
    name: 'SSS',
    config: {
      data: 'sss',
    },
  },
  {
    name: 'Business Name',
    config: {
      data: 'businessName',
    },
  },
  {
    name: 'Business Address',
    config: {
      data: 'businessAddress',
    },
  },
  {
    name: 'SEC',
    config: {
      data: 'sec',
    },
  },
  {
    name: 'Reported Date',
    config: {
      data: 'reportedDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
    },
  },
  {
    name: 'Remarks',
    config: {
      data: 'remarks',
    },
  },
  {
    name: 'Neg Date',
    config: {
      data: 'negDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
    },
  },
  {
    name: 'Close Date',
    config: {
      data: 'closeDate',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
    },
  },
  {
    name: 'Bank Name',
    config: {
      data: 'bankName',
    },
  },
  {
    name: 'Loan Sala',
    config: {
      data: 'loanSala',
    },
  },
  {
    name: 'Amount Limit',
    config: {
      data: 'amountLimit',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Card Balance',
    config: {
      data: 'cardBalance',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
];
