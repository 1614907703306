import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_AUDITED_FINANCIAL_STATEMENT = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      id
      leadId
      status
      ... on AuditedFinancialStatement {
        auditorId
        balanceSheet {
          currentYearAmount
          header
          lineItem
          previousYearAmount
        }
        companyContactInformation {
          emailAddress
          mobileNumber
          telephoneNumber
        }
        contactPersonInformation {
          address
          emailAddress
          mobileNumber
          name
          telephoneNumber
        }
        currency
        documentYear
        hasAccreditedAuditor
        incomeStatement {
          currentYearAmount
          lineItem
          previousYearAmount
        }
        informationSource
        interestRate
        isSecBirAcknowledged
        verificationMethod
        principalOfficeAddress
        resubmissionReason
        revenue
        revenueOn
        companyTin
        statementOfCashFlows {
          currentYearAmount
          lineItem
          previousYearAmount
        }
        validUntil
      }
    }
  }
`;

export function useFetchAuditedFinancialStatement(onSuccessCallback: any) {
  return useLazyQuery(FETCH_AUDITED_FINANCIAL_STATEMENT, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
