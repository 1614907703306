import {isNil} from 'lodash-es';
import React from 'react';
import { Banner, Button } from 'flowbite-react';
import { HiX } from 'react-icons/hi';

interface Props {
  title?: string;
  message?: string;
  tryAgainCallback?: () => void;
}

const SimpleError: React.FC<Props> = ({
  title,
  message,
  tryAgainCallback,
}) => {
  return (
    <Banner>
      <div className="flex w-[calc(100%-2rem)] flex-col justify-between rounded-lg border border-gray-100 bg-white p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700 md:flex-row lg:max-w-7xl">
        <div className="mb-3 mr-4 flex flex-col items-start md:mb-0 md:flex-row md:items-center">
          <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
            <b>{title}</b>
            {!isNil(message) ? (
              <p>{message}</p>
            ) : (
              <p>
                We couldn't load your data right now. <br />
                Would you like us to retry again?
              </p>
            )}
          </p>
        </div>
        <div className="flex flex-shrink-0 items-center">
          <Button onClick={tryAgainCallback}>Retry</Button>
          <Banner.CollapseButton color="gray" className="border-0 bg-transparent text-gray-500 dark:text-gray-400">
            <HiX className="h-4 w-4" />
          </Banner.CollapseButton>
        </div>
      </div>
    </Banner>
  );
};

export default SimpleError;
