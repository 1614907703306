import {isNil} from 'lodash-es';
import React, {useEffect, useState} from 'react';

interface OwnProps {
  classes?: string;
  error?: string;
  clearable?: boolean;
  showError?: boolean;
  hideLabel?: boolean;
  label?: string;
  onClearAction?: () => void;
  className?: string;
  required?: boolean;
  withoutBorder?: boolean;
}

export type Props = OwnProps & React.HTMLProps<HTMLTextAreaElement>;
type InputValue = string | number | readonly string[] | undefined;

const TextAreaInput: React.FC<Props> = (props) => {
  const {
    error,
    showError,
    onChange,
    onClearAction,
    label,
    value,
    classes,
    required,
    hideLabel,
    withoutBorder,
    ...attr
  } = props;

  const [inputValue, setInputValue] = useState<InputValue>(
    isNil(value) ? '' : value,
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const border = withoutBorder
    ? ''
    : !isNil(error)
    ? 'border border-red-500'
    : 'border border-gray-300';

  return (
    <div className={`w-full ${classes}`}>
      {!hideLabel && (
        <label
          className="block tracking-wide text-xs font-bold mb-2"
          htmlFor={`${name}`}
        >
          <span className="mr-1 uppercase">{label}</span>
          {required ? (
            <span className="text-red-500">*</span>
          ) : (
            <span className="text-gray-200">(optional)</span>
          )}
        </label>
      )}
      <textarea
        className={`appearance-none block w-full bg-white ${border} rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
        id={`${name}`}
        onChange={handleOnChange}
        rows={3}
        {...attr}
      />
      {!isNil(error) && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default TextAreaInput;
