import {Document, Packer} from 'docx';
import {saveAs} from 'file-saver';

import {SecretaryCertificateInfo} from '../interfaces/secretaryCertificate';
import generateBoardResolution from './generators/boardResolution';
import generateManagingSecretaryCertificate from './generators/managingPartnerCertificate';
import generateSecretaryCertificate from './generators/secretaryCertificate';

interface Certification {
  title: string;
  document: Document;
}

const buildCertificate = (
  leadType: string,
  certificateInfo: SecretaryCertificateInfo,
): Certification[] => {
  const certificates = {
    partnership: [
      {
        title: "Managing Partner's Certificate",
        document: generateManagingSecretaryCertificate(certificateInfo),
      },
    ],
    corporation: [
      {
        title: 'Board Resolution',
        document: generateBoardResolution(certificateInfo),
      },
      {
        title: 'Secretary Certificate',
        document: generateSecretaryCertificate(certificateInfo),
      },
    ],
  };
  return certificates[leadType ? leadType.toLowerCase() : leadType];
};

const certificateGenerator = (certificateInfo: SecretaryCertificateInfo) => {
  const certificates: Certification[] = buildCertificate(
    certificateInfo.leadType,
    certificateInfo,
  );

  if (certificates) {
    certificates.map((certificate) => {
      const {title, document} = certificate;
      Packer.toBlob(document).then((blob) => {
        saveAs(blob, `${title}.docx`);
      });
    });
  } else {
    return;
  }
};

export default certificateGenerator;
