import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_SECRETARY_CERTIFICATE_INFO = gql`
  query secretaryCertificate($leadId: Int!) {
    secretaryCertificate(leadId: $leadId) {
      leadType
      secretaryName
      secretaryAddress
      secretaryNationality
      officeAddress
      corporationName
      meetingDate
      directorsName
      managingPartnerSecAddress
      directorsAndOfficers {
        name
        numberOfShares
        position
        tin
      }
    }
  }
`;

export function useFetchSecretaryCertificateInfo(onSuccessCallback: any) {
  return useLazyQuery(FETCH_SECRETARY_CERTIFICATE_INFO, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
