import {isNil} from 'lodash-es';

import {emptyOption} from '../components/common/inputs/select/options';
import {positiveNumberWithTwoDecimalPlaces} from '../handsontable/customValidators';
import {SelectOption} from './application';
import {editableColumnProps} from './editableTable';

export interface FutureRevenueLineItem {
  quantity: number | null;
  unit: string;
  itemName: string;
  harmonizedSystemCode: string;
  unitPrice: number | null;
  amount: number | null;
}

type radioType = 'Yes' | 'No' | null;

export interface FutureRevenue {
  buyer: BuyerOption | string | null;
  buyerSignatoryPersonId: number | null;
  currency: string | null;
  status: string;
  deliveryDate: Date | null;
  isSuspectedTampered: radioType;
  informationSource: string | null;
  isFileComplete: radioType;
  isBuyerSigned: radioType;
  isBuyerStamped: radioType;
  isFileClear: radioType;
  issuedOn: Date | null;
  paymentTermsInDays: number | null;
  primaryDocumentIdentifier: string;
  primaryDocumentType: string | null;
  projectIdentifier: string;
  projectTradeAmount: number | null;
  resubmissionReason: string | null;
  isSuspectedForged: radioType;
  supplierCompanyId: number | null;
  supplierSignatoryPersonId: number | null;
  supportingDocumentTypes: string[];
  totalTradeAmount: number | null;
  lineItems: FutureRevenueLineItem[];
  unpaidTradeAmount: number | null;
  validUntil: Date | null;
  verificationMethod: string | null;
  verifiedPaidOn: Date | null;
}

export const futureRevenueDefault: FutureRevenue = {
  buyer: null,
  buyerSignatoryPersonId: null,
  currency: null,
  status: 'SUBMITTED',
  deliveryDate: null,
  isSuspectedTampered: null,
  informationSource: null,
  isFileComplete: null,
  isBuyerSigned: null,
  isBuyerStamped: null,
  isFileClear: null,
  issuedOn: null,
  paymentTermsInDays: null,
  primaryDocumentIdentifier: '',
  primaryDocumentType: null,
  projectIdentifier: '',
  projectTradeAmount: null,
  resubmissionReason: null,
  isSuspectedForged: null,
  supplierCompanyId: null,
  supplierSignatoryPersonId: null,
  supportingDocumentTypes: [],
  totalTradeAmount: null,
  lineItems: [],
  unpaidTradeAmount: null,
  validUntil: null,
  verificationMethod: null,
  verifiedPaidOn: null,
};

export const informationSourceOptions: SelectOption[] = [
  {label: 'Customer', value: 'CUSTOMER'},
  {label: 'Scraped from DA', value: 'SCRAPED_FROM_DA'},
  {label: 'Scraped from DBM', value: 'SCRAPED_FROM_DBM'},
  {label: 'Scraped from DepEd', value: 'SCRAPED_FROM_DEPED'},
  {label: 'Scraped from DOH', value: 'SCRAPED_FROM_DOH'},
  {label: 'Scraped from GPPB', value: 'SCRAPED_FROM_GPPB'},
  {label: 'Scraped from Other Websites', value: 'SCRAPED_FROM_OTHER_WEBSITES'},
  {label: 'Scraped from PAGCOR', value: 'SCRAPED_FROM_PAGCOR'},
  {label: 'Scraped from PhilGEPS', value: 'SCRAPED_FROM_PHILGEPS'},
];

export const supplierCompanyIdOptions: SelectOption[] = [
  emptyOption,
  {label: 'Supplier 1', value: 'supplier1'},
  {label: 'Supplier 2', value: 'supplier2'},
];

export const supplierSignatoryPersonIdOptions: SelectOption[] = [
  emptyOption,
  {label: 'Supplier Signatory 1', value: 'supplier_signatory1'},
  {label: 'Supplier Signatory 2', value: 'supplier_signatory2'},
];

export const buyerCompanyIdOptions: SelectOption[] = [
  emptyOption,
  {label: 'Buyer Company 1', value: 'buyer1'},
  {label: 'Buyer Company 2', value: 'buyer2'},
];

export const buyerSignatoryPersonIdOptions: SelectOption[] = [
  emptyOption,
  {label: 'Buyer Signatory 1', value: 'buyer_signatory1'},
  {label: 'Buyer Signatory 2', value: 'buyer_signatory2'},
];

export const documentTypeOptions: SelectOption[] = [
  emptyOption,
  {label: 'Acknowledgement Receipt', value: 'ACKNOWLEDGEMENT_RECEIPT'},
  {label: 'Billing Statement', value: 'BILLING_STATEMENT'},
  {label: 'Blanket PO', value: 'BLANKET_PO'},
  {label: 'Charge Invoice', value: 'CHARGE_INVOICE'},
  {label: 'Contract', value: 'CONTRACT'},
  {label: 'Counter Receipt', value: 'COUNTER_RECEIPT'},
  {label: 'Delivery Receipt', value: 'DELIVERY_RECEIPT'},
  {label: 'Job Order', value: 'JOB_ORDER'},
  {label: 'Notice of Award', value: 'NOTICE_OF_AWARD'},
  {label: 'Notice to Proceed', value: 'NOTICE_TO_PROCEED'},
  {label: 'Ordinary PO', value: 'ORDINARY_PO'},
  {label: 'Progress Billing', value: 'PROGRESS_BILLING'},
  {label: 'Purchase Order', value: 'PURCHASE_ORDER'},
  {label: 'Purchase Request', value: 'PURCHASE_REQUEST'},
  {label: 'Quotation', value: 'QUOTATION'},
  {label: 'Resolution to Award', value: 'RESOLUTION_TO_AWARD'},
  {label: 'Sales Invoice', value: 'SALES_INVOICE'},
  {label: 'Service Invoice', value: 'SERVICE_INVOICE'},
  {label: 'Statement of Account', value: 'STATEMENT_OF_ACCOUNT'},
  {label: 'Work Order', value: 'WORK_ORDER'},
  {label: 'BAC Resolution', value: 'BAC_RESOLUTION'},
  {label: 'Unknown', value: 'UNKNOWN'},
  {label: 'N/A', value: 'NA'},
];

export const verificationMethodOptions: SelectOption[] = [
  emptyOption,
  {label: 'Portal Viewing', value: 'PORTAL_VIEWING'},
  {label: 'Email', value: 'EMAIL'},
  {label: 'Phone', value: 'PHONE'},
  {label: 'Stamp/Signature', value: 'STAMP_SIGNATURE'},
  {label: 'Automatic', value: 'AUTOMATIC'},
];

export const resubmissionReasonOptions: SelectOption[] = [
  emptyOption,
  {label: 'Blurred', value: 'BLURRED'},
  {label: 'Buyer is a Related Party', value: 'BUYER_IS_A_RELATED_PARTY'},
  {label: 'Buyer is Blacklisted', value: 'BUYER_IS_BLACKLISTED'},
  {
    label: 'Buyer is Not a Registered Business',
    value: 'BUYER_IS_NOT_A_REGISTERED_BUSINESS',
  },
  {label: 'Buyer is Sole Prop', value: 'BUYER_IS_SOLE_PROP'},
  {label: 'Cropped', value: 'CROPPED'},
  {label: 'Incorrect Requirement', value: 'INCORRECT_REQUIREMENT'},
  {label: 'Needs Clarification', value: 'NEEDS_CLARIFICATION'},
  {label: 'Needs Supporting Document', value: 'NEEDS_SUPPORTING_DOCUMENT'},
  {label: 'No Buyer Signature', value: 'NO_BUYER_SIGNATURE'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const buildBuyerSignatoryOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.signatoryPersonIdOptions.length === 0) return [];

  const options = data.signatoryPersonIdOptions;

  return [{label: 'Select', value: ''}, ...options];
};

export const buildSupplierCompanyOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.supplierCompanyIdOptions.length === 0) return [];

  const options = data.supplierCompanyIdOptions;

  return [{label: 'Select', value: ''}, ...options];
};

export const buildSupplierSignatoryOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.signatoryPersonIdOptions.length === 0) return [];

  const options = data.signatoryPersonIdOptions;

  return [{label: 'Select', value: ''}, ...options];
};

export const buildHarmonizedSystemCodeOptions = (data: any) => {
  if (isNil(data)) return [];
  if (data.harmonizedSystemCodeOptions.length === 0) return [];

  return data.harmonizedSystemCodeOptions;
};

export const FutureRevenueLineItemColumns = (
  harmonizedSystemCodeOptions: string[],
) => {
  return [
    {
      name: 'Quantity',
      config: {
        data: 'quantity',
        type: 'numeric',
        numericFormat: {
          pattern: '0',
          culture: 'en-US',
        },
        allowInvalid: false,
        validator: positiveNumberWithTwoDecimalPlaces,
      },
    },
    {
      name: 'Unit',
      config: {
        data: 'unit',
        type: 'dropdown',
        source: [
          'Kilogram',
          'Piece',
          'Pound',
          'Liter',
          'Ounce',
          'Meter',
          'Yard',
        ],
        allowEmpty: true,
        allowInvalid: false,
      },
    },
    {
      name: 'Item Name',
      config: {
        data: 'itemName',
      },
    },
    {
      name: 'Harmonize System Code',
      config: {
        data: 'harmonizedSystemCode',
        type: 'dropdown',
        source: harmonizedSystemCodeOptions,
        allowEmpty: true,
        allowInvalid: false,
      },
    },
    {
      name: 'Unit Price',
      config: {
        data: 'unitPrice',
        type: 'numeric',
        numericFormat: {
          pattern: 'PHP 0,0.00',
          culture: 'en-US',
        },
        allowInvalid: false,
        validator: positiveNumberWithTwoDecimalPlaces,
      },
    },
    {
      name: 'Amount',
      config: {
        type: 'numeric',
        data: 'amount',
        numericFormat: {
          pattern: 'PHP 0,0.00',
          culture: 'en-US',
        },
        allowInvalid: false,
        validator: positiveNumberWithTwoDecimalPlaces,
      },
    },
  ] as editableColumnProps[];
};
