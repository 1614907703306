import DeleteAttachmentConfirmationModal from './DeleteAttachmentConfirmationModal';
import FileViewerModal from './FileViewerModal';
import UploadDocumentModal from './UploadDocumentModal';

export enum ModalComponent {
  FILE_VIEWER_MODAL = 'FILE_VIEWER_MODAL',
  DOCUMENT_UPLOAD_FORM = 'DOCUMENT_UPLOAD_FORM',
  DELETE_ATTACHMENT_CONFIRMATION_MODAL = 'DELETE_ATTACHMENT_CONFIRMATION_MODAL',
}

interface ModalComponents {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MODAL_COMPONENTS: ModalComponents = {
  [ModalComponent.DELETE_ATTACHMENT_CONFIRMATION_MODAL]: DeleteAttachmentConfirmationModal,
  [ModalComponent.FILE_VIEWER_MODAL]: FileViewerModal,
  [ModalComponent.DOCUMENT_UPLOAD_FORM]: UploadDocumentModal,
};

export default MODAL_COMPONENTS;
