import {useParams} from '@reach/router';
import {isNil} from 'lodash-es';
import React, {useContext, useEffect} from 'react';

import {useFetchLeads} from '../../../api/queries/leads';
import ApplicationContext from '../../../contexts/ApplicationContext';
import DocumentsContext from '../../../contexts/DocumentsContext';
import useDocumentsContextUpdater from '../../../hooks/useDocumentsContextUpdater';
import {showNotification} from '../../../utils/toast';
import AppTemplate from '../AppTemplate';
import PageNotFound from '../errors/PageNotFound';
import ResourceLoader from '../loaders/ResourceLoader';
import ApiCallNotice from '../notices/ApiCallNotice';
import DocumentsMainPanel from './documents/DocumentsMainPanel';
import DocumentsSidebar from './documents/DocumentsSidebar';

const DocumentsPage = () => {
  const params = useParams();
  const documentsUpdater = useDocumentsContextUpdater();
  const {
    lead,
    showSelectedDocument,
    permissions,
    setLead,
    setPermissions,
  } = useContext(ApplicationContext);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');
    setLead(response.lead);
  };

  const [
    fetchLeads,
    {loading: fetchingLead, error: fetchLeadError},
  ] = useFetchLeads(onFetchSuccess);

  const retryLeadFetch = () => {
    if (isNil(lead)) fetchLeads({variables: {id: parseInt(params.leadId)}});
  };

  useEffect(() => {
    fetchLeads({
      variables: {
        id: parseInt(params.leadId),
      },
    });
  }, [params.leadId]);

  if (fetchLeadError) {
    <ApiCallNotice
      advance
      type="error"
      errorMessage={fetchLeadError.message}
      tryAgainCallback={retryLeadFetch}
    />;
  }
  if (fetchingLead) return <ResourceLoader resource="Lead" />;
  if (isNil(lead)) return <PageNotFound />;

  const renderDocumentSidebar = () => {
    if (!showSelectedDocument) {
      return (
        <div className="col-span-2">
          <DocumentsSidebar />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderDocuments = () => {
    if (!permissions.viewDocuments) return <div />;
    return (
      <DocumentsContext.Provider value={documentsUpdater}>
        <hr />
        <div
          className="grid grid-cols-1 md:grid-cols-11"
          data-testid="document-page"
        >
          {renderDocumentSidebar()}
          <DocumentsMainPanel />
        </div>
      </DocumentsContext.Provider>
    );
  };

  return (
    <AppTemplate size="md" dataTestId="lead-page">
      {renderDocuments()}
    </AppTemplate>
  );
};

export default DocumentsPage;
