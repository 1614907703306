import React from 'react';

interface Props {
  header?: string;
  message?: string;
  messageClass?: string;
}

const Loader: React.FC<Props> = ({header, message, messageClass}) => {
  return (
    <div className="flex flex-col items-center justify-center p-4 overflow-hidden opacity-75">
      <div className="w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full loader"></div>
      <h2 className="text-xl font-semibold text-center">{header ? header : "Loading..."}</h2>
      <p className={messageClass ? messageClass : "w-1/3 text-center"}>
        {message ? message : "This may take a few seconds, please wait."}
      </p>
    </div>
  )
}

export default Loader;
