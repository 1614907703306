import {editableColumnProps} from './../editableTable';

export interface StatementOfCashFlows {
  lineItem: string;
  currentYearAmount: number | null;
  previousYearAmount: number | null;
}

export const statementOfCashFlowsTableConfig: editableColumnProps[] = [
  {
    name: 'Line Item',
    config: {
      data: 'lineItem',
      editor: false,
      type: 'text',
      readOnly: true,
    },
  },
  {
    name: 'Current Year Amount',
    config: {
      data: 'currentYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
  {
    name: 'Previous Year Amount',
    config: {
      data: 'previousYearAmount',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowInvalid: false,
    },
  },
];

export const defaulsStatementOfCashFlows = [
  {
    lineItem: 'Operating Income Before WC Changes',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Net Cash Used/Provided in Operating Activities',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Net Cash Used/Provided in Investment Activities',
    currentYearAmount: null,
    previousYearAmount: null,
  },
  {
    lineItem: 'Net Cash Used/Provided in Financing Activities',
    currentYearAmount: null,
    previousYearAmount: null,
  },
];
