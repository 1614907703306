import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_COMPANIES = gql`
  query CompanyIdOptions($searchParameter: String, $specifiedCompany: Boolean) {
    companyIdOptions(
      searchParameter: $searchParameter
      specifiedCompany: $specifiedCompany
    ) {
      value
      label
    }
  }
`;

export function useFetchCompanies(onSuccessCallback?: any) {
  return useLazyQuery(FETCH_COMPANIES, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
