import {subMonths} from 'date-fns';
import {useState} from 'react';

import {
  Document,
  DocumentsContext,
  Pagination,
  documentDefault,
  perPage,
  uploadableDocumentType,
} from '../interfaces/documents';

const uploadableDocuments = uploadableDocumentType
  .filter((doc) => doc.value)
  .map((doc) => doc.value);

const useDocumentsContextUpdater = (): DocumentsContext => {
  const defaultStartDate = null;
  const defaultEndDate = null;
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [selectedDocument, setSelectedDocument] = useState<Document>(
    documentDefault,
  );
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentStatus, setDocumentStatus] = useState<string | null>('All');
  const [entityType, setEntityType] = useState<string | null>('All');
  const [documentStatuses, setDocumentStatuses] = useState<string[]>([]);
  const [latestSubmittedDocuments, setLatestSubmittedDocuments] = useState<
    Document[]
  >([]);

  const [activeDocumentGroup, setActiveDocumentGroup] = useState<string>('');
  const [paginationDetails, setPaginationDetails] = useState<Pagination[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    first: perPage,
    before: null,
    last: null,
    after: null,
  });

  const [pageNum, setPageNum] = useState<number>(1);

  return {
    defaultEndDate,
    defaultStartDate,
    activeDocumentGroup,
    documents,
    documentStatus,
    documentStatuses,
    entityType,
    startDate,
    endDate,
    latestSubmittedDocuments,
    pagination,
    paginationDetails,
    pageNum,
    selectedDocument,
    setActiveDocumentGroup,
    setDocuments,
    setDocumentStatus,
    setDocumentStatuses,
    setEntityType,
    setStartDate,
    setEndDate,
    setLatestSubmittedDocuments,
    setPaginationDetails,
    setPagination,
    setPageNum,
    setSelectedDocument,
    uploadableDocuments,
  };
};

export default useDocumentsContextUpdater;
