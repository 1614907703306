import {gql, useMutation} from '@apollo/client';

export const UPDATE_TRADING_RELATIONSHIP = gql`
  mutation tradingRelatinoshipUpdate($input: TradingRelationshipAttribute!) {
    tradingRelationshipUpdate(input: $input) {
      tradingRelationship {
        amountLast12Months
        buyer {
          ... on Buyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Company {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on DimBuyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Lead {
            id
            gid
            name
            buyerAs
          }
        }
        buyerType
        createdAt
        currency
        deletedAt
        hasNda
        id
        informationSource
        productProvided
        relationshipType
        resubmissionReason
        riskopsNotes
        salesNotes
        startDate
        status
        verificationMethod
        validUntil
        timePeriod
        documentFormat
        fcCustomerTin
        fcCustomerName
        fcCustomerAddress
      }
      errors
    }
  }
`;

export function useUpdateTradingRelationship(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_TRADING_RELATIONSHIP, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
