import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_PEOPLE = gql`
  query PersonIdOptions(
    $searchParameter: String
    $specifiedSignatoryPerson: Boolean
  ) {
    personIdOptions(
      searchParameter: $searchParameter
      specifiedSignatoryPerson: $specifiedSignatoryPerson
    ) {
      value
      label
    }
  }
`;

export function useFetchPeople(onSuccessCallback?: any) {
  return useLazyQuery(FETCH_PEOPLE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
