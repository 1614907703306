import {Dialog} from '@headlessui/react';
import {ExclamationIcon} from '@heroicons/react/outline';
import {isNil} from 'lodash-es';
import React from 'react';

import {CommonModalProps} from '../../../interfaces/modal';
import Button from '../buttons/Button';

export interface OwnProps {
  attachmentId: string;
  leadId: string;
  onDeleteSuccessCallback: (attachmentId: string) => void;
}

export type Props = OwnProps & CommonModalProps;

const DeleteAttachmentConfirmationModal: React.FC<Props> = ({
  hideModal,
  onDeleteSuccessCallback,
}) => {
  const onDeleteSuccess = (response: any) => {
    if (!isNil(response) && !isNil(response.attachmentDelete.attachment)) {
      onDeleteSuccessCallback(response.attachmentDelete.attachment.id);
      if (!isNil(hideModal)) hideModal();
    }
  };

  const handleDelete = () => {
    alert('Implement Me!');
  };

  const closeModal = () => {
    if (!isNil(hideModal)) hideModal();
  };

  return (
    <div>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Delete attachment
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                This will permanently remove the attachment. Are you sure you
                want to continue this action?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button
          type="danger"
          dataTestId="deleteAttachmentBtn"
          label="Delete"
          onClick={handleDelete}
        />
        <Button
          type="transparent"
          label="Cancel"
          onClick={closeModal}
          dataTestId="cancelModalBtn"
          className="mr-2"
        />
      </div>
    </div>
  );
};

export default DeleteAttachmentConfirmationModal;
