import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_HARMONIZED_SYSTEM_CODE_OPTIONS = gql`
  query harmonizedSystemCodeOptions {
    harmonizedSystemCodeOptions
  }
`;

export function useFetchHarmonizedSystemCodeOptions(onSuccessCallback: any) {
  return useLazyQuery(FETCH_HARMONIZED_SYSTEM_CODE_OPTIONS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
