import {gql, useMutation} from '@apollo/client';

export const BUYER_UPDATE = gql`
  mutation BuyerUpdate($buyer: BuyerAttribute!) {
    buyerUpdate(input: {attributes: $buyer}) {
      buyer {
        id
        countryCode
        name
        regNum
        regType
        regDate
        regSubType
        registrantFirstName
        registrantMiddleName
        registrantLastName
        createdAt
        updatedAt
        entity {
          id
          secNumber
          companyName
          businessType
          dateRegistered
          industryDescription
          psicCode
          region
          address
        }
      }
      errors {
        field
        kind
        message
      }
    }
  }
`;

export function useUpdateBuyer(onSuccessCallback: (response: any) => void) {
  return useMutation(BUYER_UPDATE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}

export const BUYER_CREATE = gql`
  mutation BuyerCreate($buyer: BuyerAttribute!) {
    buyerCreate(input: {attributes: $buyer}) {
      buyer {
        id
        countryCode
        name
        regNum
        regType
        regDate
        regSubType
        registrantFirstName
        registrantMiddleName
        registrantLastName
        createdAt
        updatedAt
        entity {
          id
          secNumber
          companyName
          businessType
          dateRegistered
          industryDescription
          psicCode
          region
          address
        }
      }
      errors {
        field
        kind
        message
      }
    }
  }
`;

export function useCreateBuyer(onSuccessCallback: (response: any) => void) {
  return useMutation(BUYER_CREATE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}

export const BUYER_DELETE = gql`
  mutation BuyerDelete($id: ID!) {
    buyerDelete(input: {id: $id}) {
      buyer {
        id
      }
      errors {
        field
        kind
        message
      }
    }
  }
`;

export function useDeleteBuyer(onSuccessCallback: (response: any) => void) {
  return useMutation(BUYER_DELETE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
