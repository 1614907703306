import {editableColumnProps} from '../editableTable';

export interface intercompanyAffiliationItem {
  address: string;
  company: string;
  relationship: string;
  secRegistrationNumber: string;
}

export const intercompanyAffiliationItemColumns: editableColumnProps[] = [
  {
    name: 'Company',
    config: {
      data: 'company',
      allowEmpty: true,
    },
  },
  {
    name: 'Relationship',
    config: {
      data: 'relationship',
      type: 'dropdown',
      source: ['', 'Parent Company', 'Subsidiary/Affiliate'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'SEC Registaration Number',
    config: {
      data: 'secRegistrationNumber',
      allowEmpty: true,
    },
  },
  {
    name: 'Address',
    config: {
      data: 'address',
      allowEmpty: true,
    },
  },
];
