import React, {useState} from 'react';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';

export interface Page {
  page: number;
  cursor: string;
}

export interface Props {
  page: number;
  totalPages: number;
  onPageClick: (page: number) => void;
  disabled?: boolean;
}

const PDFPagination: React.FC<Props> = ({onPageClick, page, totalPages}) => {
  const [currentPage, setCurrentPage] = useState<number>(page);

  const handleOnPageClick = (page: number) => {
    if (page === currentPage) return;

    onPageClick(page);
    setCurrentPage(page);
  };

  const renderButtons = () => {
    return (
      <>
        {Array.from(new Array(totalPages), (el, index) => {
          const classes =
            currentPage === index + 1
              ? 'border-primary-500 text-primary-500 bg-fcSecondary'
              : 'border-gray-300 text-gray-500';
          const props = {
            className: `bg-white hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium ${classes}`,
          };

          return (
            <button
              key={`key-${index}`}
              onClick={() => handleOnPageClick(index + 1)}
              {...props}
            >
              {index + 1}
            </button>
          );
        })}
      </>
    );
  };

  const previousDisabled = currentPage === 1;
  const previousClasses = previousDisabled
    ? 'bg-gray-100'
    : 'bg-white hover:bg-gray-50';
  const onPreviousClick = () => {
    const pageNumber = currentPage - 1;
    setCurrentPage(pageNumber);
    handleOnPageClick(pageNumber);
  };

  const renderPreviousButton = () => {
    return (
      <button
        disabled={previousDisabled}
        onClick={onPreviousClick}
        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 ${previousClasses}`}
      >
        <span className="sr-only">Previous</span>
        <FaChevronLeft />
      </button>
    );
  };

  const nextDisabled = currentPage === totalPages;
  const nextClasses = nextDisabled
    ? 'bg-gray-100'
    : 'bg-white hover:bg-gray-50';
  const onNextClick = () => {
    const pageNumber = currentPage + 1;
    setCurrentPage(pageNumber);
    handleOnPageClick(pageNumber);
  };

  const renderNextButton = () => {
    return (
      <button
        disabled={nextDisabled}
        onClick={onNextClick}
        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 ${nextClasses}`}
      >
        <span className="sr-only">Previous</span>
        <FaChevronRight />
      </button>
    );
  };

  const renderLeftText = () => {
    return (
      <div>
        <p className="text-sm text-gray-700">
          Showing
          <span className="font-medium ml-1 mr-1">{currentPage}</span>
          of
          <span className="font-medium ml-1 mr-1">{totalPages}</span>
          results
        </p>
      </div>
    );
  };

  return (
    <div className="bg-white mt-4 py-3 flex items-center justify-between">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {renderPreviousButton()}
            <div className="bg-white hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium border-gray-300 text-gray-500">
              {currentPage} of {totalPages}
            </div>
            {renderNextButton()}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PDFPagination;
