import nationalityList from '../../utils/nationalityList';
import {editableColumnProps} from '../editableTable';

export interface directorsAndOfficerItem {
  address: string;
  board: string;
  execomm: string;
  incorporator: boolean;
  name: string;
  nationality: string;
  officer: string;
  sex: string;
  stockholder: boolean;
  tin: string;
}
export const directorsAndOfficerItemColumns: editableColumnProps[] = [
  {
    name: 'Name',
    config: {
      data: 'name',
      allowEmpty: true,
    },
  },
  {
    name: 'Address',
    config: {
      data: 'address',
      allowEmpty: true,
    },
  },
  {
    name: 'TIN',
    config: {
      data: 'tin',
      allowEmpty: true,
    },
  },
  {
    name: 'Nationality',
    config: {
      data: 'nationality',
      type: 'dropdown',
      source: [''].concat(nationalityList),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Incorporator',
    config: {
      data: 'incorporator',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'Board',
    config: {
      data: 'board',
      type: 'dropdown',
      source: ['', 'Chairman', 'Member', 'Independent Director'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Sex',
    config: {
      data: 'sex',
      type: 'dropdown',
      source: ['Male', 'Female'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Stockholder',
    config: {
      data: 'stockholder',
      type: 'checkbox',
      allowEmpty: true,
    },
  },
  {
    name: 'Officer',
    config: {
      data: 'officer',
      type: 'dropdown',
      source: [
        'President',
        'Treasurer',
        'Secretary',
        'Compliance Officer',
        'Director',
      ],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Execomm',
    config: {
      data: 'execomm',
      type: 'dropdown',
      source: ['', 'Compensation', 'Audit', 'Nomination and Election'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
];
