import React, {useContext} from 'react';

import Badge from '../../../../../components/common/Badge';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import {Document} from '../../../../../interfaces/documents';
import {informationSourceOptions} from '../../../../../interfaces/tradingRelationship';
import {dateFormatter} from '../../../../../utils/dateFormater';
import {setBadgeColor} from '../../../../../utils/docStatusFormatter';
import {titleizeUnderscoredWords} from '../../../../../utils/string';
import {getLabelFromOptions} from '../../../../../utils/value';
import DocumentViewerButton from '../DocumentViewerButton';
import TableData from './TableData';

interface Props {
  fetchingDocuments?: boolean;
}

interface TradingRelationshipDocumentInterface {
  documentFormat: string;
  timePeriod?: string;
  fcCustomerTin?: string;
  fcCustomerAddress?: string;
}

interface TradingRelationshipDocument
  extends Document,
    TradingRelationshipDocumentInterface {}

const TradingRelationshipTable: React.FC<Props> = ({fetchingDocuments}) => {
  const {documents} = useContext(DocumentsContext);

  return (
    <table className="text-center text-gray-500 whitespace-nowrap w-full divide-y divide-gray-100">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="p-4">
            Submitted Date
          </th>
          <th scope="col" className="p-4">
            Document Status
          </th>
          <th scope="col" className="p-4">
            Buyer ID
          </th>
          <th scope="col" className="p-4">
            Document Format
          </th>
          <th scope="col" className="p-4">
            Document Time Period
          </th>
          <th scope="col" className="p-4">
            Customer TIN
          </th>
          <th scope="col" className="p-4">
            Customer Address
          </th>
          <th scope="col" className="p-4">
            Valid Until
          </th>
          <th scope="col" className="p-4">
            Information Source
          </th>
          <th scope="col" className="p-4"></th>
        </tr>
      </thead>

      <tbody className="text-sm bg-white divide-y divide-gray-200">
        {(documents as TradingRelationshipDocument[]).map((document) => {
          return (
            <tr key={document.id} className="even:bg-gray-100">
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.createdAt)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                <Badge
                  type={setBadgeColor(
                    titleizeUnderscoredWords(document.status),
                  )}
                  text={titleizeUnderscoredWords(document.status)}
                ></Badge>
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.buyer?.name}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.documentFormat}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.timePeriod}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.fcCustomerTin}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {document.fcCustomerAddress}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {dateFormatter(document.validUntil)}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                {getLabelFromOptions(
                  informationSourceOptions,
                  document.informationSource,
                )}
              </TableData>
              <TableData className="p-2" loading={fetchingDocuments}>
                <DocumentViewerButton document={document} />
              </TableData>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TradingRelationshipTable;
