import _ from 'lodash';
import {debounce, isEmpty, isNil, isUndefined} from 'lodash-es';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateCmap} from '../../../../../api/mutations/cmap';
import {useFetchCmap} from '../../../../../api/queries/cmap';
import {useFetchCompanies} from '../../../../../api/queries/companies';
import {useFetchPeople} from '../../../../../api/queries/people';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {
  cmapDefault,
  courtCaseFindingItemColumns,
} from '../../../../../interfaces/cmap';
import {
  buildOptions,
  entityTypeOptions,
} from '../../../../../utils/optionsBuilder';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/cmap';
import Button from '../../../buttons/Button';
import DataTableToggle from '../../../inputs/DataTableToggle';
import Datepicker from '../../../inputs/Datepicker';
import EditableTable from '../../../inputs/EditableTable';
import Radio from '../../../inputs/Radio';
import DynamicSelect from '../../../inputs/Select';
import {
  YesOrNoBooleanOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextArea from '../../../inputs/TextArea';
import TextInput from '../../../inputs/TextInput';
import StaticLink from '../../../StaticLink';
import FormLoader from './FormLoader';
import {
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const CmapReportForm: React.FC = () => {
  const [tableToggle, setTableToggle] = useState('');
  const [personFirstUpdate, setPersonFirstUpdate] = useState<boolean>(false);
  const [companyFirstUpdate, setCompanyFirstUpdate] = useState<boolean>(false);
  const [personSearchTerm, setPersonSearchTerm] = useState<string>('');
  const [companySearchTerm, setCompanySearchTerm] = useState<string>('');

  const {selectedDocument} = useContext(DocumentsContext);
  const {file: uploadedFile} = selectedDocument;
  const {lead, permissions} = useContext(ApplicationContext);
  const leadId = Number(lead.id);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {companyId, personId} = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
    });

    if (!isNil(companyId)) {
      const companyValueArray = companyId.split('-');
      companyValueArray.shift();

      const mainCompanyId = companyValueArray.join('-');

      setCompanySearchTerm(mainCompanyId);
      setCompanyFirstUpdate(true);
    }

    if (!isNil(personId)) {
      setPersonSearchTerm(personId);
      setPersonFirstUpdate(true);
    }
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.cmapReportUpdate.cmapReport)) {
        showNotification('success', 'CMAP Report updated!');
        updateValuesAtOnce({
          ...values,
          ...response.cmapReportUpdate.cmapReport,
        });
      }

      if (!isNil(response.cmapReportUpdate.errors.fullMessages)) {
        showNotification('error', 'Failed to update CMAP Report!');
        handleGeneralError(response.cmapReport.errors.fullMessages);
      }
    }
  };

  const [
    fetchCmap,
    {data: CmapData, loading: fetchingCmap, error: fetchCmapErrors},
  ] = useFetchCmap(onFetchSuccess);

  const [
    updateCmap,
    {loading: updatingCmap, error: errorUpdatingCmap},
  ] = useUpdateCmap(updateSuccessCallback);

  useEffect(() => {
    if (selectedDocument.id) {
      fetchCmap({
        variables: {
          documentType: 'CMAP_REPORT',
          id: selectedDocument.id,
          leadId: Number(lead.id),
        },
      });
    }
  }, [fetchCmap]);

  const [
    fetchPersonSearch,
    {
      data: personSearchData,
      loading: fetchingPeople,
      error: fetchPersonSearchErrors,
    },
  ] = useFetchPeople();

  const [
    fetchCompaniesSearch,
    {
      data: companySearchData,
      loading: fetchingCompanies,
      error: fetchCompanySearchErrors,
    },
  ] = useFetchCompanies();

  const previousPersonFirstUpdate = usePrevious(personFirstUpdate);
  const previousCompanyFirstUpdate = usePrevious(companyFirstUpdate);

  useEffect(() => {
    if (!previousPersonFirstUpdate && personFirstUpdate) {
      fetchPersonSearch({
        variables: {
          searchParameter:
            isNil(personSearchTerm) || isEmpty(personSearchTerm)
              ? ''
              : personSearchTerm,
          specifiedSignatoryPerson: true,
        },
      });
    } else {
      if (!isNil(personSearchTerm)) {
        fetchPersonSearch({
          variables: {
            searchParameter: personSearchTerm,
            specifiedSignatoryPerson: false,
          },
        });
      }
    }
  }, [personSearchTerm, personFirstUpdate]);

  useEffect(() => {
    if (!previousCompanyFirstUpdate && companyFirstUpdate) {
      fetchCompaniesSearch({
        variables: {
          searchParameter:
            isNil(companySearchTerm) || isEmpty(companySearchTerm)
              ? ''
              : companySearchTerm,
          specifiedCompany: true,
        },
      });
    } else {
      if (!isNil(companySearchTerm)) {
        fetchCompaniesSearch({
          variables: {
            searchParameter: companySearchTerm,
            specifiedCompany: false,
          },
        });
      }
    }
  }, [companySearchTerm, personFirstUpdate]);

  const personChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setPersonFirstUpdate(false);
    setPersonSearchTerm(value);
  };
  const personDebouncedChangeHandler = useCallback(
    debounce(personChangeHandler, 300),
    [],
  );

  const companyChangeHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target as HTMLInputElement;

    setCompanyFirstUpdate(false);
    setCompanySearchTerm(value);
  };
  const companyDebouncedChangeHandler = useCallback(
    debounce(companyChangeHandler, 300),
    [],
  );

  const submit = () => {
    const {__typename, ...payload} = values;
    const courtCaseFindings = payload.courtCaseFindings.map((finding) => {
      const {__typename, ...newFinding} = finding;
      if (Object.values(newFinding).filter((n) => n).length > 0) {
        if (isEmpty(newFinding.natureOfCase)) delete newFinding.natureOfCase;
        if (isEmpty(newFinding.caseStatus)) delete newFinding.caseStatus;

        return newFinding;
      }
    });
    payload.courtCaseFindings = courtCaseFindings.filter((n) => n);

    if (uploadedFile) payload.attachment = selectedDocument.file;

    updateCmap({
      variables: {
        cmapReport: {
          ...payload,
          leadId: leadId,
          __typename: undefined,
        },
      },
    });
  };

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: cmapDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const {companyId, personId, courtCaseFindings} = values;

  const renderFields = () => {
    if (tableToggle) return renderDataTable();
    return renderInputs();
  };

  const renderInputs = () => {
    if (isUndefined(CmapData) && fetchingCmap) return <FormLoader />;

    if (updatingCmap) return <FormLoader text="Updating CMAP Report..." />;

    return (
      <div id="cmap-report-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="person-id-select"
          defaultValue={personId}
          error={errors.personId}
          isLoading={fetchingPeople}
          label="Person ID"
          name="personId"
          onChange={(value) => {
            handleSpecificChange({
              field: 'personId',
              value: value,
            });
          }}
          onKeyDown={personDebouncedChangeHandler}
          options={buildOptions('personIdOptions', personSearchData)}
          placeholder="Select"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="company-id-select"
          defaultValue={companyId}
          error={errors.companyId}
          isLoading={fetchingCompanies}
          label="Company ID"
          name="companyId"
          onChange={(value) => {
            handleSpecificChange({
              field: 'companyId',
              value: value,
            });
          }}
          onKeyDown={companyDebouncedChangeHandler}
          options={buildOptions('companyIdOptions', companySearchData)}
          placeholder="Select"
        />

        <TextInput
          classes="mb-2"
          data-testid="entityName-field"
          error={errors.entityName}
          label="Subject Name (Natural Person or Company)"
          name="entityName"
          onChange={handleChange}
          placeholder="Add text"
          required={true}
          value={values.entityName}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="entity-type-select"
          defaultValue={values.entityType}
          error={errors.entityType}
          label="Entity Type"
          name="entityType"
          onChange={(value) => {
            handleSpecificChange({
              field: 'entityType',
              value: value,
            });
          }}
          options={entityTypeOptions}
          placeholder="Select"
          required
        />

        <Datepicker
          classes="mb-2"
          dataTestId="report-timestamp-field"
          error={errors.createdAt}
          label="Report Timestamp"
          name="createdAt"
          onChange={(value) => {
            handleSpecificChange({field: 'createdAt', value: value});
          }}
          placeholder="Date"
          required={true}
          selected={values.createdAt as boolean & (Date | null)}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="inquiry-date-field"
          error={errors.inquiryDate}
          label="Inquiry Date"
          name="inquiryDate"
          onChange={(value) => {
            handleSpecificChange({field: 'inquiryDate', value: value});
          }}
          placeholder="Date"
          selected={values.inquiryDate as boolean & (Date | null)}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.hasNoFindings}
          error={errors.hasNoFindings}
          label="Has no findings?"
          name="hasNoFindings"
          onChange={(value) => {
            handleSpecificChange({field: 'hasNoFindings', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required={true}
        />

        <DataTableToggle
          data={courtCaseFindings}
          error={errors.courtCaseFindings}
          setTableToggle={setTableToggle}
          title="Court Case Findings"
          toggle="courtCaseFindings"
        />

        <TextArea
          classes="mb-2"
          data-testid="notes-field"
          defaultValue={values.notes}
          error={errors.notes}
          label="Notes"
          name="notes"
          onChange={handleChange}
          placeholder="Add text"
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          menuPlacement="top"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          selected={values.validUntil as boolean & (Date | null)}
        />
      </div>
    );
  };

  const renderDataTable = () => {
    return (
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        <StaticLink
          path=""
          className="text-primary-500"
          icon="FaArrowLeft"
          dataTestId="back-to-document-information"
          text="Back to Document Information"
          onClickAction={() => setTableToggle('')}
        />
        <EditableTable
          tableLabel="Court Case Findings"
          headerProps={courtCaseFindingItemColumns}
          dataProps={values.courtCaseFindings}
          minSpareRows={1}
        />
      </div>
    );
  };
  return (
    <div className="px-3 py-2">
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        {renderFields()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingCmap
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default CmapReportForm;
