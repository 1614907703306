import {
  AlignmentType,
  Document,
  Footer,
  Header,
  LevelFormat,
  PageNumber,
  PageOrientation,
  Paragraph,
  Tab,
  TabStopPosition,
  TabStopType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalAlign,
  WidthType,
  convertInchesToTwip,
} from 'docx';
import {isEmpty} from 'lodash-es';

import {SecretaryCertificateInfo} from '../../interfaces/secretaryCertificate';

const yellowHighlight = 'FFFD02';

const buildManagingSecretaryCertificate = (children: (Paragraph | Table)[]) => {
  return new Document({
    title: "Managing Partner's Certificate",
    numbering: {
      config: [
        {
          reference: 'default-numbered-list',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.START,
              style: {
                run: {
                  font: 'Tahoma',
                  size: 20,
                },
                paragraph: {
                  alignment: AlignmentType.JUSTIFIED,
                  indent: {
                    left: convertInchesToTwip(0.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
        {
          reference: 'superscript-numbered-list',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              alignment: AlignmentType.START,
              style: {
                run: {
                  font: 'Tahoma',
                  size: 20,
                  superScript: true,
                },
                paragraph: {
                  alignment: AlignmentType.JUSTIFIED,
                  indent: {left: 0, hanging: convertInchesToTwip(0.18)},
                },
              },
            },
          ],
        },
        {
          reference: 'alphabetical-list',
          levels: [
            {
              level: 0,
              format: LevelFormat.LOWER_LETTER,
              text: '%1)',
              alignment: AlignmentType.START,
              style: {
                run: {
                  font: 'Tahoma',
                  size: 20,
                },
                paragraph: {
                  alignment: AlignmentType.JUSTIFIED,
                  indent: {
                    left: convertInchesToTwip(1.25),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
      ],
    },
    styles: {
      paragraphStyles: [
        {
          id: 'default',
          name: 'Default',
          run: {
            font: 'Tahoma',
            size: 20,
          },
        },
        {
          id: 'resolves',
          name: 'Resolves',
          run: {
            font: 'Tahoma',
            size: 20,
          },
          paragraph: {
            indent: {
              firstLine: convertInchesToTwip(0.5),
              left: convertInchesToTwip(0.25),
            },
          },
        },
        {
          id: 'header-and-footer',
          name: 'Header and Footer',
          run: {
            font: 'Tahoma',
            size: 16,
            color: '000000',
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            size: {
              orientation: PageOrientation.PORTRAIT,
              height: convertInchesToTwip(11),
              width: convertInchesToTwip(8.5),
            },
            margin: {
              top: convertInchesToTwip(1),
              bottom: convertInchesToTwip(1),
              right: convertInchesToTwip(1),
              left: convertInchesToTwip(1),
            },
          },
          titlePage: true,
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                style: 'header-and-footer',
                children: [
                  new TextRun({
                    text: 'FOR PARTNERSHIPS',
                  }),
                ],
              }),
            ],
          }),
          first: new Header({
            children: [
              new Paragraph({
                style: 'header-and-footer',
                children: [
                  new TextRun({
                    text: 'FOR PARTNERSHIPS',
                  }),
                ],
              }),
            ],
          }),
        },
        children: children,
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                style: 'header-and-footer',
                children: [
                  new TextRun({
                    children: [
                      'Managing Partner’s Certificate – Authority to Transact with First Circle \t',
                      new Tab(),
                      '\t Page ',
                      PageNumber.CURRENT,
                      ' of ',
                      PageNumber.TOTAL_PAGES,
                    ],
                  }),
                ],
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
              }),
            ],
          }),
          first: new Footer({
            children: [
              new Paragraph({
                style: 'header-and-footer',
                children: [
                  new TextRun({
                    text:
                      '___________________________________________________________________',
                  }),
                  new TextRun({
                    break: 2,
                  }),
                  new TextRun({
                    superScript: true,
                    text: '1',
                  }),
                  new TextRun(
                    ' Please attach 1 valid, government-issued ID of the Authorized Representative.',
                  ),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 200,
                },
                style: 'header-and-footer',
                children: [
                  new TextRun({
                    text:
                      'Managing Partner’s Certificate – Authority to Transact with First Circle \t',
                  }),
                  new TextRun({
                    children: [
                      new Tab(),
                      '\t Page ',
                      PageNumber.CURRENT,
                      ' of ',
                      PageNumber.TOTAL_PAGES,
                    ],
                  }),
                ],
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
              }),
            ],
          }),
        },
      },
    ],
  });
};

const generateManagingSecretaryCertificate = (
  response: SecretaryCertificateInfo,
) => {
  const managingSecretaryName =
    isEmpty(response) ||
    response.directorsName === '' ||
    response.directorsName === 'Unknown'
      ? '<name>'
      : response.directorsName;
  const authorizedPersonName = isEmpty(response)
    ? "<director's name>"
    : response.directorsName;
  const managingSecretaryAddress = isEmpty(response)
    ? '<residential address of Managing Secretary>'
    : response.managingPartnerSecAddress;
  const corporationName = isEmpty(response)
    ? '<name of Partnership>'
    : response.corporationName;
  const officeAddress = isEmpty(response)
    ? '<office address>'
    : response.officeAddress;

  const addressHeading = new Paragraph({
    style: 'default',
    children: [
      new TextRun('REPUBLIC OF THE PHILIPPINES'),
      new TextRun({
        text: 'CITY OF ______________ ) S.S.',
        break: 1,
      }),
    ],
  });

  const documentHeading = new Paragraph({
    alignment: AlignmentType.CENTER,
    spacing: {
      before: 300,
      after: 400,
    },
    children: [
      new TextRun({
        style: 'default',
        text: "MANAGING PARTNER'S CERTIFICATE",
        bold: true,
        underline: {type: UnderlineType.SINGLE},
        size: 22,
        font: 'Tahoma',
      }),
    ],
  });

  const infoParagraph = new Paragraph({
    style: 'default',
    indent: {
      firstLine: convertInchesToTwip(0.5),
    },
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text: 'I, ',
      }),
      new TextRun({
        text: managingSecretaryName,
        shading: {
          color:
            managingSecretaryName === '<name>' ? yellowHighlight : 'FFFFFF',
          fill: managingSecretaryName === '<name>' ? yellowHighlight : 'FFFFFF',
        },
      }),
      new TextRun(', '),
      new TextRun({
        text: '<citizenship>',
        shading: {
          color: yellowHighlight,
          fill: yellowHighlight,
        },
      }),
      new TextRun(' citizen, of legal age, and with residential address at '),
      new TextRun({
        text: managingSecretaryAddress,
        shading: {
          color:
            managingSecretaryAddress ===
            '<residential address of Managing Partner>'
              ? yellowHighlight
              : 'FFFFFF',
          fill:
            managingSecretaryAddress ===
            '<residential address of Managing Partner>'
              ? yellowHighlight
              : 'FFFFFF',
        },
      }),
      new TextRun(
        ', after having been duly sworn in accordance with law, do hereby certify that:',
      ),
    ],
  });

  const artifact1 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 300,
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'default-numbered-list',
    },
    children: [
      new TextRun('I am the Managing Partner of '),
      new TextRun({
        text: corporationName,
        shading: {
          color:
            corporationName == '<name of Partnership>'
              ? yellowHighlight
              : 'FFFFFF',
          fill:
            corporationName == '<name of Partnership>'
              ? yellowHighlight
              : 'FFFFFF',
        },
      }),
      new TextRun(
        ' (the “Partnership”), a business entity organized and existing under the laws of the Republic of the Philippines, with principal office address at ',
      ),
      new TextRun({
        text: officeAddress,
        shading: {
          color:
            officeAddress == '<office address>' ? yellowHighlight : 'FFFFFF',
          fill:
            officeAddress == '<office address>' ? yellowHighlight : 'FFFFFF',
        },
      }),
      new TextRun('.'),
    ],
  });

  const artifact2 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 300,
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'default-numbered-list',
    },
    text:
      'As Managing Partner, I have custody of and access to the corporate records of the Partnership.',
  });

  const artifact3 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 300,
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'default-numbered-list',
    },
    children: [
      new TextRun('At a meeting of the Partners of the Partnership on '),
      new TextRun({
        text: '<date>',
        shading: {
          color: yellowHighlight,
          fill: yellowHighlight,
        },
      }),
      new TextRun(
        ' , at least a majority of the Partners of the Partnership approved the following resolutions:',
      ),
    ],
  });

  const resolved1 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, that the Partnership be authorized, as it is hereby authorized, to apply for, open, and manage an account with First Circle Growth Finance Corp. and its parent entity, Carabao Capital Philippines Pte Ltd, its affiliates, service providers, partners, and subsidiaries (collectively, “First Circle”);',
      }),
    ],
  });

  const resolved2 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, that the Partnership be authorized, as it is hereby authorized, to transact with and obtain products and services (including but not limited to the opening and maintenance of credit and business accounts, bank accounts of whatever kind and nature, as well as other products and services in relation thereto) from First Circle;',
      }),
    ],
  });

  const resolved3 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, that the Partnership be authorized, as it is hereby authorized, to submit, disclose, and deliver to First Circle any and all documents, papers, information (including personal   information   of   its   partners,   officers,   agents,   representatives,   and   employees,   as necessary), and corporate records and transactions of the Partnership, in compliance with applicable Philippine laws and regulations and First Circle’s policies;',
      }),
    ],
  });

  const resolved4 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun(
        'RESOLVED, that the Partnership hereby certifies that all information and documents submitted by it to First Circle, including information on the identity/ies of its Partner/s, are true and correct as of the date of their submission to First Circle;',
      ),
    ],
  });

  const resolved5 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun('RESOLVED, that '),
      new TextRun({
        text:
          authorizedPersonName === "<director's name>"
            ? '<managing partner’s name>'
            : authorizedPersonName,
        shading: {
          color:
            authorizedPersonName === "<director's name>"
              ? yellowHighlight
              : 'FFFFFF',
          fill:
            authorizedPersonName === "<director's name>"
              ? yellowHighlight
              : 'FFFFFF',
        },
      }),
      new TextRun(
        " be appointed as the Partnership's authorized representative",
      ),
      new TextRun({
        superScript: true,
        text: '1',
      }),
      new TextRun(
        ' in its transactions with First Circle, with full authority to:',
      ),
    ],
  });

  const authority1 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text:
      'make, receive, fill out, sign, execute, deliver, and submit all forms, papers, agreements, contracts, checks, and documents to First Circle, and negotiate and endorse checks payable to the Partnership in favor of First Circle,',
  });

  const authority2 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text:
      'review, negotiate, agree to, and accept the terms and conditions of products and services offered by First Circle and bind the Partnership under such terms,',
  });

  const blankLine = new Paragraph({
    style: 'default',
    spacing: {
      before: 100,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text: '____________________',
      }),
    ],
  });

  const validIdNote = new Paragraph({
    style: 'header-and-footer',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'superscript-numbered-list',
    },
    children: [
      new TextRun({
        text:
          ' Please attach 1 valid, government-issued ID of the Authorized Representative, who must be one of the signing directors.',
      }),
    ],
  });

  const authority3 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text:
      'encumber, pledge, or mortgage the Partnership’s properties, regardless of amount, kind or nature, as security for the Partnership’s obligations to First Circle, and sign, execute, and deliver all such documents and papers in relation thereto,',
  });

  const authority4 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text:
      'make, approve, or authorize any payment, or delegate authorized persons who will exercise such powers, to satisfy the Partnership’s obligations to First Circle,',
  });

  const authority5 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text: 'instruct and communicate with First Circle and its agents,',
  });

  const authority6 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text:
      'delegate and authorize any person, agent, or representative, with full powers of delegation, to perform duties and responsibilities assigned to it by the Partnership, and who shall assume liability for the performance of its obligations pursuant to such powers and authorization, in connection with any of the Partnership’s transactions with First Circle,',
  });

  const authority7 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'alphabetical-list',
    },
    text: 'perform any other act necessary or incidental to the above.',
  });

  const signatureTable = new Table({
    columnWidths: [convertInchesToTwip(3.5), convertInchesToTwip(3.5)],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: {
              size: convertInchesToTwip(3.25),
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                style: 'default',
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text:
                      authorizedPersonName === "<director's name>"
                        ? '<NAME OF MANAGING PARTNER APPOINTED AS AUTHORIZED REPRESENTATIVE>'
                        : authorizedPersonName,
                    shading: {
                      color:
                        authorizedPersonName === "<director's name>"
                          ? yellowHighlight
                          : 'FFFFFF',
                      fill:
                        authorizedPersonName === "<director's name>"
                          ? yellowHighlight
                          : 'FFFFFF',
                    },
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: {
              size: convertInchesToTwip(3.25),
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                style: 'default',
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "<AUTHORIZED REPRESENTATIVE'S SIGNATURE>",
                    shading: {
                      color: yellowHighlight,
                      fill: yellowHighlight,
                    },
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: {
              size: convertInchesToTwip(3.25),
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                style: 'default',
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "<AUTHORIZED REPRESENTATIVE'S EMAIL ADDRESS>",
                    shading: {
                      color: yellowHighlight,
                      fill: yellowHighlight,
                    },
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: {
              size: convertInchesToTwip(3.25),
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                style: 'default',
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "<AUTHORIZED REPRESENTATIVE'S MOBILE PHONE NUMBER>",
                    shading: {
                      color: yellowHighlight,
                      fill: yellowHighlight,
                    },
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

  const resolved6 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 500,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, that all information disclosed, documents signed and submitted, and statements made prior to the date of this document by any person acting for and on behalf of the Partnership in its transactions with First Circle, are hereby accepted and ratified by the Partnership. The Partnership acknowledges that the actions of these person/s may have resulted in valid, binding, and enforceable obligations to First Circle, and that the Partnership will comply with said obligations according to the terms thereof.',
      }),
    ],
  });

  const resolved7 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, FURTHER, that all the foregoing authorities shall continue to be in full force and effect until the same have been superseded, modified and/or revoked by a resolution to that effect, as subsequently adopted by the Partners of the Partnership, duly attested in a notarized certificate, issued by its duly-appointed Managing Partner and conveyed in a written notice actually received by First Circle, provided that such notice shall not be effective with respect to any exercise of said authorities prior to the receipt thereof, nor with respect to any checks or other instrument for the payment of money or the withdrawal of funds dated to the date of such notice, even those of services or products availed of prior to the receipt of such notice, even if presented to First Circle prior to the receipt of such notice; and that First Circle is hereby authorized at all times to rely upon the latest notice, certificate or communication received by it when so authenticated by the Managing Partner of this Partnership.',
      }),
    ],
  });

  const resolved8 = new Paragraph({
    style: 'resolves',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'RESOLVED, FINALLY, that the above resolutions are the latest resolutions adopted by the Partners and are valid and binding, and have not been revoked, amended or repealed. These resolutions shall be deemed to have superseded, modified and/or revoked all prior resolutions adopted by the Partners of the Partnership inconsistent thereto and all such prior inconsistent resolutions shall have no further force and effect, unless otherwise indicated herein.',
      }),
    ],
  });

  const artifact4 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 300,
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'default-numbered-list',
    },
    text:
      'The above-quoted resolutions have not been amended, modified or rescinded, and are in full force and effect as of the date hereof.',
  });

  const artifact5 = new Paragraph({
    style: 'default',
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 300,
      after: 300,
    },
    numbering: {
      level: 0,
      reference: 'default-numbered-list',
    },
    text:
      'The foregoing statements are true and correct in accordance with the records of the Partnership in my possession.',
  });

  const paragraphConclusion = new Paragraph({
    style: 'default',
    indent: {
      firstLine: convertInchesToTwip(0.5),
    },
    spacing: {
      before: 300,
      after: 500,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'This certification is being issued to attest to the truth of the foregoing statement and for whatever purpose it may serve.',
      }),
    ],
  });

  const dateAndLocation = new Paragraph({
    style: 'default',
    indent: {
      firstLine: convertInchesToTwip(0.5),
    },
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'IN WITNESS WHEREOF, I have hereunto set my hand this ______________ in ___________ City, Philippines.',
      }),
    ],
  });

  const managingNameParagraph = new Paragraph({
    style: 'default',
    spacing: {
      before: 300,
    },
    tabStops: [
      {
        type: TabStopType.RIGHT,
        position: 5500,
      },
    ],
    alignment: AlignmentType.RIGHT,
    children: [
      new TextRun({
        text:
          authorizedPersonName == '<name>'
            ? '\t<full name>'
            : `\t${authorizedPersonName}`,
        bold: true,
        shading: {
          color:
            authorizedPersonName == "<director's name>"
              ? yellowHighlight
              : 'FFFFFF',
          fill:
            authorizedPersonName == "<director's name>"
              ? yellowHighlight
              : 'FFFFFF',
        },
      }),
    ],
  });

  const position = new Paragraph({
    style: 'default',
    spacing: {
      after: 300,
    },
    alignment: AlignmentType.RIGHT,
    tabStops: [
      {
        type: TabStopType.RIGHT,
        position: 5500,
      },
    ],
    children: [
      new TextRun({
        text: '\tManaging Partner',
      }),
    ],
  });

  const oath = new Paragraph({
    style: 'default',
    indent: {
      firstLine: convertInchesToTwip(0.5),
    },
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text:
          'SUBSCRIBED AND SWORN TO before me this ___________________ in __________ City, affiant exhibiting to me government-issued ID ____________________ valid until ____________________.',
      }),
    ],
  });

  const docNo = new Paragraph({
    style: 'default',
    alignment: AlignmentType.LEFT,
    children: [
      new TextRun({
        text: 'Doc. No. ___;',
      }),
    ],
  });

  const pageNo = new Paragraph({
    style: 'default',
    alignment: AlignmentType.LEFT,
    children: [
      new TextRun({
        text: 'Page No. ___;',
      }),
    ],
  });

  const bookNo = new Paragraph({
    style: 'default',
    alignment: AlignmentType.LEFT,
    children: [
      new TextRun({
        text: 'Book No. ___;',
      }),
    ],
  });

  const series = new Paragraph({
    style: 'default',
    alignment: AlignmentType.LEFT,
    children: [
      new TextRun({
        text: 'Series of ___.',
      }),
    ],
  });

  const idAttachmentNote = new Paragraph({
    style: 'default',
    spacing: {
      before: 300,
      after: 300,
    },
    alignment: AlignmentType.LEFT,
    children: [
      new TextRun({
        text: 'COPY OF MANAGING PARTNER’S ID ATTACHED (BACK AND FRONT):',
        shading: {
          color: yellowHighlight,
          fill: yellowHighlight,
        },
      }),
    ],
  });

  const children: (Paragraph | Table)[] = [
    addressHeading,
    documentHeading,
    infoParagraph,
    artifact1,
    artifact2,
    artifact3,
    resolved1,
    resolved2,
    resolved3,
    resolved4,
    resolved5,
    authority1,
    authority2,
    authority3,
    authority4,
    authority5,
    authority6,
    authority7,
    signatureTable,
    resolved6,
    resolved7,
    resolved8,
    artifact4,
    artifact5,
    paragraphConclusion,
    dateAndLocation,
    managingNameParagraph,
    position,
    oath,
    docNo,
    pageNo,
    bookNo,
    series,
    idAttachmentNote,
  ];

  return buildManagingSecretaryCertificate(children);
};
export default generateManagingSecretaryCertificate;
