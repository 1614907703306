import format from 'date-fns/format';
import {isEmpty, isNil, isUndefined} from 'lodash-es';

export const datetimeFormatter = (date: Date | undefined | null) => {
  if (isNil(date)) return '---';

  return format(new Date(date), 'MM-dd-yy hh:mm a');
};

export const dateFormatter = (
  date: Date | undefined | null,
  displayFormat?: string | null,
) => {
  if (isNil(date)) return '---';

  return format(new Date(date), displayFormat || 'yyyy-MM-dd');
};

export const ISODateFormatter = (date: Date | undefined | null) => {
  if (isNil(date)) return '';

  return format(date, 'yyyy-MM-dd');
};

export const convertToDate = (dateString: string | undefined | null) => {
  if (isNil(dateString) || isUndefined(dateString) || isEmpty(dateString))
    return null;

  const date = Date.parse(dateString);

  return isNaN(date) ? null : new Date(date);
};
