import React from 'react';

import {ModalComponent} from '../components/common/modals/ModalComponents';
import {ApplicationContext, SelectOption} from '../interfaces/application';
import Lead from '../interfaces/leads';
import {ModalProps} from '../interfaces/modal';
import {Permissions, User} from '../interfaces/user';

export const defaultLead: Lead = {
  id: 0,
  companyName: '',
  hasGis: false,
  rclEndedOn: null,
  seccertCompanyType: '',
};

export const defaultPermissions: Permissions = {
  viewBvpApp: false,
  viewDocuments: false,
  downloadSecretaryCertificate: false,
  role: '',
};

export const defaultCurrentUser: User = {
  id: 0,
  email: '',
  name: '',
  first_name: '',
  last_name: '',
  role: '',
  permissions: defaultPermissions,
};

export const defaultContextValue: ApplicationContext = {
  lead: defaultLead,
  permissions: defaultPermissions,
  currentUser: defaultCurrentUser,
  setLead: (lead: Lead) => null,
  setPermissions: (permissions: Permissions) => null,
  setCurrentUser: (user: User) => null,
  fetchingUserAccessData: true,
  setFetchingUserAccessData: (value: boolean) => null,

  modalType: undefined,
  modalProps: undefined,
  showSelectedDocument: false,
  setModalProps: (props: ModalProps) => props,
  setModalType: (type: ModalComponent | undefined) => type,
  setShowSelectedDocument: (showSelectedDocument: boolean) => null,
};

const ApplicationContext = React.createContext<ApplicationContext>(
  defaultContextValue,
);

export default ApplicationContext;
