import {datadogRum} from '@datadog/browser-rum';
import {isNil} from 'lodash-es';
import {useContext, useEffect, useState} from 'react';

import {useFetchCurrentUser} from '../api/queries/currentUser';
import ApplicationContext from '../contexts/ApplicationContext';
import {
  User,
} from '../interfaces/user';

const useFetchUserData = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorFetchingData, setErrorFetchingData] = useState<boolean>(false);
  const [needsToAuthenticate, setNeedsToAuthenticate] = useState(false);

  const {
    currentUser,
    setCurrentUser,
    permissions,
    setPermissions,
    setFetchingUserAccessData,
  } = useContext(ApplicationContext);

  const onFetchCurrentUserSuccess = (response: any) => {
    if (!isNil(response) && !isNil(response.currentUser)) {
      const currentUser: User = response.currentUser;
      setCurrentUser(currentUser);
      setPermissions({...currentUser.permissions, role: currentUser.role}); // TODO: Remove role from permissions
    }
  };

  const refetch = () => {
    setLoading(true);
    fetchCurrentUser();
  };

  const hasEncounteredError = () => {
    return !isNil(errorFetchingCurrentUser);
  };

  const errorMessage = () => {
    if (!isNil(errorFetchingCurrentUser)) {
      return errorFetchingCurrentUser.message;
    }

    return 'Failed to fetch data';
  };

  const [
    fetchCurrentUser,
    {loading: fetchingCurrentUser, error: errorFetchingCurrentUser},
  ] = useFetchCurrentUser(onFetchCurrentUserSuccess);

  // Life cycle

  useEffect(() => {
    if (
      !fetchingCurrentUser &&
      !isNil(currentUser)
    ) {
      setFetchingUserAccessData(false);
      setLoading(false);
    }
  }, [currentUser, fetchingCurrentUser]);

  useEffect(() => {
    if (
      !fetchingCurrentUser &&
      isNil(errorFetchingCurrentUser)
    ) {
      setErrorFetchingData(false);
    }
  }, [fetchingCurrentUser]);

  useEffect(() => {
    if (!fetchingCurrentUser && !isNil(currentUser)) {
      // Track authenticated user on DataDog RUM
      datadogRum.setUser({
        id: currentUser.id?.toString() || undefined,
        name: currentUser.name || undefined,
        email: currentUser.email || undefined,
        role: currentUser.role || undefined,
      });
    } else if (isNil(currentUser)) {
      datadogRum.removeUser();
      setNeedsToAuthenticate(true);
    }
  }, [currentUser]);

  // This is a solution to kind of delay our initial api calls.
  // Apparently, chaining these calls after we fetch our javascript
  // code causes our FCP and LCP to be under the recommended metrics.
  // We can come back to this as we move forward.
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return {
    currentUser,
    permissions,
    errorFetchingData,
    errorFetchingCurrentUser,
    refetch,
    needsToAuthenticate,
    setNeedsToAuthenticate,
    errorMessage: errorMessage(),
    fetchingUserData: fetchingCurrentUser || loading,
    hasEncounteredError: hasEncounteredError(),
  };
};

export default useFetchUserData;
