import {buildBuyerOptions} from '../../../../../utils/buyer';
import {
  debounce,
  isNil,
  isNull,
  isUndefined,
  lowerCase,
  merge,
  omit,
  startCase,
} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateTradingRelationship} from '../../../../../api/mutations/tradingRelationship';
import {useFetchBuyerOptions} from '../../../../../api/queries/buyers';
import {useFetchTradingRelationship} from '../../../../../api/queries/tradingRelationship';
import {documentStatusOptions} from '../../../../../components/common/inputs/select/options';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {
  buyerTypeOptions,
  currencyOptions,
  documentFormatOptions,
  hasNdaOptions,
  informationSourceOptions,
  productProvidedOptions,
  relationshipTypeOptions,
  resubmissionReasonOptions,
  tradingRelationshipDefault,
  verificationMethodOptions,
} from '../../../../../interfaces/tradingRelationship';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/TradingRelationship';
import Button from '../../../buttons/Button';
import Datepicker from '../../../inputs/Datepicker';
import Radio from '../../../inputs/Radio';
import DynamicSelect from '../../../inputs/Select';
import TextArea from '../../../inputs/TextArea';
import TextInput from '../../../inputs/TextInput';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';
import Loader from '@src/components/common/loaders/Loader';

interface Props {
  setTradingRelationship: (value: any) => void;
}

const TradingRelationshipForm: React.FC<Props> = ({
  setTradingRelationship,
}) => {
  const {lead, permissions} = useContext(ApplicationContext);
  const [firstUpdate, setFirstUpdate] = useState<boolean>(false);
  const [buyerSearchTerm, setBuyerSearchTerm] = useState<
    string | null | undefined
  >(null);
  const {selectedDocument, setSelectedDocument} = useContext(DocumentsContext);
  const previousFirstUpdate = usePrevious(firstUpdate);
  const [buyerOptions, setBuyerOptions] = useState<BuyerOption[]>([]);

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');

    const {buyer} = response.document;

    updateValuesAtOnce({
      ...values,
      ...response.document,
      buyerGlobalID: buyer?.gid,
    });

    // Set buyerId for Buyer Source selected
    // field value on initial loading
    if (!isNull(buyer)) {
      setBuyerSearchTerm(buyer.regNum || buyer.name || '');
      setFirstUpdate(true);
    }
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.tradingRelationshipUpdate.tradingRelationship)) {
        const {tradingRelationship} = response.tradingRelationshipUpdate;
        const {buyer} = tradingRelationship;

        showNotification('success', 'Trading Relationship updated!');

        updateValuesAtOnce({
          ...values,
          ...tradingRelationship,
          buyerGlobalID: buyer?.gid,
        });

        if (selectedDocument.file) {
          const {file, ...updatedDocument} = selectedDocument;
          setSelectedDocument(updatedDocument);
          setTradingRelationship(tradingRelationship);
        }
      } else {
        showNotification('error', 'Failed to update Trading Relationship!');
        handleGeneralError(response.tradingRelationshipUpdate.errors);
      }
    }
  };

  const [
    fetchTradingRelationship,
    {
      loading: fetchingTradingRelationship,
      error: fetchTradingRelationshipErrors,
      data: tradingRelationshipData,
    },
  ] = useFetchTradingRelationship(onFetchSuccess);

  const [
    updateTradingRelationship,
    {loading: updatingTradingRelationship, error: errorUpdatingRelationship},
  ] = useUpdateTradingRelationship(updateSuccessCallback);

  useEffect(() => {
    if (isNull(selectedDocument.id)) return;

    fetchTradingRelationship({
      variables: {
        documentType: 'TRADING_RELATIONSHIP',
        id: selectedDocument.id,
        leadId: Number(lead.id),
      },
    });
  }, [fetchTradingRelationship]);

  const [
    fetchBuyerOptions,
    {
      data: buyerOptionsData,
      loading: fetchingBuyerOptions,
      error: fetchBuyerOptionsErrors,
    },
  ] = useFetchBuyerOptions();

  useEffect(() => {
    const buyer = tradingRelationshipData?.document?.buyer;
    const options: BuyerOption[] = buyerOptionsData?.buyerOptions || [];

    if (!isUndefined(buyer) && !isNull(buyer)) {
      options.push(tradingRelationshipData.document.buyer);
    }

    setBuyerOptions(options);
  }, [buyerOptionsData, tradingRelationshipData]);

  const changeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    setFirstUpdate(false);
    setBuyerSearchTerm(value);
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  useEffect(() => {
    if (!previousFirstUpdate && firstUpdate) {
      fetchBuyerOptions({
        variables: {
          search: buyerSearchTerm,
          buyerAs: ['BUYER', 'DIMBUYER'] // Todo: Remove DIMBUYER once moved to BUYER
        },
      });
    } else {
      if (!isNil(buyerSearchTerm)) {
        fetchBuyerOptions({
          variables: {
            search: buyerSearchTerm,
            buyerAs: ['BUYER', 'DIMBUYER']
          },
        });
      }
    }
  }, [buyerSearchTerm, firstUpdate]);

  const submit = () => {
    const omitFields = [
      'createdAt',
      'updatedAt',
      'buyer',
      'buyerGlobalID',
      'productProvided',
      'amountLast12Months',
    ];
    const {__typename, ...payload} = merge({}, values);

    const {productProvided, amountLast12Months} = payload;

    const tradingRelationship = {
      ...omit(payload, omitFields),
      amountLast12Months: numeral(amountLast12Months).value(),
      attachment: selectedDocument.file,
      buyer: values.buyerGlobalID,
      leadId: Number(lead.id),
      productProvided: startCase(lowerCase(productProvided)),
    };

    updateTradingRelationship({
      variables: {input: tradingRelationship},
    });
  };

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: tradingRelationshipDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const renderForm = () => {
    if (isUndefined(tradingRelationshipData) && fetchingTradingRelationship)
      return <FormLoader />;

    if (updatingTradingRelationship)
      return <FormLoader text="Updating Trading Relationship" />;

    if (values.status === "SCRAPING")
      return <Loader 
        header='Scraping...'
        message='Please refresh the page or come back after a short while.'
        messageClass='text-center mt-2'
      />;

    return (
      <div id="tradingrelationship-base-form">
        <DynamicSelect
          name="documentFormat"
          dataTestId="document-format-select"
          classes="mb-2"
          options={documentFormatOptions}
          label="Document Format"
          defaultValue={values.documentFormat}
          error={errors.documentFormat}
          placeholder={
            getLabelFromOptions(documentFormatOptions, values.documentFormat) ||
            'Select'
          }
          onChange={(value) => {
            handleSpecificChange({field: 'documentFormat', value: value});
          }}
          required
        />

        <TextInput
          classes="mb-2"
          name="timePeriod"
          label="Time Period"
          value={values.timePeriod}
          error={errors.timePeriod}
          placeholder="---"
          data-testid="trading-relationship-time-period-field"
          onChange={handleChange}
        />

        <TextInput
          classes="mb-2"
          data-testid="customer-tin-field"
          error={errors.fcCustomerTin}
          label="Customer TIN"
          name="fcCustomerTin"
          placeholder="---"
          value={values.fcCustomerTin}
          onChange={handleChange}
        />

        <TextInput
          classes="mb-2"
          data-testid="customer-name-field"
          error={errors.fcCustomerName}
          label="Customer Name"
          name="fcCustomerName"
          placeholder="---"
          value={values.fcCustomerName}
          onChange={handleChange}
        />

        <TextInput
          classes="mb-2"
          data-testid="customer-address-field"
          error={errors.fcCustomerAddress}
          label="Customer Address"
          name="fcCustomerAddress"
          placeholder="---"
          value={values.fcCustomerAddress}
          onChange={handleChange}
        />

        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
        />

        <DynamicSelect
          name="buyerGlobalID"
          dataTestId="buyer-select"
          classes="mb-2"
          placeholder="Select"
          options={buildBuyerOptions(buyerOptions)}
          label="Buyer ID"
          defaultValue={values.buyerGlobalID}
          isLoading={fetchingBuyerOptions}
          error={errors.buyerGlobalID}
          onChange={(value) => {
            handleSpecificChange({field: 'buyerGlobalID', value: value});
          }}
          onKeyDown={debouncedChangeHandler}
        />

        <DynamicSelect
          name="buyerType"
          dataTestId="buyer-type-select"
          classes="mb-2"
          placeholder="Select"
          options={buyerTypeOptions}
          label="Buyer Type"
          defaultValue={values.buyerType}
          error={errors.buyerType}
          onChange={(value) => {
            handleSpecificChange({field: 'buyerType', value: value});
          }}
        />

        <Radio
          name="hasNda"
          label="Has NDA?"
          options={hasNdaOptions}
          clearable={true}
          defaultValue={values.hasNda}
          classes="mb-2 w-3/4"
          onChange={(value) => {
            handleSpecificChange({field: 'hasNda', value: value});
          }}
          error={errors.hasNda}
        />

        <Datepicker
          classes="mb-2"
          name="startDate"
          label="Relationship Start Date"
          onChange={(value) => {
            handleSpecificChange({field: 'startDate', value: value});
          }}
          selected={values.startDate as boolean & (Date | null)}
          placeholder="Date"
          error={errors.startDate}
          dataTestId="start-date-field"
        />

        <DynamicSelect
          classes="mb-2"
          name="relationshipType"
          dataTestId="relationship-type-select"
          placeholder="Select"
          options={relationshipTypeOptions}
          label="Relationship Type"
          defaultValue={values.relationshipType}
          error={errors.relationshipType}
          onChange={(value) => {
            handleSpecificChange({field: 'relationshipType', value: value});
          }}
        />

        <DynamicSelect
          name="productProvided"
          dataTestId="product-provided-select"
          classes="mb-2"
          placeholder="Select"
          options={productProvidedOptions}
          label="Product Provided"
          defaultValue={values.productProvided}
          error={errors.productProvided}
          onChange={(value) => {
            handleSpecificChange({field: 'productProvided', value: value});
          }}
        />

        <TextInput
          name="amountLast12Months"
          label="Amount in the last 12 months"
          classes="mb-2"
          value={values.amountLast12Months}
          placeholder="Add amount"
          data-testid="amount-last-12-months-field"
          error={errors.amountLast12Months}
          onChange={handleChange}
          type="number"
          preventNegatives={true}
          formatNumber
        />

        <DynamicSelect
          name="currency"
          dataTestId="currency-select"
          classes="mb-2"
          placeholder="Select"
          options={currencyOptions}
          label="Currency"
          error={errors.currency}
          defaultValue={values.currency}
          onChange={(value) => {
            handleSpecificChange({field: 'currency', value: value});
          }}
        />

        <DynamicSelect
          name="verification_method"
          dataTestId="verification-method-select"
          classes="mb-2"
          placeholder="Select"
          options={verificationMethodOptions}
          label="Verification Method"
          defaultValue={values.verificationMethod}
          error={errors.verificationMethod}
          onChange={(value) => {
            handleSpecificChange({field: 'verificationMethod', value: value});
          }}
        />

        <TextArea
          classes="mb-2"
          name="salesNotes"
          label="Sales Notes"
          defaultValue={values.salesNotes}
          placeholder="Add text"
          data-testid="sales-notes-field"
          error={errors.salesNotes}
          onChange={handleChange}
        />

        <TextArea
          classes="mb-2"
          name="riskopsNotes"
          label="RiskOps Notes"
          defaultValue={values.riskopsNotes}
          error={errors.riskopsNotes}
          placeholder="Add text"
          data-testid="risk-ops-notes-field"
          onChange={handleChange}
        />

        <DynamicSelect
          name="documentStatus"
          dataTestId="status-select"
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          classes="mb-2"
          options={limitedDocumentStatusOptions(permissions.role)}
          required={true}
          label="Document Status"
          defaultValue={values.status}
          error={errors.status}
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
        />

        <DynamicSelect
          name="resubmission_reason"
          dataTestId="resubmission-reason-select"
          classes="mb-2"
          placeholder="Select"
          options={resubmissionReasonOptions}
          label="Resubmission Reason"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
        />

        <Datepicker
          classes="mb-2"
          name="validUntil"
          label="Valid Until"
          placeholder="Date"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          selected={values.validUntil as boolean & (Date | null)}
          error={errors.validUntil}
          dataTestId="valid-until-field"
        />
      </div>
    );
  };

  return (
    <div className="px-3 py-2">
      <div
        id="form-container"
        className="h-[630px] overflow-hidden overflow-y-scroll"
      >
        {renderForm()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingTradingRelationship
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default TradingRelationshipForm;
