import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

export const buildValidationSchema = () => {
  const shape = {
    countryCode: yup.string().trim().uppercase().required().label('Country'),
    name: yup.string().trim().required().label('Name'),
    regNum: yup
      .string()
      .trim()
      .uppercase()
      .required()
      .label('Registration No.'),
    regType: yup
      .string()
      .trim()
      .uppercase()
      .required()
      .oneOf([
        'PRIVATE',
        'GOVERNMENT',
        'MULTINATIONAL',
        'PUBLIC',
        'INTERNATIONAL',
      ])
      .label('Registration Type'),
    regDate: yup
      .date()
      .when(['regType', 'regSubType'], {
        is: (regType: string, regSubType: string) => {
          return regType === 'PRIVATE' && regSubType !== 'COOPERATIVE';
        },
        then: (schema: yup.DateSchema) => {
          return schema
            .default(new Date())
            .required()
            .nullable(false)
            .min(new Date(1400, 1, 1))
            .max(new Date());
        },
        otherwise: (schema: yup.DateSchema) => {
          return schema
            .default(null)
            .optional()
            .nullable(true)
            .min(new Date(1400, 1, 1))
            .max(new Date());
        },
      })
      .label('Registration Date'),
    regSubType: yup
      .string()
      .when('regType', (regType: string, schema: yup.StringSchema) => {
        if (regType === 'PRIVATE') {
          return schema
            .default('CORPORATION')
            .nullable(false)
            .required()
            .oneOf(['CORPORATION', 'PARTNERSHIP', 'SOLE_PROP', 'COOPERATIVE']);
        } else {
          return schema.default(null).optional().nullable(true);
        }
      })
      .label('Registration Sub Type'),
    registrantFirstName: yup
      .string()
      .when(['regType', 'regSubType'], {
        is: (regType: string, regSubType: string) => {
          return regType === 'PRIVATE' && regSubType === 'SOLE_PROP';
        },
        then: (schema: yup.StringSchema) => {
          return schema.nullable(false).required();
        },
        otherwise: (schema: yup.StringSchema) => {
          return schema.default(null).optional().nullable(true);
        },
      })
      .trim()
      .label('Registrant First Name'),
    registrantMiddleName: yup
      .string()
      .when(['regType', 'regSubType'], {
        is: (regType: string, regSubType: string) => {
          return regType === 'PRIVATE' && regSubType === 'SOLE_PROP';
        },
        then: (schema: yup.StringSchema) => {
          return schema.nullable(false).required();
        },
        otherwise: (schema: yup.StringSchema) => {
          return schema.default(null).optional().nullable(true);
        },
      })
      .trim()
      .label('Registrant Middle Name'),
    registrantLastName: yup
      .string()
      .when(['regType', 'regSubType'], {
        is: (regType: string, regSubType: string) => {
          return regType === 'PRIVATE' && regSubType === 'SOLE_PROP';
        },
        then: (schema: yup.StringSchema) => {
          return schema.nullable(false).required();
        },
        otherwise: (schema: yup.StringSchema) => {
          return schema.default(null).optional().nullable(true);
        },
      })
      .trim()
      .label('Registrant Last Name'),
  };

  return yup.object().shape(omitBy(shape, isNil));
};
