import React from 'react';

import Root from './components/Root';
import ErrorBoundary from './components/common/errors/ErrorBoundary';

const App = (): React.ReactElement => {
  return (
    <div>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </div>
  );
};

export default App;
