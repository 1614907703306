import React, {useState} from 'react';
import {ImDownload, ImMinus, ImPlus, ImRedo, ImUndo} from 'react-icons/im';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import CsvTable from './CsvTable';
import PDFPagination from './paginations/PDFPagination';

export interface FileViewerProps {
  filePath: string | undefined;
  fileType: string | undefined;
}

const FileViewer: React.FC<FileViewerProps> = ({filePath, fileType}) => {
  const defaultOrientationValue = 0;
  const maxOrientationValue = 270;
  const defaultScale = 1;
  const orientationValueInterval = 90;
  const scaleValueInterval = 0.1;
  const minimumScaleValue = 0.2;

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(defaultScale);
  const [orientation, setOrientation] = useState<number>(
    defaultOrientationValue,
  );
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const onPdfDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setNumPages(numPages);
    setShowButtons(true);
  };

  const zoomIn = () => {
    const newScale = scale + scaleValueInterval;
    setScale(newScale);
  };

  const zoomOut = () => {
    const newScale = scale - scaleValueInterval;
    setScale(newScale);
  };

  const rotateRight = () => {
    const newOrientation =
      orientation === maxOrientationValue
        ? defaultOrientationValue
        : orientation + orientationValueInterval;
    setOrientation(newOrientation);
  };

  const rotateLeft = () => {
    const newOrientation =
      orientation === defaultOrientationValue
        ? maxOrientationValue
        : orientation - orientationValueInterval;
    setOrientation(newOrientation);
  };
  const buttonClass =
    'opacity-80 row-span p-0 w-10 h-10 bg-gray-500 rounded-full border-0 shadow-lg hover:bg-gray-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none';
  const buttonContainerClass =
    'absolute rotate-buttons grid grid-rows-3 grid-flow-col gap-4 right-0 px-16 pt-16';

  const renderButtons = () => {
    if (!showButtons) {
      return null;
    }
    return (
      <div className="pdf-buttons">
        <div className={`${buttonContainerClass} top-0`}>
          <a href={filePath} target="_blank" rel="noopener noreferrer" download>
            <button className={buttonClass}>
              <ImDownload className="text-white inline" />
            </button>
          </a>
          <button className={buttonClass} onClick={() => rotateRight()}>
            <ImRedo className="text-white inline" />
          </button>
          <button className={buttonClass} onClick={() => rotateLeft()}>
            <ImUndo className="text-white inline" />
          </button>
        </div>
        <div className={`${buttonContainerClass} bottom-0`}>
          <button className={buttonClass} onClick={() => zoomIn()}>
            <ImPlus className="text-white inline" />
          </button>
          <button
            className={`${buttonClass} disabled:opacity-50 disabled:bg-gray-500`}
            onClick={() => zoomOut()}
            disabled={scale < minimumScaleValue}
          >
            <ImMinus className="text-white inline" />
          </button>
        </div>
      </div>
    );
  };

  const renderFileDownload = (downloadFileType: string) => {
    return (
      <div className="pt-4">
        <a
          className="text-primary-500 cursor-pointer pr-5 py-3 flex items-center text-sm underline leading-snug hover:opacity-75"
          href={filePath}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Download {downloadFileType === 'csv' ? 'csv' : 'image'}
          <button>
            <ImDownload className="ml-1.5" />
          </button>
        </a>
      </div>
    );
  };

  const renderCSVContent = () => {
    return (
      <>
        {renderFileDownload('csv')}
        <CsvTable filePath={filePath || ''} />
      </>
    );
  };

  const renderPdfContent = () => {
    return (
      <>
        <div className="document-container relative bg-fcDarkGray m-8 p-3 flex mx-auto justify-center text-blue-50">
          <Document
            file={filePath}
            onLoadSuccess={onPdfDocumentLoadSuccess}
            rotate={orientation}
          >
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              scale={scale}
              className="max-w-full"
            />
          </Document>
          {renderButtons()}
        </div>
        {numPages > 1 && (
          <div className="flex mx-auto justify-center">
            <PDFPagination
              page={pageNumber}
              totalPages={numPages}
              onPageClick={(page: number) => setPageNumber(page)}
            />
          </div>
        )}
      </>
    );
  };

  const renderImageContent = () => {
    return (
      <>
        {renderFileDownload('image')}
        <img className="object-contain rounded-lg" src={filePath} />
      </>
    );
  };

  const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

  const renderPdfViewer = () => {
    if (fileType === 'application/pdf') {
      return renderPdfContent();
    } else if (fileType === 'text/csv') {
      return renderCSVContent();
    } else if (imageTypes.includes(fileType || '')) {
      return renderImageContent();
    }
    return null;
  };

  return renderPdfViewer();
};

export default FileViewer;
