import {format} from 'date-fns';

import {editableColumnProps} from '../editableTable';

export interface secondaryLicenseItem {
  dateIssued: Date | null;
  dateStartedOperations: Date | null;
  governmentAgency: string;
  typeOfLicense: string;
}

export const secondaryLicenseItemColumns: editableColumnProps[] = [
  {
    name: 'Government Agency',
    config: {
      data: 'governmentAgency',
      type: 'dropdown',
      source: ['', 'SEC', 'BSP', 'IC'],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Type of License',
    config: {
      data: 'typeOfLicense',
      allowEmpty: true,
    },
  },
  {
    name: 'Date Issued',
    config: {
      data: 'dateIssued',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Date Started Operations',
    config: {
      data: 'dateStartedOperations',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
];
