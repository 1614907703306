import {gql, useMutation} from '@apollo/client';

export const UPDATE_TRADING_RELATIONSHIP_LINE_ITEMS = gql`
  mutation tradingRelationshipLineItemsUpdate(
    $input: TradingRelationshipsLineItemsAttribute!
  ) {
    tradingRelationshipLineItemsUpdate(input: $input) {
      tradingRelationshipId
      errors
    }
  }
`;

export function useUpdateTradingRelationshipLineItems(
  onSuccessCallback: (response: any) => void,
) {
  return useMutation(UPDATE_TRADING_RELATIONSHIP_LINE_ITEMS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
