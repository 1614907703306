import {camelCase, isEmpty, isNil, isUndefined, kebabCase} from 'lodash-es';
import React, {useContext, useEffect, useState} from 'react';

import {useUpdateDynamicDocument} from '../../../../../api/mutations/dynamicDocument';
import {useFetchDynamicForm} from '../../../../../api/queries/dynamicForm';
import {useFetchTemplate} from '../../../../../api/queries/template';
import {documentStatusOptions} from '../../../../../components/common/inputs/select/options';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {documentTypeEnum} from '../../../../../interfaces/documents';
import {nationalities} from '../../../../../utils/nationalityList';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/DynamicDocument';
import EditableTable from '../../../../common/inputs/EditableTable';
import StaticLink from '../../../../common/StaticLink';
import Button from '../../../buttons/Button';
import Datepicker from '../../../inputs/Datepicker';
import Radio from '../../../inputs/Radio';
import DynamicSelect, {Value} from '../../../inputs/Select';
import TextInput from '../../../inputs/TextInput';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const DynamicDocumentForm: React.FC = () => {
  const {lead, permissions} = useContext(ApplicationContext);
  const {selectedDocument} = useContext(DocumentsContext);
  const {file: uploadedFile} = selectedDocument;

  const [dynamicFields, setDynamicFields] = useState<Record<string, any>>({});
  const [documentTemplate, setDocumentTemplate] = useState<string>('');
  const [activeTable, setActiveTable] = useState<string>('');

  const dynamicDocumentLabel = () => {
    return documentTypeEnum[selectedDocument.documentType];
  };

  const updateSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(response.dynamicDocumentUpdate.dynamicDocument)) {
        showNotification('success', `${dynamicDocumentLabel()} updated!`);
      } else {
        showNotification(
          'error',
          `Failed to update ${dynamicDocumentLabel()}!`,
        );
        handleGeneralError(response.dynamicDocumentUpdate.errors.fullMessages);
      }
    } else {
      showNotification('error', 'Something went wrong. Please try again');
    }
  };

  const [
    updateDynamicDocument,
    {loading: updatingDynamicDocument, error: errorUpdatingDynamicDocument},
  ] = useUpdateDynamicDocument(updateSuccessCallback);

  function submit(): void {
    const {id: documentId} = selectedDocument;
    const {resubmissionReason, status, validUntil} = values;

    const dynamicData = {};

    Object.keys(dynamicFields).map((key) => {
      dynamicData[key] = values[key];
    });

    const payload: Record<string, any> = {
      documentType: selectedDocument.documentType,
      id: documentId,
      leadId: lead.id,
      data: dynamicData,
      resubmissionReason,
      status,
      validUntil,
    };

    if (uploadedFile) payload.attachment = selectedDocument.file;

    updateDynamicDocument({
      variables: {
        input: payload,
      },
    });
  }

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: {
      resubmissionReason: '',
      status: 'SUBMITTED',
      noExpiration: true,
    },
    dynamicFields: Object.keys(dynamicFields),
    submitAction: submit,
    validationSchema: buildValidationSchema(
      selectedDocument.documentType,
      dynamicFields,
    ),
  });
  const onTemplateFetchSuccess = (response: any) => {
    const templateData = response.template;
    const templateFields =
      templateData[selectedDocument.documentType || ''].fields;

    setDocumentTemplate(templateData);
    setDynamicFields(templateFields);
  };

  const onValuesFetchSuccess = (response: any) => {
    if (response.length === 0) {
      return showNotification('error', 'Fetch failed.');
    }

    const form_data = {
      ...response.document,
      ...response.document.data,
    };

    updateValuesAtOnce({
      ...values,
      ...form_data,
    });
  };

  const defaultDropdownValue = (field: any, currentValue: string) => {
    if (field.label === 'Information Source') {
      return defaultInformationSource(permissions.role, currentValue);
    }
    return currentValue;
  };
  const datepickerClasses = (field: any) => {
    if (
      !['DTI_COR'].includes(selectedDocument.documentType) &&
      field.label === 'Valid Until' &&
      values.noExpiration === true
    ) {
      return 'mb-2 hidden';
    }

    return 'mb-2';
  };

  const textInputClasses = (field: any) => {
    if (
      selectedDocument.documentType === 'PERSONAL_ID' &&
      field.label === 'Personal TIN' &&
      values.idType != 'Tax Identification Number'
    ) {
      return 'mb-2 hidden';
    }

    if (
      selectedDocument.documentType === 'PERSONAL_ID' &&
      field.label === 'License No.' &&
      values.idType != "Driver's License"
    ) {
      return 'mb-2 hidden';
    }
    return 'mb-2';
  };

  const requiredField = (field: any) => {
    // If No expiration field is unchecked, requires on status verified
    if (
      selectedDocument.documentType != 'PROOF_OF_BILLING' &&
      field.label === 'Valid Until' &&
      (values.noExpiration === null || values.noExpiration === false)
    ) {
      return values.status === 'VERIFIED';
    }

    // Require field if `requiredOnIdType` is chosen `idType`
    if (
      !isNil(field.requiredOnIdType) &&
      field.requiredOnIdType === values.idType
    ) {
      return values.status === 'VERIFIED';
    }

    if (field.requiredOnStatus === 'VERIFIED') {
      return values.status === 'VERIFIED';
    }

    if (field.requiredOnStatus === 'REQUIRES_RESUBMISSION') {
      return values.status === 'REQUIRES_RESUBMISSION';
    }

    if (field.required) {
      return true;
    }
    return false;
  };

  const [
    fetchDynamicForm,
    {
      data: dynamicDocumentData,
      loading: fetchingDynamicDocumentData,
      error: fetchDynamicDocumentErrors,
    },
  ] = useFetchDynamicForm(onValuesFetchSuccess);

  const [
    fetchTemplate,
    {
      data: templateData,
      loading: fetchingTemplate,
      error: fetchingTemplateErrors,
    },
  ] = useFetchTemplate(onTemplateFetchSuccess);

  useEffect(() => {
    const {documentType} = selectedDocument;
    fetchTemplate({
      variables: {documentType: documentType},
    });

    if (selectedDocument.id) {
      fetchDynamicForm({
        variables: {
          documentType: documentType,
          id: selectedDocument.id,
          leadId: Number(lead.id),
        },
      });
    }
  }, [selectedDocument]);

  const renderInput = (config, key) => {
    const {label} = config;

    return (
      <TextInput
        key={key}
        name={key}
        label={label}
        required={requiredField(config)}
        classes={textInputClasses(config)}
        value={values[key]}
        data-testid={`${key}-field`}
        error={errors[key]}
        onChange={handleChange}
      />
    );
  };

  const renderNumberInput = (config, key) => {
    const {label} = config;

    return (
      <TextInput
        type="number"
        key={key}
        name={key}
        label={label}
        required={requiredField(config)}
        classes={textInputClasses(config)}
        value={values[key]}
        data-testid={`${kebabCase(key)}-field`}
        error={errors[key]}
        numbersOnly
        onChange={handleChange}
      />
    );
  };

  const renderDate = (config, key) => {
    const {label} = config;
    const dataValue = values[key] === '' ? null : values[key];
    return (
      <Datepicker
        key={key}
        name={key}
        classes={datepickerClasses(config)}
        required={requiredField(config)}
        label={label}
        value={dataValue}
        onChange={(value) => {
          handleSpecificChange({field: key, value: value});
        }}
        selected={dataValue as boolean & (Date | null)}
        placeholder="Date"
        error={errors[key]}
        dataTestId={`${kebabCase(key)}-field`}
        autoComplete="off"
      />
    );
  };

  const renderSelect = (config, key) => {
    const {label, required, options} = config;

    return (
      <DynamicSelect
        key={key}
        name={key}
        label={label}
        classes="mb-2"
        placeholder="Select"
        menuPlacement={label === 'Resubmission Reason' ? 'top' : 'bottom'}
        required={requiredField(config)}
        defaultValue={defaultDropdownValue(config, values[key])}
        onChange={(value) => {
          handleSpecificChange({field: key, value: value});
        }}
        options={options}
        error={errors[key]}
        dataTestId={`${kebabCase(key)}-select`}
      />
    );
  };

  const renderRadio = (config, key) => {
    const {label, required, options} = config;

    return (
      <Radio
        key={key}
        name={key}
        label={label}
        required={requiredField(config)}
        options={options}
        defaultValue={values[key]}
        classes="mb-2"
        onChange={(value) => {
          handleSpecificChange({field: key, value: value});
        }}
        error={errors[key]}
      />
    );
  };

  const buildTableProps = (data: any) => {
    if (isNil(data)) return [];

    const datum = convertToCamelCase(data);
    findNationalityFields(datum);

    return datum;
  };

  const convertToCamelCase = (data) => {
    return data.map((field) => {
      return {
        ...field,
        config: {
          ...field.config,
          data: camelCase(field.config.data),
        },
      };
    });
  };

  const findNationalityFields = (data) => {
    const nationalityFields = data.filter(
      (d) => d.config.source === 'nationality',
    );
    nationalityFields.map((field) => {
      field.config.source = nationalities;
      return field;
    });
    return data;
  };

  const renderTable = () => {
    const {label, fields} = dynamicFields[activeTable];
    values[activeTable] = values[activeTable] || [];

    return (
      <div className="h-[630px] overflow-hidden overflow-y-scroll">
        <StaticLink
          path=""
          className="text-primary-500"
          icon="FaArrowLeft"
          dataTestId="back-to-document-information"
          text={`Back to ${label}`}
          onClickAction={() => setActiveTable('')}
        />
        <EditableTable
          tableLabel={label}
          headerProps={buildTableProps(fields)}
          dataProps={values[activeTable]}
          minSpareRows={1}
        />
      </div>
    );
  };

  const renderTableToggle = (config, key) => {
    return (
      <div className="w-full px-3 py-2">
        <label className="flex tracking-wide text-xs font-bold mb-2">
          {config.label}
        </label>
        <Button
          dataTestId="submit"
          label={`${isEmpty(values[key]) ? 'Add' : 'Edit'} Table`}
          type="primary"
          className="rounded"
          onClick={() => setActiveTable(key)}
        />
      </div>
    );
  };

  const renderField = (fieldConfig, key) => {
    const {type} = fieldConfig;
    switch (type) {
      case 'input':
        return renderInput(fieldConfig, key);
      case 'number':
        return renderNumberInput(fieldConfig, key);
      case 'select':
        return renderSelect(fieldConfig, key);
      case 'boolean':
        return renderRadio(fieldConfig, key);
      case 'date':
        return renderDate(fieldConfig, key);
      case 'table':
        return renderTableToggle(fieldConfig, key);
      default:
        return;
    }
  };

  const renderFields = () => {
    return Object.keys(dynamicFields).map((key) => {
      return renderField(dynamicFields[key], key);
    });
  };

  const renderSpecificDocument = () => {
    if (isEmpty(documentTemplate)) return null;

    return renderFields();
  };

  const minHeight = () => {
    if (!isEmpty(activeTable)) return '';
    return 'h-[630px]';
  };

  const renderFormLayout = () => {
    if (!isEmpty(activeTable)) return renderTable();
    return renderForm();
  };

  const renderForm = () => {
    if (documentTemplate === '') {
      return <FormLoader text={`Building Form ${dynamicDocumentLabel()}`} />;
    }

    if (updatingDynamicDocument) {
      return <FormLoader text={`Updating ${dynamicDocumentLabel()}`} />;
    }
    if (fetchDynamicDocumentErrors) {
      return <FormLoader />;
    }
    if (isUndefined(dynamicDocumentData) && fetchingDynamicDocumentData) {
      return <FormLoader />;
    }
    return (
      <div>
        {renderSpecificDocument()}
        <DynamicSelect
          dataTestId="status-select"
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          name="status"
          classes="mb-2"
          label="Document Status"
          menuPlacement="top"
          defaultValue={values.status}
          required={true}
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          error={errors.status}
        />
      </div>
    );
  };

  return (
    <div className="px-3 py-2">
      <div
        id="form-container"
        className={`${minHeight()} overflow-hidden overflow-y-scroll`}
      >
        {renderFormLayout()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingDynamicDocument
        }
        label="Save"
        type="primary"
        className="w-full rounded-none mt-4"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default DynamicDocumentForm;
