import {Pagination} from 'flowbite-react';
import {isNil} from 'lodash-es';
import React, {useState} from 'react';

export interface Page {
  page: number;
  cursor: string;
}

export interface Props {
  page: number;
  total: number;
  itemsPerPage: number;
  totalPages: number;
  currentCursor: string;
  onPageClick: (page: number, cursor: string) => void;
  pages: Page[];
  disabled: boolean;
}

const PaginationNew: React.FC<Props> = ({
  total,
  itemsPerPage,
  onPageClick,
  page,
  currentCursor,
  totalPages,
  pages,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(page);

  const handleOnPageChange = (page: number) => {
    if (page === currentPage) return;

    const cursor = pages.find((p: Page) => p && p.page === page)?.cursor || currentCursor;
    if (isNil(cursor)) return;

    setCurrentPage(currentPage);
    onPageClick(page, cursor);
  };

  const renderLeftText = () => {
    return (
      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {(currentPage * itemsPerPage) - (itemsPerPage - 1)}{' '}
        </span>
        to{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {currentPage === totalPages ? total : currentPage * itemsPerPage}{' '}
        </span>
        of{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {total}{' '}
        </span>
        results
      </span>
    );
  };

  return (
    <nav
      className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
      aria-label="Table navigation"
    >
      {renderLeftText()}
      <Pagination
        layout="navigation"
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handleOnPageChange}
        showIcons
      />
    </nav>
  );
};

export default PaginationNew;
