import {
  percentValue,
  positiveNumberWithTwoDecimalPlaces,
} from '../../handsontable/customValidators';
import nationalityList from '../../utils/nationalityList';
import {editableColumnProps} from '../editableTable';

export interface stockholderInformationItem {
  address: string;
  amountPhpPaid: number | null;
  amountPhp: number | null;
  entityType: string;
  name: string;
  nationality: string;
  numberOfShares: number | null;
  percentOwnership: number | null;
  tin: string;
  typeOfShare: string;
}
export const stockholderInformationItemColumns: editableColumnProps[] = [
  {
    name: 'Name',
    config: {
      data: 'name',
    },
  },
  {
    name: 'Entity Type',
    config: {
      data: 'entityType',
      type: 'dropdown',
      source: ['', 'Company', 'Natural Person'],
      allowInvalid: false,
    },
  },
  {
    name: 'Address',
    config: {
      data: 'address',
    },
  },
  {
    name: 'TIN',
    config: {
      data: 'tin',
    },
  },
  {
    name: 'Nationality',
    config: {
      data: 'nationality',
      type: 'dropdown',
      source: [''].concat(nationalityList),
      allowInvalid: false,
    },
  },
  {
    name: 'Type of Share',
    config: {
      data: 'typeOfShare',
      type: 'dropdown',
      source: ['', 'Common', 'Preferred'],
      allowInvalid: false,
    },
  },
  {
    name: 'Number of Shares',
    config: {
      data: 'numberOfShares',
      type: 'numeric',
      allowInvalid: false,
    },
  },
  {
    name: 'Amount (PHP)',
    config: {
      data: 'amountPhp',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowEmpty: false,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Percent Ownership',
    config: {
      data: 'percentOwnership',
      type: 'numeric',
      allowInvalid: false,
      allowEmpty: true,
      validator: percentValue,
    },
  },
  {
    name: 'Amount PHP Paid',
    config: {
      data: 'amountPhpPaid',
      type: 'numeric',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowEmpty: false,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
];
