import {Dialog, Transition} from '@headlessui/react';
import {isNil} from 'lodash-es';
import React, {Fragment, useContext} from 'react';
import {FaWindowClose} from 'react-icons/fa';

import ApplicationContext from '../../../contexts/ApplicationContext';
import MODAL_COMPONENTS from './ModalComponents';

const Modal: React.FC = () => {
  const {modalType, setModalType, modalProps} = useContext(ApplicationContext);

  const hideModal = (): void => {
    setModalType(undefined);
  };

  const ModalComponent = !isNil(modalType)
    ? MODAL_COMPONENTS[modalType]
    : undefined;

  return (
    <Transition.Root show={!isNil(modalType)} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={hideModal}
      >
        <div className="flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block max-w-full align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle">
              <div className="bg-fcBlue text-white px-6 py-4 text-left flex justify-between">
                <div>{!isNil(modalProps) ? modalProps['modalTitle'] : ''}</div>
                <FaWindowClose
                  onClick={hideModal}
                  data-testid="closeModalIcon"
                  className="ml-4 text-white hover:text-fcSecondary-300"
                  size={20}
                />
              </div>
              {!isNil(ModalComponent) && (
                <ModalComponent {...modalProps} hideModal={hideModal} />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
