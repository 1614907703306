import {debounce, isNull, isUndefined} from 'lodash-es';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useUpdateAuditedFinancialStatement} from '../../../../../api/mutations/auditedFinancialStatement';
import {useFetchAuditedFinancialStatement} from '../../../../../api/queries/auditedFinancialStatement';
import {useFetchAuditorSearch} from '../../../../../api/queries/auditorSearch';
import Button from '../../../../../components/common/buttons/Button';
import DataTableToggle from '../../../../../components/common/inputs/DataTableToggle';
import Datepicker from '../../../../../components/common/inputs/Datepicker';
import ApplicationContext from '../../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../../contexts/DocumentsContext';
import useForm from '../../../../../hooks/useForm';
import {
  auditedFinancialStatementDefault,
  informationSourceOptions,
  verificationMethodOptions,
} from '../../../../../interfaces/auditedFinancialStatement';
import {resubmissionReasonOptions} from '../../../../../interfaces/auditedFinancialStatement';
import {buildAuditorOptions} from '../../../../../interfaces/auditors';
import {convertEmptyValuesToNull} from '../../../../../utils/object';
import {showNotification} from '../../../../../utils/toast';
import {getLabelFromOptions} from '../../../../../utils/value';
import {buildValidationSchema} from '../../../../../validations/AuditedFinancialStatement';
import Radio from '../../../inputs/Radio';
import DynamicSelect, {Value} from '../../../inputs/Select';
import {
  YesOrNoBooleanOptions,
  currencyOptions,
  documentStatusOptions,
} from '../../../inputs/select/options';
import TextInput from '../../../inputs/TextInput';
import YearPicker from '../../../inputs/YearPicker';
import BalanceSheetTable from './auditedFinancialStatement/BalanceSheetTable';
import CompanyContactInformationTable from './auditedFinancialStatement/CompanyContactInformationTable';
import ContactPersonInformationTable from './auditedFinancialStatement/ContactPersonInformationTable';
import IncomeStatementTable from './auditedFinancialStatement/IncomeStatementTable';
import StatementOfCashFlowsTable from './auditedFinancialStatement/StatementOfCashFlowsTable';
import FormLoader from './FormLoader';
import {
  defaultInformationSource,
  isTempWorkerAndHasNoAccess,
  limitedDocumentStatusOptions,
} from './utils/HelperFunctions';

const AuditedFinancialStatementForm: React.FC = () => {
  const {lead, permissions} = useContext(ApplicationContext);
  const [tableToggle, setTableToggle] = useState('');
  const {selectedDocument} = useContext(DocumentsContext);
  const {file: uploadedFile} = selectedDocument;

  function submit(): void {
    const {__typename, ...payload} = values;

    if (uploadedFile) payload.attachment = selectedDocument.file;

    updateAuditedFinancialStatement({
      variables: {
        input: {
          ...payload,
          balanceSheet: payload.balanceSheet.map((bs) => {
            const {__typename, ...hash} = bs;
            return convertEmptyValuesToNull(hash);
          }),
          companyContactInformation: payload.companyContactInformation.map(
            (cci) => {
              const {__typename, ...hash} = cci;
              return convertEmptyValuesToNull(hash);
            },
          ),
          contactPersonInformation: payload.contactPersonInformation.map(
            (cpi) => {
              const {__typename, ...hash} = cpi;
              return convertEmptyValuesToNull(hash);
            },
          ),
          leadId: Number(lead.id),
          incomeStatement: payload.incomeStatement.map((ic) => {
            const {__typename, ...hash} = ic;
            return convertEmptyValuesToNull(hash);
          }),
          interestRate: numeral(payload.interestRate).value(),
          revenue: numeral(payload.revenue).value(),
          statementOfCashFlows: payload.statementOfCashFlows.map((socf) => {
            const {__typename, ...hash} = socf;
            return convertEmptyValuesToNull(hash);
          }),
        },
      },
    });
  }

  const onFetchSuccess = (response: any) => {
    if (response.length === 0)
      return showNotification('error', 'Fetch failed.');
    updateValuesAtOnce({
      ...values,
      ...response.document,
    });
  };

  const [
    fetchAuditedFinancialStatement,
    {
      data: AuditedFinancialStatementData,
      loading: fetchingAuditedFinancialStatement,
      error: fetchAuditedFinancialStatementErrors,
    },
  ] = useFetchAuditedFinancialStatement(onFetchSuccess);

  useEffect(() => {
    if (selectedDocument.id) {
      fetchAuditedFinancialStatement({
        variables: {
          documentType: 'AUDITED_FINANCIAL_STATEMENT',
          id: selectedDocument.id,
          leadId: Number(lead.id),
        },
      });
    }
  }, [fetchAuditedFinancialStatement]);

  const [auditorSearchTerm, setAuditorSearchTerm] = useState<Value>('');

  useEffect(() => {
    fetchAuditorSearch({
      variables: {
        searchParameter: auditorSearchTerm,
        specifiedAuditor: false,
      },
    });
  }, [auditorSearchTerm]);

  const [
    fetchAuditorSearch,
    {
      data: auditorSearchData,
      loading: fetchingAuditorSearch,
      error: fetchAuditorSearchErrors,
    },
  ] = useFetchAuditorSearch(() => null);

  const changeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {value} = event.target as HTMLInputElement;

    setAuditorSearchTerm(value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const updateSuccessCallback = (response: any) => {
    if (!isNull(response)) {
      if (
        !isNull(
          response.auditedFinancialStatementUpdate.auditedFinancialStatement,
        )
      ) {
        showNotification('success', 'Audited Financial Statement updated!');
        updateValuesAtOnce({
          ...values,
          ...response.auditedFinancialStatementUpdate.auditedFinancialStatement,
        });
      } else {
        showNotification(
          'error',
          'Failed to update Audited Financial Statement!',
        );
        handleGeneralError(response.auditedFinancialStatementUpdate.errors);
      }
    }
  };

  const [
    updateAuditedFinancialStatement,
    {
      loading: updatingAuditedFinancialStatement,
      error: errorUpdatingAuditedFinancialStatement,
    },
  ] = useUpdateAuditedFinancialStatement(updateSuccessCallback);

  const {
    handleChange,
    values,
    handleSpecificChange,
    handleSubmit,
    errors,
    handleGeneralError,
    updateValuesAtOnce,
  } = useForm({
    defaultState: auditedFinancialStatementDefault,
    runValidationOnEveryChange: true,
    submitAction: submit,
    validationSchema: buildValidationSchema(),
  });

  const {
    balanceSheet,
    companyContactInformation,
    contactPersonInformation,
    incomeStatement,
    statementOfCashFlows,
  } = values;

  const defaultValidUntil = (currentValue: Date | null) => {
    if (!isNull(currentValue)) return currentValue;
    if (!isNull(values.revenueOn)) {
      const defaultValue = new Date(values.revenueOn);
      defaultValue.setFullYear(defaultValue.getFullYear() + 1);
      defaultValue.setMonth(3);
      handleSpecificChange({field: 'validUntil', value: defaultValue});
      return defaultValue;
    }

    return null;
  };

  const renderInputs = () => {
    if (
      isUndefined(AuditedFinancialStatementData) &&
      fetchingAuditedFinancialStatement
    ) {
      return <FormLoader />;
    } else if (updatingAuditedFinancialStatement) {
      return <FormLoader text="Updating Audited Financial Statement" />;
    }

    return (
      <div id="audited-financial-statement-form">
        <DynamicSelect
          classes="my-2"
          dataTestId="information-source-select"
          defaultValue={defaultInformationSource(
            permissions.role,
            values.informationSource,
          )}
          error={errors.informationSource}
          label="Information Source"
          name="informationSource"
          onChange={(value) => {
            handleSpecificChange({field: 'informationSource', value: value});
          }}
          options={informationSourceOptions}
          placeholder="Select"
          required
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="auditor-select"
          defaultValue={values.auditorId}
          error={errors.auditorId}
          isLoading={fetchingAuditorSearch}
          label="Auditor ID"
          name="auditorId"
          onChange={(value) => {
            handleSpecificChange({field: 'auditorId', value: value});
          }}
          onKeyDown={debouncedChangeHandler}
          options={buildAuditorOptions(auditorSearchData)}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <Datepicker
          autoComplete="off"
          classes="mb-2"
          dataTestId="revenue-on-field"
          error={errors.revenueOn}
          label="Fiscal Year End Date"
          name="revenueOn"
          onChange={(value) => {
            handleSpecificChange({field: 'revenueOn', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={values.revenueOn as boolean & (Date | null)}
        />

        <YearPicker
          autoComplete="off"
          classes="mb-2"
          dataTestId="document-year-field"
          error={errors.documentYear}
          label="Filing Year"
          maxDate={new Date()}
          name="documentYear"
          onChange={(value) => {
            handleSpecificChange({field: 'documentYear', value: value});
          }}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
          selected={values.documentYear as boolean & (Date | null)}
        />

        <TextInput
          classes="mb-2"
          data-testid="principal-office-address-field"
          error={errors.principalOfficeAddress}
          label="Principal Office Address"
          name="principalOfficeAddress"
          onChange={handleChange}
          placeholder="Add text"
          value={values.principalOfficeAddress}
        />

        <TextInput
          classes="mb-2"
          data-testid="company-tin-field"
          error={errors.companyTin}
          label="Company TIN"
          name="companyTin"
          numbersOnly
          onChange={handleChange}
          placeholder="Add number"
          type="number"
          value={values.companyTin}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="currency-select"
          defaultValue={values.currency}
          error={errors.currency}
          label="Currency"
          name="currency"
          onChange={(value) => {
            handleSpecificChange({field: 'currency', value: value});
          }}
          options={currencyOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DataTableToggle
          data={companyContactInformation}
          error={errors.companyContactInformation}
          setTableToggle={setTableToggle}
          title="Company Contact Information"
          toggle="companyContactInformation"
        />

        <DataTableToggle
          data={contactPersonInformation}
          error={errors.contactPersonInformation}
          setTableToggle={setTableToggle}
          title="Contact Person Information"
          toggle="contactPersonInformation"
        />

        <TextInput
          classes="mb-2"
          data-testid="revenue-field"
          error={errors.revenue}
          formatNumber
          label="Revenue"
          name="revenue"
          onChange={handleChange}
          placeholder="Add amount"
          required={values.status === 'VERIFIED'}
          type="number"
          value={values.revenue}
        />

        <DataTableToggle
          data={balanceSheet}
          error={errors.balanceSheet}
          required={values.status === 'VERIFIED'}
          setTableToggle={setTableToggle}
          title="Statement of Financial Position"
          toggle="balanceSheet"
        />

        <TextInput
          classes="mb-2"
          data-testid="interest-rate-field"
          error={errors.interestRate}
          formatNumber
          formatPercentage
          integerOnly
          label="Loan Payable Interest Rate"
          name="interestRate"
          onChange={handleChange}
          placeholder="Add percentage"
          preventNegatives
          type="number"
          value={values.interestRate}
        />

        <DataTableToggle
          data={incomeStatement}
          error={errors.incomeStatement}
          required={values.status === 'VERIFIED'}
          setTableToggle={setTableToggle}
          title="Income Statement"
          toggle="incomeStatement"
        />

        <DataTableToggle
          data={statementOfCashFlows}
          error={errors.statementOfCashFlows}
          setTableToggle={setTableToggle}
          title="Statement of Cash Flows"
          toggle="statementOfCashFlows"
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.isSecBirAcknowledged}
          error={errors.isSecBirAcknowledged}
          label="Acknowledged by SEC or BIR?"
          name="isSecBirAcknowledged"
          onChange={(value) => {
            handleSpecificChange({field: 'isSecBirAcknowledged', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required={values.status === 'VERIFIED'}
        />

        <Radio
          classes="mb-2 w-3/4"
          clearable
          defaultValue={values.hasAccreditedAuditor}
          error={errors.hasAccreditedAuditor}
          label="Auditor is Accredited?"
          name="hasAccreditedAuditor"
          onChange={(value) => {
            handleSpecificChange({field: 'hasAccreditedAuditor', value: value});
          }}
          options={YesOrNoBooleanOptions}
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="verification-method-select"
          defaultValue={values.verificationMethod}
          error={errors.verificationMethod}
          label="Verification Method"
          name="verificationMethod"
          onChange={(value) => {
            handleSpecificChange({field: 'verificationMethod', value: value});
          }}
          options={verificationMethodOptions}
          placeholder="Select"
          required={values.status === 'VERIFIED'}
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="resubmission-reason-select"
          defaultValue={values.resubmissionReason}
          error={errors.resubmissionReason}
          label="Resubmission Reason"
          menuPlacement="top"
          name="resubmissionReason"
          onChange={(value) => {
            handleSpecificChange({field: 'resubmissionReason', value: value});
          }}
          options={resubmissionReasonOptions}
          placeholder="Select"
          required={values.status === 'REQUIRES_RESUBMISSION'}
        />

        <Datepicker
          classes="mb-2"
          dataTestId="valid-until-field"
          error={errors.validUntil}
          label="Valid Until"
          name="validUntil"
          onChange={(value) => {
            handleSpecificChange({field: 'validUntil', value: value});
          }}
          placeholder="Date"
          required={values.status === 'VERIFIED'}
          selected={
            defaultValidUntil(values.validUntil) as boolean & (Date | null)
          }
        />

        <DynamicSelect
          classes="mb-2"
          dataTestId="status-select"
          defaultValue={values.status}
          disabled={isTempWorkerAndHasNoAccess(permissions.role, values.status)}
          error={errors.status}
          label="Document Status"
          menuPlacement="top"
          name="documentStatus"
          onChange={(value) => {
            handleSpecificChange({field: 'status', value: value});
          }}
          options={limitedDocumentStatusOptions(permissions.role)}
          placeholder={
            getLabelFromOptions(documentStatusOptions, values.status) ||
            'Select'
          }
          required
        />
      </div>
    );
  };

  const renderFields = () => {
    switch (tableToggle) {
      case 'balanceSheet':
        return (
          <BalanceSheetTable
            data={balanceSheet}
            setTableToggle={setTableToggle}
          />
        );
      case 'companyContactInformation':
        return (
          <CompanyContactInformationTable
            data={companyContactInformation}
            setTableToggle={setTableToggle}
          />
        );
      case 'contactPersonInformation':
        return (
          <ContactPersonInformationTable
            data={contactPersonInformation}
            setTableToggle={setTableToggle}
          />
        );
      case 'incomeStatement':
        return (
          <IncomeStatementTable
            data={incomeStatement}
            setTableToggle={setTableToggle}
          />
        );
      case 'statementOfCashFlows':
        return (
          <StatementOfCashFlowsTable
            data={statementOfCashFlows}
            setTableToggle={setTableToggle}
          />
        );
      default:
        return renderInputs();
    }
  };

  return (
    <div className="px-3 py-2">
      <div
        id="form-container"
        className="h-[630px] overflow-hidden overflow-y-scroll"
      >
        {renderFields()}
      </div>
      <Button
        dataTestId="submit"
        disabled={
          isTempWorkerAndHasNoAccess(permissions.role, values.status) ||
          updatingAuditedFinancialStatement
        }
        label="Save"
        type="primary"
        className="w-full rounded-none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default AuditedFinancialStatementForm;
