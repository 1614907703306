import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {requiredStringField} from './common/fields';

export const buildValidationSchema = () => {
  const shape = {
    // TODO: Add the rest of the validations on the BE ticket
    documentType: requiredStringField({attribute: 'Document Type'}),
    documentAttachment: yup
      .array()
      .required()
      .nullable(true)
      .label('Document Attachment'),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
