import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import type {ServerError, ServerParseError} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';
import type {GraphQLError} from 'graphql';
import {isNil} from 'lodash-es';
import React, {useMemo, useState} from 'react';

import GeneralNetworkFailure from '../components/common/errors/GeneralNetworkFailure';
import GraphQLFailure from '../components/common/errors/GraphQLFailure';
import UnauthenticatedAccess from '../components/common/errors/UnauthenticatedAccess';
import UnauthorizedAccess from '../components/common/errors/UnauthorizedAccess';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const Provider: React.FC<Props> = ({children}) => {
  const [unauthenticated, setUnauthenticated] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [graphQLFailed, setGraphQLFailed] = useState<boolean>(false);
  const [graphQLErrors, setGraphQLErrors] = useState<readonly GraphQLError[]>(
    [],
  );
  const [generalNetFailed, setGeneralNetFailed] = useState<boolean>(false);
  const [netError, setNetError] = useState<
    Error | ServerError | ServerParseError | undefined
  >(undefined);

  const authLink = setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (!isNil(networkError)) {
      setNetError(networkError);
      if (
        networkError.message ===
        'Response not successful: Received status code 401'
      )
        return setUnauthenticated(true);
      if (
        networkError.message ===
        'Response not successful: Received status code 403'
      )
        return setUnauthorized(true);

      return setGeneralNetFailed(true);
    }
    if (!isNil(graphQLErrors)) {
      setGraphQLErrors(graphQLErrors);
      setGraphQLFailed(true);
    }
  });

  // By default, this will include needed cookies that
  // we are sharing in .firstcircle.ph as part of the authentication.
  // Please change if needed
  const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_BASE_API_URI}`,
    credentials: 'include',
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: errorLink.concat(authLink.concat(httpLink)),
        cache: new InMemoryCache({
          typePolicies: {
            CreditLimit: {
              keyFields: ['leadId'],
            },
            Lead: {
              keyFields: ['id'],
            },
          },
        }),
      }),
    [unauthorized, unauthenticated],
  );

  if (unauthenticated) return <UnauthenticatedAccess />;
  if (unauthorized) return <UnauthorizedAccess />;
  if (generalNetFailed) return <GeneralNetworkFailure error={netError} />;
  if (graphQLFailed) return <GraphQLFailure errors={graphQLErrors} />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
