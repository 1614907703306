import React, {useContext} from 'react';

import ApplicationContext from '../../../../contexts/ApplicationContext';
import DocumentsContext from '../../../../contexts/DocumentsContext';
import {Document} from '../../../../interfaces/documents';
import Button from '../../buttons/Button';

interface Props {
  document: Document;
}

const DocumentViewerButton: React.FC<Props> = ({document}) => {
  const {setSelectedDocument} = useContext(DocumentsContext);
  const {setShowSelectedDocument} = useContext(ApplicationContext);

  const showDocument = (document: Document) => {
    setSelectedDocument(document);
    setShowSelectedDocument(true);
  };

  const docRequired = !['CMAP_REPORT', 'LOANDEX_REPORT'].includes(
    document.documentType,
  );

  if (document.fileName === '' && docRequired) {
    return (
      <span className="items-center justify-left text-sm content-center text-gray-600 mr-2">
        No document uploaded
      </span>
    );
  } else {
    return (
      <Button
        className="h-6"
        onClick={() => showDocument(document)}
        dataTestId="viewDocumentBtn"
        label="View Document"
        type="bordered_primary"
        micro
      />
    );
  }
};

export default DocumentViewerButton;
