import {format} from 'date-fns';

import {positiveNumberWithTwoDecimalPlaces} from '../../handsontable/customValidators';
import {editableColumnProps} from '../editableTable';

export interface investmentInOtherCorporationItem {
  amountPhp: number | null;
  assetType: string;
  dateOfBoardResolution: Date | null;
}

export const investmentInOtherCorporationItemColumns: editableColumnProps[] = [
  {
    name: 'Asset Type',
    config: {
      data: 'assetType',
      type: 'dropdown',
      source: [
        'Stocks',
        'Bonds',
        'Commercial Paper',
        'Loans/Credits/Advances',
        'Government Treasury Bills',
        'Others',
      ],
      allowInvalid: false,
      allowEmpty: true,
    },
  },
  {
    name: 'Amount (PHP)',
    config: {
      type: 'numeric',
      data: 'amountPhp',
      numericFormat: {
        pattern: 'PHP 0,0.00',
        culture: 'en-US',
      },
      allowEmpty: false,
      allowInvalid: false,
      validator: positiveNumberWithTwoDecimalPlaces,
    },
  },
  {
    name: 'Date of Board Resolution',
    config: {
      data: 'dateOfBoardResolution',
      type: 'date',
      dateFormat: 'MM/DD/YYYY',
      correctFormat: true,
      defaultDate: format(new Date(), 'MM/dd/yyy'),
      allowInvalid: false,
      allowEmpty: true,
    },
  },
];
