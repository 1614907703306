import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_LOANDEX = gql`
  query Document($leadId: Int!, $id: ID!, $documentType: DocumentTypeEnum!) {
    document(leadId: $leadId, id: $id, documentType: $documentType) {
      createdAt
      id
      leadId
      riskopsNotes
      status
      ... on LoandexReport {
        aliasName
        companyId
        dtiNumber
        entityName
        entityType
        gsisNumber
        hasNoFindings
        inquiredOn
        inquiringBankName
        inquiringBankNumber
        ownershipShare
        personId
        report {
          amountOtherCurrency
          amountPhp
          balanceDate
          balanceType
          comment
          contingentBalance
          currencyType
          currentBalance
          dateGranted
          dueDate
          experienceCode
          expiryDate
          facilityCode
          facilityNo
          inquiryDate
          itemsInLitigation
          pastDueBalance
          respondingBankFi
          securityCode
          securityName
        }
        reportGenerator
        secNumber
        sssNumber
        subjectNumber
        tin
        validUntil
      }
    }
  }
`;

export function useFetchLoanDex(onSuccessCallback: any) {
  return useLazyQuery(FETCH_LOANDEX, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
