import {isNil, omitBy} from 'lodash-es';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import {requiredBooleanField, requiredStringField} from './common/fields';

export const buildValidationSchema = () => {
  const shape = {
    // TODO: Add the rest of the validations on the BE ticket
    entityName: requiredStringField({attribute: 'Subject Name'}),
    entityType: requiredStringField({attribute: 'Entity Type'}),
    hasNoFindings: requiredBooleanField({attribute: 'Has no findings?'}),
    inquiringBankName: requiredStringField({attribute: 'Inquiring Bank Name'}),
    inquiringBankNumber: requiredStringField({
      attribute: 'Inquiring Bank Number',
    }),
    ownershipShare: yup
      .number()
      .positive('Value must be a positive number')
      .min(0)
      .max(100)
      .nullable(),
    createdAt: requiredStringField({attribute: 'Report Timestamp'}),
    status: requiredStringField({attribute: 'Document Status'}),
  };
  return yup.object().shape(omitBy(shape, isNil));
};
