import {gql, useMutation} from '@apollo/client';

export const UPDATE_FUTURE_REVENUE = gql`
  mutation futureRevenueUpdate($futureRevenue: FutureRevenueAttribute!) {
    futureRevenueUpdate(input: $futureRevenue) {
      futureRevenue {
        id
        leadId
        informationSource
        supplierCompanyId
        supplierSignatoryPersonId
        buyer {
          ... on Buyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Company {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on DimBuyer {
            id
            gid
            name
            regNum
            buyerAs
          }
          ... on Lead {
            id
            gid
            name
            buyerAs
          }
        }
        buyerSignatoryPersonId
        primaryDocumentIdentifier
        projectIdentifier
        primaryDocumentType
        supportingDocumentTypes
        currency
        totalTradeAmount
        unpaidTradeAmount
        projectTradeAmount
        issuedOn
        deliveryDate
        paymentTermsInDays
        verifiedPaidOn
        lineItems {
          quantity
          unit
          itemName
          harmonizedSystemCode
          unitPrice
          amount
        }
        isFileClear
        isFileComplete
        isBuyerStamped
        isBuyerSigned
        isSuspectedTampered
        isSuspectedForged
        verificationMethod
        resubmissionReason
        validUntil
        status
      }
      errors
    }
  }
`;

export function useFutureRevenue(onSuccessCallback: (response: any) => void) {
  return useMutation(UPDATE_FUTURE_REVENUE, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
  });
}
