import {isEmpty} from 'lodash-es';

export const positiveIntegerRegex = /^[1-9]+[0-9]*$/;
export const percentValueRegex = /^[0-9][0-9]?$|100$/;
export const alphanumericValueRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;

export const emailValidator = (value, callback) => {
  if (/.+@.+/.test(value) || isEmpty(value)) {
    callback(true);
  } else {
    callback(false);
  }
};

export const telephoneNumberValidator = (value, callback) => {
  if (
    !isNaN(value) &&
    (value.length === 7 || value.length === 8 || isEmpty(value))
  ) {
    callback(true);
  } else {
    callback(false);
  }
};

export const mobileNumberValidator = (value, callback) => {
  if ((!isNaN(value) && value.length === 11) || isEmpty(value)) {
    callback(true);
  } else {
    callback(false);
  }
};

export const positiveIntegerOnly = (
  value: string,
  callback: (value: boolean) => void,
) => {
  if (positiveIntegerRegex.test(value) || isEmpty(value)) {
    callback(true);
  } else {
    callback(false);
  }
};

export const positiveNumberWithTwoDecimalPlaces = (
  value: number,
  callback: (value: boolean) => void,
) => {
  if (isFinite(value) && value >= 0) {
    const valueInString = value.toString();
    const decimalValue = valueInString.split('.')[1] || '00';

    decimalValue.length > 2 ? callback(false) : callback(true);
  } else {
    callback(false);
  }
};

export const percentValue = (
  value: number,
  callback: (value: boolean) => void,
) => {
  if (percentValueRegex.test(value.toString()) || value.toString() === '') {
    callback(true);
  } else {
    callback(false);
  }
};
export const alphanumericValue = (
  value: string,
  callback: (value: boolean) => void,
) => {
  callback(alphanumericValueRegex.test(value) || value == '');
};

export const notEmptyValidator = (value, callback) => {
  if (!value || value.trim() === '') {
    callback(false);
  } else {
    callback(true);
  }
};
