import {RouteComponentProps} from '@reach/router';
import {isNil} from 'lodash';
import React, {useState} from 'react';
import {FaExclamationTriangle} from 'react-icons/fa';

import Button from '../buttons/Button';

type Props = RouteComponentProps;

const UnauthenticatedAccess: React.FC<Props> = () => {
  const [navigating, setNavigating] = useState<boolean>(false);

  const handleLoginClick = () => {
    if (!isNil(process.env.REACT_APP_FCC_ABSOLUTE_URI)) {
      window.location.href = process.env.REACT_APP_FCC_ABSOLUTE_URI;
    }
    setNavigating(true);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
      <div className="sm:block md:flex">
        <div className="pr-4 md:border-r-2 border-gray-200">
          <div className="hidden md:block text-primary-500">
            <FaExclamationTriangle size="60" className="mb-3 mx-auto" />
          </div>
          <h2 className="uppercase text-5xl text-primary-500 font-bold mb-3">
            401
          </h2>
        </div>

        <div className="md:pl-5">
          <h2 className="uppercase lg:text-3xl font-bold text-gray-500">
            Unauthenticated Access
          </h2>
          <p className="text-sm lg:text-base text-gray-400">
            You do not have access on this page. Please login to continue.
          </p>
          <div className="mt-4 w-full">
            <Button
              isLoading={navigating}
              type="primary"
              label="Login"
              dataTestId="login-button"
              onClick={handleLoginClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthenticatedAccess;
