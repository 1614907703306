import {gql, useLazyQuery} from '@apollo/client';

// TODO: Move permissions once admin_user has its own query
export const FETCH_BUYER_SIGNATORY = gql`
  query signatoryPersonIdOptions(
    $searchParameter: String!
    $specifiedSignatoryPerson: Boolean!
  ) {
    signatoryPersonIdOptions(
      searchParameter: $searchParameter
      specifiedSignatoryPerson: $specifiedSignatoryPerson
    ) {
      value
      label
    }
  }
`;

export function useFetchSignatoryPersonSearch(onSuccessCallback: any) {
  return useLazyQuery(FETCH_BUYER_SIGNATORY, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
