import React from 'react';

import {
  Document,
  DocumentsContext,
  Pagination,
  documentDefault,
} from '../interfaces/documents';

export const defaultContextValue: DocumentsContext = {
  activeDocumentGroup: '',
  documents: [],
  documentStatus: null,
  documentStatuses: [],
  startDate: new Date(),
  endDate: new Date(),
  entityType: null,
  latestSubmittedDocuments: [],
  pagination: {
    first: null,
    before: null,
    last: null,
    after: null,
  },
  paginationDetails: [],
  pageNum: 1,
  selectedDocument: documentDefault,
  setActiveDocumentGroup: () => null,
  setDocuments: (documents: Document[]) => null,
  setDocumentStatus: (status: string | null) => null,
  setDocumentStatuses: (documents: string[]) => null,
  setStartDate: (date: Date) => null,
  setEndDate: (date: Date) => null,
  setEntityType: (value: string | null) => null,
  setLatestSubmittedDocuments: () => null,
  setPaginationDetails: (details: Pagination[]) => null,
  setPagination: (pagination: Pagination) => null,
  setPageNum: (pageNum: number) => null,
  setSelectedDocument: (setSelectedDocument: Document) => null,
  defaultStartDate: new Date(),
  defaultEndDate: new Date(),
  uploadableDocuments: [],
};

const DocumentsContext = React.createContext<DocumentsContext>(
  defaultContextValue,
);

export default DocumentsContext;
