import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_BANK_OPTIONS = gql`
  query bankIdOptions($searchParameter: String, $specifiedBank: Boolean) {
    bankIdOptions(
      searchParameter: $searchParameter
      specifiedBank: $specifiedBank
    ) {
      value
      label
    }
  }
`;

export function useFetchBankOptions(onSuccessCallback: any) {
  return useLazyQuery(FETCH_BANK_OPTIONS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
