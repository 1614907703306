import {gql, useLazyQuery} from '@apollo/client';

export const FETCH_DOCUMENTS = gql`
  query Documents(
    $leadId: Int!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: DocumentsFilterAttribute
    $documentType: DocumentTypeEnum
  ) {
    documents(
      leadId: $leadId
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      documentType: $documentType
    ) {
      nodes {
        id
        documentType
        leadId
        status
        createdAt
        updatedAt
        updatedBy
        fileName
        filePath
        fileType
        supportingDocumentPath

        ... on AuditedFinancialStatement {
          interestRate
          documentYear
          informationSource
          validUntil
        }

        ... on BankStatement {
          bankIdentifier
          informationSource
          statementEndDate
          statementStartDate
          validUntil
        }

        ... on GeneralInformationSheet {
          gisYear
          informationSource
          validUntil
        }

        ... on IncomeTaxReturn {
          fiscalYearEndDate
          validUntil
          informationSource
        }

        ... on LoandexReport {
          entityName
          entityType
          validUntil
        }

        ... on NFISReport {
          entityName
          entityType
          validUntil
        }

        ... on FutureRevenue {
          buyer {
            ... on Buyer {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on Company {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on DimBuyer {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on Lead {
              id
              gid
              name
              buyerAs
            }
          }
          informationSource
          issuedOn
          unpaidTradeAmount
          validUntil
          currency
        }

        ... on TradingRelationship {
          buyer {
            ... on Buyer {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on Company {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on DimBuyer {
              id
              gid
              name
              regNum
              buyerAs
            }
            ... on Lead {
              id
              gid
              name
              buyerAs
            }
          }
          id
          informationSource
          timePeriod
          documentFormat
          fcCustomerTin
          fcCustomerAddress
          lineItemsState
          validUntil
        }

        ... on DynamicDocument {
          data
        }

        ... on CMAPReport {
          entityName
          entityType
          validUntil
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      totalPages
      pages {
        after
        before
        first
        last
      }
    }
    latestDocuments(leadId: $leadId) {
      id
      documentType
      createdAt
      status
      updatedAt
    }
    documentStatuses
  }
`;

export function useFetchDocuments(onSuccessCallback: any) {
  return useLazyQuery(FETCH_DOCUMENTS, {
    onCompleted: onSuccessCallback,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
}
