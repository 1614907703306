import {SelectOption} from '../../../../interfaces/application';

export const emptyOption: SelectOption = {
  label: 'Select',
  value: null,
};

export const currencyOptions: SelectOption[] = [
  emptyOption,
  {label: 'PHP', value: 'PHP'},
  {label: 'USD', value: 'USD'},
];

export const YesOrNoBooleanOptions: SelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const YesOrNoTextOptions: SelectOption[] = [
  {label: 'Yes', value: 'TRUE'},
  {label: 'No', value: 'FALSE'},
];

export const documentStatusOptions: SelectOption[] = [
  {label: 'Submitted', value: 'SUBMITTED'},
  {label: 'In Processing', value: 'IN_PROCESSING'},
  {label: 'Verified', value: 'VERIFIED'},
  {label: 'Rejected', value: 'REJECTED'},
  {label: 'Requires Resubmission', value: 'REQUIRES_RESUBMISSION'},
  {label: 'Out of Date', value: 'OUT_OF_DATE'},
];

export const informationSourceOptions: SelectOption[] = [
  emptyOption,
  {label: 'Customer', value: 'Customer'},
  {label: 'SEC', value: 'SEC'},
];
